import React from "react";

const UnlinkButton = (props) => (
  <div {...props} className="icon-and-text">
    <i class="icon bi bi-link-45deg"></i>
    Unlink
  </div>
);

export default UnlinkButton;
