import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

export default function UpdateNestedPortfolioStatusConfirmation({
  isOpen,
  onHide,
  onSubmit,
  nestingPortfolioCurrentStatus,
}) {
  const handleSubmit = (e) => {
    onSubmit();
  };

  const handleCancel = (e) => {
    onHide();
  };

  return (
    <Modal show={isOpen} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Onward Nesting Change Confirmation</Modal.Title>
        <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={onHide}></button>
      </Modal.Header>
      <Form>
        <Modal.Body>
          {nestingPortfolioCurrentStatus === "Yes" ? (
            <p>
              Removing Onward nesting will restrict nested portfolio users to
              see the portfolio activities.
            </p>
          ) : (
            <p>
              Onward nesting is giving access to users of nested portfolio(s)
            </p>
          )}
          Are you really want to change onward nesting?
        </Modal.Body>
        <Modal.Footer>
          <Button className="button primary" onClick={handleSubmit}>
            Change
          </Button>
          <Button className="button tertiary" onClick={handleCancel}>
            Cancel
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
