import React from "react";
import IndicatorTotalsTable from "./IndicatorTotalsTable";

export default function IndicatorTotals({ result }) {
  return (
    <>
      <h4 className="h4 mb-1">Indicators</h4>
      {result.indicators && result.indicators.length ? (
        result.indicators.map((i) => (
          <IndicatorTotalsTable key={i.id} result={result} indicator={i} />
        ))
      ) : (
        <p>
          There are no indicators for this result
        </p>
      )}
    </>
  );
}
