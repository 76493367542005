import React from "react";

const ManageResultPopover = React.forwardRef((props, ref) => {
  return (
    <div class="actions mb-4">
      <div class="icon-and-text" onClick={props.onClickEdit}>
        <i class="icon bi bi-pencil-square"></i>Edit
      </div>
      <div class="icon-and-text has-left-border" onClick={props.onClickDelete}>
        <i class="icon bi bi-trash"></i>Delete
      </div>
    </div>
  );
});

export default ManageResultPopover;
