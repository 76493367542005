import { useCallback } from "react";
import callApi from "../Helpers/callApi";
import { useAddToast } from "../Context/ToastContext";
import { GetErrors } from "../Helpers/errors";

export default function useResultService() {
  // useCallback needed to memoise toast hook
  const addToast = useCallback(useAddToast(), []);

  // useCallback needed to memoise fetchers used in useEffect hooks
  const getResults = useCallback(async () => {
    const response = await callApi(`results`);
    const data = await response.json();

    if (!response.ok) {
      addToast({
        variant: "error",
        message: "There was an error fetching the results",
      });
    }

    return { status: response.status, ok: response.ok, data };
  }, [addToast]);

  const getResult = useCallback(
    async (resultId) => {
      const response = await callApi(`results/${resultId}`);
      const data = await response.json();

      if (!response.ok) {
        addToast({
          variant: "error",
          message: "There was an error fetching the result",
        });
      }
      return { status: response.status, ok: response.ok, data };
    },
    [addToast]
  );

  const updateResult = async (result) => {
    const response = await callApi(`results/${result.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(result),
    });
    const data = await response.json();

    if (!response.ok) {
      let errors = GetErrors(data);
      addToast({
        variant: "error",
        message: errors.join(". "),
      });
    }

    return { status: response.status, ok: response.ok, data };
  };

  const deleteResult = async (resultId) => {
    const response = await callApi(`results/${resultId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      addToast({
        variant: "error",
        message: "There was an error deleting the result",
      });
    }

    return { status: response.status, ok: response.ok };
  };

  const getIndicators = useCallback(
    async (resultId, portfolioId) => {
      const response = await callApi(
        `results/${resultId}/indicators/${portfolioId}`
      );
      const data = await response.json();

      if (!response.ok) {
        addToast({
          variant: "error",
          message: "There was an error fetching the indicators for this result",
        });
      }

      return { status: response.status, ok: response.ok, data };
    },
    [addToast]
  );

  const linkIndicator = async (resultId, indicatorId) => {
    const response = await callApi(
      `results/${resultId}/indicators/${indicatorId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      addToast({
        variant: "error",
        message: "There was an error linking the indicator to this result",
      });
    }

    return { status: response.status, ok: response.ok };
  };

  const unlinkIndicator = async (resultId, indicatorId) => {
    const response = await callApi(
      `results/${resultId}/indicators/${indicatorId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      addToast({
        variant: "error",
        message: "There was an error unlinking the indicator from this result",
      });
    }

    return { status: response.status, ok: response.ok };
  };

  const addPeriod = async (
    resultId,
    indicatorId,
    period,
    shouldCreateRecordCombinations
  ) => {
    const response = await callApi(
      `results/${resultId}/indicators/${indicatorId}/periods?shouldCreateRecordCombinations=${shouldCreateRecordCombinations}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(period),
      }
    );

    if (!response.ok) {
      addToast({
        variant: "error",
        message: "There was an error adding a period",
      });
    }

    return { status: response.status, ok: response.ok };
  };

  const getIndicatorSummary = useCallback(
    async (resultId, indicatorId, portfolioId) => {
      const response = await callApi(
        `results/${resultId}/indicators/${indicatorId}/summary/${portfolioId}`
      );
      const data = await response.json();

      if (!response.ok) {
        addToast({
          variant: "error",
          message: "There was an error fetching the indicator summary",
        });
      }

      return { status: response.status, ok: response.ok, data };
    },
    [addToast]
  );

  const getIndicatorTotals = useCallback(
    async (resultId, indicatorId, portfolioId) => {
      const response = await callApi(
        `results/${resultId}/indicators/${indicatorId}/totals/${portfolioId}`
      );
      const data = await response.json();

      if (!response.ok) {
        addToast({
          variant: "error",
          message: "There was an error fetching the indicator totals",
        });
      }

      return { status: response.status, ok: response.ok, data };
    },
    [addToast]
  );

  const removeSubResult = async (result, subResult) => {
    const response = await callApi(`results/${result.id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify([
        {
          op: "replace",
          path: "/subResults",
          value: result.subResults.filter((sr) => sr.id !== subResult.id),
        },
      ]),
    });

    if (!response.ok) {
      addToast({
        variant: "error",
        message: "There was an error when removing the subresult",
      });
    }

    return { status: response.status, ok: response.ok };
  };

  const addSubResults = async (result, subResults) => {
    const response = await callApi(`results/${result.id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify([
        {
          op: "replace",
          path: "/subResults",
          value: [...result.subResults, ...subResults],
        },
      ]),
    });

    if (!response.ok) {
      addToast({
        variant: "error",
        message: "There was an error when adding the subresults",
      });
    }

    return { status: response.status, ok: response.ok };
  };

  const addResultToWrapperResult = async (result, subResults) => {
    const response = await callApi(
      `results/${result.id}/AddtoWrapperResultGroup`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(subResults),
      }
    );

    if (!response.ok) {
      addToast({
        variant: "error",
        message: "There was an error when adding the subresults",
      });
    }

    return { status: response.status, ok: response.ok };
  };

  const removeResultFromWrapperResult = async (result, subResults) => {
    const response = await callApi(
      `results/${result.id}/RemoveFromWrapperResultGroup`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(subResults),
      }
    );

    if (!response.ok) {
      addToast({
        variant: "error",
        message: "There was an error when removing the subresults",
      });
    }

    return { status: response.status, ok: response.ok };
  };

  const getIndicatorLastUpdateDate = useCallback(
    async (indicatorId, portfolioId) => {
      const response = await callApi(
        `indicators/${indicatorId}/lastupdateddate/${portfolioId}`
      );
      const data = await response.json();

      if (!response.ok) {
        addToast({
          variant: "error",
          message: "There was an error fetching the indicator summary",
        });
      }

      return { status: response.status, ok: response.ok, data };
    },
    [addToast]
  );

  return {
    getResults,
    getResult,
    deleteResult,
    updateResult,
    getIndicators,
    linkIndicator,
    unlinkIndicator,
    addPeriod,
    getIndicatorSummary,
    getIndicatorTotals,
    removeSubResult,
    addSubResults,
    addResultToWrapperResult,
    removeResultFromWrapperResult,
    getIndicatorLastUpdateDate,
  };
}
