import React from "react";


export default function PortfolioListItem({ portfolio }) {
    return (
        <div className="item">
            <a href={`/${portfolio.id}`}>
                <div className="item-info">
                    <i class="icon bi bi-folder"></i>
                    {portfolio.description ? (
                        <>
                            <span className="title tooltip">{portfolio.title}
                                <i class="tooltip-icon bi bi-info-circle ms-2"></i>
                                <div class="right">
                                    <p className="item-description">{portfolio.description}</p>
                                    <i></i>
                                </div>
                            </span>
                        </>
                    ) : (
                        <span className="title">{portfolio.title}</span>
                    )}
                    <div className="icon-and-text margin-left-auto">
                        <i class="icon bi-arrow-right-circle"></i>
                        View Portfolio
                    </div>
                </div>
            </a>
        </div>
    );
}
