import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

export default function ContactOwnerForDeleteDisaggregation({
  isOpen,
  onHide,
  onSubmit,
  portfolioTitle,
  disaggregationTitle,
}) {
  const handleSubmit = (e) => {
    onSubmit();
  };

  const handleCancel = (e) => {
    onHide();
  };

  return (
    <Modal show={isOpen} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Contact Owner</Modal.Title>
      </Modal.Header>
      <Form>
        <Modal.Body>
          <p>
            {disaggregationTitle} is being in use at {portfolioTitle}.
          </p>

          <p>
            Do you still want to use in your portfolio? otherwise we will delete
            the evidence.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button className="button primary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button className="button tertiary" onClick={handleSubmit}>
            Sent Email
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
