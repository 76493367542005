import React from "react";
import Spinner from "react-bootstrap/Spinner";

const LoginLogoutHeader = (props) => (
    <>
        <div className="page-title">
            <div className="title">
                <nav className="breadcrumbs">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item active" aria-current="page">REX Authentication</li>
                    </ol>
                </nav>
                <h1 className="h1">{props.pageName}</h1>
            </div>
        </div>
        <h4 className="h4 mb-4">{props.message}</h4>
        {props.spinner && (
            <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        )}
    </>
);

export default LoginLogoutHeader;