import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { ToggleButtonGroup } from "react-bootstrap";
import { ToggleButton } from "react-bootstrap";
import Button from "react-bootstrap/Button";

export default function NestedPortfolioModalConfirmation({
  isOpen,
  nestedPortfolioActivities,
  onHide,
  onSubmit,
  portfolioUniqueTitle,
  portfolioTitle,
  currentPortfolioTitle,
}) {
  const [value, setValue] = useState("");
  const handleSubmit = (e) => {
    onSubmit(portfolioUniqueTitle, value, true);
  };

  const handleCancel = (e) => {
    onHide();
  };

  const handleChange = (val) => {
    setValue(val);
  };

  return (
    <Modal show={isOpen} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>Nesting Portfolio Confirmation</Modal.Title>
        <button
          type="button"
          className="btn-close btn-close-white"
          aria-label="Close"
          onClick={onHide}
        ></button>
      </Modal.Header>
      <Form>
        <Modal.Body>
          <p>
            You are nesting {currentPortfolioTitle} in {portfolioTitle}
          </p>
          <p>
            Portfolio {portfolioTitle} may also be included in other portfolios.
            Do you want users of those 'upstream' portfolios to see the contents
            of this portfolio, or just users who have user access to this
            portfolio?
          </p>

          <ToggleButtonGroup
            type="radio"
            name="options"
            bsPrefix="d-block mb-4"
            onChange={handleChange}
          >
            <ToggleButton id="tbg-radio-1" value="Yes" bsPrefix="radio-button d-block">
              Yes - all onward nesting users can view this portfolio
              <span className="checkmark"></span>
            </ToggleButton>
            <ToggleButton id="tbg-radio-2" value="Parent and child users only" bsPrefix="radio-button d-block">
              No - only users with access to this portfolio and the portfolios
              it is directly nested in can view contents
              <span className="checkmark"></span>
            </ToggleButton>
          </ToggleButtonGroup>
          {/*nestedPortfolioActivities.length === 0 && (
            <p>
              Users of {portfolioTitle} will be able to see everything in{" "}
              {currentPortfolioTitle}
            </p>
          )*/}

          <p>Allowing users to view is expanding access</p>
          <p>
            All owners of this portfolio will be notified by email that the
            portfolio has been included
          </p>
          <p>Do you still wish to include?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="button primary"
            disabled={value === ""}
            onClick={handleSubmit}
          >
            Share
          </Button>
          <Button className="button tertiary" onClick={handleCancel} type="button">
            Cancel
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
