import React, { useState, useEffect } from "react";
import useResultService from "../Services/useResultService";
import moment from "moment";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import Collapsible from "../Shared/Collapsible";
import { useParams } from "react-router-dom";

import { BsDash } from "react-icons/bs";

export default function QualitativeIndicatorListItem({
  indicator,
  result,
  indicatorUrl,
  isUserActivityOwner,
  isUserPortfolioOwner,
  isSharedActivity,
  onRemoveIndicator,
}) {
  const [summaryData, setSummaryData] = useState({});
  const [indicatorLastUpdateDate, setIndicatorLastUpdateDate] = useState(null);
  const { getIndicatorSummary, getIndicatorLastUpdateDate } =
    useResultService();
  const { portfolioId } = useParams();
  useEffect(() => {
    const fetchResults = async () => {
      const response = await getIndicatorSummary(
        result.id,
        indicator.id,
        portfolioId
      );
      if (response.ok) {
        setSummaryData(response.data);
      }
    };

    fetchResults();
  }, [result.id, indicator.id, getIndicatorSummary, portfolioId]);

  useEffect(() => {
    const fetchResults = async () => {
      const response = await getIndicatorLastUpdateDate(
        indicator.id,
        portfolioId
      );
      if (response.ok) {
        setIndicatorLastUpdateDate(response.data.lastUpdated);
      }
    };

    fetchResults();
  }, [indicator.id, getIndicatorLastUpdateDate, portfolioId]);

  return (
    <>
      <Collapsible label={indicator.title} className="collapsible-basic">
        <div>
          <div className="mt-4 mb-4">
            <Link to={indicatorUrl} className="icon-and-text">
              <i class="icon bi bi-card-list"></i>
              View Indicator
            </Link>
            {(isUserActivityOwner || isUserPortfolioOwner) &&
              !isSharedActivity && (
                <div
                  className="icon-and-text has-left-border"
                  onClick={onRemoveIndicator}
                >
                  <i class="icon bi bi-trash"></i>Remove
                </div>
              )}
          </div>
          {indicatorLastUpdateDate != null && (
            <div className="mt-3 mb-2">
              <small className="text-secondary">
                Last Updated{" "}
                {moment(indicatorLastUpdateDate).format("Do MMM YYYY HH:mm")}
              </small>
            </div>
          )}
          <Table size="sm" responsive bordered className="my-2">
            <thead>
              <tr>
                <th />
                <th>All Periods</th>
                {(summaryData.periodSummaries || []).map((ps) => (
                  <th
                    key={ps.periodId}
                    className="text-center;"
                    title={
                      moment(ps.start).format("Do MMM YYYY") +
                      " - " +
                      moment(ps.end).format("Do MMM YYYY")
                    }
                  >
                    {ps.title
                      ? ps.title
                      : moment(ps.start).format("MM/YY") +
                        " - " +
                        moment(ps.end).format("MM/YY")}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Targets</td>
                <td className="bg-light">
                  {summaryData.areAllTargetsAchieved != null
                    ? summaryData.areAllTargetsAchieved
                      ? "All achieved"
                      : "Not all achieved"
                    : "Not set"}
                </td>
                {(summaryData.periodSummaries || []).map((ps) => (
                  <td key={ps.periodId}>
                    <div
                      className="d-flex align-items-center"
                      title={ps.isTargetAchieved}
                    >
                      {ps.isTargetAchieved != null &&
                        ps.isTargetAchieved === 1 && (
                          <i class="target-icon text-color-success bi bi-check2-all"></i>
                        )}
                      {ps.isTargetAchieved != null &&
                        ps.isTargetAchieved === 2 && (
                          <i class="target-icon text-color-success bi bi-check2"></i>
                        )}
                      {ps.isTargetAchieved != null &&
                        ps.isTargetAchieved === 3 && (
                          <i class="target-icon text-color-warning bi bi-circle-half"></i>
                        )}
                      {ps.isTargetAchieved != null &&
                        ps.isTargetAchieved === 4 && (
                          <i class="target-icon text-color-error bi bi-x-circle"></i>
                        )}
                      <span className="ml-1">{ps.isTargetAchievedText}</span>
                    </div>
                  </td>
                ))}
              </tr>
              <tr>
                <td>Trend</td>
                <td className="bg-light" />
                {(summaryData.periodSummaries || []).map((ps) => (
                  <td>
                    <div
                      className="d-flex align-items-center"
                      title={ps.trendFromPreviousPeriodText}
                    >
                      {ps.trendFromPreviousPeriod != null &&
                        ps.trendFromPreviousPeriod === 1 && (
                          <i class="target-icon text-color-success bi bi-caret-up-fill"></i>
                        )}
                      {ps.trendFromPreviousPeriod != null &&
                        ps.trendFromPreviousPeriod === 2 && <BsDash />}
                      {ps.trendFromPreviousPeriod != null &&
                        ps.trendFromPreviousPeriod === 3 && (
                          <i class="target-icon text-color-error bi bi-caret-down-fill"></i>
                        )}
                      <span className="ml-1">
                        {ps.trendFromPreviousPeriodText}
                      </span>
                    </div>
                  </td>
                ))}
              </tr>
              <tr>
                <td>Records</td>
                <td className="bg-light">
                  {(summaryData.periodSummaries || []).reduce(
                    (acc, ps) => acc + ps.recordCount,
                    0
                  )}{" "}
                  records
                </td>
                {(summaryData.periodSummaries || []).map((ps) => (
                  <td>
                    <div>{ps.recordCount} records</div>
                  </td>
                ))}
              </tr>
            </tbody>
          </Table>
        </div>
      </Collapsible>
    </>
  );
}
