import React from "react";
import ManageIndicatorPopover from "../ManageIndicatorPopover";
import Badge from "react-bootstrap/Badge";
import { Link, useRouteMatch } from "react-router-dom";

export default function IndicatorListItemLink(props) {
  const {
    indicator,
    onClickDeleteIndicator,
    onClickEditIndicator,
    onClickShareIndicator,
    isUserPortfolioOwner,
    onClickRemove,
    onClickGotoHome,
    onClickAddToPortfolio,
    onClickAskToShare,
  } = props;
  let match = useRouteMatch();

  return (
    <div className="list-group-item list-group-item-action">
      {indicator.title}

      <Badge variant="secondary" className="mr-2">
        {indicator.isPublic ? "Public" : "Private"}
      </Badge>

      <div className="activity-actions">
        <Link
          className="icon-and-text margin-left-auto"
          to={`${match.url}/${indicator.id}`}
        >
          <i class="icon bi-arrow-right-circle"></i>
          View Indicator
        </Link>
        <ManageIndicatorPopover
          onClickDelete={() => onClickDeleteIndicator(indicator)}
          onClickEdit={() => onClickEditIndicator(indicator)}
          onClickShare={() => onClickShareIndicator(indicator)}
          removeVisible={indicator.isShared}
          isFromNestingOrSharedActivity={
            indicator.isFromNestingOrSharedActivity
          }
          isPublic={indicator.isPublic}
          isUserPortfolioOwner={isUserPortfolioOwner}
          onClickRemove={() => onClickRemove(indicator)}
          onClickGotoHome={() => onClickGotoHome(indicator)}
          onClickAddToPortfolio={() => onClickAddToPortfolio(indicator)}
          onClickAskToShare={() => onClickAskToShare(indicator)}
        />
      </div>
    </div>
  );
}
