import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

export default function EditActivityModal({
  activity,
  isOpen,
  onHide,
  onSubmit,
  onChangeProperty,
}) {
  const [isValidated, setIsValidated] = useState(false);

  const handleSubmit = (e) => {
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      onSubmit();
      onHide();
    }

    setIsValidated(true);
  };

  return (
    <Modal show={isOpen} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>Edit Activity</Modal.Title>
        <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={onHide}></button>
      </Modal.Header>
      <Form noValidate validated={isValidated} onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group className="mb-4" controlId="activityTitle">
            <Form.Label>Title</Form.Label>
            <Form.Control
              required
              onChange={(e) => onChangeProperty("title", e.target.value)}
              type="text"
              value={activity ? activity.title : null}
              placeholder="Activity title"
            />
            <Form.Control.Feedback type="invalid">
              Please enter an activity title
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-4" controlId="activityDescription">
            <Form.Label>Description</Form.Label>
            <Form.Control
              required
              onChange={(e) => onChangeProperty("description", e.target.value)}
              as="textarea"
              rows="3"
              value={activity ? activity.description : null}
              placeholder="Activity description"
            />
            <Form.Control.Feedback type="invalid">
              Please enter an activity description
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-4" controlId="iatiIdentifier">
            <Form.Label>IATI Identifier</Form.Label>
            <Form.Control
              onChange={(e) =>
                onChangeProperty("iatiIdentifier", e.target.value)
              }
              type="text"
              value={activity ? activity.iatiIdentifier : null}
              placeholder="IATI Identifier"
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button className="button primary" type="submit">
            Edit Activity
          </Button>
          <Button className="button tertiary" onClick={onHide}>
            Cancel
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
