import React from "react";
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import { useSWRApi } from "../../Helpers/callApi";
import AddNewRelationshipModal from "./AddNewRelationshipModal";
import { useState } from "react";
import callApi from "../../Helpers/callApi";
import { mutate } from "swr";
import authService from "../../api-authorization/AuthorizeService";
import DeleteRelationshipModal from "./DeleteRelationshipModal";

export default function RelationListForPortfolio({
  portfolioId,
  portfolio,
  nestedPortfolioActivities,
}) {
  const [disabled, setDisabled] = useState(false);
  const [downloadText, setDownloadText] = useState("Export relationship data");
  const portfolios = [portfolio];
  const [isDeleteRelationshipModalOpen, setIsDeleteRelationshipModalOpen] =
    useState(false);
  const [fromResultIdForDelete, setFromResultIdForDelete] = useState(0);
  const [toResultIdForDelete, setToResultIdForDelete] = useState(0);

  const generatePortfolios = (item) => {
    if (item.portfolioViewModel.title !== null) {
      portfolios.push(item.portfolioViewModel);
    }

    if (item.nestedPortfolioViewModels.length > 0) {
      item.nestedPortfolioViewModels.map((eachItem) =>
        generatePortfolios(eachItem)
      );
    }
  };

  nestedPortfolioActivities.map((item) => {
    //portfolios.push(item.portfolioViewModel);
    //return portfolios;
    return generatePortfolios(item);
  });

  console.log(portfolios);
  const [isAddNewRelationshipModalOpen, setIsAddNewRelationshipModalOpen] =
    useState(false);
  let { data: resultsData, isLoading: isLoadingRelation } = useSWRApi(
    `portfolios/allrelations/${portfolioId}`
  );

  let results = resultsData ? resultsData : [];

  /*if (!results.some((r) => r.relations.length > 0)) {
    return (
      <>
        {" "}
        <h2 className="h2">Relations</h2>
        <p>There are no relations in this portfolio</p>
      </>
    );
  }*/

  const handleAddRelationshipModalOpen = () => {
    setIsAddNewRelationshipModalOpen(true);
  };

  const handleAddNewRelationshipModalClose = () => {
    setIsAddNewRelationshipModalOpen(false);
  };

  const handleDeleteRelationshipModalOpen = (fromResult, toResult) => {
    setFromResultIdForDelete(fromResult.id);
    setToResultIdForDelete(toResult.id);

    setIsDeleteRelationshipModalOpen(true);
  };

  const handleDeleteRelationshipModalClose = () => {
    setFromResultIdForDelete(0);
    setToResultIdForDelete(0);

    setIsDeleteRelationshipModalOpen(false);
  };

  const handleDeleteRelationship = async () => {
    let response = await callApi(
      `relations/from/${fromResultIdForDelete}/to/${toResultIdForDelete}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.ok) {
      handleDeleteRelationshipModalClose();
      mutate(`portfolios/allrelations/${portfolioId}`);
      mutate(`portfolios/${portfolioId}/allresults`);
    }
  };

  const handleAddNewRelationshipSubmit = async (
    fromActivityId,
    fromResultId,
    toActivityId,
    toResultId
  ) => {
    let response = await callApi(
      `relations/${fromActivityId}/from/${fromResultId}/${toActivityId}/to/${toResultId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        /*body: JSON.stringify({
        fromResultId: parseInt(fromResultId),
        fromActivityId:parseInt(fromActivityId),
        toResultId: parseInt(toResultId),
        toActivityId:parseInt(toActivityId)
      }),*/
      }
    );

    if (response.ok) {
      handleAddNewRelationshipModalClose();
      mutate(`portfolios/allrelations/${portfolioId}`);
      mutate(`portfolios/${portfolioId}/allresults`);
    }
  };

  const exportUrl = `api/portfolios/${portfolioId}/exportRelations`;

  const [spinnerState, setSpinnerState] = useState("none");

  const downloadData = async () => {
    setDisabled(true);
    setSpinnerState("inline-block");
    setDownloadText("Downloading..");
    const token = await authService.getAccessToken();
    const options = {
      headers: {
        method: "GET",
      },
    };
    options.headers.Authorization = `Bearer ${token}`;
    await fetch(exportUrl, options).then((response) => {
      setDisabled(false);
      setSpinnerState("none");
      setDownloadText("Export relationship data");
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.target = "_BLANK";
        a.rel = "noopener noreferrer";
        a.href = url;
        a.download = `exported-data.csv`;
        a.click();
      });
      //window.location.href = response.url;
    });
  };

  return (
    <>
      <h2 className="h2">Relations</h2>
      {isLoadingRelation ? (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      ) : (
        <>
          <div className="mt-3 mb-5">
            <div
              className="icon-and-text"
              onClick={handleAddRelationshipModalOpen}
            >
              <i class="bi bi-diagram-3 icon"></i>
              New relationship
            </div>
            <div
              className="icon-and-text has-left-border"
              onClick={() => downloadData()}
              disabled={disabled}
            >
              <i class="bi bi-cloud-download icon"></i>
              {downloadText}
            </div>
            <Spinner
              style={{ display: spinnerState }}
              as="span"
              className="ms-2"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          </div>
          {!results.some((r) => r.relations.length > 0) === true && (
            <p className="mt-3 text-secondary">
              There are no relations in this portfolio
            </p>
          )}
          {!results.some((r) => r.relations.length > 0) === false && (
            <Table className="mt-3" bordered>
              <thead>
                <tr>
                  <th>From</th>
                  <th>To</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {results.map((r) =>
                  r.relations.map((rel) => {
                    const fromResult = results.find(
                      (r) => r.id === rel.fromResultId
                    );
                    const toResult = results.find(
                      (r) => r.id === rel.toResultId
                    );

                    if (!fromResult || !toResult) return null;

                    return (
                      <tr key={rel.id}>
                        <td>
                          <div className="mb-2">
                            <span className="description">
                              {fromResult.portfolioTitle ===
                              fromResult.originatingPortfolioTitle
                                ? "This portfolio"
                                : fromResult.originatingPortfolioTitle}
                            </span>
                          </div>
                          <div className="mb-2">{fromResult.title}</div>
                          <small>
                            {fromResult.labels &&
                              fromResult.labels.split(",").map((l, i) => (
                                <span key={i} className="badge info">
                                  {l}
                                </span>
                              ))}
                          </small>
                        </td>
                        <td>
                          <div className="mb-2">
                            <span className="description">
                              {toResult.portfolioTitle ===
                              toResult.originatingPortfolioTitle
                                ? "This portfolio"
                                : toResult.originatingPortfolioTitle}
                            </span>
                          </div>
                          <div className="mb-2">{toResult.title}</div>
                          <small>
                            {toResult.labels &&
                              toResult.labels.split(",").map((l, i) => (
                                <span key={i} className="badge success">
                                  {l}
                                </span>
                              ))}
                          </small>
                        </td>
                        <td className="align-top">
                          <>
                            {r.activityId === null ? (
                              <Link
                                className="icon-and-text"
                                to={(location) => ({
                                  ...location,
                                  pathname: `relations/${rel.id}`,
                                })}
                              >
                                <i class="icon bi-arrow-right-circle"></i>View
                              </Link>
                            ) : (
                              <Link
                                to={(location) => ({
                                  ...location,
                                  pathname: `activities/${r.activityId}/relations/${rel.id}`,
                                })}
                                className="icon-and-text"
                              >
                                <i class="icon bi-arrow-right-circle"></i>View
                              </Link>
                            )}
                            <br></br>
                            <div
                              style={{ marginTop: "10px" }}
                              class="icon-and-text"
                              onClick={() =>
                                handleDeleteRelationshipModalOpen(
                                  fromResult,
                                  toResult
                                )
                              }
                            >
                              <i class="icon bi-trash"></i>Delete relationship
                            </div>
                          </>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </Table>
          )}
          <AddNewRelationshipModal
            isOpen={isAddNewRelationshipModalOpen}
            onSubmit={handleAddNewRelationshipSubmit}
            onHide={handleAddNewRelationshipModalClose}
            allPortfolios={portfolios}
          ></AddNewRelationshipModal>
          <DeleteRelationshipModal
            isOpen={isDeleteRelationshipModalOpen}
            onHide={handleDeleteRelationshipModalClose}
            onSubmit={handleDeleteRelationship}
          />
        </>
      )}
    </>
  );
}
