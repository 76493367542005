import React from "react";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";

export default function SearchIndicatorList(props) {
  const { indicators, onCopyIndicator, searchTerm } = props;

  const highlight = (text) => {
    const parts = text.split(new RegExp(`(${searchTerm})`, "gi"));
    return (
      <>
        {parts.map((p) => {
          if (p.toLowerCase() === searchTerm.toLowerCase()) {
            return <mark className="p-0">{p}</mark>;
          }
          return p;
        })}
      </>
    );
  };

  return (
    <>
      {indicators.length ? (
        <ListGroup style={{ marginTop: "5px" }}>
          {indicators.map((i) => (
            <ListGroup.Item>
              <div className="d-flex w-100 justify-content-between">
                <h5>{highlight(i.title)}</h5>
                <Button
                  onClick={() => onCopyIndicator(i)}
                  variant="outline-primary"
                >
                  Add to portfolio
                </Button>
              </div>
              <h6 className="text-muted">Description</h6>
              <p>{i.description}</p>
              <h6 className="text-muted">Methodology</h6>
              <p>{i.methodology}</p>
            </ListGroup.Item>
          ))}
        </ListGroup>
      ) : (
        <div className="mt-4">
          {!searchTerm || searchTerm.length === 0
            ? ""
            : "No indicators found"}
        </div>
      )}
    </>
  );
}
