import React, { useState, useEffect } from "react";
import ReactFlow, {
  removeElements,
  Background,
  isNode,
  isEdge,
  Controls,
  addEdge,
} from "react-flow-renderer";
import callApi from "../../Helpers/callApi";
import Sidebar from "../ResultsPage/Sidebar";
import AddResultModal from "../../Activity/AddResultModal";
import AddToGroupModal from "../ResultsPage/AddToGroupModal";
import { useAddToast } from "../../Context/ToastContext";
import usePortfolioService from "../../Services/usePortfolioService";
import useResultService from "../../Services/useResultService";
import { Container } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import { mutate } from "swr";
import AddEvidenceModal from "../../Activity/TheoryOfChange/AddEvidenceModal";
import LinkEvidenceModal from "../../Activity/TheoryOfChange/LinkEvidenceModal";
import UnlinkEvidenceModal from "../../Activity/TheoryOfChange/UnlinkEvidenceModal";
import DeleteEvidenceModal from "../../Activity/TheoryOfChange/DeleteEvidenceModal";
import AddToGroupOptionModal from "../ResultsPage/AddToGroupOptionModal";

export default function TheoryOfChange({
  results,
  portfolioId,
  activities,
  isLoadingResults,
  onExpandGroup,
  onRemoveResult,
  onAddResult,
  onRemoveSubResult,
  onModifySubResults,
  nestedPortfoliosColorData,
}) {
  const { createResult, getEvidence } = usePortfolioService();
  const { updateResult, deleteResult, removeSubResult, addSubResults } =
    useResultService();
  const [groupActivityResults, setGroupActivityResults] = useState([]);
  const [newResultAddedOrModified, setNewResultAddedOrModified] =
    useState(false);
  const thisPortfolioActivities = activities
    ? activities.filter((x) => x.isShared === false)
    : [];
  const sharedPortfolios = activities
    ? [...new Set(activities.filter((x) => x.isShared === true))]
    : [];
  let uniquePortfolioNames = sharedPortfolios
    ? [...new Set(sharedPortfolios.map((x) => x.originatingPortfolioTitle))]
    : [];

  uniquePortfolioNames = uniquePortfolioNames.sort((a, b) =>
    a.localeCompare(b)
  );

  useEffect(() => {
    const fetchData = async () => {
      const response = await callApi(
        `portfolios/${portfolioId}/GetGroupActivityResults`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const result = await response.json();
      setGroupActivityResults(result);
    };

    fetchData();
  }, [portfolioId, newResultAddedOrModified]);

  useEffect(() => {
    const getAllSubResults = (result, allSubResults = []) => {
      for (let i = 0, length = result.subResults.length; i < length; i++) {
        const subResult = results.find((r) => r.id === result.subResults[i].id); // eslint-disable-line
        if (!subResult) return allSubResults;
        if (!subResult.subResults || subResult.subResults.length === 0) {
          allSubResults.push(subResult);
        } else {
          allSubResults = getAllSubResults(subResult, allSubResults);
        }
      }
      return allSubResults;
    };

    const setupData = () => {
      const visibleResults = results
        .filter(
          (r) =>
            !results.some(
              (res) =>
                res.subResults.some((sr) => sr.id === r.id) && !res.expanded
            )
        )
        .filter((r) => !r.expanded);

      let initialNodes = [
        ...visibleResults.map((r, i) => ({
          id: r.id.toString(),
          data: {
            label: (
              <>
                <b>{r.title}</b>
                <p>{r.description}</p>
                {r.subResults.length > 0 && (
                  <div className="w-100 bg-secondary text-white rounded">
                    {r.subResults.length} Sub Result
                    {r.subResults.length > 1 ? "s" : null}
                  </div>
                )}
                {r.parentResults.length > 0 && (
                  <div className="mt-1 w-100 bg-secondary text-white rounded">
                    {r.parentResults.length} Parent Result
                    {r.parentResults.length > 1 ? "s" : null}
                  </div>
                )}
              </>
            ),
          },
          position: r.portfolioPosition
            ? {
                x:
                  r.portfolioPosition.x !== null
                    ? r.portfolioPosition.x
                    : r.position.x !== null
                    ? r.position.x
                    : 0,
                y:
                  r.portfolioPosition.y !== null
                    ? r.portfolioPosition.y
                    : r.position.y !== null
                    ? r.position.y
                    : 0,
              }
            : r.position
            ? { x: r.position.x, y: r.position.y }
            : { x: 0, y: 0 },
          targetPosition: "bottom",
          sourcePosition: "top",
          style: {
            background: r.color != null ? r.color : "#FFFFFF",
          },
        })),
      ];

      let initialEdges = [
        ...visibleResults.map((r) => {
          const resultRelations = r.relations.map((rel) => ({
            id: `${rel.id}-${rel.fromResultId}-${rel.toResultId}`,
            data: {
              relationId: rel.id,
              evidence: rel.evidence,
              isJustified: rel.isJustified,
            },
            source: rel.fromResultId.toString(),
            target: rel.toResultId.toString(),
            animated: true,
            arrowHeadType: "arrowclosed",
            style: {
              strokeWidth: 3,
              stroke: rel.isJustified ? "green" : "black",
            },
            label: rel.evidence.length
              ? `Evidence: ${rel.evidence.length}`
              : null,
            labelBgStyle: { stroke: "black", strokeWidth: "1" },
          }));

          // If any sub result of the current result has a relation to another visible result - create a relation from the current result
          const subResultFromRelations = getAllSubResults(r)
            .map((sr) => {
              const result = results.find((r) => r.id === sr.id);
              if (!result) return null;
              return result.relations.map((rel) => ({
                id: `${rel.id}-${rel.fromResultId}-${rel.toResultId}`,
                data: {
                  relationId: rel.id,
                  evidence: rel.evidence,
                  isInferredRelation: true,
                },
                source: r.id.toString(),
                target: rel.toResultId.toString(),
                animated: true,
                arrowHeadType: "arrowclosed",
                style: { strokeWidth: 3 },
                label: rel.evidence.length
                  ? `Evidence: ${rel.evidence.length}`
                  : null,
                labelBgStyle: { stroke: "black", strokeWidth: "1" },
              }));
            })
            .filter((x) => !!x)
            .flat();

          // If any visible result has a relation to a sub-result of the current result - create a relation to the current result
          const subResultToRelations = visibleResults
            .filter((result) =>
              result.relations.some((rel) =>
                getAllSubResults(r).some((sr) => sr.id === rel.toResultId)
              )
            )
            .map((result) => ({
              id: `${result.id}-${r.id}`,
              source: result.id.toString(),
              target: r.id.toString(),
              animated: true,
              data: {
                isInferredRelation: true,
              },
              arrowHeadType: "arrowclosed",
              style: { strokeWidth: 3 },
              labelBgStyle: { stroke: "black", strokeWidth: "1" },
            }));

          const subResultToSubResultRelations = visibleResults
            .filter(
              (result) =>
                getAllSubResults(result).filter((subResult) =>
                  subResult.relations.some((rel) =>
                    getAllSubResults(r).some((sr) => sr.id === rel.toResultId)
                  )
                ).length > 0
            )
            .map((result) => ({
              id: `${result.id}-${r.id}`,
              source: result.id.toString(),
              target: r.id.toString(),
              animated: true,
              data: {
                isInferredRelation: true,
              },
              arrowHeadType: "arrowclosed",
              style: { strokeWidth: 3 },
              labelBgStyle: { stroke: "black", strokeWidth: "1" },
            }));

          return [
            ...resultRelations,
            ...subResultFromRelations,
            ...subResultToRelations,
            ...subResultToSubResultRelations,
          ];
        }),
      ].flat();

      let initialElements = [...initialNodes, ...initialEdges];
      setElements(initialElements);
    };

    setupData();
  }, [results]);

  const [elements, setElements] = useState([]);
  const [selectedElements, setSelectedElements] = useState([]);
  const [isAddResultModalOpen, setIsAddResultModalOpen] = useState(false);
  const [isAddToGroupModalOpen, setIsAddToGroupModalOpen] = useState(false);
  const [isAddToGroupOptionModalOpen, setIsAddToGroupOptionModalOpen] =
    useState(false);
  //evidence
  const [evidence, setEvidence] = useState([]);
  const [selectedRelationId, setSelectedRelationId] = useState("");
  const [isAddEvidenceModalOpen, setAddEvidenceModalOpen] = useState(false);
  const [isLinkEvidenceModalOpen, setLinkEvidenceModalOpen] = useState(false);
  const [isUnlinkEvidenceModalOpen, setUnlinkEvidenceModalOpen] =
    useState(false);
  const [isDeleteEvidenceModalOpen, setDeleteEvidenceModalOpen] =
    useState(false);
  const [evidenceIdToBeDeleted, setEvidenceIdToBeDeleted] = useState("");
  const [evidenceToBeUnlinked, setEvidenceToBeUnlinked] = useState(null);
  const addToast = useAddToast();

  const onLoad = (reactFlowInstance) =>
    reactFlowInstance.fitView({ padding: 0.2 });

  const onMoveNode = (event, node) => {
    let result = results.find((r) => r.id === parseInt(node.id));
    result.portfolioPosition = node.position;
    result.portfolioPosition.portfolioId = parseInt(portfolioId);
    result.portfolioPosition.resultId = result.id;
    handleUpdateResult(result);
  };

  const onConnect = (params) => {
    handleAddRelation({
      fromResultId: params.source,
      toResultId: params.target,
    });

    mutate(`portfolios/${portfolioId}/allresults`);
    mutate(`portfolios/allactivities/${portfolioId}`);
    mutate(`portfolios/allrelations/${portfolioId}`);
  };

  const onElementsRemove = (elementsToRemove) => {
    elementsToRemove.forEach((e) => {
      if (isEdge(e)) {
        handleDeleteRelation({
          fromResultId: e.source.toString(),
          toResultId: e.target.toString(),
        });
      }
    });
    setElements((els) => removeElements(elementsToRemove, els));

    mutate(`portfolios/${portfolioId}/allresults`);
    mutate(`portfolios/allactivities/${portfolioId}`);
    mutate(`portfolios/allrelations/${portfolioId}`);
  };

  const handleUpdateResult = async (result) => {
    await updateResult(result);

    mutate(`portfolios/${portfolioId}/allresults`);
    mutate(`portfolios/allactivities/${portfolioId}`);
    mutate(`portfolios/allrelations/${portfolioId}`);
  };

  const handleAddRelation = async (relation) => {
    let response = await callApi(`relations`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fromResultId: parseInt(relation.fromResultId),
        toResultId: parseInt(relation.toResultId),
      }),
    });

    if (response.ok) {
      let newRelation = await response.json();
      let newElement = {
        id: `${newRelation.id}-${newRelation.fromResultId}-${newRelation.toResultId}`,
        source: newRelation.fromResultId.toString(),
        target: newRelation.toResultId.toString(),
        data: {
          relationId: newRelation.id,
          evidence: [],
        },
        animated: true,
        arrowHeadType: "arrowclosed",
        style: { strokeWidth: 3 },
      };
      setElements((els) => addEdge(newElement, els));
    } else {
      addToast({
        variant: "error",
        message: "Something went wrong when creating the relation",
      });
    }
  };

  const handleDeleteRelation = async (relation) => {
    await callApi(
      `relations/from/${relation.fromResultId}/to/${relation.toResultId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  const handleDeleteGroup = async (result) => {
    const response = await deleteResult(result.id);
    setSelectedElements([]);

    if (response.ok) {
      onRemoveResult(result);
    }
  };

  const handleSelectGroup = (result) => {
    setSelectedElements([{ id: `${result.id}`, type: "default" }]);
  };

  const handleCreatePortfolioResult = async (title, description, labels) => {
    const selectedResults = selectedElements.filter((e) => isNode(e));
    const subResults = results.filter((r) =>
      selectedResults.some((sr) => r.id === parseInt(sr.id))
    );
    const sumOfPositions = subResults.reduce(
      (acc, cur) => {
        if (!cur.portfolioPosition) {
          return {
            x: acc.x + cur.position.x,
            y: acc.y + cur.position.y,
          };
        }
        return {
          x: acc.x + cur.portfolioPosition.x,
          y: acc.y + cur.portfolioPosition.y,
        };
      },
      { x: 0, y: 0 }
    );
    const averagePosition = {
      x: Math.round(sumOfPositions.x / subResults.length),
      y: Math.round(sumOfPositions.y / subResults.length),
    };

    let response = await createResult(
      portfolioId,
      title,
      description,
      labels,
      subResults,
      averagePosition
    );
    if (response.ok) {
      setSelectedElements([]);
      onAddResult(response.data);
      setNewResultAddedOrModified(!newResultAddedOrModified);
    }
  };

  const handleRemoveSubResult = async (result, subResult) => {
    const response = await removeSubResult(result, subResult);

    if (response.ok) {
      onRemoveSubResult();
    }
  };

  const handleAddResultsToGroup = async (groupId) => {
    const resultsToAdd = selectedElements
      .filter((e) => isNode(e))
      .map((e) => results.find((r) => r.id === parseInt(e.id)));
    const group = results.find((r) => r.id === groupId);
    const response = await addSubResults(group, resultsToAdd);
    if (response.ok) {
      onModifySubResults();
    }
  };

  const openAddResultModalOption = () => {
    setIsAddToGroupOptionModalOpen(false);
    setIsAddResultModalOpen(true);
  };

  const openExistingResultModalOption = () => {
    setIsAddToGroupOptionModalOpen(false);
    setIsAddToGroupModalOpen(true);
  };

  const handleAddEvidenceModalClose = () => setAddEvidenceModalOpen(false);
  const handleDeleteEvidenceModalOpen = () => setDeleteEvidenceModalOpen(true);

  const handleDeleteEvidenceModalClose = () => {
    setEvidenceIdToBeDeleted(null);
    setDeleteEvidenceModalOpen(false);
  };
  const handleLinkEvidenceModalClose = () => {
    setLinkEvidenceModalOpen(false);
    setSelectedRelationId("");
  };
  const handleUnlinkEvidenceModalClose = () => {
    setUnlinkEvidenceModalOpen(false);
    setSelectedRelationId("");
    setEvidenceToBeUnlinked(null);
  };

  const handleClickDeleteEvidence = (evidenceId) => {
    setEvidenceIdToBeDeleted(evidenceId);
    handleDeleteEvidenceModalOpen();
  };

  const handleLinkEvidenceClick = (relationId) => {
    setSelectedRelationId(relationId);
    setLinkEvidenceModalOpen(true);
  };
  const handleUnlinkEvidenceClick = (relationId, evidence) => {
    setSelectedRelationId(relationId);
    setEvidenceToBeUnlinked(evidence);
    setUnlinkEvidenceModalOpen(true);
  };

  const handleEvidenceIsJustifiedChanged = async (relationId, isJustified) => {
    const response = await callApi(`relations/${relationId}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify([
        {
          op: "replace",
          path: "/isJustified",
          value: isJustified,
        },
      ]),
    });

    if (response.ok) {
      setSelectedElements((prevState) => {
        return prevState.map((e) => {
          if (e.data.relationId === relationId) {
            e.data.isJustified = isJustified;
            return e;
          }

          return e;
        });
      });
    }
  };

  const handleAddEvidenceSubmit = async (
    title,
    type,
    summary,
    comments,
    documentLink,
    produced,
    validFrom,
    validTo,
    isExternallyProduced,
    evidenceProducedWithFCDOFunding,
    fundingIATIIdentifier
  ) => {
    const response = await callApi(`relations/${selectedRelationId}/evidence`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        title,
        type,
        summary,
        comments,
        documentLink,
        produced,
        validFrom,
        validTo,
        isExternallyProduced,
        evidenceProducedWithFCDOFunding,
        fundingIATIIdentifier,
        portfolioId,
        relationId: selectedRelationId,
      }),
    });

    if (response.ok) {
      let newEvidence = await response.json();
      newEvidence.id = newEvidence.id.toString();
      // //setting selected element state
      setSelectedElements((prevState) => {
        return prevState.map((e) => {
          return {
            ...e,
            data: {
              ...e.data,
              evidence: [...e.data.evidence, newEvidence],
            },
          };
        });
      });
      let selE = selectedElements[0];
      let el = elements.filter((d) => d.id === selectedElements[0].id);
      // Remove old element from the elements list
      setElements((els) => removeElements(el, els));
      // Add back the updated element
      setElements((els) => addEdge(selE, els));
    }
    handleAddEvidenceModalClose();
  };

  const handleLinkEvidenceSubmit = async (
    evidence,
    direction,
    causality,
    isNecessary,
    evidenceProduced,
    isRelativeImportance,
    relativeImportanceScale,
    isTimeLag,
    timeLag,
    isScaleChange,
    evidenceEffect1,
    evidenceEffect2,
    comment
  ) => {
    const response = await callApi(
      `relations/${selectedRelationId}/evidence/${evidence.id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          direction,
          causality,
          isNecessary,
          evidenceProduced,
          isRelativeImportance,
          relativeImportanceScale,
          isTimeLag,
          timeLag,
          isScaleChange,
          evidenceEffect1,
          evidenceEffect2,
          comment,
        }),
      }
    );
    console.log(response);
    if (response.ok) {
      setSelectedElements((prevState) => {
        return prevState.map((e) => {
          if (e.data.relationId === selectedRelationId) {
            return {
              ...e,
              data: {
                ...e.data,
                evidence: [...e.data.evidence, evidence],
              },
            };
          }

          return e;
        });
      });

      setElements((prevState) => {
        return prevState.map((e) => {
          if (e.data.relationId === selectedRelationId) {
            return {
              ...e,
              data: {
                ...e.data,
                evidence: [...e.data.evidence, evidence],
              },
            };
          }
          return e;
        });
      });
    }
    handleLinkEvidenceModalClose();
  };

  const handleDeleteEvidence = async () => {
    const response = await callApi(`evidence/${evidenceIdToBeDeleted}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      setSelectedElements((prevState) => {
        return prevState.map((e) => {
          return {
            ...e,
            data: {
              ...e.data,
              evidence: e.data.evidence.filter(
                (d) => d.id !== evidenceIdToBeDeleted
              ),
            },
          };
        });
      });
      let el = elements.filter((d) => d.id === selectedElements[0].id);
      // Remove old element from the elements list
      setElements((els) => removeElements(el, els));
      // Add back the updated element
      setElements((els) => addEdge(selectedElements[0], els));
    }
  };

  const handleUnlinkEvidenceSubmit = async () => {
    const response = await callApi(
      `relations/${selectedRelationId}/evidence/${evidenceToBeUnlinked.id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.ok) {
      setSelectedElements((prevState) => {
        return prevState.map((e) => {
          if (e.data.relationId === selectedRelationId) {
            return {
              ...e,
              data: {
                ...e.data,
                evidence: e.data.evidence.filter(
                  (e) => e.id !== evidenceToBeUnlinked.id
                ),
              },
            };
          }

          return e;
        });
      });

      setElements((prevState) => {
        return prevState.map((e) => {
          if (e.data.relationId === selectedRelationId) {
            return {
              ...e,
              data: {
                ...e.data,
                evidence: e.data.evidence.filter(
                  (e) => e.id !== evidenceToBeUnlinked.id
                ),
              },
            };
          }
          return e;
        });
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await getEvidence(portfolioId);
      setEvidence(response.data);
    };

    fetchData();
  }, [portfolioId, getEvidence]);

  return (
    <>
      <Container fluid>
        <Row>
          <Col md={2}>
            {isLoadingResults ? (
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            ) : thisPortfolioActivities.length ||
              sharedPortfolios.length ||
              nestedPortfoliosColorData.length ? (
              <div
                style={{
                  height: "100vh",
                  width: "100%",
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                {thisPortfolioActivities.length > 0 && (
                  <p>
                    <strong>This portfolio</strong>
                  </p>
                )}
                {thisPortfolioActivities.map((value, index) => (
                  <>
                    <Row>
                      <Col md={2}>
                        <div
                          style={{
                            backgroundColor: value.color,
                            width: "1.2em",
                            height: "1.2em",
                          }}
                        ></div>
                      </Col>
                      <Col md={10}>
                        <p style={{ fontSize: "12px" }}>
                          <Link
                            to={(location) => ({
                              ...location,
                              pathname: `activities/${value.id}`,
                            })}
                          >
                            {value.title}
                          </Link>
                        </p>
                      </Col>
                    </Row>
                  </>
                ))}

                {uniquePortfolioNames.map((item, index) => (
                  <div key={item}>
                    <p>
                      <strong>From: {item}</strong>
                    </p>

                    {sharedPortfolios
                      .filter((x) => x.originatingPortfolioTitle === item)
                      .map((v, i) => (
                        <>
                          <Row>
                            <Col sm={2}>
                              <div
                                style={{
                                  backgroundColor: v.color,
                                  width: "1.2em",
                                  height: "1.2em",
                                }}
                              ></div>
                            </Col>
                            <Col sm={10}>
                              <p style={{ fontSize: "12px" }}>
                                <Link
                                  to={(location) => ({
                                    ...location,
                                    pathname: `activities/${v.id}`,
                                  })}
                                >
                                  {v.title}
                                </Link>
                              </p>
                            </Col>
                          </Row>
                        </>
                      ))}
                  </div>
                ))}
                {nestedPortfoliosColorData.length > 0 && (
                  <p>
                    <strong>Included portfolio</strong>
                  </p>
                )}
                {nestedPortfoliosColorData.map((item, index) => (
                  <>
                    <Row>
                      <Col sm={2}>
                        <div
                          style={{
                            backgroundColor: item.color,
                            width: "1.2em",
                            height: "1.2em",
                          }}
                        ></div>
                      </Col>
                      <Col sm={10}>
                        <p style={{ fontSize: "12px" }}>
                          {item.portfolioViewModel.title}
                        </p>
                      </Col>
                    </Row>
                  </>
                ))}
              </div>
            ) : (
              <div></div>
            )}
          </Col>
          <Col md={10}>
            <div className="pt-3" style={{ width: "100%", height: "100vh" }}>
              <ReactFlow
                elements={elements}
                onLoad={onLoad}
                onNodeDragStop={onMoveNode}
                onConnect={onConnect}
                onElementsRemove={onElementsRemove}
                onSelectionChange={(els) => setSelectedElements(els)}
                connectionLineStyle={{ strokeWidth: 3 }}
                arrowHeadType="arrowclosed"
                multiSelectionKeyCode="Alt"
              >
                <Background variant="dots" gap={24} size={1} />
                <Controls showInteractive={false} />
              </ReactFlow>
              <AddResultModal
                isOpen={isAddResultModalOpen}
                onHide={() => setIsAddResultModalOpen(false)}
                onSubmit={handleCreatePortfolioResult}
              />
              <AddToGroupModal
                isOpen={isAddToGroupModalOpen}
                onHide={() => setIsAddToGroupModalOpen(false)}
                onSubmit={handleAddResultsToGroup}
                groups={results.filter((r) => r.subResults.length > 0)}
                groupActivityResults={groupActivityResults}
              />
              <AddToGroupOptionModal
                isOpen={isAddToGroupOptionModalOpen}
                onHide={() => setIsAddToGroupOptionModalOpen(false)}
                onCreateResultButtonClick={openAddResultModalOption}
                onExistingResultButtonClick={openExistingResultModalOption}
              />
            </div>
          </Col>
        </Row>
        <Sidebar
          selectedElements={selectedElements}
          results={results}
          onAddResultClick={() => setIsAddResultModalOpen(true)}
          onExpandGroup={onExpandGroup}
          onDeleteGroup={handleDeleteGroup}
          onSelectGroup={handleSelectGroup}
          onRemoveSubResult={handleRemoveSubResult}
          onAddToGroupClick={() => setIsAddToGroupModalOpen(true)}
          onAddToGroupOptionClick={() => setIsAddToGroupOptionModalOpen(true)}
          handleDeleteEvidence={handleClickDeleteEvidence}
          onClickLinkEvidence={handleLinkEvidenceClick}
          onClickUnlinkEvidence={handleUnlinkEvidenceClick}
          handleEvidenceIsJustifiedChanged={handleEvidenceIsJustifiedChanged}
        />
        <AddEvidenceModal
          isOpen={isAddEvidenceModalOpen}
          onHide={handleAddEvidenceModalClose}
          onSubmit={handleAddEvidenceSubmit}
        />
        <LinkEvidenceModal
          isOpen={isLinkEvidenceModalOpen}
          onHide={handleLinkEvidenceModalClose}
          onSubmit={handleLinkEvidenceSubmit}
          evidence={evidence}
          selectedElements={selectedElements}
        />
        <DeleteEvidenceModal
          isOpen={isDeleteEvidenceModalOpen}
          onHide={handleDeleteEvidenceModalClose}
          onSubmit={handleDeleteEvidence}
        />
        <UnlinkEvidenceModal
          isOpen={isUnlinkEvidenceModalOpen}
          onHide={handleUnlinkEvidenceModalClose}
          onSubmit={handleUnlinkEvidenceSubmit}
        />
      </Container>
    </>
  );
}
