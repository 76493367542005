import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import callApi from "../../Helpers/callApi";

import "react-bootstrap-typeahead/css/Typeahead.css";

export default function AddNewRelationshipModal({
  isOpen,
  onHide,
  onSubmit,
  allPortfolios,
}) {
  const [allActivitiesFromPortfolio, setAllActivitiesFromPortfolio] = useState(
    []
  );
  const [allActivitiesToPortfolio, setAllActivitiesToPortfolio] = useState([]);
  const [allResultsFromActivity, setAllResultsFromActivity] = useState([]);
  const [allResultsToActivity, setAllResultsToActivity] = useState([]);

  const [selectedFromPortfolioId, setSelectedFromPortfolioId] = useState(0);
  const [selectedToPortfolioId, setSelectedToPortfolioId] = useState(0);

  const [selectedFromActivityId, setSelectedFromActivityId] = useState(null);
  const [selectedToActivityId, setSelectedToActivityId] = useState(null);

  const [selectedFromResultId, setSelectedFromResultId] = useState(null);
  const [selectedToResultId, setSelectedToResultId] = useState(null);

  const reset = () => {
    setAllActivitiesFromPortfolio([]);
    setAllActivitiesToPortfolio([]);
    setAllResultsFromActivity([]);
    setAllResultsToActivity([]);
    setSelectedFromPortfolioId(0);
    setSelectedToPortfolioId(0);
    setSelectedFromActivityId(null);
    setSelectedToActivityId(null);
    setSelectedFromResultId(null);
    setSelectedToResultId(null);
  };

  const handleCancel = (e) => {
    reset();
    onHide();
  };

  const handleLoadActivity = async (portfolioId, fromWhere) => {
    const response = await callApi(
      `portfolios/allactivitiesforrelationship/${portfolioId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const result = await response.json();

    if (fromWhere === "fromPortfolio") {
      setSelectedFromPortfolioId(portfolioId);
      setAllActivitiesFromPortfolio(result.activities);
    } else {
      setSelectedToPortfolioId(portfolioId);
      setAllActivitiesToPortfolio(result.activities);
    }
  };

  const handleLoadResult = async (activityId, fromWhere) => {
    if (fromWhere === "fromPortfolio") {
      const response = await callApi(
        `activities/${activityId}/resultandindicatorsforrelationship/${selectedFromPortfolioId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const result = await response.json();
      setAllResultsFromActivity(result);
      setSelectedFromActivityId(activityId);
    } else {
      const response = await callApi(
        `activities/${activityId}/resultandindicatorsforrelationship/${selectedToPortfolioId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const result = await response.json();
      setAllResultsToActivity(result);
      setSelectedToActivityId(activityId);
    }
  };

  const handleSubmit = (e) => {
    onSubmit(
      selectedFromActivityId,
      selectedFromResultId,
      selectedToActivityId,
      selectedToResultId
    );
  };

  const handleFromResult = (fromResult) => {
    setSelectedFromResultId(fromResult);
  };

  const handleToResult = (toResult) => {
    setSelectedToResultId(toResult);
  };

  return (
    <Modal show={isOpen} onHide={onHide} backdrop="static" keyboard={false}>
      <Modal.Header onHide={reset}>
        <Modal.Title>New Relationship</Modal.Title>
        <button
          type="button"
          className="btn-close btn-close-white"
          aria-label="Close"
          onClick={handleCancel}
        ></button>
      </Modal.Header>
      <Form>
        <Modal.Body>
          <div>Relationship from:</div>
          <Form.Group controlId="selectFromPortfolio">
            <Form.Label>Select portfolio:</Form.Label>
            <Form.Control
              as="select"
              className="select-menu"
              onChange={(e) =>
                handleLoadActivity(e.target.value, "fromPortfolio")
              }
            >
              <option disabled selected value="">
                -- select an option --
              </option>
              {allPortfolios
                //.filter((x) => x.isPublic === true)
                .map((a) => (
                  <option key={a.id} value={a.id}>
                    {a.title}
                  </option>
                ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="selectFromPortfolioActivity">
            <Form.Label>Select activity:</Form.Label>
            <Form.Control
              as="select"
              className="select-menu"
              onChange={(e) =>
                handleLoadResult(e.target.value, "fromPortfolio")
              }
            >
              <option disabled selected value="">
                -- select an option --
              </option>
              {allActivitiesFromPortfolio
                //.filter((x) => x.isPublic === true)
                .map((a) => (
                  <option key={a.id} value={a.id}>
                    {a.title}
                  </option>
                ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="selectFromActivityResult">
            <Form.Label>Select result:</Form.Label>
            <Form.Control
              as="select"
              className="select-menu"
              onChange={(e) => handleFromResult(e.target.value)}
            >
              <option disabled selected value="">
                -- select an option --
              </option>
              <option value="0">Use activity as result</option>
              {allResultsFromActivity
                //.filter((x) => x.isPublic === true)
                .map((a) => (
                  <option key={a.id} value={a.id}>
                    {a.title}
                  </option>
                ))}
            </Form.Control>
          </Form.Group>
          <div style={{ marginTop: "30px" }}>Relationship to:</div>
          <Form.Group controlId="selectToPortfolio">
            <Form.Label>Select portfolio:</Form.Label>
            <Form.Control
              as="select"
              className="select-menu"
              onChange={(e) =>
                handleLoadActivity(e.target.value, "toPortfolio")
              }
            >
              <option disabled selected value="">
                -- select an option --
              </option>
              {allPortfolios
                //.filter((x) => x.isPublic === true)
                .map((a) => (
                  <option key={a.id} value={a.id}>
                    {a.title}
                  </option>
                ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="selectToPortfolioActivity">
            <Form.Label>Select activity:</Form.Label>
            <Form.Control
              as="select"
              className="select-menu"
              onChange={(e) => handleLoadResult(e.target.value, "toPortfolio")}
            >
              <option disabled selected value="">
                -- select an option --
              </option>
              {allActivitiesToPortfolio
                //.filter((x) => x.isPublic === true)
                .map((a) => (
                  <option key={a.id} value={a.id}>
                    {a.title}
                  </option>
                ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="selectToActivityResult">
            <Form.Label>Select result:</Form.Label>
            <Form.Control
              as="select"
              className="select-menu"
              onChange={(e) => handleToResult(e.target.value)}
            >
              <option disabled selected value="">
                -- select an option --
              </option>
              <option value="0">Use activity as result</option>
              {allResultsToActivity
                //.filter((x) => x.isPublic === true)
                .map((a) => (
                  <option key={a.id} value={a.id}>
                    {a.title}
                  </option>
                ))}
            </Form.Control>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="button primary"
            onClick={handleSubmit}
            disabled={
              selectedFromResultId === null && selectedToResultId === null
            }
          >
            Submit
          </Button>
          <Button className="button tertiary" onClick={handleCancel}>
            Cancel
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
