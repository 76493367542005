import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

export default function EditPortfolioModal({
    portfolio,
    isOpen,
    onHide,
    onSubmit
}) {
    const [isValidated, setIsValidated] = useState(false);
    const [portfolioTitle, setportfolioTitle] = useState(portfolio.title);
    const [portfolioUniqueTitle, setportfolioUniqueTitle] = useState(
        portfolio.uniqueTitle
    );
    const [portfolioDescription, setportfolioDescription] = useState(
        portfolio.description
    );

    const handleClose = () => {
        setportfolioTitle("");
        setportfolioDescription("");
        setportfolioUniqueTitle("");
        onHide();
    };

    const handleSubmit = e => {
        const form = e.currentTarget;

        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
        } else {
            onSubmit(portfolioTitle, portfolioUniqueTitle, portfolioDescription);
            handleClose();
        }

        setIsValidated(true);
    };

    return (
        <Modal show={isOpen} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title>Edit Portfolio</Modal.Title>
                <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={onHide}></button>
            </Modal.Header>
            <Form noValidate validated={isValidated} onSubmit={handleSubmit}>
                <Modal.Body>
                    <Form.Group className="mb-4" controlId="portfolioTitle">
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                            required
                            onChange={e => setportfolioTitle(e.target.value)}
                            value={portfolioTitle}
                            type="text"
                            placeholder="Portfolio title"
                        />
                        <Form.Control.Feedback type="invalid">
                            Please enter a portfolio title
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="portfolioUniqueTitle">
                        <Form.Label>Unique Title</Form.Label>
                        <Form.Control
                            required
                            onChange={e => setportfolioUniqueTitle(e.target.value)}
                            value={portfolioUniqueTitle}
                            type="text"
                            placeholder="Portfolio unique title"
                        />
                        <Form.Control.Feedback type="invalid">
                            Please enter a unique portfolio title
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="portfolioDescription">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            required
                            onChange={e => setportfolioDescription(e.target.value)}
                            value={portfolioDescription}
                            as="textarea"
                            rows="7"
                            placeholder="Portfolio description"
                        />
                        <Form.Control.Feedback type="invalid">
                            Please enter a portfolio description
                        </Form.Control.Feedback>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="button primary" type="submit">
                        Save
                    </Button>
                    <Button className="button tertiary" onClick={handleClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}
