import React from "react";
import { Switch, Route } from "react-router-dom";
import ActivityDetails from "./ActivityDetails";
import IndicatorLayout from "../Indicator/IndicatorLayout";
import EvidenceLayout from "../Evidence/EvidenceLayout";
import RelationLayout from "../Relation/RelationLayout";
import ChangeRequestDetails from "../Activity/ChangeRequestDetails";

export default function HomeLayout() {
    return (
        <Switch>
            <Route path="/:portfolioId/activities/:activityId/relations/:relationId">
                <RelationLayout />
            </Route>
            <Route path="/:portfolioId/activities/:activityId/evidence/:evidenceId">
                <EvidenceLayout />
            </Route>
            <Route path="/:portfolioId/activities/:activityId/results/:resultId/:indicatorId">
                <IndicatorLayout />
            </Route>
            <Route path="/:portfolioId/activities/:activityId/change-requests/:changeRequestId">
                <ChangeRequestDetails />
            </Route>
            <Route path="/:portfolioId/activities/:activityId/:tabKey?">
                <ActivityDetails />
            </Route>
        </Switch>
    );
}
