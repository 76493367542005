import React, { useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import useActivityService from "../../Services/useActivityService";

export default function ExportActivityAggregatedData(props) {
  const { activityId } = props;
  const { getAggregatedExport } = useActivityService();
  const [disabled, setDisabled] = useState(false);
  const [downloadText, setDownloadText] = useState("Download");
  const [spinnerState, setSpinnerState] = useState("none");

  const downloadData = async () => {
    setDisabled(true);
    setSpinnerState("inline-block");
    setDownloadText("Downloading..");
    let response = await getAggregatedExport(activityId);
    setDisabled(false);
    setSpinnerState("none");
    setDownloadText("Download");
    let blob = response.data;
    if (blob) {
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.target = "_BLANK";
      a.rel = "noopener noreferrer";
      a.href = url;
      a.download = `exported-data.csv`;
      a.click();
    }
  };

  return (
    <div className="export-data">
      <h4 className="h4 mb-1">Export aggregated activity data</h4>
      <p>Download aggregated indicator data for this activity</p>
      <button
        class="button tertiary with-icon mt-1"
        onClick={downloadData}
        disabled={disabled}
      >
        <i class="icon bi bi-cloud-download"></i>
        {downloadText}
      </button>
      <div>
        <Spinner
          style={{ display: spinnerState }}
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
          className="mt-4"
        />
      </div>
    </div>
  );
}
