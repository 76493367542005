import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import DeleteRecordModal from "./DeleteRecordModal";

export default function QualitativeRecordValue({
  record,
  onUpdateRecord,
  onDeleteRecord,
  disaggregations,
  isSharedActivity,
}) {
  const [actualValue, setActualValue] = useState(record.value);
  const [targetValue, setTargetValue] = useState(record.targetValue);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleEditClick = () => setIsEditing(true);
  const handleDeleteClick = () => setIsDeleteModalOpen(true);

  const handleSaveClick = () => {
    setIsEditing(false);
    record.value = actualValue;
    record.targetValue = targetValue;
    onUpdateRecord(record);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setActualValue(record.value);
    setTargetValue(record.targetValue);
  };

  return isEditing ? (
    <>
      <tr>
        <td width="50%">
          <Form.Control
            onChange={(e) => setTargetValue(e.target.value)}
            value={targetValue}
            as="textarea"
            className="textarea"
            rows="3"
          />
        </td>
        <td colSpan="2" width="50%">
          <Form.Control
            onChange={(e) => setActualValue(e.target.value)}
            value={actualValue}
            as="textarea"
            className="textarea"
            rows="3"
          />
        </td>
      </tr>
      <tr>
        <td colSpan="3">
          <div className="actions">
            <Button className="button primary" onClick={handleSaveClick}>
              Save
            </Button>
            <Button className="button tertiary" onClick={handleCancelClick}>
              Cancel
            </Button>
          </div>
        </td>
      </tr>
    </>

  ) : (
    <>
      <tr className="table-active small">
        <td colSpan={2}>
          <div className="w-100 d-flex flex-nowrap justify-content-around">
            {disaggregations.map((d) => {
              let dv = record.disaggregationValues.find(
                (x) => x.disaggregation === d.title
              );
              if (dv != null) {
                return (
                  <div key={dv.id}>
                    {d.title}: {dv.value}
                  </div>
                );
              } else {
                return <div className="alert error">{d.title}: not set</div>;
              }
            })}
          </div>
        </td>
        <td></td>
      </tr>
      <tr>
        <td className="border border-1">{record.targetValue}</td>
        <td className="border border-1">{record.value}</td>
        <td align="right">
          <div className="text-right">
            {!isSharedActivity && (
              <div className="icon-and-text" onClick={handleEditClick}>
                <i className="icon bi bi-pencil-square"></i>
                Edit
              </div>
            )}
            {!isSharedActivity && (
              <div className="icon-and-text has-left-border" onClick={handleDeleteClick}>
                <i className="icon bi bi-trash"></i>
                Delete
              </div>
            )}
          </div>

          <DeleteRecordModal
            isOpen={isDeleteModalOpen}
            onHide={() => setIsDeleteModalOpen(false)}
            onSubmit={() => onDeleteRecord(record)}
          />
        </td>
      </tr>
    </>
  );
}
