import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

export default function AddToGroupModal(props) {
  const { isOpen, onHide, onSubmit, groupActivityResults } = props;
  const [selectedGroupId, setSelectedGroupId] = useState(null);

  const resetState = () => {
    setSelectedGroupId(null);
  };

  const handleHide = () => {
    onHide();
    resetState();
  };

  const handleSubmit = () => {
    onSubmit(parseInt(selectedGroupId));
    handleHide();
  };

  return (
    <Modal show={isOpen} onHide={handleHide} backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>Add To Existing Group</Modal.Title>
        <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={handleHide}></button>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Control
            as="select"
            defaultValue={-1}
            onChange={(e) => setSelectedGroupId(e.target.value)}
          >
            <option disabled value="-1">
              -- Select Group --
            </option>
            {groupActivityResults.map((g) => (
              <option key={g.id} value={g.id}>
                {g.title}
              </option>
            ))}
          </Form.Control>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="button primary" onClick={handleSubmit}>
          Add To Group
        </Button>
        <Button className="button tertiary" onClick={handleHide}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
