import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import authService from "../api-authorization/AuthorizeService";
import callApi from "../Helpers/callApi";
import Button from "react-bootstrap/Button";
import ChangeRequestChanges from "./ChangeRequestChanges";
import ChangeRequestFailures from "./ChangeRequestFailures";
import { Link } from "react-router-dom";
import moment from "moment";
import usePortfolioService from "../Services/usePortfolioService";
import usePortfolioOwnerStatus from "../Hooks/usePortfolioOwnerStatus";
import useActivityOwnerStatus from "../Hooks/useActivityOwnerStatus";

export default function ChangeRequestDetails() {
  const { portfolioId, activityId, changeRequestId } = useParams();
  const [portfolio, setPortfolio] = useState({});
  const [activity, setActivity] = useState({});
  const [changeRequest, setChangeRequest] = useState({});
  const [changeSet, setChangeSet] = useState(null);
  const [validationFailures, setValidationFailures] = useState(null);
  const [file, setFile] = useState();
  const [fileLabel, setFileLabel] = useState();
  const [changeFileDisabled, setChangeFileDisabled] = useState(true);
  const { getPortfolio } = usePortfolioService();
  const isUserPortfolioOwner = usePortfolioOwnerStatus();
  const isUserActivityOwner = useActivityOwnerStatus();

  useEffect(() => {
    const fetchData = async () => {
      const response = await callApi(
        `portfolios/${portfolioId}/activities/${activityId}`
      );
      const data = await response.json();
      setActivity(data);
    };

    fetchData();
  }, [activityId, portfolioId]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getPortfolio(portfolioId);
      if (response.ok) setPortfolio(response.data);
    };

    fetchData();
  }, [portfolioId, getPortfolio]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await callApi(
        `activities/${activityId}/change-requests/${changeRequestId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setChangeRequest(data);
    };

    fetchData();
  }, [activityId, portfolioId, changeRequestId]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await callApi(
        `activities/${activityId}/change-requests/${changeRequestId}/changeset`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (response.status === 200) {
        setChangeSet(data);
        setValidationFailures(null);
      } else if (response.status === 400) {
        setChangeSet(data);
        setValidationFailures(data.dataSetValidationResponse);
      }
    };

    fetchData();
  }, [activityId, portfolioId, changeRequestId]);

  const reloadChangeRequest = async () => {
    const response = await callApi(
      `activities/${activityId}/change-requests/${changeRequestId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    setChangeRequest(data);
  };

  const reloadChangeSet = async () => {
    const response = await callApi(
      `activities/${activityId}/change-requests/${changeRequestId}/changeset`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    if (response.status === 200) {
      setChangeSet(data);
      setValidationFailures(null);
    } else if (response.status === 400) {
      setChangeSet(data);
      setValidationFailures(data.dataSetValidationResponse);
    }
  };

  const handleChangeRequestApprove = async () => {
    const response = await callApi(
      `activities/${activityId}/change-requests/${changeRequestId}/approve`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.ok) {
      setChangeSet(null);
      reloadChangeRequest();
    }
  };

  const handleDownloadFile = async () => {
    const downloadUrl = `api/activities/${activityId}/change-requests/${changeRequestId}/download`;
    const token = await authService.getAccessToken();
    const options = {
      headers: {
        method: "GET",
      },
    };
    options.headers.Authorization = `Bearer ${token}`;
    await fetch(downloadUrl, options).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.target = "_BLANK";
        a.rel = "noopener noreferrer";
        a.href = url;
        a.download = `${changeRequest.title}`;
        a.click();
      });
    });
  };

  const onFileChangeHandler = (event) => {
    let fileCount = event.target.files.length;
    if (fileCount === 1) {
      let fileToImport = event.target.files[0];
      setFile(fileToImport);
      setFileLabel(fileToImport.name);
      setChangeFileDisabled(false);
    } else {
      setChangeFileDisabled(true);
    }
  };

  const handleChangeFile = async () => {
    const formData = new FormData();
    formData.append("file", file);

    const response = await callApi(
      `activities/${activityId}/change-requests/${changeRequestId}/updateFile`,
      {
        method: "POST",
        body: formData,
        headers: {},
      }
    );

    if (response.ok) {
      reloadChangeSet();
      reloadChangeRequest();
      setFileLabel("");
      setChangeFileDisabled(true);
    }
  };

  return (
    <>
      <div className="page-title">
        <div className="title">
          <nav class="breadcrumbs">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              <li class="breadcrumb-item">
                <Link to={`/${portfolioId}`}>{portfolio.title}</Link>
              </li>
              <li class="breadcrumb-item active">
                <Link to={`/${portfolioId}/activities/${activityId}`}>
                  {activity.title}
                </Link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Change Request
              </li>
            </ol>
          </nav>
          <h1 className="h1">Change Request</h1>
        </div>
      </div>

      <h2 className="h2 mb-4">Change Request</h2>

      <div className="text-secondary mb-1 mt-3">
        Created {moment(changeRequest.created).format("Do MMM YYYY")} by{" "}
        {changeRequest.createdByUserUserName}
      </div>

      <div className="text-secondary mb-4">
        Last Updated: {moment(changeRequest.updated).format("Do MMMM")}
      </div>

      <p className="description mb-0">Change Request Title</p>
      <p>{changeRequest.title}</p>

      <div className="file-download">
        <p className="description mb-3">Current Uploaded File</p>
        <div className="d-flex mb-1">
          <p className="mb-0 me-3">{changeRequest.title}</p>

          <button className="icon-and-text" onClick={handleDownloadFile}>
            <i className="icon bi bi-cloud-arrow-down"></i>
            Download File
          </button>
        </div>
      </div>

      {changeRequest.canChangeFile ? (
        <div className="mt-4">
          <h3 className="h3">Change File</h3>
          <div className="d-flex">
            <div className="file-input mb-4">
              <input
                type="file"
                id="file"
                className="file"
                onChange={onFileChangeHandler}
              />
              <label for="file">
                <i className="icon bi bi-file-earmark"></i> Select file
              </label>
              {fileLabel ? (
                <div className="file-name custom-file-label" htmlFor="file">
                  {fileLabel}
                </div>
              ) : null}
            </div>
          </div>
          <Button
            className="button primary"
            onClick={handleChangeFile}
            disabled={changeFileDisabled}
          >
            Change
          </Button>
        </div>
      ) : (
        <></>
      )}

      {changeRequest.canImport && (
        <>
          {changeSet != null && (
            <>
              <h3 className="h3 mt-4">Changes</h3>
              {changeSet.hasChanges ? (
                <>
                  <ChangeRequestChanges
                    changeSet={changeSet}
                    portfolioId={portfolioId}
                    activityId={activityId}
                  />
                  {(isUserActivityOwner || isUserPortfolioOwner) && (
                    <div className="mt-4 mb-3 d-flex">
                      <Button
                        className="button primary"
                        onClick={handleChangeRequestApprove}
                      >
                        Approve
                      </Button>
                    </div>
                  )}
                </>
              ) : (
                <p>There are no changes.</p>
              )}
            </>
          )}

          {validationFailures != null && (
            <ChangeRequestFailures failures={validationFailures} />
          )}
          {changeSet != null && changeSet.isNAExistsOnly && (
            <>
              <div className="alert error">
                <p className="text-bold">
                  The indicator(s) imported have missing data, are you sure you
                  want to import without providing period and/or target and
                  actual data?<br></br>if this correct, proceed with the
                  submission.
                </p>
              </div>
              {(isUserActivityOwner || isUserPortfolioOwner) && (
                <div className="mt-4 mb-3 d-flex">
                  <Button
                    className="button primary"
                    onClick={handleChangeRequestApprove}
                  >
                    Approve
                  </Button>
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
