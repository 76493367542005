import React from "react";
import { Link, useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { BsBoxArrowInDownLeft } from "react-icons/bs";
import UnlinkButton from "../../Shared/UnlinkButton";
import Form from "react-bootstrap/Form";
import usePortfolioOwnerStatus from "../../Hooks/usePortfolioOwnerStatus";

export default function RelationSidebarContent({
  fromResult,
  toResult,
  edge,
  evidenceList,
  evidenceIsJustified,
  onClickLinkEvidence,
  onClickUnlinkEvidence,
  handleDeleteEvidence,
  handleEvidenceIsJustifiedChanged,
}) {
  const { portfolioId } = useParams();
  evidenceList = evidenceList ? evidenceList : [];

  const onIsEvidenceJustifiedChanged = (value) => {
    handleEvidenceIsJustifiedChanged(edge, value);
  };
  const isUserPortfolioOwner = usePortfolioOwnerStatus();
  return (
    <>
      <h3>Relation</h3>
      <div className="font-weight-bold text-secondary">From</div>
      <p>{fromResult.title}</p>
      <div className="font-weight-bold text-secondary">To</div>
      <p>{toResult.title}</p>
      {fromResult.activityId != null ? (
        <Link
          to={`/${portfolioId}/activities/${fromResult.activityId}/relations/${edge}`}
        >
          More information <BsBoxArrowInDownLeft className="ml-1" />
        </Link>
      ) : (
        <Link to={`/${portfolioId}/relations/${edge}`}>
          More information <BsBoxArrowInDownLeft className="ml-1" />
        </Link>
      )}
      <hr />

      <h3>Evidence</h3>
      {
        <>
          <Form.Group controlId="evidenceExternallyProduced" className="mt-2">
            {isUserPortfolioOwner && (
              <Form.Check
                type="checkbox"
                checked={evidenceIsJustified}
                onChange={(e) => onIsEvidenceJustifiedChanged(e.target.checked)}
                label="This relation is justified"
              />
            )}
          </Form.Group>
          {isUserPortfolioOwner && (
            <Button
              className="mr-2"
              size="sm"
              variant="primary"
              onClick={() => onClickLinkEvidence(edge)}
            >
              Link Existing Evidence
            </Button>
          )}
        </>
      }
      {evidenceList.map((evidence) => (
        <Card key={evidence.id} className="p-2 mt-2">
          <div className="d-flex w-100 justify-content-between align-items-center">
            <p className="font-weight-bold mb-0">
              {evidence.title ? evidence.title : evidence.type}
            </p>
            {
              <div>
                {/*
                <DeleteButton
                  onClick={() => handleDeleteEvidence(evidence.id)}
                />*/}
                {isUserPortfolioOwner && (
                  <UnlinkButton
                    className="ml-1"
                    onClick={() => onClickUnlinkEvidence(edge, evidence)}
                  />
                )}
              </div>
            }
          </div>
          <small className="d-flex text-secondary">{evidence.type}</small>
          <p>{evidence.summary}</p>
        </Card>
      ))}
    </>
  );
}
