import React, { useEffect, useState } from "react";
import useResultService from "../Services/useResultService";

export default function ResultTableData({ resultId }) {
  const [result, setResult] = useState({});
  const { getResult } = useResultService();

  useEffect(() => {
    const fetchData = async () => {
      const response = await getResult(resultId);
      if (response.ok) {
        setResult(response.data);
      }
    };

    fetchData();
  }, [resultId, getResult]);

  return <td>{result.title}</td>;
}
