import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import useActivityService from "../../Services/useActivityService";

export default function RelationList({ activityId, portfolioId }) {
  const [results, setResults] = useState([]);
  const { getResults } = useActivityService();

  useEffect(() => {
    const fetchData = async () => {
      const response = await getResults(activityId, portfolioId);
      setResults(response.data);
    };

    fetchData();
  }, [activityId, portfolioId, getResults]);

  if (!results.some((r) => r.relations.length > 0)) {
    return (
      <>
        <h2 className="h2">Relations</h2>
        <p className="mt-2 text-secondary">
          There are no relations in this activity
        </p>
      </>
    );
  }

  return (
    <>
      <h2 className="h2">Relations</h2>
      {results.length ? (
        <Table className="mt-3" bordered>
          <thead>
            <tr>
              <th>From</th>
              <th>To</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {results.map((r) =>
              r.relations.map((rel) => {
                const fromResult = results.find(
                  (r) => r.id === rel.fromResultId
                );
                const toResult = results.find((r) => r.id === rel.toResultId);

                if (!fromResult || !toResult) return null;

                return (
                  <tr key={rel.id}>
                    <td>
                      <div>{fromResult.title}</div>
                      <small>
                        {fromResult.labels &&
                          fromResult.labels.split(",").map((l, i) => (
                            <span key={i} className="badge info">
                              {l}
                            </span>
                          ))}
                      </small>
                    </td>
                    <td>
                      <div>{toResult.title}</div>
                      <small>
                        {toResult.labels &&
                          toResult.labels.split(",").map((l, i) => (
                            <span key={i} className="badge success">
                              {l}
                            </span>
                          ))}
                      </small>
                    </td>
                    <td className="align-top">
                      <Link
                        className="icon-and-text"
                        to={(location) => ({
                          ...location,
                          pathname: location.pathname + `/${rel.id}`,
                        })}
                      >
                        <i class="icon bi-arrow-right-circle"></i>View
                      </Link>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </Table>
      ) : (
        <p>There are no relations</p>
      )}
    </>
  );
}
