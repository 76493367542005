import React, { useState, useEffect } from "react";
import useResultService from "../../Services/useResultService";
import Badge from "react-bootstrap/Badge";
import ListGroup from "react-bootstrap/ListGroup";
import { Link, useParams } from "react-router-dom";
import { BsBoxArrowInDownLeft } from "react-icons/bs";
import { Spinner } from "react-bootstrap";

export default function ResultSidebarContent({ result }) {
  const { portfolioId, activityId } = useParams();
  const [indicators, setIndicators] = useState([]);
  const { getIndicators } = useResultService();
  const [showSpinner, setShowSpinner] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getIndicators(result.id, portfolioId);
      setShowSpinner(false);
      if (response.ok) {
        setIndicators(response.data);
      }
    };

    fetchData();
  }, [result, getIndicators, portfolioId]);

  return (
    <>
      <h3>Result</h3>
      <div className="font-weight-bold text-secondary">Title</div>
      <p>{result.title}</p>
      <div className="font-weight-bold text-secondary">Description</div>
      <p>{result.description}</p>
      <div className="font-weight-bold text-secondary">Labels</div>
      <p>
        {result.labels &&
          result.labels.split(",").map((l, i) => (
            <span key={i} className="pl-1">
              <Badge variant="secondary">{l}</Badge>
            </span>
          ))}
      </p>
      <div className="font-weight-bold text-secondary">Document Link</div>
      <p>{result.documentLink}</p>
      <div className="font-weight-bold text-secondary mb-1">Indicators</div>
      {showSpinner === true ? (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      ) : (
        <ListGroup>
          {indicators.map((i) => (
            <ListGroup.Item key={i.id}>
              <Link
                to={`/${portfolioId}/activities/${activityId}/results/${result.id}/${i.id}`}
              >
                {i.title}
                <BsBoxArrowInDownLeft className="ml-1" />
              </Link>
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}
    </>
  );
}
