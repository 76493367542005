import React from "react";

const ManageIndicatorPopover = React.forwardRef((props, ref) => {
  return (
    <>
      {props.removeVisible === false &&
        props.isFromNestingOrSharedActivity === false && (
          <div className="icon-and-text has-left-border" onClick={props.onClickDelete}>
            <i className="icon bi bi-trash"></i>Delete
          </div>

        )}
      {props.removeVisible === false &&
        props.isFromNestingOrSharedActivity === false && (
          <div className="icon-and-text has-left-border" onClick={props.onClickEdit}>
            <i className="icon bi bi-pencil-square"></i>
            Edit
          </div>
        )}
      {props.isUserPortfolioOwner === true &&
        props.removeVisible === false &&
        props.isFromNestingOrSharedActivity === false && (
          <div className="icon-and-text has-left-border" onClick={props.onClickShare}>
            <i className="icon bi bi-share"></i>
            Share
          </div>
        )}
      {props.removeVisible === true && (
        <>
          <div className="icon-and-text has-left-border" onClick={props.onClickRemove}>
            <i className="icon bi bi-trash"></i>
            Remove
          </div>
          <div className="icon-and-text has-left-border" onClick={props.onClickGotoHome}>
            <i className="icon bi bi-arrow-right-circle"></i>
            Home Portfolio
          </div>
        </>
      )}
      {props.isFromNestingOrSharedActivity === true && (
        <>
          {props.isPublic ? (
            <div className="icon-and-text has-left-border" onClick={props.onClickAddToPortfolio}>
              <i className="icon bi bi-pluscircle"></i>
              Add to portfolio
            </div>
          ) : (
            <div className="icon-and-text has-left-border" onClick={props.onClickAskToShare}>
              <i className="icon bi bi-share"></i>
              Ask owner to share
            </div>
          )}
        </>
      )}
    </>
  );
});

export default ManageIndicatorPopover;
