import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ResultListItem from "./ResultListItem";
import AddResultModal from "./AddResultModal";
import EditResultModal from "./EditResultModal";
import AddIndicatorModal from "./AddIndicatorModal/AddIndicatorModal";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import DeleteResultModal from "./DeleteResultModal";
import useActivityService from "../Services/useActivityService";
import useResultService from "../Services/useResultService";
import { mutate } from "swr";

export default function ResultList({
  activity,
  isUserActivityOwner,
  isUserPortfolioOwner,
}) {
  const [results, setResults] = useState([]);
  const [viewType, setViewType] = useState("tabular");
  const [isAddResultModalOpen, setAddResultModalOpen] = useState(false);
  const [isEditResultModalOpen, setEditResultModalOpen] = useState(false);
  const [isAddIndicatorModalOpen, setAddIndicatorModalOpen] = useState(false);
  const [isDeleteResultModalOpen, setDeleteResultModalOpen] = useState(false);
  const [resultIdToBeDeleted, setResultIdToBeDeleted] = useState(null);
  const [resultToBeEdited, setResultToBeEdited] = useState(null);
  const { portfolioId, activityId } = useParams();
  const { getResultsWithIndicators, createResult } = useActivityService();
  const { getIndicators, updateResult, deleteResult, linkIndicator } =
    useResultService();

  useEffect(() => {
    const fetchResults = async () => {
      const response = await getResultsWithIndicators(activityId, portfolioId);
      const results = response.data;
      results.map(async (r) => {
        const response = await getIndicators(r.id, portfolioId);
        const indicators = response.data;
        setResults((prevState) =>
          prevState.map((result) => {
            if (result.id === r.id) {
              result.indicators = indicators;
            }
            return result;
          })
        );
        return r;
      });

      setResults(results);
    };

    fetchResults();
  }, [activityId, portfolioId, getIndicators, getResultsWithIndicators]);

  const handleAddResultModalOpen = () => setAddResultModalOpen(true);
  const handleAddResultModalClose = () => setAddResultModalOpen(false);
  const handleAddIndicatorModalOpen = () => setAddIndicatorModalOpen(true);
  const handleAddIndicatorModalClose = () => setAddIndicatorModalOpen(false);
  const handleDeleteResultModalOpen = () => setDeleteResultModalOpen(true);
  const handleDeleteResultModalClose = () => {
    setResultIdToBeDeleted(null);
    setDeleteResultModalOpen(false);
  };
  const handleEditResultModalOpen = () => setEditResultModalOpen(true);
  const handleEditResultModalClose = () => {
    setResultToBeEdited(null);
    setEditResultModalOpen(false);
  };

  const handleAddResultSubmit = async (title, description, labels) => {
    const response = await createResult(
      parseInt(activityId),
      title,
      description,
      labels
    );

    if (response.ok) {
      mutate(`activities/${activityId}/results/${portfolioId}`);
      mutate(`portfolios/${portfolioId}/allresults`);
      setResults([...results, response.data]);
    }

    handleAddResultModalClose();
  };

  const handleEditResultSubmit = async (
    resultId,
    activityId,
    portfolioId,
    title,
    description,
    labels,
    position
  ) => {
    const response = await updateResult({
      id: resultId,
      activityId,
      portfolioId,
      title,
      description,
      labels,
      position,
    });

    if (response.ok) {
      mutate(`activities/${activityId}/results/${portfolioId}`);
      mutate(`portfolios/${portfolioId}/allresults`);

      let result = response.data;
      setResults((prevState) =>
        prevState.map((r) => {
          if (r.id === result.id) {
            result.indicators = r.indicators;
            return result;
          }
          return r;
        })
      );
      handleEditResultModalClose();
    }
  };

  const handleLinkIndicatorSubmit = async (result, indicators) => {
    for (let indicator of indicators) {
      const response = await linkIndicator(result.id, indicator.id);

      if (response.ok) {
        setResults((prevState) => {
          return prevState.map((r) => {
            if (r.id === result.id) {
              r.indicators = [...r.indicators, indicator];
            }
            return r;
          });
        });
      }
    }

    handleAddIndicatorModalClose();
  };

  const handleClickEditResult = (result) => {
    setResultToBeEdited(result);
    handleEditResultModalOpen();
  };

  const handleClickDeleteResult = (resultId) => {
    setResultIdToBeDeleted(resultId);
    handleDeleteResultModalOpen();
  };

  const handleDeleteResult = async () => {
    const response = await deleteResult(resultIdToBeDeleted);

    if (response.ok) {
      mutate(`activities/${activityId}/results/${portfolioId}`);
      mutate(`portfolios/${portfolioId}/allresults`);

      setResults((prevstate) =>
        prevstate.filter((r) => r.id !== resultIdToBeDeleted)
      );
      handleDeleteResultModalClose();
    }
  };

  const handleRemoveIndicator = (result, indicatorId) => {
    setResults((prevstate) => {
      return prevstate.map((r) => {
        if (r.id === result.id) {
          r.indicators = r.indicators.filter((i) => i.id !== indicatorId);
        }
        return r;
      });
    });
  };

  return (
    <>
      {!activity.isShared && (
        <>
          <h2 className="h2">Results</h2>
          {(isUserPortfolioOwner || isUserActivityOwner) && (
            <div className="actions">
              <button
                className="icon-and-text"
                onClick={handleAddResultModalOpen}
              >
                <i className="icon bi bi-bar-chart"></i>
                Add Result
              </button>
              <button
                className="icon-and-text has-left-border"
                onClick={handleAddIndicatorModalOpen}
              >
                <i className="icon bi bi-speedometer2 ps-3"></i>
                Add Indicator
              </button>
            </div>
          )}
        </>
      )}
      {(isUserPortfolioOwner || isUserActivityOwner) && !activity.isShared && (
        <></>
      )}
      <div className="mt-4 mb-4 w-25">
        <p className="description">Change data view</p>
        <DropdownButton
          title={viewType.charAt(0).toUpperCase() + viewType.slice(1)}
        >
          <Dropdown.Item onSelect={() => setViewType("graphical")}>
            Graphical
          </Dropdown.Item>
          <Dropdown.Item onSelect={() => setViewType("tabular")}>
            Tabular
          </Dropdown.Item>
        </DropdownButton>
      </div>

      {results.map((r, i) => (
        <ResultListItem
          viewType={viewType}
          result={r}
          key={i}
          recordID={i}
          onClickDeleteResult={handleClickDeleteResult}
          onClickEditResult={handleClickEditResult}
          onRemoveIndicator={handleRemoveIndicator}
          isSharedActivity={activity.isShared}
          isUserActivityOwner={isUserActivityOwner}
          isUserPortfolioOwner={isUserPortfolioOwner}
        />
      ))}
      {resultToBeEdited && (
        <EditResultModal
          isOpen={isEditResultModalOpen}
          onHide={handleEditResultModalClose}
          onSubmit={handleEditResultSubmit}
          resultBeingEdited={resultToBeEdited}
        />
      )}
      <AddResultModal
        isOpen={isAddResultModalOpen}
        onHide={handleAddResultModalClose}
        onSubmit={handleAddResultSubmit}
      />
      <AddIndicatorModal
        isOpen={isAddIndicatorModalOpen}
        onHide={handleAddIndicatorModalClose}
        onLinkIndicatorSubmit={handleLinkIndicatorSubmit}
        portfolioId={portfolioId}
        results={results}
      />
      <DeleteResultModal
        isOpen={isDeleteResultModalOpen}
        onHide={handleDeleteResultModalClose}
        onSubmit={handleDeleteResult}
      />
    </>
  );
}
