import React from "react";
import { Switch, Route } from "react-router-dom";
import EvidenceDetails from "./EvidenceDetails";
import EvidenceDetailsForPortfolio from "./EvidenceDetailsForPortfolio";

export default function EvidenceLayout() {
  return (
    <Switch>
      <Route path="/:portfolioId/activities/:activityId/evidence/:evidenceId">
        <EvidenceDetails />
      </Route>
      <Route path="/:portfolioId/evidence/:evidenceId">
        <EvidenceDetailsForPortfolio />
      </Route>
    </Switch>
  );
}
