import React, { useState } from "react";

export default function ImportActivityDataResponse(importResponse) {
    const [errors] = useState(importResponse.importResponse.errors);
    const [dataRows] = useState(importResponse.importResponse.dataRows);
    const [success] = useState(importResponse.importResponse.success);
    const [source] = useState(importResponse.importResponse.source);
    const rowStatusValues = [
        "Record is valid and will be updated",
        "Record is valid and will be added",
        "Record is valid",
        "Record is not valid",
        "Record is valid and has been updated",
        "Record is valid and will been added",
    ];
    return (
        <div className="py-3">
            <h2>{success ? "Imported Successfully." : "Import Failed."}</h2>

            {!success && (
                <div>
                    <h3>{source.fileName}</h3>

                    {errors.length > 0 && (
                        <table className="table table-sm">
                            <thead>
                                <tr>
                                    <th>Error</th>
                                </tr>
                            </thead>
                            {errors ? (
                                errors.map((e, key) => (
                                    <tr>
                                        <td>{e.error}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr className="text-secondary">
                                    <td>There are no errors</td>
                                </tr>
                            )}
                        </table>
                    )}

                    {dataRows.length > 0 && (
                        <>
                            <p>Row Details:</p>
                            <table className="table table-sm">
                                <thead>
                                    <tr>
                                        <th>Number</th>
                                        <th>Status</th>
                                        <th>Errors</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataRows ? (
                                        dataRows.map((dr, drKey) => (
                                            <tr>
                                                <td>{dr.rowNumber}</td>
                                                <td>{rowStatusValues[dr.rowStatus - 1]}</td>
                                                <td>
                                                    <ul>
                                                        {dr.errors.map((dre, dreKey) => (
                                                            <li>
                                                                {dre.columnName} - {dre.error}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td>None</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </>
                    )}
                </div>
            )}
        </div>
    );
}
