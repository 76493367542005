import React from "react";
import ListGroup from "react-bootstrap/ListGroup";
import format from "../Helpers/formatNumber";
import moment from "moment";
import {
  aggregatePeriodsActual,
  aggregatePeriodsTarget,
  aggregatePeriodActual,
  aggregatePeriodTarget,
  calculateProgress,
} from "../Helpers/aggregatePeriods";

export default function PeriodList({
  periods,
  onSelectPeriodId,
  selectedPeriodId,
  indicatorTypeId,
  indicatorAggregationMethod,
}) {
  const totalTotalActual = aggregatePeriodsActual(
    periods,
    indicatorAggregationMethod
  );
  const totalTotalTarget = aggregatePeriodsTarget(
    periods,
    indicatorAggregationMethod
  );

  return (
    <>

      {periods.length ? (
        <>
          {indicatorTypeId === 2 && (
            <div class="period-list-progress mb-2">
              <div class="data column">
                <div>
                  <span>Total Planned:</span>
                  {format(totalTotalTarget)}
                </div>
                <div>
                  <span>Total Achieved:</span>
                  {format(totalTotalActual)}
                </div>
                {totalTotalActual !== 0 &&
                  totalTotalTarget !== 0 && (
                    <div>
                      <span>Progress:</span>
                      {format(
                        calculateProgress(totalTotalActual, totalTotalTarget)
                      ) + "%"}
                    </div>
                  )}
              </div>
            </div>
          )}

          <ListGroup className="mt-1 list-group period list-group-flush">
            {periods.map((period) => (
              <ListGroup.Item
                key={period.id}
                active={selectedPeriodId && selectedPeriodId === period.id}
                onClick={() => onSelectPeriodId(period.id)}
                className="list-group-item list-group-item-action mb-2"
              >
                <span className="d-block">
                  {period.title
                    ? period.title
                    : moment(period.start).format("Do MMM YYYY") +
                    " - " +
                    moment(period.end).format("Do MMM YYYY")}
                </span>

                {indicatorTypeId === 2 && (
                  <div class="period-progress">
                    <div class="data">
                      <span>Planned: {format(aggregatePeriodTarget(period))}</span>
                      <span>Achieved: {format(aggregatePeriodActual(period))}</span>
                      {aggregatePeriodTarget(period) !== 0 && (
                        <span>
                          {format(
                            calculateProgress(
                              aggregatePeriodActual(period),
                              aggregatePeriodTarget(period)
                            )
                          ) + "%"}
                        </span>
                      )}
                    </div>
                  </div>
                )}

                {period.lastUpdated != null && (
                  <div className="mt-1">
                    <span
                      className={
                        selectedPeriodId && selectedPeriodId === period.id
                          ? ""
                          : "last-updated mb-3"
                      }
                    >
                      Last Updated{" "}
                      {moment(period.lastUpdated).format("Do MMM YYYY HH:mm")}
                    </span>
                  </div>
                )}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </>
      ) : (
        <p className="my-1 text-muted">
          There are no periods of measurement for this indicator yet
        </p>
      )}
    </>
  );
}
