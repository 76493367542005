import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import callApi from "../../Helpers/callApi";
import DisaggregationList from "./DisaggregationList";
import SearchedDisaggregationsList from "./SearchedDisaggregationsList";
import DisaggregationEditModal from "./DisaggregationEditModal";
import AddDisaggregationModal from "./AddDisaggregationModal";
import { useAddToast } from "../../Context/ToastContext";
import ShareDisaggregationModalToPortfolio from "./ShareDisaggregationModalToPortfolio";
import ShareDisaggregationModal from "./ShareDisaggregationModal";
import ShareDisaggregationModalConfirmation from "./ShareDisaggregationModalConfirmation";
import AddToPortfolioConfirmation from "./AddToPortfolioConfirmation";
import RequestAccessModalConfirmation from "../ActivityList/RequestAccessModalConfirmation";
import ContactOwnerForDeleteDisaggregation from "./ContactOwnerForDeleteDisaggregation";

export default function DisaggregationsPage(props) {
  const addToast = useAddToast();
  const {
    disaggregations,
    portfolioId,
    onAddDisaggregation,
    onRemoveDisaggregation,
    onEditDisaggregation,
    isUserPortfolioOwner,
    sharedIndicatorRemoved,
  } = props;

  const [disaggregationSharedAtPortfolio, setDisaggregationSharedAtPortfolio] =
    useState([]);
  const [
    sharedActivityOrNestedPortfolioDisaggregations,
    setSharedActivityOrNestedPortfolioDisaggregations,
  ] = useState([]);
  const [
    sharedDisaggregationRemovedOrAdded,
    setSharedDisaggregationRemovedOrAdded,
  ] = useState(false);

  const [openContactOwnerModal, setOpenContactOwnerModal] = useState(false);
  const [contactOwnerPortfolio, setContactOwnerPortfolio] = useState(null);
  const [disaggregationForcontactOwner, setDisaggregationForcontactOwner] =
    useState(null);
  const [disaggregationPublicProperty, setDisaggregationPublicProperty] =
    useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const response = await callApi(
        `portfolios/${portfolioId}/shareddisaggregations`
      );
      const disaggregationResponse = await response.json();
      setDisaggregationSharedAtPortfolio(disaggregationResponse);
    };

    fetchData();
  }, [portfolioId, sharedDisaggregationRemovedOrAdded, sharedIndicatorRemoved]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await callApi(
        `portfolios/${portfolioId}/SharedActivityOrNestedPortfolioDisaggregations`
      );
      const disaggregationResponse = await response.json();
      setSharedActivityOrNestedPortfolioDisaggregations(disaggregationResponse);
    };

    fetchData();
  }, [portfolioId]);

  const [searchTerm, setSearchTerm] = useState(null);
  const [searchedDisaggregations, setSearchedDisaggregations] = useState([]);
  const [isAddDisaggregationModalOpen, setIsAddDisaggregationModalOpen] =
    useState(false);
  const [isDeleteDisaggregationModalOpen, setIsDeleteDisaggregationModalOpen] =
    useState(false);
  const [disaggregationToBeDeleted, setDisaggregationToBeDeleted] =
    useState(null);

  const [typedValueEdited, setTypedValueEdited] = useState("");
  const [isAddingNewDisaggregation, setIsAddingNewDisaggregation] =
    useState(false);
  const [disaggregationToBeEdited, setDisaggregationToBeEdited] =
    useState(null);
  const [disaggregationToBeShared, setDisaggregationToBeShared] =
    useState(null);
  const [disaggregationValuesToBeEdited, setDisaggregationValuesToBeEdited] =
    useState([]);
  const [isEditDisaggregationModalOpen, setIsEditDisaggregationModalOpen] =
    useState(false);
  const [
    isShareDisaggregationModalToPortfolioOpen,
    setIsShareDisaggregationModalToPortfolioOpen,
  ] = useState(false);
  //const [allDisaggregations, setAllDisaggregations] = useState([]);
  const [isShareDisaggregationModalOpen, setIsShareDisaggregationModalOpen] =
    useState(false);
  const [
    isShareDisaggregationModalOpenConfirmation,
    setIsShareDisaggregationModalOpenConfirmation,
  ] = useState(false);
  const [sharedDisaggregations, setSharedDisaggregations] = useState([]);
  const [portfolioUniqueTitle, setPortfolioUniqueTitle] = useState(null);
  const [isRemoveDisaggregationModalOpen, setIsRemoveDisaggregationModalOpen] =
    useState(false);
  const [disaggregationToBeRemoved, setDisaggregationToBeRemoved] =
    useState(null);
  const [isRequestAccess, setIsRequestAccess] = useState(false);
  const [disaggregationAskedToShare, setDisaggregationAskedToShare] =
    useState(null);
  const [disaggregationAddToPortfolio, setDisaggregationAddToPortfolio] =
    useState(null);
  const [isAddToPortfolioOpen, setIsAddToPortfolioOpen] = useState(false);

  const handleDisaggregationToBeEditedClick = (disaggregation) => {
    setIsEditDisaggregationModalOpen(true);
    setDisaggregationToBeEdited(disaggregation);
    setDisaggregationValuesToBeEdited(disaggregation.disaggregationValues);
    setDisaggregationPublicProperty(disaggregation.isPublic);
  };

  const handleDisaggregationToBeSharedClick = (disaggregation) => {
    setDisaggregationToBeShared(disaggregation);
    setSharedDisaggregations((item) => [...item, disaggregation.id]);
    setIsShareDisaggregationModalOpen(true);
  };

  const handleEditDisaggregationModalClose = () => {
    setDisaggregationToBeEdited(null);
    setDisaggregationValuesToBeEdited(null);
    setIsEditDisaggregationModalOpen(false);
  };
  const handleSetEditedDisaggregationProperty = (property, value) => {
    setDisaggregationToBeEdited((prevState) => ({
      ...prevState,
      [property]: value,
    }));
  };
  const handleTypedValueChange = (changedValue) => {
    setTypedValueEdited(changedValue);
  };
  const handleNewDisaggregationValueEditPage = () => {
    let tempData = {
      value: typedValueEdited,
      disaggregation: disaggregationToBeEdited.id,
    };
    setDisaggregationValuesToBeEdited((prevValues) => [
      ...prevValues,
      tempData,
    ]);
    setTypedValueEdited("");
    setDisaggregationToBeEdited((p) => ({
      ...p,
      disaggregationValues: [...p.disaggregationValues, tempData],
    }));
  };

  const handleRemoveDisaggregationValueToBeEdited = (val) => {
    setDisaggregationToBeEdited((p) => ({
      ...p,
      disaggregationValues: p.disaggregationValues.filter((d) => d.id !== val),
    }));
    setDisaggregationValuesToBeEdited((prevState) =>
      prevState.filter((data) => data.id !== val)
    );
  };
  const handleAddDisaggregationSubmit = async (
    title,
    description,
    disaggregationValues,
    IsPublic
  ) => {
    const response = await callApi(
      `portfolios/${portfolioId}/disaggregations`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          title,
          description,
          disaggregationValues: disaggregationValues.map((v) => ({
            value: v,
          })),
          IsPublic,
        }),
      }
    );
    if (response.ok) {
      const newDisaggregation = await response.json();
      onAddDisaggregation(newDisaggregation);
    }
    setIsAddDisaggregationModalOpen(false);
  };

  const handleCopyDisaggregationSubmit = async (disaggregation) => {
    const response = await callApi(
      `portfolios/${portfolioId}/disaggregation/${disaggregation.id}/AddToDisaggregation`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.ok) {
      //const newDisaggregation = await response.json();
      //onAddDisaggregation(newDisaggregation);
      setIsAddingNewDisaggregation(false);
      setSearchTerm(null);
      setSearchedDisaggregations(null);
    } else {
      const jsonResponse = await response.json();
      addToast({
        variant: "error",
        message: jsonResponse.errors[0].message,
      });
    }
  };

  const handleSearchDisaggregations = async (searchString) => {
    setSearchTerm(searchString);
    const response = await callApi(
      `disaggregations/${portfolioId}/search/${searchString}`
    ); //`disaggregations?searchString=${searchString}`

    if (response.ok) {
      const searchedDisaggregations = await response.json();
      setSearchedDisaggregations(searchedDisaggregations);
    }
  };

  const handleDeleteDisaggregation = async () => {
    const response = await callApi(
      `disaggregations/${disaggregationToBeDeleted.id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.ok) {
      onRemoveDisaggregation(disaggregationToBeDeleted);
    } else {
      const jsonResponse = await response.json();
      addToast({
        variant: "error",
        message: jsonResponse.errors[0].description,
      });
    }

    setIsDeleteDisaggregationModalOpen(false);
    setDisaggregationToBeDeleted(null);
  };

  const handleDeleteDisaggregationClick = (disaggregation) => {
    setIsDeleteDisaggregationModalOpen(true);
    setDisaggregationToBeDeleted(disaggregation);
  };

  const handleEditDisaggregationSubmit = async () => {
    const response = await callApi(
      `disaggregations/${disaggregationToBeEdited.id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(disaggregationToBeEdited),
      }
    );
    if (response.ok) {
      const editedDisaggregation = await response.json();

      if (editedDisaggregation.isPublic !== disaggregationPublicProperty) {
        if (editedDisaggregation.isPublic === false) {
          addToast({
            variant: "information",
            isDanger: false,
            message:
              "The disaggregation is being changed to private, the disaggregation will no longer be available to find and add by other users but existing uses will continue as shared usage.",
          });
        } else {
          addToast({
            variant: "information",
            isDanger: false,
            message:
              "This disaggregation will now be available for users to find and add to their portfolios, existing shared users will be unaffected.",
          });
        }
      }

      onEditDisaggregation(editedDisaggregation);
    }
    setDisaggregationToBeEdited(null);
    setDisaggregationValuesToBeEdited([]);
    handleEditDisaggregationModalClose();
  };

  const handleShareDisaggregationsToPortfolioModalClose = () => {
    setIsShareDisaggregationModalToPortfolioOpen(false);
  };

  const handleShareDisaggregationsToPortfolioModalOpen = () => {
    setIsShareDisaggregationModalToPortfolioOpen(true);
  };

  const handleShareDisaggregationToPortfolioSubmit = async (
    portfolioUniqueTitle,
    disaggregationIds,
    shareAll
  ) => {
    if (shareAll) {
      await callApi(
        `portfolios/${portfolioId}/disaggregation/share-all-disaggregation?destinationPortfolioUniqueTitle=${portfolioUniqueTitle}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } else {
      await callApi(
        `portfolios/${portfolioId}/disaggregation/share-disaggregation?destinationPortfolioUniqueTitle=${portfolioUniqueTitle}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            disaggregationIds: disaggregationIds,
          }),
        }
      );
    }

    //mutate(`portfolios/${portfolioId}/evidence`);
  };

  /*useEffect(() => {
    const fetchData = async () => {
      const response = await callApi(`portfolios/${portfolioId}/allIndicators`);
      const indicatorsResponse = await response.json();
      setAllDisaggregations(indicatorsResponse);
    };

    fetchData();
  }, [portfolioId]);*/

  const handleShareDisaggregationModalClose = () => {
    setDisaggregationToBeShared(null);
    setSharedDisaggregations([]);
    setIsShareDisaggregationModalOpen(false);
  };

  const handleShareDisaggregationModalOpenConfirmation = () => {
    setIsShareDisaggregationModalOpenConfirmation(true);
    setIsShareDisaggregationModalOpen(false);
  };

  const handleShareDisaggregationModalConfirmationClose = () => {
    setDisaggregationToBeShared(null);
    setSharedDisaggregations([]);
    setPortfolioUniqueTitle(null);
    setIsShareDisaggregationModalOpenConfirmation(false);
  };

  const handleRemoveDisaggregationClick = (disaggregation) => {
    setDisaggregationToBeRemoved(disaggregation);
    setIsRemoveDisaggregationModalOpen(true);
  };

  const handleRemoveDisaggregationClose = () => {
    setDisaggregationToBeRemoved(null);
    setIsRemoveDisaggregationModalOpen(false);
  };

  const handleRemoveDisaggregationConfirm = async () => {
    let removeResponse = await callApi(
      `portfolios/${portfolioId}/removedisaggregationsharing/${disaggregationToBeRemoved.id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!removeResponse.ok) {
      const jsonResponse = await removeResponse.json();
      addToast({
        variant: "error",
        message: jsonResponse.error[0],
      });
    } else {
      setSharedDisaggregationRemovedOrAdded(
        !sharedDisaggregationRemovedOrAdded
      );
      handleRemoveDisaggregationClose();
    }
  };

  const handleGotoHomePortfolioClick = (disaggregation) => {
    window.location.replace(
      `/${disaggregation.sharedFromPortfolioId}/disaggregations`
    );
  };

  const handleAddToPortfolioSubmit = async () => {
    const response = await callApi(
      `portfolios/${portfolioId}/disaggregation/${disaggregationAddToPortfolio.id}/disaggregation-addtoportfolio`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.ok) {
      setIsAddToPortfolioOpen(false);
      setDisaggregationAddToPortfolio(null);
    }
    setSharedDisaggregationRemovedOrAdded(!sharedDisaggregationRemovedOrAdded);
  };

  const handleAddToPortfolio = async (disaggregation) => {
    setDisaggregationAddToPortfolio(disaggregation);
    setIsAddToPortfolioOpen(true);
  };

  const handleAddToPortfolioClose = () => {
    setIsAddToPortfolioOpen(false);
    setDisaggregationAddToPortfolio(null);
  };

  const handleAskToShare = async (disaggregation) => {
    setDisaggregationAskedToShare(disaggregation);
    setIsRequestAccess(true);
  };

  const handleAskToShareSubmit = async () => {
    const response = await callApi(
      `portfolios/${portfolioId}/disaggregation/${disaggregationAskedToShare.id}/disaggregation-asktoshare`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.ok) {
      setIsRequestAccess(false);
      setDisaggregationAskedToShare(null);
    }
  };

  const hideAskToShare = () => {
    setIsRequestAccess(false);
    setDisaggregationAskedToShare(null);
    setDisaggregationForcontactOwner(null);
  };

  const handleContactPortfolioOwner = (portfolio, disaggregation) => {
    setContactOwnerPortfolio(portfolio);
    setDisaggregationForcontactOwner(disaggregation);
    setOpenContactOwnerModal(true);
  };

  const contactPortfolioOwnerSubmit = async () => {
    await callApi(
      `portfolios/${contactOwnerPortfolio.id}/contactdisaggregationsharedportfolioowner/${disaggregationForcontactOwner.id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    setContactOwnerPortfolio(null);
    setOpenContactOwnerModal(false);
  };

  const hideContactOwnerModal = () => {
    setContactOwnerPortfolio(null);
    setOpenContactOwnerModal(false);
  };

  return (
    <>
      <div className="mb-2">
        <h2 className="h2">Disaggregations</h2>
        {isAddingNewDisaggregation ? (
          <>
            <div className="filter-activities mb-4">
              <label className="bold">Search existing disaggregations</label>
              <Form.Control
                type="text"
                placeholder="Search existing disaggregations"
                onChange={(e) => handleSearchDisaggregations(e.target.value)}
              />
            </div>
            <div className="actions">
              <div
                className="icon-and-text"
                variant="primary"
                onClick={() => setIsAddDisaggregationModalOpen(true)}
              >
                <i className="icon bi bi-plus-circle"></i>
                Create Disaggregation
              </div>
              <div
                className="icon-and-text has-left-border"
                onClick={() => setIsAddingNewDisaggregation(false)}
              >
                <i class="icon bi bi-x-circle"></i>
                Cancel
              </div>
              <div
                className="icon-and-text has-left-border"
                onClick={handleShareDisaggregationsToPortfolioModalOpen}
              >
                <i className="icon bi bi-share"></i>
                Share Disaggregations
              </div>
            </div>
          </>
        ) : isUserPortfolioOwner ? (
          <>
            <div
              className="icon-and-text"
              onClick={() => setIsAddingNewDisaggregation(true)}
            >
              <i className="icon bi bi-plus-circle"></i>
              Add Disaggregation
            </div>
            <div
              className="icon-and-text has-left-border"
              onClick={handleShareDisaggregationsToPortfolioModalOpen}
            >
              <i className="icon bi bi-share"></i>
              Share Disaggregations
            </div>
          </>
        ) : null}
      </div>
      {isAddingNewDisaggregation ? (
        <SearchedDisaggregationsList
          disaggregations={searchedDisaggregations}
          onCopyDisaggregation={handleCopyDisaggregationSubmit}
          searchTerm={searchTerm}
        />
      ) : (
        <DisaggregationList
          portfolioId={portfolioId}
          disaggregations={disaggregations}
          onDeleteDisaggregationClick={handleDeleteDisaggregationClick}
          onEditDisaggregationClick={handleDisaggregationToBeEditedClick}
          onShareDisaggregationClick={handleDisaggregationToBeSharedClick}
          isUserPortfolioOwner={isUserPortfolioOwner}
          disaggregationsSharedAtPortfolio={disaggregationSharedAtPortfolio}
          onRemoveDisaggregationClick={handleRemoveDisaggregationClick}
          onGoToHomePortfolioClick={handleGotoHomePortfolioClick}
          sharedActivityOrNestedPortfolioDisaggregations={
            sharedActivityOrNestedPortfolioDisaggregations
          }
          handleAddToPortfolio={handleAddToPortfolio}
          handleAskToShare={handleAskToShare}
          handleContactPortfolioOwner={handleContactPortfolioOwner}
        />
      )}

      <AddDisaggregationModal
        isOpen={isAddDisaggregationModalOpen}
        onHide={() => setIsAddDisaggregationModalOpen(false)}
        onSubmit={handleAddDisaggregationSubmit}
      />
      <ShareDisaggregationModalToPortfolio
        portfolioId={portfolioId}
        isOpen={isShareDisaggregationModalToPortfolioOpen}
        onSubmit={handleShareDisaggregationToPortfolioSubmit}
        onHide={handleShareDisaggregationsToPortfolioModalClose}
        allDisaggregations={disaggregations}
      />

      <ShareDisaggregationModal
        isOpen={isShareDisaggregationModalOpen}
        onSubmit={handleShareDisaggregationModalOpenConfirmation}
        onHide={handleShareDisaggregationModalClose}
        disaggregation={disaggregationToBeShared}
        onSetPortfolioUniqueTitle={setPortfolioUniqueTitle}
      />
      <ShareDisaggregationModalConfirmation
        isOpen={isShareDisaggregationModalOpenConfirmation}
        onSubmit={handleShareDisaggregationToPortfolioSubmit}
        onHide={handleShareDisaggregationModalConfirmationClose}
        sharedDisaggregations={sharedDisaggregations}
        portfolioUniqueTitle={portfolioUniqueTitle}
      />

      <DisaggregationEditModal
        isEditDisaggregationModalOpen={isEditDisaggregationModalOpen}
        handleEditDisaggregationModalClose={handleEditDisaggregationModalClose}
        handleSetEditedDisaggregationProperty={
          handleSetEditedDisaggregationProperty
        }
        disaggregationToBeEdited={disaggregationToBeEdited}
        disaggregationValuesToBeEdited={disaggregationValuesToBeEdited}
        handleRemoveDisaggregationValueToBeEdited={
          handleRemoveDisaggregationValueToBeEdited
        }
        handleEditDisaggregationSubmit={handleEditDisaggregationSubmit}
        handleTypedValueChange={handleTypedValueChange}
        handleNewDisaggregationValueEditPage={
          handleNewDisaggregationValueEditPage
        }
        typedValueEdited={typedValueEdited}
      />
      <AddToPortfolioConfirmation
        isOpen={isAddToPortfolioOpen}
        onSubmit={handleAddToPortfolioSubmit}
        onHide={handleAddToPortfolioClose}
      />
      <RequestAccessModalConfirmation
        isOpen={isRequestAccess}
        onSubmit={handleAskToShareSubmit}
        onHide={hideAskToShare}
      />
      <Modal
        show={isRemoveDisaggregationModalOpen}
        onHide={handleRemoveDisaggregationClose}
      >
        <Modal.Header>
          <Modal.Title>Remove Disaggregation</Modal.Title>
          <button
            type="button"
            className="btn-close btn-close-white"
            aria-label="Close"
            onClick={handleRemoveDisaggregationClose}
          ></button>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to remove this disaggregation?</p>
          <p>
            This will only remove this disaggregation from this portfolio. The
            disaggregation will not be deleted.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="button primary"
            onClick={handleRemoveDisaggregationConfirm}
          >
            Remove
          </Button>
          <Button
            className="button tertiary"
            onClick={handleRemoveDisaggregationClose}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={isDeleteDisaggregationModalOpen}
        onHide={() => setIsDeleteDisaggregationModalOpen(false)}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>Delete Disaggregation</Modal.Title>
          <button
            type="button"
            className="btn-close btn-close-white"
            aria-label="Close"
            onClick={() => setIsDeleteDisaggregationModalOpen(false)}
          ></button>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this disaggregation?
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="button primary"
            onClick={() => handleDeleteDisaggregation()}
          >
            Delete
          </Button>
          <Button
            className="button tertiary"
            onClick={() => setIsDeleteDisaggregationModalOpen(false)}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <ContactOwnerForDeleteDisaggregation
        isOpen={openContactOwnerModal}
        onHide={hideContactOwnerModal}
        onSubmit={contactPortfolioOwnerSubmit}
        disaggregationTitle={
          disaggregationForcontactOwner === null
            ? ""
            : disaggregationForcontactOwner.title
        }
        portfolioTitle={
          contactOwnerPortfolio === null ? "" : contactOwnerPortfolio.title
        }
      ></ContactOwnerForDeleteDisaggregation>
    </>
  );
}
