import React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import format from "../Helpers/formatNumber";

export default function TotalsTable(props) {
  const { totalValue, totalTarget, progress } = props;

  return (
    <div className="period-progress">
      <div className="data">
        <span>Total Planned: {format(totalTarget)}</span>
        <span>Total Achieved: {format(totalValue)}</span>
        {totalTarget !== 0 && (
          <span>Progress: {format(progress)}%</span>
        )}
      </div>
      {totalTarget !== 0 && (
        <ProgressBar now={progress} label={`${progress}%`} />
      )}
    </div>
  );
}
