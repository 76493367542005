import React from "react";

const NestingPortfolioPopover = React.forwardRef((props, ref) => {
  return (
    <div className="actions">
      {props.isUserPortfolioOwner === true && (
        <div class="icon-and-text" onClick={props.onClickRemove}>
          <i class="icon bi bi-trash"></i>Un-nest from this portfolio
        </div>
      )}

      <div
        class="icon-and-text has-left-border"
        onClick={props.onClickGotoHome}
      >
        <i class="icon bi bi-arrow-right-circle"></i>Go to portfolio to edit or
        share
      </div>
    </div>
  );
});

export default NestingPortfolioPopover;
