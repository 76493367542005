import React from "react";
import { Link } from "react-router-dom";

export default function Breadcrumbs({ items }) {
  return (
    <nav className="breadcrumbs">
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <a href="/">Home</a>
        </li>
        {items.map((i, j) => (
          <React.Fragment key={j}>
            {i.path ? (
              <li className="breadcrumb-item">
                <Link to={i.path}>{i.label}</Link>
              </li>
            ) : (
              <li className="breadcrumb-item active" aria-current="page">{i.label}</li>
            )}
          </React.Fragment>
        ))}
      </ol>
    </nav>
  )
}
