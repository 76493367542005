import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import useActivityService from "../Services/useActivityService";
import Collapse from "react-bootstrap/Collapse";

export default function ImportDataDocumentation({ activityId }) {
  const { getExportTemplate } = useActivityService();
  const [open, setOpen] = useState(false);

  const handleDownloadTemplate = async () => {
    let response = await getExportTemplate(activityId);
    let blob = response.data;
    if (blob) {
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.target = "_BLANK";
      a.rel = "noopener noreferrer";
      a.href = url;
      a.download = `import-template.csv`;
      a.click();
    }
  };

  return (
    <div className="import-data-help pt-4">
      <Accordion>
        <Card className="pb-1">
          <Card.Header>
            <Accordion.Toggle
              as={Button}
              className="ps-0"
              variant="link"
              eventKey="0"
            >
              <div class="icon-and-text">
                <i class="icon bi-info-circle"></i>
                More information on importing data
              </div>
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <p className="mt-3">
                The data import accepts .xlsx files with the following
                structure:
              </p>
              <Table bordered responsive>
                <thead>
                  <tr>
                    <th>Activity</th>
                    <th>Result</th>
                    <th>Indicator</th>
                    <th>Indicator Type</th>
                    <th>Period Start</th>
                    <th>Period End</th>
                    <th>Target</th>
                    <th>Actual</th>
                    <th>
                      <em>Disaggregation 1</em>
                    </th>
                    <th>
                      <em>Disaggregation 2</em>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <em className="d-block mb-2">Activity title value</em>
                      string
                    </td>
                    <td>
                      <em className="d-block mb-2">Result title value</em>string
                    </td>
                    <td>
                      <em className="d-block mb-2">Indicator title value</em>
                      string
                    </td>
                    <td>
                      <em className="d-block mb-2">
                        select either: <br></br>Quantitative or <br></br>
                        Qualitative
                      </em>
                    </td>
                    <td>
                      <em className="d-block mb-2">Date value</em>
                      Format: dd/mm/yyyy
                    </td>
                    <td>
                      <em className="d-block mb-2">Date value</em>
                      Format: dd/mm/yyyy
                    </td>
                    <td>
                      <em className="d-block mb-2">Target value</em>
                      if selected{" "}
                      <span
                        style={{
                          textDecorationLine: "underline",
                        }}
                      >
                        quantitative
                      </span>
                      :integer <br></br>if selected{" "}
                      <span
                        style={{
                          textDecorationLine: "underline",
                        }}
                      >
                        qualitative
                      </span>
                      :string
                    </td>
                    <td>
                      <em className="d-block mb-2">Actual value</em>
                      if selected{" "}
                      <style text-decoration="underline">quantitative</style>
                      :integer <br></br>if selected{" "}
                      <style text-decoration="underline">qualitative</style>
                      :string
                    </td>
                    <td>
                      <em className="d-block mb-2">Disaggregation 1 value</em>
                      string
                    </td>
                    <td>
                      <em className="d-block mb-2">Disaggregation 2 value</em>
                      string
                    </td>
                  </tr>
                </tbody>
              </Table>
              <small className="small-text">
                <em>Where the items in italics are to be set by the user.</em>
              </small>
              <h4 className="h4 mt-5 mb-2">Sample upload</h4>
              <p className="mb-3">A sample upload might look like this:</p>
              <Table bordered responsive>
                <thead>
                  <tr>
                    <th>Activity</th>
                    <th>Result</th>
                    <th>Indicator</th>
                    <th>Indicator Type</th>
                    <th>Period Start</th>
                    <th>Period End</th>
                    <th>Target</th>
                    <th>Actual</th>
                    <th>Age</th>
                    <th>Gender</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Access to better healthcare</td>
                    <td>Improved health for all citizens</td>
                    <td>Number of vaccinations</td>
                    <td>Quantitative</td>
                    <td>01/01/2020</td>
                    <td>01/03/2020</td>
                    <td>1000</td>
                    <td>1512</td>
                    <td>0-18</td>
                    <td>male</td>
                  </tr>
                  <tr>
                    <td>Access to better healthcare</td>
                    <td>Improved health for all citizens</td>
                    <td>Number of vaccinations</td>
                    <td>Quantitative</td>
                    <td>01/01/2020</td>
                    <td>01/03/2020</td>
                    <td>2000</td>
                    <td>2512</td>
                    <td>18+</td>
                    <td>female</td>
                  </tr>
                  <tr>
                    <td>Access to better healthcare</td>
                    <td>Improved health for all citizens</td>
                    <td>Number of vaccinations</td>
                    <td>Quantitative</td>
                    <td>01/01/2020</td>
                    <td>01/03/2020</td>
                    <td>1000</td>
                    <td>1534</td>
                    <td>0-18</td>
                    <td>female</td>
                  </tr>
                  <tr>
                    <td>Access to better healthcare</td>
                    <td>Improved access to hospitals</td>
                    <td>Hospital visits</td>
                    <td>Quantitative</td>
                    <td>01/01/2020</td>
                    <td>01/12/2020</td>
                    <td>100</td>
                    <td>123</td>
                    <td>18+</td>
                    <td>male</td>
                  </tr>
                  <tr>
                    <td>Access to better healthcare</td>
                    <td>Improved access to hospitals</td>
                    <td>
                      Healthcare becomes <br></br>one of top government<br></br>{" "}
                      priority
                    </td>
                    <td>Qualitative</td>
                    <td>01/01/2020</td>
                    <td>01/12/2020</td>
                    <td>
                      Description of change in<br></br> views/behaviour{" "}
                      <br></br>expected following UK <br></br>intervention
                    </td>
                    <td>
                      Evidence of change in<br></br> views or behaviour{" "}
                      <br></br>observed and how UK <br></br>contributed
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </Table>

              <Link
                class="help-link mb-4 d-block collapsed"
                onClick={() => setOpen(!open)}
                aria-controls="import-help"
                aria-expanded={open}
              >
                <i class="bi bi-question-circle icon"></i> Import help
              </Link>
              <Collapse in={open}>
                <p className="mt-2 w-75">
                  The importer will aim to use entities if they already exist in
                  REX, if it doesn't find them then it will create them. For
                  example, in the above example, if there is already an
                  indicator with the title "Number of Vaccinations" then this
                  indicator will be used. If none with the same title are found
                  then a new indicator will be created for the portfolio.
                </p>
              </Collapse>
              <h4 className="h4 mt-5">Download templates</h4>
              <div
                className="icon-and-text d-block mb-2"
                variant="outline-primary"
                onClick={handleDownloadTemplate}
              >
                <i class="icon bi bi-cloud-download"></i> Download populated
                template
              </div>
              <Link
                className="icon-and-text mb-4"
                to="/import-template.xlsx"
                target="_blank"
                download
              >
                <i class="icon bi bi-cloud-download"></i> Download blank
                template
              </Link>
              <div className="alert info">
                <div className="text">
                  <i className="icon bi-info-circle"></i>
                  Note that the populated template will have to be converted to
                  .xlsx before uploading
                </div>
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
}
