import React from "react";
import { useHistory } from "react-router-dom";

export default function MenuSideBar() {
    const history = useHistory();
    const pathname = history.location.pathname;
    //let containsSubstring = pathname.match(new RegExp("activities")) !== null;
    const splits = pathname.split("/");

    return (
        <>
            {splits.length === 2 
                ?
                <ul className="main-nav">
                    <li>
                        <a
                            href="/"
                            className={
                                history.location.pathname === "/" ? "link active" : "link"
                            }
                        >
                            <i className="icon bi bi-folder"></i>
                            <span>Portfolios</span>
                        </a>
                    </li>
                </ul>
                :
                <ul className="main-nav">
                    <li>
                        <a
                            href="/"
                            className={
                                history.location.pathname === "/" ? "link active" : "link"
                            }
                        >
                            <i className="icon bi bi-folder"></i>
                            <span>Portfolios</span>
                        </a>
                    </li>
                    <li>
                        <a
                            className={
                                history.location.pathname === "/" + splits[1] + "/activities"
                                    ? "link active"
                                    : "link"
                            }
                            href={"/" + splits[1] + "/activities"}
                        >
                            <i className="icon bi bi-activity"></i>
                            <span>Activities</span>
                        </a>
                        {splits.length > 3 && (
                            <ul className="sub-nav">
                                <li>
                                    <a
                                        className={
                                            (history.location.pathname.match(
                                                new RegExp(
                                                    "/" +
                                                    splits[1] +
                                                    "/activities/" +
                                                    splits[3] +
                                                    "/results"
                                                )
                                            ) !==
                                                null) ===
                                                true
                                                ? "link active"
                                                : "link"
                                        }
                                        href={
                                            "/" + splits[1] + "/activities/" + splits[3] + "/results"
                                        }
                                    >
                                        <i className="icon bi bi-bar-chart"></i>
                                        <span>Results</span>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className={
                                            history.location.pathname ===
                                                "/" +
                                                splits[1] +
                                                "/activities/" +
                                                splits[3] +
                                                "/theory-of-change"
                                                ? "link active"
                                                : "link"
                                        }
                                        href={
                                            "/" +
                                            splits[1] +
                                            "/activities/" +
                                            splits[3] +
                                            "/theory-of-change"
                                        }
                                    >
                                        <i className="icon bi bi-diagram-3"></i>
                                        <span>Theory of Change</span>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className={
                                            (history.location.pathname.match(
                                                new RegExp(
                                                    "/" +
                                                    splits[1] +
                                                    "/activities/" +
                                                    splits[3] +
                                                    "/relations"
                                                )
                                            ) !==
                                                null) ===
                                                true
                                                ? "link active"
                                                : "link"
                                        }
                                        href={
                                            "/" + splits[1] + "/activities/" + splits[3] + "/relations"
                                        }
                                    >
                                        <i className="icon bi bi-table"></i>
                                        <span>Relationships</span>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className={
                                            history.location.pathname ===
                                                "/" +
                                                splits[1] +
                                                "/activities/" +
                                                splits[3] +
                                                "/export-data"
                                                ? "link active"
                                                : "link"
                                        }
                                        href={
                                            "/" +
                                            splits[1] +
                                            "/activities/" +
                                            splits[3] +
                                            "/export-data"
                                        }
                                    >
                                        <i className="icon bi bi-download"></i>
                                        <span>Export Data</span>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className={
                                            history.location.pathname ===
                                                "/" +
                                                splits[1] +
                                                "/activities/" +
                                                splits[3] +
                                                "/import-data"
                                                ? "link active"
                                                : "link"
                                        }
                                        href={
                                            "/" +
                                            splits[1] +
                                            "/activities/" +
                                            splits[3] +
                                            "/import-data"
                                        }
                                    >
                                        <i className="icon bi bi-upload"></i>
                                        <span>Import Data</span>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className={
                                            history.location.pathname ===
                                                "/" + splits[1] + "/activities/" + splits[3] + "/settings"
                                                ? "link active"
                                                : "link"
                                        }
                                        href={
                                            "/" + splits[1] + "/activities/" + splits[3] + "/settings"
                                        }
                                    >
                                        <i className="icon bi bi-person-gear"></i>
                                        <span>Programme Users</span>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className={
                                            history.location.pathname ===
                                                "/" + splits[1] + "/activities/" + splits[3] + "/update-log"
                                                ? "link active"
                                                : "link"
                                        }
                                        href={
                                            "/" + splits[1] + "/activities/" + splits[3] + "/update-log"
                                        }
                                    >
                                        <i className="icon bi bi-list-columns"></i>
                                        <span>Update Log</span>
                                    </a>
                                </li>
                            </ul>
                        )}
                    </li>
                    <li>
                        <a
                            className={
                                history.location.pathname === "/" + splits[1] + "/results"
                                    ? "link active"
                                    : "link"
                            }
                            href={"/" + splits[1] + "/results"}
                        >
                            <i className="icon icon bi bi-bar-chart"></i>
                            <span>Results</span>
                        </a>
                    </li>
                    <li>
                        <a
                            className={
                                history.location.pathname === "/" + splits[1] + "/evidence"
                                    ? "link active"
                                    : "link"
                            }
                            href={"/" + splits[1] + "/evidence"}
                        >
                            <i className="icon bi bi-box-seam"></i>
                            <span>Evidence</span>
                        </a>
                    </li>
                    <li>
                        <a
                            className={
                                history.location.pathname ===
                                    "/" + splits[1] + "/theory-of-change"
                                    ? "link active"
                                    : "link"
                            }
                            href={"/" + splits[1] + "/theory-of-change"}
                        >
                            <i className="icon bi bi-diagram-3"></i>
                            <span>Theory of Change</span>
                        </a>
                    </li>
                    <li>
                        <a
                            className={
                                history.location.pathname === "/" + splits[1] + "/relations"
                                    ? "link active"
                                    : "link"
                            }
                            href={"/" + splits[1] + "/relations"}
                        >
                            <i className="icon bi bi-table"></i>
                            <span>Relationships</span>
                        </a>
                    </li>
                    <li>
                        <a
                            className={
                                (history.location.pathname.match(
                                    new RegExp("/" + splits[1] + "/indicators")
                                ) !==
                                    null) ===
                                    true
                                    ? "link active"
                                    : "link"
                            }
                            href={"/" + splits[1] + "/indicators"}
                        >
                            <i className="icon bi bi-speedometer2"></i>
                            <span>Indicators</span>
                        </a>
                    </li>
                    <li>
                        <a
                            className={
                                history.location.pathname === "/" + splits[1] + "/disaggregations"
                                    ? "link active"
                                    : "link"
                            }
                            href={"/" + splits[1] + "/disaggregations"}
                        >
                            <i className="icon bi bi-exclamation-triangle"></i>
                            <span>Disaggregations</span>
                        </a>
                    </li>
                    <li>
                        <a
                            className={
                                history.location.pathname === "/" + splits[1] + "/export-data"
                                    ? "link active"
                                    : "link"
                            }
                            href={"/" + splits[1] + "/export-data"}
                        >
                            <i className="icon bi bi-download"></i>
                            <span>Export Data</span>
                        </a>
                    </li>
                    <li>
                        <a
                            className={
                                history.location.pathname === "/" + splits[1] + "/settings"
                                    ? "link active"
                                    : "link"
                            }
                            href={"/" + splits[1] + "/settings"}
                        >
                            <i className="icon bi bi-person-gear"></i>
                            <span>Portfolio Users</span>
                        </a>
                    </li>
                </ul>
            }
        </>
    );
}
