import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

export default function DeleteResultModal(props) {
  const { isOpen, onHide, onSubmit } = props;

  return (
    <Modal show={isOpen} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>Delete Result</Modal.Title>
        <button
          type="button"
          className="btn-close btn-close-white"
          aria-label="Close"
          onClick={onHide}
        ></button>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to delete this result?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="button primary"
          onClick={() => {
            onHide();
            onSubmit();
          }}
        >
          Delete Result
        </Button>
        <Button type="button" className="button tertiary" onClick={onHide}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
