import React, { useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import IndicatorForm from "./IndicatorForm";
import Button from "react-bootstrap/Button";

export default function AddIndicatorModal({ isOpen, onHide, onSubmit }) {
  const form = useRef(null);
  const [isValidated, setIsValidated] = useState(false);
  const [newIndicatorTitle, setNewIndicatorTitle] = useState("");
  const [newIndicatorDescription, setNewIndicatorDescription] = useState("");
  const [newIndicatorMethodology, setNewIndicatorMethodology] = useState("");
  const [newIndicatorType, setNewIndicatorType] = useState(2);
  const [newIndicatorUnits, setNewIndicatorUnits] = useState("");
  const [newIndicatorDocumentLink, setNewIndicatorDocumentLink] = useState("");
  const [newIndicatorAggregationMethod, setNewIndicatorAggregationMethod] =
    useState("sum");
  const [newIndicatorIsPublic, setNewIndicatorIsPublic] = useState(false);

  const clearState = () => {
    setIsValidated(false);
    setNewIndicatorTitle("");
    setNewIndicatorDescription("");
    setNewIndicatorMethodology("");
    setNewIndicatorType(2);
    setNewIndicatorUnits("");
    setNewIndicatorDocumentLink("");
    setNewIndicatorAggregationMethod("sum");
  };

  const handleClick = () => {
    if (form.current.checkValidity() === true) {
      onSubmit(
        newIndicatorTitle,
        newIndicatorDescription,
        newIndicatorMethodology,
        newIndicatorType,
        newIndicatorUnits,
        newIndicatorDocumentLink,
        newIndicatorAggregationMethod,
        newIndicatorIsPublic
      );
      clearState();
      onHide();
    } else {
      setIsValidated(true);
    }
  };

  return (
    <Modal show={isOpen} onHide={onHide} size="lg">
      <Modal.Header>
        <Modal.Title>Add Indicator</Modal.Title>
        <button
          type="button"
          className="btn-close btn-close-white"
          aria-label="Close"
          onClick={onHide}
        ></button>
      </Modal.Header>
      <Modal.Body>
        <IndicatorForm
          ref={form}
          validated={isValidated}
          onSetIndicatorTitle={setNewIndicatorTitle}
          onSetIndicatorDescription={setNewIndicatorDescription}
          onSetIndicatorMethodology={setNewIndicatorMethodology}
          onSetIndicatorType={setNewIndicatorType}
          onSetIndicatorUnits={setNewIndicatorUnits}
          onSetIndicatorDocumentLink={setNewIndicatorDocumentLink}
          onSetIndicatorAggregationMethod={setNewIndicatorAggregationMethod}
          onSetIndicatorIsPublic={setNewIndicatorIsPublic}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button className="button primary" onClick={handleClick}>
          Add Indicator
        </Button>
        <Button className="button tertiary" onClick={onHide}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
