import React from "react";
import format from "../../Helpers/formatNumber";
import Spinner from "react-bootstrap/Spinner";

export default function IndicatorTotals(props) {
  const { indicator } = props;

  if (!indicator) return null;

  return (
    <>
      <span className="description">Portfolio Totals</span>
      {!indicator.totalLoaded && (
        <Spinner animation="grow" variant="secondary" />
      )}
      <div className="period-progress">
        <div className="data">
          <span>
            Total Planned:
            {indicator.totalTargetValue != null
              ? format(indicator.totalTargetValue)
              : ""}
          </span>
          <span>
            Total Achieved:
            {indicator.totalActualValue != null
              ? format(indicator.totalActualValue)
              : ""}
          </span>
          <span>
            Progress:
            {indicator.totalTargetValue && indicator.totalTargetValue
              ? format(
                Math.round(
                  (indicator.totalActualValue / indicator.totalTargetValue) *
                  100
                )
              ) + "%"
              : ""}
          </span>
        </div>
        <div className="progress" style={{ height: "25px", width: "300px" }}>
          <div
            className="progress-bar"
            role="progressbar"
            aria-label="Progress Bar"
            style={{ width: "25%" }}
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            25%
          </div>
        </div>
      </div>
    </>
  );
}
