import React, { Component } from "react"; //, Fragment
import { NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import authService from "./AuthorizeService";
import { ApplicationPaths } from "./ApiAuthorizationConstants";

export class LoginMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      userName: null,
    };
  }

  componentDidMount() {
    this._subscription = authService.subscribe(() => this.populateState());
    this.populateState();
  }

  componentWillUnmount() {
    authService.unsubscribe(this._subscription);
  }

  async populateState() {
    const [isAuthenticated, user] = await Promise.all([
      authService.isAuthenticated(),
      authService.getUser(),
    ]);
    this.setState({
      isAuthenticated,
      userName: user && user.name,
    });
  }

  render() {
    const { isAuthenticated, userName } = this.state;
    if (!isAuthenticated) {
      const registerPath = `${ApplicationPaths.Register}`;
      const loginPath = `${ApplicationPaths.Login}`;
      return this.anonymousView(registerPath, loginPath);
    } else {
      const profilePath = `${ApplicationPaths.Profile}`;
      const logoutPath = {
        pathname: `${ApplicationPaths.LogOut}`,
        state: { local: true },
      };
      return this.authenticatedView(userName, profilePath, logoutPath);
    }
  }

  authenticatedView(userName, profilePath, logoutPath) {
    return (
      <div className="utility-links">
        <div className="utility-link no-divider">
          <NavLink tag={Link} className="link" to={profilePath}>
            <i className="bi bi-person-circle icon"></i>
            <span className="link-text">{userName}</span>
          </NavLink>
        </div>
        <div className="utility-link">
          <NavLink tag={Link} className="link" to={logoutPath}>
            <i className="bi bi-box-arrow-in-right icon"></i>
            <span className="link-text">Logout</span>
          </NavLink>
        </div>
      </div>
    );
  }

  anonymousView(registerPath, loginPath) {
    return (
      <div className="utility-links">
        <div className="utility-link no-divider">
          <NavLink tag={Link} className="link" to={registerPath}>
            <i className="bi bi-pencil-square icon"></i>
            <span className="link-text">Register</span>
          </NavLink>
        </div>
        <div className="utility-link">
          <NavLink tag={Link} className="link" to={loginPath}>
            <i className="bi bi-person-circle icon"></i>
            <span className="link-text">Login</span>
          </NavLink>
        </div>
      </div>
    );
  }
}
