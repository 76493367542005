import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

export default function UnlinkEvidenceModal(props) {
  const { isOpen, onHide, onSubmit } = props;

  return (
    <Modal show={isOpen} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>Unlink Evidence</Modal.Title>
        <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={onHide}></button>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to unlink this evidence from this relation?</p>
        <p>The evidence will not be deleted. It will still be visible in the Evidence tab and can be relinked later.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="button primary"
          onClick={() => {
            onSubmit();
            onHide();
          }}
        >
          Unlink Evidence
        </Button>
        <Button className="button tertiary" onClick={onHide}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
