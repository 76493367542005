import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { ToggleButtonGroup } from "react-bootstrap";
import { ToggleButton } from "react-bootstrap";
import Button from "react-bootstrap/Button";

export default function ShareActivityModalConfirmation({
  activity,
  isOpen,
  onHide,
  onSubmit,
  portfolioUniqueTitle,
  previouslySharedPortfoliosOfActivity,
}) {
  const [value, setValue] = useState("");

  const handleSubmit = (e) => {
    onSubmit(activity.id, portfolioUniqueTitle, value);
  };

  const handleCancel = (e) => {
    onHide();
    setValue("");
  };

  const handleChange = (val) => {
    setValue(val);
  };

  return (
    <Modal show={isOpen} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>Share Activity Confirmation</Modal.Title>
        <button
          type="button"
          className="btn-close btn-close-white"
          aria-label="Close"
          onClick={onHide}
        ></button>
      </Modal.Header>
      <Form>
        <Modal.Body>
          <p>
            By sharing this activity, all users of the portfolio {"<"}
            {portfolioUniqueTitle}
            {">"} will be able to see this activity as though guest users to the
            activity.
          </p>
          <p>
            The portfolio {"<"}
            {portfolioUniqueTitle}
            {">"} may be nested in other portfolios. Do you want users of the
            portfolios which {"<"}
            {portfolioUniqueTitle}
            {">"} to see this activity.
          </p>
          <ToggleButtonGroup
            type="radio"
            name="options"
            onChange={handleChange}
          >
            <ToggleButton id="tbg-radio-1" value="Yes">
              Yes - let users see this activity in the portfolio wherever the
              portfolio is nested
            </ToggleButton>
            <ToggleButton id="tbg-radio-2" value="Parent and child users only">
              No - only allow users of this portfolio and the shared portfolio
              to view
            </ToggleButton>
          </ToggleButtonGroup>

          <p>
            All owners of this activity will be notified by email that the
            activity has been shared.
          </p>
          <p>Do you still wish to share?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="button primary"
            onClick={handleSubmit}
            disabled={value === ""}
          >
            Share
          </Button>
          <Button className="button tertiary" onClick={handleCancel}>
            Cancel
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
