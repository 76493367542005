import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

export default function AddPeriodModal(props) {
  const { isOpen, onHide, onSubmit, indicatorTypeId } = props;
  const [title, setTitle] = useState(null);
  const [isLocked, setIsLocked] = useState(false);
  const [narrative, setNarrative] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isAggregatable, setIsAggregatable] = useState(true);
  const [
    shouldCreateRecordCombinations,
    setShouldCreateRecordCombinations,
  ] = useState(false);
  const [validationMessage, setValidationMessage] = useState(null);

  const handleHide = () => {
    setTitle(null);
    setNarrative(null);
    setStartDate(null);
    setEndDate(null);
    setIsLocked(false);
    setIsAggregatable(true);
    onHide();
  };

  const isValidInput = () => {
    if (!startDate || !endDate) return false;

    if (endDate < startDate) {
      if (validationMessage === null) {
        setValidationMessage(
          "Please ensure the end date is later than the start date "
        );
      }
      return false;
    }

    if (validationMessage !== null) {
      setValidationMessage(null);
    }
    return true;
  };

  return (
    <Modal show={isOpen} onHide={handleHide}>
      <Modal.Header>
        <Modal.Title>Add Period</Modal.Title>
        <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={handleHide}></button>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="title">
            <Form.Label>Title (optional)</Form.Label>
            <Form.Control
              onChange={(e) => setTitle(e.target.value)}
              type="text"
              placeholder="e.g. Baseline, End of Activity Lifetime"
            />
          </Form.Group>
          <Form.Group controlId="narrative">
            <Form.Label>Narrative (optional)</Form.Label>
            <Form.Control
              onChange={(e) => setNarrative(e.target.value)}
              as="textarea"
              placeholder="Narrative"
            />
          </Form.Group>
          <Form.Group controlId="periodStart">
            <Form.Label>Start Date</Form.Label>
            <Form.Control
              onChange={(e) => setStartDate(e.target.value)}
              type="date"
              placeholder="Start Date"
            />
          </Form.Group>
          <Form.Group controlId="periodEnd">
            <Form.Label>End Date</Form.Label>
            <Form.Control
              onChange={(e) => setEndDate(e.target.value)}
              type="date"
              placeholder="End Date"
            />
          </Form.Group>
        </Form>
        {validationMessage && (
          <span className="text-danger">{validationMessage}</span>
        )}
        {indicatorTypeId === 2 && (
          <>
            <Form.Group controlId="shouldCreateRecordCombinations">
              <Form.Check
                onChange={(e) => setIsAggregatable(e.target.checked)}
                type="checkbox"
                label="This period should be included in all aggregations"
                checked={isAggregatable}
              />
            </Form.Group>
            <Form.Group controlId="shouldCreateRecordCombinations">
              <Form.Check
                onChange={(e) =>
                  setShouldCreateRecordCombinations(e.target.checked)
                }
                type="checkbox"
                label="Create all disaggregated record combinations (optional)"
                checked={shouldCreateRecordCombinations}
              />
              <p className="text-secondary">
                Selecting this option will mean that every combination of
                disaggregations will be calculated for this indicator, and
                records will be added for each one.
              </p>
              <p className="text-secondary">
                This can be helpful if there aren't many combinations but can be
                overly complex if there are.
              </p>
            </Form.Group>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="button primary"
          disabled={!isValidInput()}
          onClick={() => {
            onSubmit(
              {
                title,
                narrative,
                start: startDate,
                end: endDate,
                isLocked: isLocked,
                isAggregatable: isAggregatable,
              },
              shouldCreateRecordCombinations
            );
            handleHide();
          }}
        >
          Add Period
        </Button>
        <Button className="button tertiary" onClick={handleHide}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
