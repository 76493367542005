import React from "react";

export default function IndicatorTotalsForQualitative({ allPeriods }) {
  let totalRecords = 0;

  allPeriods.map((item) => (totalRecords = totalRecords + item.records.length));

  return (
    <div>
      <p className="description">Portfolio Totals</p>
      <div className="mb-3">
        <span className="inline-block me-2">Total records </span>
        {totalRecords}
      </div>
    </div>
  );
}
