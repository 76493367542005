import React from "react";
import { Switch, Route } from "react-router-dom";
import IndicatorDetails from "./IndicatorDetails";

export default function IndicatorLayout() {
  return (
    <Switch>
      <Route path="/:portfolioId/activities/:activityId/results/:resultId/:indicatorId">
        <IndicatorDetails />
      </Route>
    </Switch>
  );
}
