import React from "react";
//import { OverlayTrigger } from "react-bootstrap";
//import ThreeDotsButton from "../../Shared/ThreeDotsButton";
//import ManageIndicatorPopover from "../ManageIndicatorPopover";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Collapsible from "./../../Shared/Collapsible";

export default function DisaggregationListItemLink(props) {
  const {
    portfolioId,
    disaggregation,
    onShareDisaggregationClick,
    onEditDisaggregationClick,
    onDeleteDisaggregationClick,
    isUserPortfolioOwner,
    onRemoveDisaggregationClick,
    onGoToHomePortfolioClick,
    onClickAddToPortfolio,
    onClickAskToShare,
    handleContactPortfolioOwner,
  } = props;
  //let match = useRouteMatch();
  const portfolioIdInt = parseInt(portfolioId);

  return (
    <>
      <Collapsible
        key={disaggregation.id}
        label={disaggregation.title}
        className="collapsible"
      >
        {isUserPortfolioOwner ? (
          <div className="mt-3 mb-4">
            {disaggregation.isShared === false &&
              disaggregation.isFromNestingOrSharedActivity === false && (
                <>
                  {
                    <div
                      onClick={() => onShareDisaggregationClick(disaggregation)}
                      className="icon-and-text"
                    >
                      <i class="icon bi bi-share"></i>
                      Share
                    </div>
                  }
                  <div
                    onClick={() => onEditDisaggregationClick(disaggregation)}
                    className="icon-and-text has-left-border"
                  >
                    <i class="icon bi bi-pencil-square"></i>
                    Edit
                  </div>
                  <div
                    onClick={() => onDeleteDisaggregationClick(disaggregation)}
                    className="icon-and-text has-left-border"
                  >
                    <i class="icon bi bi-trash"></i>
                    Delete
                  </div>
                </>
              )}
            {disaggregation.isShared &&
              disaggregation.isFromNestingOrSharedActivity === false && (
                <>
                  <div
                    onClick={() => onRemoveDisaggregationClick(disaggregation)}
                    className="icon-and-text has-left-border"
                  >
                    <i class="icon bi bi-trash"></i>
                    Remove
                  </div>
                  <Button
                    onClick={() => onGoToHomePortfolioClick(disaggregation)}
                    className="icon-and-text has-left-border"
                  >
                    <i class="icon bi bi-arrow-right-circle"></i>
                    Go to home portfolio to edit or share
                  </Button>
                </>
              )}
            {disaggregation.isFromNestingOrSharedActivity && (
              <>
                {disaggregation.isPublic === true ? (
                  <div
                    onClick={() => onClickAddToPortfolio(disaggregation)}
                    className="icon-and-text has-left-border"
                  >
                    <i class="icon bi bi-plus-circle"></i>
                    Add to portfolio
                  </div>
                ) : (
                  <div
                    onClick={() => onClickAskToShare(disaggregation)}
                    className="icon-and-text has-left-border"
                  >
                    <i class="icon bi bi-share"></i>
                    Ask owner to share
                  </div>
                )}
              </>
            )}
          </div>
        ) : null}
        <span className="badge success inline mt-2">
          {disaggregation.isPublic ? "Public" : "Private"}
        </span>
        <p className="mt-4">{disaggregation.description}</p>
        {disaggregation.disaggregationValues.length ? (
          <ul className="ul">
            {disaggregation.disaggregationValues.map((v, i) => (
              <li key={i}>{v.value}</li>
            ))}
          </ul>
        ) : (
          <em className="description">This disaggregation has no values</em>
        )}
        {disaggregation.sharedActivityOrNestedPortfolioId ===
          portfolioIdInt && (
            <>
              {disaggregation.sharedWithPortfolios.length > 0 && (
                <>
                  <p className="description">
                    This disaggregation is currently used in:
                  </p>
                  <ul className="ul">
                    {disaggregation.sharedWithPortfolios.map((value, index) => (
                      <li>
                        {value.title}{" "}
                        <Link
                          onClick={() =>
                            handleContactPortfolioOwner(value, disaggregation)
                          }
                        >
                          contact owner
                        </Link>
                      </li>
                    ))}
                  </ul>
                  <div className="alert info mb-0">
                    If the disaggregation is used in other portfolios you will not
                    be able to delete
                  </div>
                </>
              )}
            </>
          )}
      </Collapsible>
    </>
  );
}
