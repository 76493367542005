import React from "react";
//import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
//import Badge from "react-bootstrap/Badge";
import DisaggregationListItemLink from "./DisaggregationListItemLink";

export default function DisaggregationList(props) {
  const {
    portfolioId,
    disaggregations,
    onDeleteDisaggregationClick,
    onEditDisaggregationClick,
    onShareDisaggregationClick,
    isUserPortfolioOwner,
    disaggregationsSharedAtPortfolio,
    onRemoveDisaggregationClick,
    onGoToHomePortfolioClick,
    sharedActivityOrNestedPortfolioDisaggregations,
    handleAddToPortfolio,
    handleAskToShare,
    handleContactPortfolioOwner,
  } = props;

  let uniquePortfolioNames = disaggregationsSharedAtPortfolio
    ? [
      ...new Set(
        disaggregationsSharedAtPortfolio.map(
          (x) => x.sharedFromPortfolioTitle
        )
      ),
    ]
    : [];

  uniquePortfolioNames = uniquePortfolioNames.sort((a, b) =>
    a.localeCompare(b)
  );

  let uniquePortfolioNamesFromNesting =
    sharedActivityOrNestedPortfolioDisaggregations
      ? [
        ...new Set(
          sharedActivityOrNestedPortfolioDisaggregations.map(
            (x) => x.sharedActivityOrNestedPortfolioTitle
          )
        ),
      ]
      : [];

  uniquePortfolioNamesFromNesting = uniquePortfolioNamesFromNesting.sort(
    (a, b) => a.localeCompare(b)
  );

  return (
    <ListGroup>
      {disaggregations.length ||
        disaggregationsSharedAtPortfolio.length ||
        sharedActivityOrNestedPortfolioDisaggregations.length ? (
        <>
          {disaggregations.length ? (
            <>
              <h4 className="h4 mt-4 mb-3">
                This portfolio
              </h4>
            </>
          ) : (
            <></>
          )}
          {disaggregations.map((d) => (
            <DisaggregationListItemLink
              portfolioId={portfolioId}
              disaggregation={d}
              key={d.id}
              onShareDisaggregationClick={onShareDisaggregationClick}
              onEditDisaggregationClick={onEditDisaggregationClick}
              onDeleteDisaggregationClick={onDeleteDisaggregationClick}
              isUserPortfolioOwner={isUserPortfolioOwner}
              handleContactPortfolioOwner={handleContactPortfolioOwner}
            ></DisaggregationListItemLink>
          ))}
          {uniquePortfolioNames.length ? (
            <>
              <div className="mt-3 mb-3">
                <span>Added to this portfolio:</span>
              </div>
            </>
          ) : (
            <></>
          )}
          {uniquePortfolioNames.map((item, index) => (
            <div key={item}>
              <h4 className="h4 mt-2 mb-3">From: {item}</h4>
              {disaggregationsSharedAtPortfolio
                .filter((x) => x.sharedFromPortfolioTitle === item)
                .map((v, i) => (
                  <DisaggregationListItemLink
                    portfolioId={portfolioId}
                    disaggregation={v}
                    key={v.id}
                    onShareDisaggregationClick={onShareDisaggregationClick}
                    onEditDisaggregationClick={onEditDisaggregationClick}
                    onDeleteDisaggregationClick={onDeleteDisaggregationClick}
                    isUserPortfolioOwner={isUserPortfolioOwner}
                    onRemoveDisaggregationClick={onRemoveDisaggregationClick}
                    onGoToHomePortfolioClick={onGoToHomePortfolioClick}
                    handleContactPortfolioOwner={handleContactPortfolioOwner}
                  ></DisaggregationListItemLink>
                ))}
            </div>
          ))}

          {sharedActivityOrNestedPortfolioDisaggregations.length ? (
            <>
              <div className="mt-4 mb-2">
                <span>
                  In activities or portfolios shared with or nested in this
                  portfolio (read only - can't be used):
                </span>
              </div>
              {uniquePortfolioNamesFromNesting.map((item, index) => (
                <div className="mt-2">
                  <h4 className="h4 mt-2 mb-3">
                    From: {item}
                  </h4>
                  {sharedActivityOrNestedPortfolioDisaggregations
                    .filter(
                      (x) => x.sharedActivityOrNestedPortfolioTitle === item
                    )
                    .map((v, i) => (
                      <DisaggregationListItemLink
                        disaggregation={v}
                        portfolioId={portfolioId}
                        key={v.id}
                        onShareDisaggregationClick={onShareDisaggregationClick}
                        onEditDisaggregationClick={onEditDisaggregationClick}
                        onDeleteDisaggregationClick={
                          onDeleteDisaggregationClick
                        }
                        isUserPortfolioOwner={isUserPortfolioOwner}
                        onRemoveDisaggregationClick={
                          onRemoveDisaggregationClick
                        }
                        onGoToHomePortfolioClick={onGoToHomePortfolioClick}
                        onClickAddToPortfolio={handleAddToPortfolio}
                        onClickAskToShare={handleAskToShare}
                        handleContactPortfolioOwner={
                          handleContactPortfolioOwner
                        }
                      ></DisaggregationListItemLink>
                    ))}
                </div>
              ))}
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <p className="mt-4">There are no disaggregations</p>
      )}
    </ListGroup>
  );
}
