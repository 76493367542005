import React from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

export default function RemoveSharedActivityModalConfirmation({
  isOpen,
  onHide,
  onSubmit,
  portfolioRemovingFrom,
}) {
  const handleSubmit = (e) => {
    onSubmit();
  };

  const handleCancel = (e) => {
    onHide();
  };

  return (
    <Modal show={isOpen} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>Removing Nesting Portfolio Confirmation</Modal.Title>
        <button
          type="button"
          className="btn-close btn-close-white"
          aria-label="Close"
          onClick={onHide}
        ></button>
      </Modal.Header>
      <Form>
        <Modal.Body>
          <p>
            Removing this activity means that users of portfolio
            {"<"}
            {portfolioRemovingFrom}
            {">"}
            or any portfolios it is included in will no longer be able see this
            activity.
          </p>
          <p>
            Owners of {"<"}
            {portfolioRemovingFrom}
            {">"} will be notified that this activity is no longer included
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button className="button primary" onClick={handleSubmit}>
            Remove
          </button>
          <button type="button" className="button tertiary" onClick={handleCancel}>
            Cancel
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
