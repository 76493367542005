import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";

export default function DisaggregationEditModal(props) {
  const {
    isEditDisaggregationModalOpen,
    handleEditDisaggregationModalClose,
    handleSetEditedDisaggregationProperty,
    disaggregationToBeEdited,
    disaggregationValuesToBeEdited,
    handleRemoveDisaggregationValueToBeEdited,
    handleEditDisaggregationSubmit,
    handleTypedValueChange,
    handleNewDisaggregationValueEditPage,
    typedValueEdited,
  } = props;

  return (
    <>
      <Modal
        show={isEditDisaggregationModalOpen}
        onHide={handleEditDisaggregationModalClose}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>Edit Disaggregation</Modal.Title>
          <button
            type="button"
            className="btn-close btn-close-white"
            aria-label="Close"
            onClick={handleEditDisaggregationModalClose}
          ></button>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-4" controlId="disaggregationEditTitle">
              <Form.Label>Title</Form.Label>
              <Form.Control
                onChange={(e) =>
                  handleSetEditedDisaggregationProperty("title", e.target.value)
                }
                type="text"
                placeholder="Disaggregation title"
                value={
                  disaggregationToBeEdited
                    ? disaggregationToBeEdited.title
                    : null
                }
              />
            </Form.Group>
            <Form.Group
              className="mb-4"
              controlId="disaggregationEditDescription"
            >
              <Form.Label>Description</Form.Label>
              <Form.Control
                onChange={(e) =>
                  handleSetEditedDisaggregationProperty(
                    "description",
                    e.target.value
                  )
                }
                as="textarea"
                rows="3"
                placeholder="Disaggregation description"
                value={
                  disaggregationToBeEdited
                    ? disaggregationToBeEdited.description
                    : null
                }
              />
            </Form.Group>
            <Form.Group controlId="IsPublic">
              <Form.Label>Is disaggregation public</Form.Label>
              <Form.Check
                checked={
                  disaggregationToBeEdited
                    ? disaggregationToBeEdited.isPublic
                    : null
                }
                type="checkbox"
                label={
                  disaggregationToBeEdited
                    ? disaggregationToBeEdited.isPublic
                      ? "Public"
                      : "Private"
                    : null
                }
                onChange={(e) =>
                  handleSetEditedDisaggregationProperty(
                    "isPublic",
                    e.target.checked
                  )
                }
              />
              <Form.Text>
                Public disaggregations will be searchable and copyable from
                other portfolios
              </Form.Text>
            </Form.Group>
            <div className="added-values mb-3">
              {disaggregationValuesToBeEdited !== null ? (
                disaggregationValuesToBeEdited.map((v) => (
                  <div className="badge" title={v.value}>
                    {v.value}{" "}
                    <i
                      className="icon bi bi-x-circle"
                      onClick={() => {
                        handleRemoveDisaggregationValueToBeEdited(v.id);
                      }}
                    ></i>
                  </div>
                ))
              ) : (
                <span>No values added yet</span>
              )}
            </div>
            <InputGroup className="w-50 mb-3">
              <FormControl
                value={typedValueEdited}
                onChange={(e) => handleTypedValueChange(e.target.value)}
                placeholder="Disaggregation values"
                aria-label="Disaggregation Values"
              />
              <InputGroup.Append>
                <div
                  className="button tertiary with-icon ms-2 margin-top-auto add-data"
                  onClick={handleNewDisaggregationValueEditPage}
                >
                  <i class="icon bi bi-plus-circle"></i>
                  Add
                </div>
              </InputGroup.Append>
            </InputGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="button primary"
            onClick={() => handleEditDisaggregationSubmit()}
          >
            Update Disaggregation
          </Button>
          <Button
            className="button tertiary"
            onClick={handleEditDisaggregationModalClose}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
