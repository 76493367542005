import React from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

export default function RemoveNestedPortfolioModalConfirmation({
  isOpen,
  onHide,
  onSubmit,
  portfolioRemovingFrom,
}) {
  const handleSubmit = (e) => {
    onSubmit();
  };

  const handleCancel = (e) => {
    onHide();
  };

  return (
    <Modal show={isOpen} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>Removing Nesting Portfolio Confirmation</Modal.Title>
        <button
          type="button"
          className="btn-close btn-close-white"
          aria-label="Close"
          onClick={onHide}
        ></button>
      </Modal.Header>
      <Form>
        <Modal.Body>
          <p>
            Removing this portfolio means that users of portfolio {"<"}
            {portfolioRemovingFrom}
            {">"} will no longer be able to see this portfolio.
          </p>
          <p>
            Owners of {"<"}
            {portfolioRemovingFrom}
            {">"} will be notified that this portfolio is no longer included
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button className="button primary" onClick={handleSubmit}>
            Remove
          </Button>
          <Button className="button tertiary" onClick={handleCancel}>
            Cancel
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
