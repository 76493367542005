import React from "react";

export default function ChangeRequestFailures({ failures }) {
  const errors = failures.errors || [];
  const dataRows = failures.validatedRows || [];
  const rowErrorCount = failures.rowErrorCount;
  return (
    <div className="py-3">
      <h3 className="h3 mt-4">Validation Errors</h3>

      <div>
        {errors.length > 0 && (
          <>
            {errors ? (
              <>
                <div className="alert error">
                  <p className="text-bold">{errors.length} General
                    {errors.length === 1 ? (
                      <> Error </>
                    ) : (
                      <> Errors </>
                    )}
                    Found
                  </p>

                  {errors.map((e, key) => (
                    <span className="mb-2"><i className="bi bi-exclamation-circle me-2"></i>{e.error}</span>
                  ))}
                </div>
              </>

            ) : (
              <div className="alert success">
                There are no errors
              </div>
            )}
          </>
        )}

        {dataRows.length > 0 && (
          <>
            <div className="alert error mb-4">
              {rowErrorCount} Row Errors Found
            </div>
            <table className="table table-sm">
              <thead>
                <tr>
                  <th>Row</th>
                  <th>Errors</th>
                </tr>
              </thead>
              <tbody>
                {dataRows ? (
                  dataRows.map((dr, drKey) => (
                    <tr>
                      <td className="vertical-align-top"><span className="d-block pt-2 pb-2">{dr.rowNumber}</span></td>
                      <td className="vertical-align-top">
                        {dr.errors &&
                          dr.errors.length > 0 && (
                            <ul className="ul m-0">
                              {dr.errors.map((dre, dreKey) => (
                                <li>
                                  {dre.columnName} - {dre.error}
                                </li>
                              ))}
                            </ul>
                          )}
                        {dr.errors && dr.errors.length === 0 && <><span className="d-block pt-2 pb-2">None</span></>}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="vertical-align-top"><span className="d-block pt-2 pb-2">None</span></td>
                  </tr>
                )}
              </tbody>
            </table>
          </>
        )}
      </div>
    </div>
  );
}
