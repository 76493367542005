export function aggregatePeriodsActual(periods, aggregationMethod) {
  if (!periods || periods.length === 0) {
    return 0;
  }

  if (aggregationMethod === "sum") {
    return periods.reduce((total, period) => {
      if (period.isAggregatable) {
        let periodTotal = aggregatePeriodActual(period);
        total += periodTotal;
      }
      return total;
    }, 0);
  } else if (aggregationMethod === "most recent") {
    let sortedPeriods = periods.sort((a, b) => b.end - a.end);
    return aggregatePeriodActual(sortedPeriods[0]);
  } else if (aggregationMethod === "max") {
    let periodTotals = periods.map((p) => aggregatePeriodActual(p));
    return Math.max(...periodTotals);
  } else if (aggregationMethod === "min") {
    let periodTotals = periods.map((p) => aggregatePeriodActual(p));
    return Math.min(...periodTotals);
  } else {
    return 0;
  }
}

export function aggregatePeriodsTarget(periods, aggregationMethod) {
  if (!periods || periods.length === 0) {
    return 0;
  }

  if (aggregationMethod === "sum") {
    return periods.reduce((total, period) => {
      if (period.isAggregatable) {
        let periodTotal = aggregatePeriodTarget(period);
        total += periodTotal;
      }
      return total;
    }, 0);
  } else if (aggregationMethod === "most recent") {
    let sortedPeriods = periods.sort((a, b) => b.end - a.end);
    return aggregatePeriodTarget(sortedPeriods[0]);
  } else if (aggregationMethod === "max") {
    let periodTotals = periods.map((p) => aggregatePeriodTarget(p));
    return Math.max(...periodTotals);
  } else if (aggregationMethod === "min") {
    let periodTotals = periods.map((p) => aggregatePeriodTarget(p));
    return Math.min(...periodTotals);
  } else {
    return 0;
  }
}

export function aggregatePeriodActual(period) {
  return period.records.reduce((acc, record) => {
    acc += record.value;
    return acc;
  }, 0);
}

export function aggregatePeriodTarget(period) {
  return period.records.reduce((acc, record) => {
    acc += record.targetValue;
    return acc;
  }, 0);
}

export function calculateProgress(x, y) {
  return Math.round((x / y) * 100);
}
