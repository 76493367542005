import React from "react";
import { Switch, Route } from "react-router-dom";
import AuthorizeRoute from "../api-authorization/AuthorizeRoute";
import PortfolioDetails from "../Portfolio/PortfolioDetails";
import ActivityLayout from "../Activity/ActivityLayout";
import IndicatorItemDetail from "./IndicatorsPage/IndicatorItemDetail";
import RelationDetailsForPortfolio from "../Relation/RelationDetailsForPortfolio";
import EvidenceLayout from "../Evidence/EvidenceLayout";
import IndicatorSummary from "./IndicatorsPage/IndicatorSummary";

export default function HomeLayout() {
  return (
    <Switch>
      <Route path="/:portfolioId/activities/:activityId">
        <ActivityLayout />
      </Route>
      <Route path="/:portfolioId/indicators/:indicatorId/summary">
        <IndicatorSummary />
      </Route>
      <Route path="/:portfolioId/indicators/:indicatorId">
        <IndicatorItemDetail />
      </Route>
      <Route path="/:portfolioId/relations/:relationId">
        <RelationDetailsForPortfolio />
      </Route>
      <Route path="/:portfolioId/evidence/:evidenceId">
        <EvidenceLayout />
      </Route>
      <AuthorizeRoute
        path="/:portfolioId/:tabKey?"
        component={PortfolioDetails}
      />
    </Switch>
  );
}
