import React, { useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import useActivityService from "../../Services/useActivityService";

export default function ExportActivityData(props) {
  const { activityId } = props;
  const { getExport } = useActivityService();
  const [format, setFormat] = useState("csv");
  const [disabled, setDisabled] = useState(false);
  const [downloadText, setDownloadText] = useState("Download");
  const [spinnerState, setSpinnerState] = useState("none");

  const downloadData = async () => {
    setDisabled(true);
    setSpinnerState("inline-block");
    setDownloadText("Downloading...");
    let response = await getExport(activityId, format);
    setDisabled(false);
    setSpinnerState("none");
    setDownloadText("Download");
    let blob = response.data;
    if (blob) {
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.target = "_BLANK";
      a.rel = "noopener noreferrer";
      a.href = url;
      a.download = `exported-data.${format}`;
      a.click();
    }
  };

  return (
    <div className="export-data no-padding-top">
      <h2 className="h2">Export Data</h2>
      <h4 className="h4 mt-4 mb-1">Export activity data</h4>
      <p>
        Select a format option and then download the raw record data for this
        activity
      </p>

      <div class="filter-activities d-flex mt-1">
        <select
          onChange={(e) => setFormat(e.target.value)}
          className="select-menu"
        >
          <option disabled selected>
            Select Format
          </option>
          <option value="csv">CSV</option>
          <option value="json">JSON</option>
        </select>
        <button
          class="button tertiary with-icon ms-2"
          onClick={downloadData}
          disabled={disabled}
        >
          <i class="icon bi bi-cloud-download"></i>
          {downloadText}
        </button>
      </div>
      <Spinner
        style={{ display: spinnerState }}
        as="span"
        animation="grow"
        size="sm"
        role="status"
        aria-hidden="true"
        className="mt-4"
      />
    </div>
  );
}
