import React from "react";
import IndicatorList from "./IndicatorList";
import ManageResultPopover from "./ManageResultPopover";
import Collapsible from "../Shared/Collapsible";

export default function ResultListItem({
  result,
  viewType,
  recordID,
  onClickDeleteResult,
  onClickEditResult,
  onRemoveIndicator,
  isSharedActivity,
  isUserActivityOwner,
  isUserPortfolioOwner,
}) {
  return (
    <>
      <Collapsible
        label={result.title}
        className={`collapsible${recordID === 0 ? " no-border-top" : ""}`}
      >
        {(isUserActivityOwner || isUserPortfolioOwner) && !isSharedActivity && (
          <ManageResultPopover
            onClickDelete={() => onClickDeleteResult(result.id)}
            onClickEdit={() => onClickEditResult(result)}
          />
        )}
        {result.labels &&
          result.labels.split(",").map((l, i) => (
            <span key={i} className="badge info inline-block me-2 mb-3">
              {l}
            </span>
          ))}
        <h4 className="h4">Description</h4>

        {!result.description ? (
          <p>No Description</p>
        ) : (
          <p>{result.description}</p>
        )}
        <IndicatorList
          viewType={viewType}
          isSharedActivity={isSharedActivity}
          isUserActivityOwner={isUserActivityOwner}
          isUserPortfolioOwner={isUserPortfolioOwner}
          onRemoveIndicator={onRemoveIndicator}
          result={result}
        />
      </Collapsible>
    </>
  );
}
