import React from "react";
import { isEdge, isNode } from "react-flow-renderer";
import RelationSidebarContent from "./RelationSidebarContent";
import ResultSidebarContent from "./ResultSidebarContent";

export default function Sidebar({
  selectedElements,
  results,
  onClickLinkEvidence,
  onClickUnlinkEvidence,
  handleAddEvidenceModalOpen,
  handleDeleteEvidence,
  handleEvidenceIsJustifiedChanged,
}) {
  return (
    <div
      className="shadow"
      style={{
        position: "absolute",
        height: "100%",
        top: 56,
        right: 0,
        bottom: 0,
        width: selectedElements ? 350 : 0,
        transition: "width 0.5s",
        overflow: "hidden",
        zIndex: 999,
      }}
    >
      <div
        className="py-5 px-3"
        style={{
          height: "100%",
          width: selectedElements ? 350 : 0,
          position: "absolute",
          zIndex: 11,
          top: 56,
          right: selectedElements ? 0 : -350,
          overflowX: "hidden",
          transition: "0.5s",
          background: "white",
          visibility: selectedElements ? "visible" : "hidden",
        }}
      >
        {selectedElements &&
          selectedElements.map((e, i) => {
            return isEdge(e) && results.length ? (
              <RelationSidebarContent
                key={i}
                fromResult={results.find((r) => r.id === parseInt(e.source))}
                toResult={results.find((r) => r.id === parseInt(e.target))}
                edge={e.data.relationId}
                evidenceList={e.data.evidence}
                evidenceIsJustified={e.data.isJustified}
                onClickLinkEvidence={onClickLinkEvidence}
                onClickUnlinkEvidence={onClickUnlinkEvidence}
                handleAddEvidenceModalOpen={handleAddEvidenceModalOpen}
                handleDeleteEvidence={handleDeleteEvidence}
                handleEvidenceIsJustifiedChanged={
                  handleEvidenceIsJustifiedChanged
                }
              />
            ) : isNode(e) && results.length ? (
              <ResultSidebarContent
                result={results.find((r) => r.id === parseInt(e.id))}
              />
            ) : (
              <p>not recognised</p>
            );
          })}
      </div>
    </div>
  );
}
