import React, { useEffect, useState } from "react";
import {
  Badge,
  //Alert,
  Modal,
  DropdownButton,
  Dropdown,
  //Tab,
  //Nav,
} from "react-bootstrap";
//import AddButton from "../../Shared/AddButton";
//import Table from "react-bootstrap/Table";
import { useParams, useHistory, Link } from "react-router-dom";
import usePortfolioService from "../../Services/usePortfolioService";
import IndicatorTotals from "./IndicatorTotals";
import callApi from "../../Helpers/callApi";
import EditIndicatorModal from "./EditIndicatorModal";
import Breadcrumbs from "../../Shared/Breadcrumbs";
//import DisaggregationShow from "./DisaggregationShow";
import { useAddToast } from "../../Context/ToastContext";
import ContactOwnerForDeleteIndicator from "./ContactOwnerForDeleteIndicator";
import IndicatorTotalsForQualitative from "./IndicatorTotalsForQualitative";
import Collapsible from "../../Shared/Collapsible";

export default function IndicatorItemDetail() {
  const history = useHistory();
  const addtoToast = useAddToast();
  //const [tabKey, setTabKey] = useState("tabular");
  const [indicator, setIndicator] = useState({});
  const [disaggregations, setDisaggregations] = useState([]);
  const [indicatorTotals, setIndicatorTotals] = useState({});

  const { portfolioId, indicatorId } = useParams();
  const { getPortfolio, getIndicator, getDisaggregationsWithShared } =
    usePortfolioService(); //getDisaggregations

  const [portfolio, setPortfolio] = useState({});
  const [deleteIndicatorModalOpen, setDeleteIndicatorModalOpen] =
    useState(false);
  const [iseditIndicatorModalOpen, setIsEditIndicatorModalOpen] =
    useState(false);
  const [isAddDisaggregationModalOpen, setIsAddDisaggregationModalOpen] =
    useState(false);
  const [isRemoveDisaggregationModalOpen, setIsRemoveDisaggregationModalOpen] =
    useState(false);
  const [portfolioDisaggregations, setPortfolioDisaggregations] = useState([]);
  const [selectedDisaggregation, setSelectedDisaggregation] = useState(null);
  const [selectedDisaggregationforDelete, setSelectedDisaggregationforDelete] =
    useState(null);
  const [evidences, setEvidences] = useState([]);
  const [sharedWithPortfolios, setSharedWithPortfolios] = useState([]);
  const [openContactOwnerModal, setOpenContactOwnerModal] = useState(false);
  const [contactOwnerPortfolio, setContactOwnerPortfolio] = useState(null);
  const [indicatorPublicProperty, setIndicatorPublicProperty] = useState(false);
  const [contributingResults, setContributingResults] = useState([]);

  const [thisPortfolioResults, setThisPortfolioResults] = useState([]);
  const [sharedResults, setSharedResults] = useState([]);
  const [uniquePortfolioTitles, setUniquePortfolioTitles] = useState([]);

  const [thisPortfolioEvidences, setThisPortfolioEvidences] = useState([]);
  const [sharedEvidences, setSharedEvidences] = useState([]);
  const [
    uniquePortfolioTitlesForEvidence,
    setUniquePortfolioTitlesForEvidence,
  ] = useState([]);

  const [allPeriods, setAllPeriods] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getPortfolio(portfolioId);
      setPortfolio(response.data);
    };

    fetchData();
  }, [portfolioId, getPortfolio]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getIndicator(indicatorId, portfolioId);
      setIndicator(response.data.indicatorViewModel);
      setDisaggregations(response.data.disaggregations);
      setEvidences(response.data.evidences);

      const a = response.data.evidences
        ? response.data.evidences.filter(
          (x) => x.originatingPortfolioId === parseInt(portfolioId)
        )
        : [];
      setThisPortfolioEvidences(a);

      const otherResults = response.data.evidences
        ? response.data.evidences.filter(
          (x) => x.originatingPortfolioId !== parseInt(portfolioId)
        )
        : [];

      let uniquePortfolioNames = otherResults
        ? [...new Set(otherResults.map((x) => x.originatingPortfolioTitle))]
        : [];

      uniquePortfolioNames = uniquePortfolioNames.sort((a, b) =>
        a.localeCompare(b)
      );
      setUniquePortfolioTitlesForEvidence(uniquePortfolioNames);
      setSharedEvidences(otherResults);
    };

    fetchData();
  }, [indicatorId, getIndicator, portfolioId]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await callApi(
        `indicators/${indicatorId}/totalswiththeportfoliocontributingresults/${portfolioId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const totalResponse = await response.json();
        totalResponse.totalLoaded = true;
        setIndicatorTotals(totalResponse);
      }
    };
    fetchData();
  }, [indicatorId, portfolioId]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getDisaggregationsWithShared(portfolioId);
      setPortfolioDisaggregations(response.data);
    };

    fetchData();
  }, [portfolioId, getDisaggregationsWithShared]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await callApi(
        `indicators/${indicatorId}/sharedwithportfolios/${portfolioId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const jsonResponse = await response.json();
        setSharedWithPortfolios(jsonResponse);
      }
    };

    fetchData();
  }, [indicatorId, portfolioId]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await callApi(
        `portfolios/${portfolioId}/contributingresults/${indicatorId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const jsonResponse = await response.json();
        setContributingResults(jsonResponse);

        const a = jsonResponse
          ? jsonResponse.filter(
            (x) => x.originatingPortfolioId === parseInt(portfolioId)
          )
          : [];
        setThisPortfolioResults(a);

        const otherResults = jsonResponse
          ? jsonResponse.filter(
            (x) => x.originatingPortfolioId !== parseInt(portfolioId)
          )
          : [];

        let uniquePortfolioNames = otherResults
          ? [...new Set(otherResults.map((x) => x.originatingPortfolioTitle))]
          : [];

        uniquePortfolioNames = uniquePortfolioNames.sort((a, b) =>
          a.localeCompare(b)
        );
        setUniquePortfolioTitles(uniquePortfolioNames);
        setSharedResults(otherResults);
      }
    };

    fetchData();
  }, [indicatorId, portfolioId]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await callApi(
        `portfolios/${portfolioId}/summaryresults/${indicatorId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const jsonResponse = await response.json();
        setAllPeriods(jsonResponse.periods);
      }
    };

    fetchData();
  }, [indicatorId, portfolioId]);

  const onAddDisaggregationClick = () => {
    setIsAddDisaggregationModalOpen(true);
  };

  const handleRemoveDisaggregationClick = (disaggregation) => {
    setIsRemoveDisaggregationModalOpen(true);
    setSelectedDisaggregationforDelete(disaggregation);
  };

  const onIndicatorToBeDeletedClick = () => {
    setDeleteIndicatorModalOpen(true);
  };

  const deleteIndicatorModalClose = () => {
    setDeleteIndicatorModalOpen(false);
  };

  const onIndicatorToBeEditedClick = () => {
    setIndicatorPublicProperty(indicator.isPublic);
    setIsEditIndicatorModalOpen(true);
  };

  const handleDeleteIndicatorClick = async () => {
    const response = await callApi(`indicators/${indicatorId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      setDeleteIndicatorModalOpen(false);
      history.replace(`/${portfolioId}/indicators`);
    } else {
      const jsonResponse = await response.json();
      addtoToast({
        variant: "error",
        message: jsonResponse.errors[0].description,
      });
    }
  };

  const handleEditIndicatorSubmit = async () => {
    const response = await callApi(`indicators/${indicatorId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(indicator),
    });

    if (response.ok) {
      const editedIndicator = await response.json();
      setIndicator(editedIndicator);

      if (editedIndicator.isPublic !== indicatorPublicProperty) {
        if (editedIndicator.isPublic === false) {
          addtoToast({
            variant: "information",
            isDanger: false,
            message:
              "The indicator is being changed to private, the indicator will no longer be available to find and add by other users but existing uses will continue as shared usage.",
          });
        } else {
          addtoToast({
            variant: "information",
            isDanger: false,
            message:
              "This indicator will now be available for users to find and add to their portfolios, existing shared users will be unaffected.",
          });
        }
      }
    }
  };

  const handleEditIndicatorPropertyChange = (property, value) => {
    setIndicator({ ...indicator, [property]: value });
  };

  const handleAddDisaggregationSubmit = async () => {
    const response = await callApi(
      `indicators/${indicatorId}/disaggregations/${selectedDisaggregation.id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.ok) {
      setDisaggregations((currentArray) => [
        ...currentArray,
        selectedDisaggregation,
      ]);
    }
    setIsAddDisaggregationModalOpen(false);
  };

  const handleRemoveDisaggregationConfirm = async () => {
    const response = await callApi(
      `Indicators/${indicatorId}/disaggregations/${selectedDisaggregationforDelete.id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.ok) {
      const updatedResult = disaggregations.filter(
        (d) => d.id !== selectedDisaggregationforDelete.id
      );
      setDisaggregations(updatedResult);
      setIsRemoveDisaggregationModalOpen(false);
      setSelectedDisaggregationforDelete(null);
    }
  };

  const breadcrumbItems = [
    {
      path: `/${portfolioId}/indicators`,
      label: portfolio ? portfolio.title : "Indicators",
    },
    { label: indicator.title },
  ];

  const contactPortfolioOwner = (portfolio) => {
    setContactOwnerPortfolio(portfolio);
    setOpenContactOwnerModal(true);
  };

  const contactPortfolioOwnerSubmit = async () => {
    await callApi(
      `portfolios/${contactOwnerPortfolio.id}/contactindicatorsharedportfolioowner/${indicator.id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    setContactOwnerPortfolio(null);
    setOpenContactOwnerModal(false);
  };

  const hideContactOwnerModal = () => {
    setContactOwnerPortfolio(null);
    setOpenContactOwnerModal(false);
  };

  const onClickGotoUrl = (result) => {
    window.location.replace(
      `/${portfolioId}/activities/${result.activityId}/results/${result.id}/${indicatorId}`
    );
  };

  const onClickGotoUrlEvidence = (evidence) => {
    window.location.replace(
      `/${evidence.originatingPortfolioId}/evidence/${evidence.id}`
    );
  };

  const gotoSummary = () => {
    window.location.replace(
      `/${portfolioId}/indicators/${indicatorId}/summary`
    );
  };

  return (
    <>
      <div className="page-title">
        <div className="title">
          <Breadcrumbs items={breadcrumbItems} />
          <h1 className="h1">Indicator Details</h1>
        </div>
        <div className="actions"></div>
      </div>
      <h2 class="h2 mb-4">{indicator.title}</h2>


      {indicator.isShared === false ? (
        <>
          <div className="mt-3 mb-4">
            {indicator.isShared === false && (
              <div
                className="icon-and-text has-right-border"
                onClick={() => onAddDisaggregationClick()}
              >
                <i class="icon bi-plus-circle"></i>
                Add Disaggregation
              </div>
            )}
            <div
              className="icon-and-text"
              onClick={() => {
                onIndicatorToBeEditedClick();
              }}
            >
              <i class="icon bi-pencil-square"></i>
              Edit Indicator
            </div>
            <div
              onClick={() => onIndicatorToBeDeletedClick()}
              disabled={
                sharedWithPortfolios.length === 0 ? "false" : "true"
              }
              className="icon-and-text has-left-border"
            >
              <i class="icon bi-trash"></i>
              Delete Indicator
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

      {indicator.isPublic ? (
        <Badge className="badge inline-block success">
          Public
        </Badge>
      ) : (
        <>
          <Badge className="badge inline-block success mb-1">Private</Badge>
          <p>
            <small className="small-text">
              This indicator will not be searchable in other portfolios
            </small>
          </p>
        </>
      )}

      <p className="description mb-1">Description</p>
      <p style={{ whiteSpace: "pre-line" }}>{indicator.description}</p>

      <div className="indicator-totals mb-4">
        {indicator.typeId === 2 && (
          <IndicatorTotals indicator={indicatorTotals} />
        )}
        {indicator.typeId === 1 && (
          <IndicatorTotalsForQualitative allPeriods={allPeriods} />
        )}
        <div
          className="icon-and-text mt-3"
          onClick={() => gotoSummary()}
        >
          <i class="icon bi bi-clipboard-data"></i>
          See Data Breakdown
        </div>
      </div>

      <div>
        <h4 className="h4 mb-4">Disaggregations</h4>
        {disaggregations && disaggregations.length ? (
          disaggregations.map((d, i) => (

            <Collapsible
              label={d.title}
              className="collapsible"
              key={d.id}
            >
              <div className="mt-4">
                {indicator.isShared === false && (
                  <div
                    onClick={() => handleRemoveDisaggregationClick(d)}
                    className="icon-and-text mb-4"
                  >
                    <i class="icon bi bi-trash"></i>
                    Remove
                  </div>
                )}
                {d.description && (
                  <p>{d.description}</p>
                )}
                {d.disaggregationValues.length ? (
                  <ul className="ul">
                    {d.disaggregationValues.map((v, j) => (
                      <li key={v.id}>{v.value}</li>
                    ))}
                  </ul>
                ) : (
                  <p>
                    This disaggregation has no values
                  </p>
                )}
              </div>
            </Collapsible>

          ))
        ) : (
          <p className="mt-n3">
            There are no disaggregations for this indicator
          </p>
        )}
      </div>
      {/*<Tab.Container id="aggregated-data-tabs" defaultActiveKey="tabular">
          <div className="d-flex justify-content-center mt-3 ">
            <Nav
              variant="pills"
              className="border rounded p-2"
              activeKey={tabKey}
              onSelect={setTabKey}
            >
              <Nav.Item>
                <Nav.Link eventKey="tabular">Tabular</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="graphical">Graphical</Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          <Tab.Content>
            <Tab.Pane eventKey="graphical">
              <DisaggregationShow
                title={portfolio.title}
                disaggregations={disaggregations}
                indicatorTotals={indicatorTotals}
                handleRemoveDisaggregationClick={
                  handleRemoveDisaggregationClick
                }
                representKey={tabKey}
              ></DisaggregationShow>
            </Tab.Pane>
            <Tab.Pane eventKey="tabular">
              <DisaggregationShow
                title={portfolio.title}
                disaggregations={disaggregations}
                indicatorTotals={indicatorTotals}
                handleRemoveDisaggregationClick={
                  handleRemoveDisaggregationClick
                }
                representKey={tabKey}
              ></DisaggregationShow>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>*/}

      <p className="mt-4">
        Results visible in this portfolio using the indicator and contributing
        to indicator totals
      </p>

      {contributingResults.length > 0 && (
        <div>
          {thisPortfolioResults.length > 0 && (
            <div>
              <p className="description mb-3">
                From this portfolio
              </p>
              <ul className="mb-5">
                {thisPortfolioResults.map((v, i) => (
                  <li key={v.id} className="mb-3">
                    <Link onClick={() => onClickGotoUrl(v)}>
                      {v.title} in {v.activityTitle}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {sharedResults.length > 0 && (
            <div>
              <h4 className="h4 mt-3 mb-3">
                Shared with or nested in this portfolio:
              </h4>
            </div>
          )}
          {uniquePortfolioTitles.map((item, index) => (
            <div key={item}>
              <p className="description mt-3">From: {item}</p>
              <>
                <ul>
                  {sharedResults
                    .filter((x) => x.originatingPortfolioTitle === item)
                    .map((v, i) => (
                      <li key={v.id} className="mb-3">
                        <Link onClick={() => onClickGotoUrl(v)}>
                          {v.title} in {v.activityTitle}
                        </Link>
                      </li>
                    ))}
                </ul>
              </>
            </div>
          ))}
        </div>
      )}
      {evidences.length > 0 && (
        <>
          <h4 className="h4 mt-4 mb-2">Evidence</h4>
          <div>
            {thisPortfolioEvidences.length > 0 && (
              <div>
                <p className="mb-3">
                  Evidence supported by the indicator in this portfolio
                </p>
                <ul>
                  {thisPortfolioEvidences.map((v, i) => (
                    <li key={v.id} className="mb-3">
                      <Link onClick={() => onClickGotoUrlEvidence(v)}>
                        {v.title} in {v.originatingPortfolioTitle}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {sharedEvidences.length > 0 && (
              <div>
                <p className="description">
                  Shared with or nested in this portfolio:
                </p>
              </div>
            )}
            {uniquePortfolioTitlesForEvidence.map((item, index) => (
              <div key={item}>
                <span>
                  <strong>From: {item}</strong>
                </span>
                <>
                  <ul>
                    {sharedEvidences
                      .filter((x) => x.originatingPortfolioTitle === item)
                      .map((v, i) => (
                        <li key={v.id} className="mb-3">
                          <Link onClick={() => onClickGotoUrlEvidence(v)}>
                            {v.title} in {v.originatingPortfolioTitle}
                          </Link>
                        </li>
                      ))}
                  </ul>
                </>
              </div>
            ))}
          </div>
        </>
      )}
      {indicator.sharedActivityOrNestedPortfolioId === portfolio.id ? (
        <>
          {sharedWithPortfolios.length > 0 && (
            <div style={{ marginTop: "10px" }}>
              <h4 className="h4 mt-4 mb-2">Usage</h4>
              <div>
                <span>
                  This indicator is currently used in:
                </span>
                <ul>
                  {sharedWithPortfolios.map((value, index) => (
                    <li key={value.id}>
                      {" "}
                      {value.title}{" "}
                      <Link onClick={() => contactPortfolioOwner(value)}>
                        contact owner
                      </Link>
                    </li>
                  ))}
                </ul>
                <p>
                  If the indicator is used in other portfolios you will not be
                  able to delete
                </p>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {indicator.sharedFromPortfolioTitle !== null && (
            <div style={{ marginTop: "10px" }}>
              <h4 className="h4 mt-4 mb-2">Usage</h4>
              <div>
                This indicator has home portfolio: [
                <Link
                  to={(location) => ({
                    ...location,
                    pathname: `/${indicator.sharedFromPortfolioId}/indicators`,
                  })}
                >
                  {indicator.sharedFromPortfolioTitle}
                </Link>
                ]
              </div>
            </div>
          )}
        </>
      )}

      <EditIndicatorModal
        isOpen={iseditIndicatorModalOpen}
        onSubmit={handleEditIndicatorSubmit}
        onHide={() => setIsEditIndicatorModalOpen(false)}
        onChangeIndicatorProperty={handleEditIndicatorPropertyChange}
        indicator={indicator}
      />
      <ContactOwnerForDeleteIndicator
        isOpen={openContactOwnerModal}
        onHide={hideContactOwnerModal}
        onSubmit={contactPortfolioOwnerSubmit}
        indicatorTitle={indicator.title}
        portfolioTitle={
          contactOwnerPortfolio === null ? "" : contactOwnerPortfolio.title
        }
      ></ContactOwnerForDeleteIndicator>
      <Modal
        show={isAddDisaggregationModalOpen}
        onHide={() => setIsAddDisaggregationModalOpen(false)}
      >
        <Modal.Header>
          <Modal.Title>Add Disaggregation</Modal.Title>
          <button
            type="button"
            className="btn-close btn-close-white"
            aria-label="Close"
            onClick={() => setIsAddDisaggregationModalOpen(false)}
          ></button>
        </Modal.Header>
        <Modal.Body>
          <label>Select Disaggregation</label>
          <DropdownButton
            title={
              selectedDisaggregation
                ? selectedDisaggregation.title
                : "Select one"
            }
          >
            {portfolioDisaggregations.map((d, i) => (
              <Dropdown.Item
                onSelect={() => setSelectedDisaggregation(d)}
                key={i}
              >
                {d.title}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="button primary"
            onClick={() => handleAddDisaggregationSubmit()}
            disabled={!selectedDisaggregation}
          >
            Add Disaggregation
          </button>
          <button
            type="button"
            className="button tertiary"
            onClick={() => setIsAddDisaggregationModalOpen(false)}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={deleteIndicatorModalOpen}
        onHide={() => deleteIndicatorModalClose()}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>Delete Indicator</Modal.Title>
          <button
            type="button"
            className="btn-close btn-close-white"
            aria-label="Close"
            onClick={() => setIsRemoveDisaggregationModalOpen(false)}
          ></button>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this indicator?</Modal.Body>
        <Modal.Footer>
          <button
            className="button primary"
            onClick={handleDeleteIndicatorClick}
          >
            Delete
          </button>
          <button
            type="button"
            className="button tertiary"
            onClick={() => deleteIndicatorModalClose()}
          >
            {" "}
            Cancel{" "}
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={isRemoveDisaggregationModalOpen}
        onHide={() => setIsRemoveDisaggregationModalOpen(false)}
      >
        <Modal.Header>
          <Modal.Title>Remove Disaggregation</Modal.Title>
          <button
            type="button"
            className="btn-close btn-close-white"
            aria-label="Close"
            onClick={() => setIsRemoveDisaggregationModalOpen(false)}
          ></button>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to remove this disaggregation?
        </Modal.Body>
        <Modal.Footer>
          <button
            className="button primary"
            onClick={handleRemoveDisaggregationConfirm}
          >
            Remove
          </button>
          <button
            type="button"
            className="button tertiary"
            onClick={() => setIsRemoveDisaggregationModalOpen(false)}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
