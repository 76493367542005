import React from "react";
import { ReactComponent as UnauthorizedGraphic } from "./Unauthorized.svg";

const Unauthorised = () => {
  return (
    <>
      <div className="page-title">
        <div className="title">
          <nav className="breadcrumbs">
            <ol className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">System Error</li>
            </ol>
          </nav>
          <h1 className="h1">
            User Not Authorised
          </h1>
        </div>
      </div>
      <div className="d-flex flex-column">
        <UnauthorizedGraphic />
        <div className="alert error mt-4">
          <div className="text">
            <i className="icon bi bi-exclamation-circle"></i>
            You are not authorised to carry out this action
          </div>
        </div>
      </div>
    </>
  );
};

export default Unauthorised;
