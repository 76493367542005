import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

export default function AddQualitativeRecordTableRow(props) {
  const { isAddingRecord, onAddRecord, onCancelRecord, disaggregations } =
    props;
  const [selectedDisaggregations, setSelectedDisaggregations] = useState({});
  const [actualValue, setActualValue] = useState("");
  const [targetValue, setTargetValue] = useState("");

  let indicatorTypeId = 1;

  const handleDisaggregationChange = (
    disaggregation,
    disaggregationValueId
  ) => {
    const disaggregationValue = disaggregation.disaggregationValues.find(
      (d) => d.id === parseInt(disaggregationValueId)
    );
    setSelectedDisaggregations((prevState) => ({
      ...prevState,
      [disaggregation.title]: disaggregationValue,
    }));
  };

  const handleSubmitRecord = () => {
    const record = {
      indicatorTypeId: indicatorTypeId,
      value: actualValue,
      targetValue,
      disaggregationValues: [
        ...Object.entries(selectedDisaggregations).map((d) => {
          const disaggregation = d[0];
          const disaggregationValue = d[1];
          return {
            id: disaggregationValue.id,
            value: disaggregationValue.value,
            disaggregation,
          };
        }),
      ],
    };

    onAddRecord(record);
    setActualValue("");
    setTargetValue("");
  };

  const handleCancelRecord = () => {
    setActualValue("");
    setTargetValue("");
    onCancelRecord();
  };

  if (!isAddingRecord) return null;

  return (
    <tr className="table-active">
      <td>
        {disaggregations.length ? (
          disaggregations.map((d) => (
            <span key={d.id} className="mr-2">
              <Form.Label>{d.title}</Form.Label>
              <Form.Control
                as="select"
                defaultValue={
                  selectedDisaggregations[d.title]
                    ? selectedDisaggregations[d.title].id
                    : null
                }
                onChange={(e) => handleDisaggregationChange(d, e.target.value)}
              >
                <option selected disabled value="-1">
                  -- Select Value --
                </option>
                {d.disaggregationValues.map((dv, i) => (
                  <option key={dv.id} value={dv.id}>
                    {dv.value}
                  </option>
                ))}
              </Form.Control>
            </span>
          ))
        ) : (
          <span className="description">
            There are no disaggregations linked to this indicator
          </span>
        )}
      </td>
      <td colSpan="2">
        <div className="d-flex flex-column">
          <Form.Label className="d-flex w-100 justify-content-between">
            <span>Planned or Expected Value</span>
          </Form.Label>
          <Form.Control
            onChange={(e) => setTargetValue(e.target.value)}
            value={targetValue}
            as="textarea"
            rows="3"
          />
          <Form.Label className="mt-3 d-flex w-100 justify-content-between">
            <span>Acheived or Assessed Value</span>
          </Form.Label>
          <Form.Control
            onChange={(e) => setActualValue(e.target.value)}
            value={actualValue}
            as="textarea"
            rows="3"
          />
          <div className="mt-2 w-100 d-flex">
            <Button
              onClick={handleSubmitRecord}
              className="me-3 button primary"
            >
              Submit
            </Button>
            <Button onClick={handleCancelRecord} className="button tertiary">
              Cancel
            </Button>
          </div>
        </div>
      </td>
    </tr>
  );
}
