import React, { useState } from "react";

//import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";

//import { Link } from "react-router-dom";
//import moment from "moment";
import AddEvidenceModal from "../../Activity/TheoryOfChange/AddEvidenceModal";
//import { BsBoxArrowInDownLeft } from "react-icons/bs";
import usePortfolioService from "../../Services/usePortfolioService";
import { useParams } from "react-router-dom";
import ShareEvidenceModal from "./ShareEvidenceModal";
import callApi from "../../Helpers/callApi";
import { mutate } from "swr";
import { Spinner } from "react-bootstrap";
//import Badge from "react-bootstrap/Badge";
import SearchEvidenceList from "./SearchEvidenceList";
import { ListGroup } from "react-bootstrap";
import EvidenceListItemLink from "./EvidenceListItemLink";
import DeleteEvidenceModal from "../../Activity/TheoryOfChange/DeleteEvidenceModal";
import { useAddToast } from "../../Context/ToastContext";
import EditEvidenceModal from "../../Evidence/EditEvidenceModal";
import useEvidenceService from "../../Services/useEvidenceService";
import ShareEvidenceModalAtList from "../Evidence/ShareEvidenceModalAtList";
import ShareEvidenceModalConfirmation from "./ShareEvidenceModalConfirmation";
import Modal from "react-bootstrap/Modal";
import EvidenceAddToPortfolioConfirmation from "./EvidenceAddToPortfolioConfirmation";
import RequestAccessModalConfirmation from "../ActivityList/RequestAccessModalConfirmation";

export default function EvidenceListAtPortfolioView({
  isUserPortfolioOwner,
  evidence,
  isLoadingEvidence,
  sharedActivityOrNestedPortfolioEvidences,
}) {
  const { portfolioId } = useParams();
  const addtoToast = useAddToast();
  const [isAddingNewEvidence, setIsAddingNewEvidence] = useState(false);
  const [isAddEvidenceModalOpen, setIsAddEvidenceModalOpen] = useState(false);
  const { createEvidence } = usePortfolioService();
  const [isShareEvidenceModalOpen, setIsShareEvidenceModalOpen] =
    useState(false);
  const [searchTerm, setSearchTerm] = useState(null);
  const [searchedEvidences, setSearchedEvidences] = useState([]);
  const [isDeleteEvidenceModalOpen, setIsDeleteEvidenceModalOpen] =
    useState(false);
  const [evidenceIdToBeDeleted, setEvidenceIdToBeDeleted] = useState(null);
  const [evidenceToBeEdited, setEvidenceToBeEdited] = useState(null);
  const [isEditEvidenceModalOpen, setIsEditEvidenceModalOpen] = useState(false);
  const { updateEvidence } = useEvidenceService();
  const [evidenceToBeShared, setEvidenceToBeShared] = useState(null);
  const [isShareSingleEvidenceModalOpen, setIsShareSingleEvidenceModalOpen] =
    useState(false);
  const [isShareModalOpenConfirmation, setIsShareModalOpenConfirmation] =
    useState(false);
  const [portfolioUniqueTitle, setPortfolioUniqueTitle] = useState(null);
  const [sharedEvidences, setSharedEvidences] = useState([]);
  const [isRemoveEvidenceModalOpen, setIsRemoveEvidenceModalOpen] =
    useState(false);
  const [evidenceToBeRemoved, setEvidenceToBeRemoved] = useState(null);
  const [isAddToPortfolioOpen, setIsAddToPortfolioOpen] = useState(false);
  const [evidenceToBeAddToPortfolio, setEvidenceToBeAddToPortfolio] =
    useState(null);
  const [evidenceToBeAskedToShare, setEvidenceToBeAskedToShare] =
    useState(null);
  const [isRequestAccess, setIsRequestAccess] = useState(false);
  const [evidencePublicProperty, setEvidencePublicProperty] = useState(false);

  const thisPortfolioEvidence = evidence
    ? evidence.filter((x) => x.isShared === false)
    : [];
  const sharedEvidence = evidence
    ? [...new Set(evidence.filter((x) => x.isShared === true))]
    : [];
  let uniquePortfolioNames = sharedEvidence
    ? [...new Set(sharedEvidence.map((x) => x.originatingPortfolioTitle))]
    : [];

  uniquePortfolioNames = uniquePortfolioNames.sort((a, b) =>
    a.localeCompare(b)
  );

  let uniquePortfolioNamesFromNesting = sharedActivityOrNestedPortfolioEvidences
    ? [
      ...new Set(
        sharedActivityOrNestedPortfolioEvidences.map(
          (x) => x.sharedActivityOrNestedPortfolioTitle
        )
      ),
    ]
    : [];

  uniquePortfolioNamesFromNesting = uniquePortfolioNamesFromNesting.sort(
    (a, b) => a.localeCompare(b)
  );

  const handleAddEvidence = async (
    title,
    type,
    summary,
    comments,
    documentLink,
    produced,
    validFrom,
    validTo,
    evidenceProducedWithFCDOFunding,
    fundingIATIIdentifier,
    methods,
    authors,
    isPublic
  ) => {
    const response = await createEvidence(
      portfolioId,
      title,
      type,
      summary,
      comments,
      documentLink,
      produced,
      validFrom,
      validTo,
      null,
      evidenceProducedWithFCDOFunding,
      fundingIATIIdentifier,
      methods,
      authors,
      isPublic
    );

    if (response.ok) {
      setIsAddingNewEvidence(false);
      mutate(`portfolios/${portfolioId}/evidence`);
    }
  };

  const handleShareEvidenceModalClose = () => {
    setIsShareEvidenceModalOpen(false);
  };
  const handleShareEvidenceModalOpen = () => {
    setIsShareEvidenceModalOpen(true);
  };

  const handleShareEvidenceSubmit = async (
    portfolioUniqueTitle,
    evidenceIds,
    shareAll
  ) => {
    if (shareAll) {
      await callApi(
        `portfolios/${portfolioId}/evidence/share-all-evidence?destinationPortfolioUniqueTitle=${portfolioUniqueTitle}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } else {
      await callApi(
        `portfolios/${portfolioId}/evidence/share-evidence?destinationPortfolioUniqueTitle=${portfolioUniqueTitle}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            evidenceIds: evidenceIds,
          }),
        }
      );
    }

    mutate(`portfolios/${portfolioId}/evidence`);
  };

  const handleSearchEvidences = async (searchString) => {
    setSearchTerm(searchString);
    const response = await callApi(
      `evidence/${portfolioId}/search/${searchString}`
    );

    if (response.ok) {
      const searchedEvidences = await response.json();
      setSearchedEvidences(searchedEvidences);
    }
  };

  const handleAddPortfolioSubmit = async (evidence) => {
    const response = await callApi(
      `portfolios/${portfolioId}/evidences/${evidence.id}/AddToEvidence`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.ok) {
      //const newIndicator = await response.json();
      //setIndicators((prevState) => [...prevState, newIndicator]);
      setIsAddingNewEvidence(false);
      //setShareIndicatorAdded(!shareIndicatorAdded);
    }
    setSearchTerm(null);
    setSearchedEvidences([]);
  };

  const handleCancelSearchOrAddEvidence = () => {
    setIsAddingNewEvidence(false);
    setSearchedEvidences([]);
    setSearchTerm(null);
  };

  const handleDeleteEvidenceSubmit = async () => {
    if (!evidenceIdToBeDeleted.isShared) {
      const response = await callApi(`evidence/${evidenceIdToBeDeleted.id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        handleDeleteEvidenceModalClose();
        mutate(`portfolios/${portfolioId}/evidence`);
      } else {
        const jsonResponse = await response.json();
        addtoToast({
          variant: "error",
          message: jsonResponse.errors[0].description,
        });
      }
    }
  };

  const handleDeleteEvidence = async (evidence) => {
    setEvidenceIdToBeDeleted(evidence);
    setIsDeleteEvidenceModalOpen(true);
  };

  const handleDeleteEvidenceModalClose = () => {
    setEvidenceIdToBeDeleted(null);
    setIsDeleteEvidenceModalOpen(false);
  };

  const handleEditEvidenceSubmit = async (editedEvidence) => {
    let fullEvidenceObject = { ...evidence, ...editedEvidence };
    const response = await updateEvidence(fullEvidenceObject);

    if (response.ok) {
      mutate(`portfolios/${portfolioId}/evidence`);
      handleCloseEditEvidenceModal();

      if (fullEvidenceObject.isPublic !== evidencePublicProperty) {
        if (fullEvidenceObject.isPublic === false) {
          addtoToast({
            variant: "information",
            isDanger: false,
            message:
              "The evidence is being changed to private, the evidence will no longer be available to find and add by other users but existing uses will continue as shared usage.",
          });
        } else {
          addtoToast({
            variant: "information",
            isDanger: false,
            message:
              "This evidence will now be available for users to find and add to their portfolios, existing shared users will be unaffected.",
          });
        }
      }
    }
  };

  const handleEditEvidence = (evidence) => {
    setIsEditEvidenceModalOpen(true);
    setEvidenceToBeEdited(evidence);
    setEvidencePublicProperty(evidence.isPublic);
  };

  const handleCloseEditEvidenceModal = () => {
    setIsEditEvidenceModalOpen(false);
    setEvidenceToBeEdited(null);
  };

  const handleShareSingleEvidenceModalClose = () => {
    setEvidenceToBeShared(null);
    setIsShareSingleEvidenceModalOpen(false);
  };

  const handleShareEvidence = async (evidence) => {
    setEvidenceToBeShared(evidence);
    setSharedEvidences((item) => [...item, evidence.id]);
    setIsShareSingleEvidenceModalOpen(true);
  };

  const handleShareEvidenceModalOpenConfirmation = () => {
    setIsShareModalOpenConfirmation(true);
    setIsShareSingleEvidenceModalOpen(false);
  };

  const handleShareEvidenceModalOpenConfirmationClose = () => {
    setIsShareModalOpenConfirmation(false);
  };

  const handleGotoEvidenceHomePortfolio = async (evidence) => {
    window.location.replace(`/${evidence.originatingPortfolioId}/evidence`);
  };

  const handleClickRemoveEvidence = async (evidence) => {
    setIsRemoveEvidenceModalOpen(true);
    setEvidenceToBeRemoved(evidence);
  };

  const handleRemoveEvidenceClose = () => {
    setIsRemoveEvidenceModalOpen(false);
    setEvidenceToBeRemoved(null);
  };

  const handleRemoveEvidenceConfirm = async () => {
    let removeResponse = await callApi(
      `evidence/${evidenceToBeRemoved.id}/${portfolioId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!removeResponse.ok) {
      const jsonResponse = await removeResponse.json();
      addtoToast({
        variant: "error",
        message: jsonResponse.error[0],
      });
    } else {
      mutate(`portfolios/${portfolioId}/evidence`);
      handleRemoveEvidenceClose();
    }
  };

  const handleAddToPortfolioSubmit = async () => {
    const response = await callApi(
      `portfolios/${portfolioId}/evidence/${evidenceToBeAddToPortfolio.id}/evidence-addtoportfolio`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.ok) {
      handleAddToPortfolioClose();
      mutate(`portfolios/${portfolioId}/evidence`);
    }
  };

  const handleAddToPortfolio = (evidence) => {
    setEvidenceToBeAddToPortfolio(evidence);
    setIsAddToPortfolioOpen(true);
  };

  const handleAddToPortfolioClose = () => {
    setEvidenceToBeAddToPortfolio(null);
    setIsAddToPortfolioOpen(false);
  };

  const handleAskToShare = (evidence) => {
    setEvidenceToBeAskedToShare(evidence);
    setIsRequestAccess(true);
  };

  const hideAskToShare = () => {
    setEvidenceToBeAskedToShare(null);
    setIsRequestAccess(false);
  };

  const handleAskToShareSubmit = async () => {
    const response = await callApi(
      `portfolios/${portfolioId}/evidence/${evidenceToBeAskedToShare.id}/evidence-asktoshare`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.ok) {
      hideAskToShare();
    }
  };

  return (
    <>
      <div>
        <h2 class="h2">Evidence</h2>

        {isAddingNewEvidence ? (
          <>
            <div className="filter-activities mb-4">
              <label className="bold">Search existing evidence</label>
              <Form.Control
                type="text"
                placeholder="Search existing evidence"
                onChange={(e) => handleSearchEvidences(e.target.value)}
              />
            </div>
            <div className="actions">
              <div
                className="icon-and-text"
                variant="primary"
                onClick={() => setIsAddEvidenceModalOpen(true)}
              >
                <i className="icon bi bi-speedometer2"></i>
                Create Evidence
              </div>
              <div
                className="icon-and-text has-left-border"
                onClick={handleCancelSearchOrAddEvidence}
              >
                <i class="icon bi bi-x-circle"></i>
                Cancel
              </div>
            </div>
          </>
        ) : isUserPortfolioOwner ? (
          <>
            <div
              className="icon-and-text"
              onClick={() => setIsAddingNewEvidence(true)}
            >
              <i className="icon bi bi-plus-circle"></i>
              Add Evidence
            </div>
            <div
              className="icon-and-text has-left-border"
              onClick={() => handleShareEvidenceModalOpen(true)}
            >
              <i className="icon bi bi-share"></i>Share Evidence
            </div>
          </>
        ) : null}
      </div>
      {isAddingNewEvidence ? (
        <>
          <SearchEvidenceList
            evidences={searchedEvidences}
            onCopyIndicator={handleAddPortfolioSubmit}
            searchTerm={searchTerm}
          />
        </>
      ) : (
        <>
          {isLoadingEvidence ? (
            <div className="mt-4">
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          ) : thisPortfolioEvidence.length ||
            sharedEvidence.length ||
            sharedActivityOrNestedPortfolioEvidences.length ? (
            <>
              {thisPortfolioEvidence.length > 0 && (
                <>
                  <h4 className="h4 mt-4 mb-3">This portfolio</h4>
                  <ListGroup>
                    {thisPortfolioEvidence.map((i, j) => (
                      <>
                        <EvidenceListItemLink
                          evidence={i}
                          isUserPortfolioOwner={isUserPortfolioOwner}
                          onClickDeleteEvidence={handleDeleteEvidence}
                          onClickEditEvidence={handleEditEvidence}
                          onClickShareEvidence={handleShareEvidence}
                          onClickGotoHome={handleGotoEvidenceHomePortfolio}
                          onClickRemove={handleClickRemoveEvidence}
                          onClickAddToPortfolio={handleAddToPortfolio}
                          onClickAskToShare={handleAskToShare}
                        ></EvidenceListItemLink>
                      </>
                    ))}
                  </ListGroup>
                </>
              )}
              {uniquePortfolioNames.length ? (
                <>
                  <h4 className="h4 mt-4 mb-3">Added to this portfolio:</h4>
                </>
              ) : (
                <></>
              )}
              {uniquePortfolioNames.map((item, index) => (
                <div key={item}>
                  <span className="description inline">From: </span>
                  {item}
                  <>
                    <ListGroup className="mt-4">
                      {sharedEvidence
                        .filter((x) => x.originatingPortfolioTitle === item)
                        .map((v, i) => (
                          <EvidenceListItemLink
                            evidence={v}
                            isUserPortfolioOwner={isUserPortfolioOwner}
                            onClickDeleteEvidence={handleDeleteEvidence}
                            onClickEditEvidence={handleEditEvidence}
                            onClickShareEvidence={handleShareEvidence}
                            onClickGotoHome={handleGotoEvidenceHomePortfolio}
                            onClickRemove={handleClickRemoveEvidence}
                            onClickAddToPortfolio={handleAddToPortfolio}
                            onClickAskToShare={handleAskToShare}
                          ></EvidenceListItemLink>
                        ))}
                    </ListGroup>
                  </>
                </div>
              ))}
              {sharedActivityOrNestedPortfolioEvidences.length ? (
                <>
                  <div className="mt-4 mb-2">
                    <span>
                      In activities or portfolios shared with or nested in this
                      portfolio (read only - can't be used):
                    </span>
                  </div>
                  {uniquePortfolioNamesFromNesting.map((item, index) => (
                    <div key={item} className="mt-2">
                      <h4 className="h4 mt-2 mb-3">
                        From: {item}
                      </h4>
                      <>
                        <ListGroup>
                          {sharedActivityOrNestedPortfolioEvidences
                            .filter(
                              (x) =>
                                x.sharedActivityOrNestedPortfolioTitle === item
                            )
                            .map((v, i) => (
                              <EvidenceListItemLink
                                evidence={v}
                                isUserPortfolioOwner={isUserPortfolioOwner}
                                onClickDeleteEvidence={handleDeleteEvidence}
                                onClickEditEvidence={handleEditEvidence}
                                onClickShareEvidence={handleShareEvidence}
                                onClickRemove={handleClickRemoveEvidence}
                                onClickAddToPortfolio={handleAddToPortfolio}
                                onClickAskToShare={handleAskToShare}
                                onClickGotoHome={
                                  handleGotoEvidenceHomePortfolio
                                }
                              ></EvidenceListItemLink>
                            ))}
                        </ListGroup>
                      </>
                    </div>
                  ))}
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <p className="mt-3">There are no evidence in this portfolio</p>
          )}
        </>
      )}

      <AddEvidenceModal
        isOpen={isAddEvidenceModalOpen}
        onHide={() => setIsAddEvidenceModalOpen(false)}
        onSubmit={handleAddEvidence}
      />
      <DeleteEvidenceModal
        isOpen={isDeleteEvidenceModalOpen}
        onHide={handleDeleteEvidenceModalClose}
        onSubmit={handleDeleteEvidenceSubmit}
      />

      {isEditEvidenceModalOpen && (
        <EditEvidenceModal
          isOpen={isEditEvidenceModalOpen}
          onHide={handleCloseEditEvidenceModal}
          evidence={evidenceToBeEdited}
          onSubmit={handleEditEvidenceSubmit}
        />
      )}
      <ShareEvidenceModalAtList
        isOpen={isShareSingleEvidenceModalOpen}
        onSubmit={handleShareEvidenceModalOpenConfirmation}
        onHide={handleShareSingleEvidenceModalClose}
        evidence={evidenceToBeShared}
        onSetPortfolioUniqueTitle={setPortfolioUniqueTitle}
      />
      <ShareEvidenceModalConfirmation
        isOpen={isShareModalOpenConfirmation}
        onHide={handleShareEvidenceModalOpenConfirmationClose}
        sharedEvidences={sharedEvidences}
        portfolioUniqueTitle={portfolioUniqueTitle}
        onSubmit={handleShareEvidenceSubmit}
      ></ShareEvidenceModalConfirmation>

      <ShareEvidenceModal
        portfolioId={portfolioId}
        isOpen={isShareEvidenceModalOpen}
        onSubmit={handleShareEvidenceSubmit}
        onHide={handleShareEvidenceModalClose}
        allEvidence={thisPortfolioEvidence}
      />
      <EvidenceAddToPortfolioConfirmation
        isOpen={isAddToPortfolioOpen}
        onSubmit={handleAddToPortfolioSubmit}
        onHide={handleAddToPortfolioClose}
      />
      <RequestAccessModalConfirmation
        isOpen={isRequestAccess}
        onSubmit={handleAskToShareSubmit}
        onHide={hideAskToShare}
      />
      <Modal
        show={isRemoveEvidenceModalOpen}
        onHide={handleRemoveEvidenceClose}
      >
        <Modal.Header>
          <Modal.Title>Remove Evidence</Modal.Title>
          <button
            type="button"
            className="btn-close btn-close-white"
            aria-label="Close"
            onClick={handleRemoveEvidenceClose}
          ></button>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to remove this evidence?</p>
          <p>
            This will only remove this evidence from this portfolio. The
            evidence will not be deleted.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="button primary"
            onClick={handleRemoveEvidenceConfirm}
          >
            Save
          </button>
          <button
            type="button"
            className="button tertiary"
            onClick={handleRemoveEvidenceClose}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
