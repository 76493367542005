import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

export default function AddQuantativeRecordTableRow(props) {
  const { isAddingRecord, disaggregations, onAddRecord, records, units } =
    props;
  const [selectedDisaggregations, setSelectedDisaggregations] = useState({});
  const [actualValue, setActualValue] = useState(0);
  const [targetValue, setTargetValue] = useState(0);

  let indicatorTypeId = 2;

  let areAllDisaggregationsCompleted =
    Object.entries(selectedDisaggregations).length === disaggregations.length;

  const hasAMatchingSelectedValue = (disaggregationValue) => {
    return Object.entries(selectedDisaggregations).some((sdv) => {
      return disaggregationValue.id === sdv[1].id;
    });
  };

  const hasTheSameDisaggregationValuesAsSelected = (disaggregationValues) => {
    return (
      disaggregationValues.every((dv) => hasAMatchingSelectedValue(dv)) &&
      disaggregationValues.length ===
        Object.entries(selectedDisaggregations).length
    );
  };

  let isAnotherRecordWithSameDisaggregations = records.some((r) =>
    hasTheSameDisaggregationValuesAsSelected(r.disaggregationValues)
  );

  const handleDisaggregationChange = (
    disaggregation,
    disaggregationValueId
  ) => {
    const disaggregationValue = disaggregation.disaggregationValues.find(
      (d) => d.id === parseInt(disaggregationValueId)
    );
    setSelectedDisaggregations((prevState) => ({
      ...prevState,
      [disaggregation.title]: disaggregationValue,
    }));
  };

  const handleSubmitRecord = () => {
    const record = {
      indicatorTypeId: indicatorTypeId,
      value: actualValue,
      targetValue,
      disaggregationValues: [
        ...Object.entries(selectedDisaggregations).map((e) => {
          const disaggregation = e[0];
          const disaggregationValue = e[1];
          return {
            id: disaggregationValue.id,
            value: disaggregationValue.value,
            disaggregation,
          };
        }),
      ],
    };

    setActualValue(0);
    setTargetValue(0);
    onAddRecord(record);
  };

  if (!isAddingRecord) return null;

  return (
    <>
      <tr className="table-active">
        <td colSpan={disaggregations.length + 1} className="valign-top">
          <div className="add-record">
            {!areAllDisaggregationsCompleted && (
              <div className="alert info mb-0">
                Please provide a value for all disaggregations
              </div>
            )}
            {disaggregations.length &&
              disaggregations.map((d) => (
                <div key={d.id}>
                  <Form.Label>{d.title}</Form.Label>
                  <Form.Control
                    as="select"
                    className="select-menu"
                    defaultValue={
                      selectedDisaggregations[d.title]
                        ? selectedDisaggregations[d.title].id
                        : null
                    }
                    onChange={(e) =>
                      handleDisaggregationChange(d, e.target.value)
                    }
                  >
                    <option selected disabled value="-1">
                      -- Select Value --
                    </option>
                    {d.disaggregationValues.map((dv, i) => (
                      <option key={dv.id} value={dv.id}>
                        {dv.value}
                      </option>
                    ))}
                  </Form.Control>
                </div>
              ))}
          </div>
        </td>
        <td colSpan="3" className="valign-top">
          <div className="add-record-values">
            <div className="d-flex flex-column">
              <Form.Label className="d-flex w-100 justify-content-between">
                <span>Planned Value</span>
                <span className="text-muted">{units}</span>
              </Form.Label>
              <input
                type="number"
                step="0.01"
                onFocus={(e) => e.target.select()}
                onChange={(e) => setTargetValue(parseFloat(e.target.value))}
                value={targetValue}
                className="textbox"
              />
            </div>
            <div className="d-flex flex-column">
              <Form.Label className="d-flex w-100 justify-content-between">
                <span>Achieved Value</span>
                <span className="text-muted">{units}</span>
              </Form.Label>
              <input
                type="number"
                step="0.01"
                onFocus={(e) => e.target.select()}
                onChange={(e) => setActualValue(parseFloat(e.target.value))}
                value={actualValue}
                className="textbox"
              />
            </div>
            <div className="mt-2">
              {isAnotherRecordWithSameDisaggregations && (
                <div className="alert error">
                  Another record has the same disaggregations
                </div>
              )}
              <Button
                disabled={
                  !areAllDisaggregationsCompleted ||
                  isAnotherRecordWithSameDisaggregations
                }
                onClick={handleSubmitRecord}
                className="button primary"
              >
                Submit
              </Button>
            </div>
          </div>
        </td>
      </tr>
      {disaggregations.length === 0 && (
        <tr>
          <td colSpan={disaggregations.length + 1 + 3}>
            <span className="description">
              There are no disaggregations linked to this indicator
            </span>
          </td>
        </tr>
      )}{" "}
    </>
  );
}
