import React from "react";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import moment from "moment";

export default function ChangeRequestList({ portfolio, activity, onApprove }) {
  return (
    <>
      {activity.changeRequests &&
        activity.changeRequests.length > 0 && (
          <div className="py-3">
            <h4 className="h4">Change Requests</h4>
            <Table className="mt-3" striped bordered hover>
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Created</th>
                  <th>Updated</th>
                  <th>Approved</th>
                  <th>Changes</th>
                </tr>
              </thead>
              <tbody>
                {(activity.changeRequests || []).map((cr) => (
                  <tr>
                    <td>{cr.title}</td>
                    <td>
                      {moment(cr.created).format("Do MMM YYYY")} by{" "}
                      {cr.createdByUserUserName}
                    </td>
                    <td>{moment(cr.updated).format("Do MMM YYYY")}</td>
                    <td>
                      {cr.accepted && (
                        <>
                          {moment(cr.accepted).format("Do MMM YYYY")}
                          {" by "} {cr.createdByUserUserName}
                        </>
                      )}
                    </td>
                    <td>
                      {cr.canViewChangeSet && (
                        <div className="d-flex flex-wrap">
                          <Link
                            className="icon-and-text"
                            to={`/${portfolio.id}/activities/${activity.id
                              }/change-requests/${cr.id}`}
                          >
                            <i className="icon bi bi-arrow-right-circle"></i>
                            View
                          </Link>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
    </>
  );
}
