import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

export default function UnshareEvidenceModal(props) {
  const { isOpen, onHide, onSubmit } = props;

  return (
    <Modal show={isOpen} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Remove Evidence</Modal.Title>
        <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={onHide}></button>
      </Modal.Header>
      <Modal.Body>
        <p>
          Are you sure you want to remove this Evidence from this portfolio?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="button primary"
          onClick={() => {
            onSubmit();
            onHide();
          }}
        >
          Remove Evidence
        </Button>
        <Button className="button tertiary" onClick={onHide}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
