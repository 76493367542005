import moment from "moment";
import React from "react";
import {
  BsFillCaretUpFill,
  BsFillCaretDownFill,
  BsDash,
  BsFillXCircleFill,
  BsCheckAll,
  BsCheck,
  BsCircleHalf,
} from "react-icons/bs";
export function IndicatorSummaryTableRowComponentQualitativeIndicator({
  result,
  changeHandler,
  activity,
  allPeriods,
  addTargetValue,
  addAchievedValue,
  resultTotalTargetValue,
  resultTotalAchievedValue,
  isPlannedVisible,
  isAchievedVisible,
  selectedDisaggregations,
  disaggregations,
  uniquePeriodTitles,
}) {
  /*const addTargetDisaggregationValue = (period, valueItem) => {
    let sum = 0;
    for (let i = 0; i < period.records.length; i++) {
      for (let j = 0; j < period.records[i].disaggregationValues.length; j++) {
        if (period.records[i].disaggregationValues[j].id === valueItem.id) {
          sum = sum + period.records[i].targetValue;
        }
      }
    }
    return sum;
  };*/

  return (
    <tr key={result.id}>
      <td style={{ verticalAlign: "top" }}>{result.title}</td>
      <td style={{ verticalAlign: "top" }}>{result.activityTitle}</td>
      <td style={{ verticalAlign: "top" }}>
        <table>
          <tr>
            <td style={{ border: "none" }}>Targets</td>
          </tr>
          <tr>
            <td style={{ border: "none" }}>Trend</td>
          </tr>
          <tr>
            <td style={{ border: "none" }}>Records</td>
          </tr>
        </table>
      </td>
      {uniquePeriodTitles.map((periodtitle, periodtitleIndex) => (
        <td style={{ verticalAlign: "top" }}>
          <table>
            <tr>
              {allPeriods.map((period, periodIndex) => (
                <>
                  {period.resultId === result.id &&
                    moment(period.start).format("DD/MM/YYYY") +
                    "-" +
                    moment(period.end).format("DD/MM/YYYY") ===
                    periodtitle && (
                      <td style={{ border: "none" }}>
                        <div
                          className="d-flex align-items-center"
                          title={period.isTargetAchieved}
                        >
                          {period.isTargetAchieved === null && (
                            <label style={{ marginBottom: "0em" }}>
                              &nbsp;&nbsp;&nbsp;
                            </label>
                          )}
                          {period.isTargetAchieved != null &&
                            period.isTargetAchieved === 1 && (
                              <label style={{ marginBottom: "0em" }}>
                                <BsCheckAll className="target-icon text-color-success" />
                              </label>
                            )}
                          {period.isTargetAchieved != null &&
                            period.isTargetAchieved === 2 && (
                              <label style={{ marginBottom: "0em" }}>
                                <BsCheck className="target-icon" />
                              </label>
                            )}
                          {period.isTargetAchieved != null &&
                            period.isTargetAchieved === 3 && (
                              <label style={{ marginBottom: "0em" }}>
                                <BsCircleHalf className="target-icon" />
                              </label>
                            )}
                          {period.isTargetAchieved != null &&
                            period.isTargetAchieved === 4 && (
                              <label style={{ marginBottom: "0em" }}>
                                <BsFillXCircleFill className="target-icon text-color-error" />
                              </label>
                            )}
                        </div>
                      </td>
                    )}
                </>
              ))}
            </tr>
            <tr>
              {allPeriods.map((period, periodIndex) => (
                <>
                  {period.resultId === result.id &&
                    moment(period.start).format("DD/MM/YYYY") +
                    "-" +
                    moment(period.end).format("DD/MM/YYYY") ===
                    periodtitle && (
                      <td style={{ border: "none" }}>
                        <div
                          className="d-flex align-items-center"
                          title={period.trendFromPreviousPeriodText}
                        >
                          {period.trendFromPreviousPeriod === null && (
                            <label style={{ marginBottom: "0em" }}>
                              &nbsp;&nbsp;&nbsp;
                            </label>
                          )}
                          {period.trendFromPreviousPeriod != null &&
                            period.trendFromPreviousPeriod === 1 && (
                              <label style={{ marginBottom: "0em" }}>
                                <BsFillCaretUpFill className="target-icon text-color-success" />
                              </label>
                            )}
                          {period.trendFromPreviousPeriod != null &&
                            period.trendFromPreviousPeriod === 2 && (
                              <label style={{ marginBottom: "0em" }}>
                                <BsDash className="target-icon" />
                              </label>
                            )}
                          {period.trendFromPreviousPeriod != null &&
                            period.trendFromPreviousPeriod === 3 && (
                              <label style={{ marginBottom: "0em" }}>
                                <BsFillCaretDownFill className="target-icon text-color-error" />
                              </label>
                            )}
                        </div>
                      </td>
                    )}
                </>
              ))}
            </tr>
            <tr>
              {allPeriods.map((period, periodIndex) => (
                <>
                  {period.resultId === result.id &&
                    moment(period.start).format("DD/MM/YYYY") +
                    "-" +
                    moment(period.end).format("DD/MM/YYYY") ===
                    periodtitle && (
                      <td style={{ border: "none" }}>
                        <label style={{ marginBottom: "0em" }}>
                          {period.records.length}
                        </label>
                      </td>
                    )}
                </>
              ))}
            </tr>
          </table>
        </td>
      ))}
    </tr>
  );
}
