import React from "react";
import { Link, useRouteMatch } from "react-router-dom";
import ManageActivityPopover from "./ManageActivityPopover";
import { RiShareLine } from "react-icons/ri";

export default function ActivityListItem({
  activity,
  onClickDeleteActivity,
  onClickEditActivity,
  onClickShareActivity,
  onClickRemoveActivity,
  isUserPortfolioOwner,
  onClickGotoActivityHomePortfolio,
}) {
  let match = useRouteMatch();

  return (
    <li className="list-group-item list-group-item-action">
      <div class="d-flex flex-row align-items-center">
        {activity.isShared && (
          <span className="badge success inline">
            <RiShareLine className="mr-1" />
            {activity.originatingPortfolioTitle}
          </span>
        )}
        <span class="break-text">{activity.title}</span>
      </div>
      <div class="activity-actions">
        <Link to={`${match.url}/${activity.id}`} className="icon-and-text">
          <i class="icon bi-arrow-right-circle"></i>View
        </Link>
        <ManageActivityPopover
          onClickDelete={() => onClickDeleteActivity(activity)}
          onClickEdit={() => onClickEditActivity(activity)}
          onClickShare={() => onClickShareActivity(activity)}
          onClickRemove={() => onClickRemoveActivity(activity)}
          removeVisible={activity.isShared}
          isUserPortfolioOwner={isUserPortfolioOwner}
          onClickGotoHome={() => onClickGotoActivityHomePortfolio(activity)}
        />
      </div>
    </li>
  );
}
