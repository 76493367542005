import React, { useState } from "react";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

export default function AddEvidenceModal(props) {
  const { isOpen, onHide, onSubmit, evidence } = props;
  const [isValidated, setIsValidated] = useState(false);
  const [evidenceTitle, setEvidenceTitle] = useState(evidence.title);
  const [evidenceType, setEvidenceType] = useState(evidence.type);
  const [evidenceMethods, setEvidenceMethods] = useState(evidence.methods);
  const [evidenceSummary, setEvidenceSummary] = useState(evidence.summary);
  const [evidenceComments, setEvidenceComments] = useState(evidence.comments);
  const [evidenceAuthors, setEvidenceAuthors] = useState(evidence.authors);
  const [evidenceDocumentLink, setEvidenceDocumentLink] = useState(
    evidence.documentLink
  );
  const [evidenceProduced, setEvidenceProduced] = useState(evidence.produced);
  const [evidenceValidFrom, setEvidenceValidFrom] = useState(
    evidence.validFrom
  );
  const [evidenceValidTo, setEvidenceValidTo] = useState(evidence.validTo);
  const [evidenceProducedWithFCDOFunding, setEvidenceProducedWithFCDOFunding] =
    useState(evidence.evidenceProducedWithFCDOFunding);
  const [fundingIATIIdentifier, setFundingIATIIdentifier] = useState(
    evidence.fundingIATIIdentifier
  );
  const [evidenceIsPublic, setEvidenceIsPublic] = useState(evidence.isPublic);

  const resetState = () => {
    setEvidenceTitle("");
    setEvidenceType("");
    setEvidenceMethods("");
    setEvidenceSummary("");
    setEvidenceComments("");
    setEvidenceAuthors("");
    setEvidenceProduced(null);
    setEvidenceValidFrom(null);
    setEvidenceValidTo(null);
    setEvidenceProducedWithFCDOFunding(false);
    setFundingIATIIdentifier(null);
    setEvidenceIsPublic(false);
  };

  const handleHide = () => {
    onHide();
    resetState();
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      onSubmit({
        id: evidence.id,
        title: evidenceTitle,
        type: evidenceType,
        summary: evidenceSummary,
        comments: evidenceComments,
        documentLink: evidenceDocumentLink,
        produced: evidenceProduced,
        validFrom: evidenceValidFrom,
        validTo: evidenceValidTo,
        evidenceProducedWithFCDOFunding: evidenceProducedWithFCDOFunding,
        fundingIATIIdentifier: fundingIATIIdentifier,
        methods: evidenceMethods,
        authors: evidenceAuthors,
        isPublic: evidenceIsPublic,
      });
      handleHide();
    }

    setIsValidated(true);
  };

  return (
    <Modal show={isOpen} onHide={handleHide} backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>Edit Evidence</Modal.Title>
        <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={handleHide}></button>
      </Modal.Header>
      <Form
        autoComplete="off"
        noValidate
        validated={isValidated}
        onSubmit={handleSubmit}
      >
        <Modal.Body>
          <Form.Group controlId="evidenceTitle">
            <Form.Label>Title</Form.Label>
            <Form.Control
              value={evidenceTitle}
              required
              onChange={(e) => setEvidenceTitle(e.target.value)}
              type="text"
              placeholder="Evidence title"
            />
            <Form.Control.Feedback type="invalid">
              Please enter a title for the evidence
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="evidenceType">
            <Form.Label>Type</Form.Label>
            <Form.Control
              required
              onChange={(e) => setEvidenceType(e.target.value)}
              type="text"
              value={evidenceType}
              placeholder="Evidence type"
            />
            <Form.Control.Feedback type="invalid">
              Please enter a type for the evidence
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="evidenceMethods">
            <Form.Label>Methods</Form.Label>
            <Form.Control
              required
              onChange={(e) => setEvidenceMethods(e.target.value)}
              type="text"
              value={evidenceMethods}
              placeholder="Evidence methods"
            />
            <Form.Control.Feedback type="invalid">
              Please enter evidence methods
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="evidenceSummary">
            <Form.Label>Summary</Form.Label>
            <Form.Control
              required
              onChange={(e) => setEvidenceSummary(e.target.value)}
              as="textarea"
              rows="3"
              value={evidenceSummary}
              placeholder="Evidence Summary"
            />
            <Form.Control.Feedback type="invalid">
              Please enter a summary for the evidence
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="evidenceComments">
            <Form.Label>Comments</Form.Label>
            <Form.Control
              onChange={(e) => setEvidenceComments(e.target.value)}
              as="textarea"
              rows="3"
              value={evidenceComments}
              placeholder="Comments on validity or context"
            />
          </Form.Group>
          <Form.Group controlId="evidenceDocumentLink">
            <Form.Label>Document Link</Form.Label>
            <Form.Control
              onChange={(e) => setEvidenceDocumentLink(e.target.value)}
              type="url"
              pattern="https?://.*"
              value={evidenceDocumentLink}
              placeholder="A link to the evidence"
            />
            <Form.Control.Feedback type="invalid">
              Please enter a valid url beginning with http:// or https://
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="evidenceProduced">
            <Form.Label>When was the evidence produced</Form.Label>
            <Form.Control
              required
              onChange={(e) => setEvidenceProduced(e.target.value)}
              type="date"
              value={
                evidenceProduced
                  ? moment(evidenceProduced).format("YYYY-MM-DD")
                  : null
              }
              placeholder="Produced"
            />
            <Form.Control.Feedback type="invalid">
              Please enter the date the evidence was produced
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Label>Evidence relates to period</Form.Label>
          <Form.Row>
            <Form.Group as={Col} controlId="evidenceValidFrom">
              <Form.Label>From</Form.Label>
              <Form.Control
                required
                onChange={(e) => setEvidenceValidFrom(e.target.value)}
                type="date"
                value={
                  evidenceValidFrom
                    ? moment(evidenceValidFrom).format("YYYY-MM-DD")
                    : null
                }
                placeholder="Valid From"
              />
              <Form.Control.Feedback type="invalid">
                Please enter the start of the validity period
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} controlId="evidenceValidTo">
              <Form.Label>To</Form.Label>
              <Form.Control
                required
                onChange={(e) => setEvidenceValidTo(e.target.value)}
                type="date"
                value={
                  evidenceValidTo
                    ? moment(evidenceValidTo).format("YYYY-MM-DD")
                    : null
                }
                placeholder="Valid To"
              />
              <Form.Control.Feedback type="invalid">
                Please enter the end of the validity period
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Group controlId="evidenceAuthors">
            <Form.Label>Authors (optional)</Form.Label>
            <Form.Control
              onChange={(e) => setEvidenceAuthors(e.target.value)}
              type="text"
              value={evidenceAuthors}
              placeholder="Evidence authors"
            />
          </Form.Group>
          <Form.Group controlId="EvidenceProducedWithFCDOFunding">
            <Form.Label>Production</Form.Label>
            <Form.Check
              type="checkbox"
              checked={evidenceProducedWithFCDOFunding}
              onChange={(e) =>
                setEvidenceProducedWithFCDOFunding(e.target.checked)
              }
              label="Evidence produced with FCDO funding"
            />
          </Form.Group>
          <Form.Group controlId="FundingIATIIdentifier">
            <Form.Label>Funding IATI identifier (optional)</Form.Label>
            <Form.Control
              onChange={(e) => setFundingIATIIdentifier(e.target.value)}
              type="text"
              placeholder="IATI identifier"
              value={fundingIATIIdentifier}
            />
          </Form.Group>
          <Form.Group controlId="EvidenceIsPublic">
            <Form.Label>Is evidence public</Form.Label>
            <Form.Check
              type="checkbox"
              checked={evidenceIsPublic}
              onChange={(e) => setEvidenceIsPublic(e.target.checked)}
              label={evidenceIsPublic ? "Public" : "Private"}
            />
            <Form.Text>
              Public evidences will be searchable and copyable from other
              portfolios
            </Form.Text>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button className="button primary" type="submit">
            Save
          </Button>
          <Button className="button tertiary" onClick={handleHide}>
            Cancel
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
