import React from "react"; //useState
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
//import { useState } from "react";
//import { ToggleButtonGroup } from "react-bootstrap";
//import { ToggleButton } from "react-bootstrap";

export default function ShareIndicatorModalConfirmation({
  sharedIndicators,
  isOpen,
  onHide,
  onSubmit,
  portfolioUniqueTitle,
}) {
  //const [value, setValue] = useState("");

  const handleSubmit = (e) => {
    onSubmit(portfolioUniqueTitle, sharedIndicators, false);
  };

  const handleCancel = (e) => {
    onHide();
    //setValue("");
  };

  /*const handleChange = (val) => {
    setValue(val);
  };*/

  return (
    <Modal show={isOpen} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>Share Indicator Confirmation</Modal.Title>
        <button
          type="button"
          className="btn-close btn-close-white"
          aria-label="Close"
          onClick={handleCancel}
        ></button>
      </Modal.Header>
      <Form>
        <Modal.Body>
          {/*        
          <ToggleButtonGroup
            type="radio"
            name="options"
            onChange={handleChange}
          >
            <ToggleButton id="tbg-radio-1" value="Yes">
              Yes - let users see this activity in the portfolio wherever the
              portfolio is nested
            </ToggleButton>
            <ToggleButton id="tbg-radio-2" value="Parent and child users only">
              No - only allow users of this portfolio and the shared portfolio
              to view
            </ToggleButton>
          </ToggleButtonGroup>
          <p>
            All owners of this activity will be notified by email that the
            activity has been shared.
          </p>*/}
          <p>Do you still wish to share?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button className="button primary" onClick={handleSubmit}>
            Share
          </Button>
          <Button className="button tertiary" onClick={handleCancel}>
            Cancel
          </Button>
          {/*disabled={value === ""}*/}
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
