import React, { useState, useEffect } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import moment from "moment";
import DeletePeriodModal from "./DeletePeriodModal";
import QuantativeRecordValue from "./QuantativeRecordValue";
import callApi from "../Helpers/callApi";
import TotalsTable from "./TotalsTable";
import AggregatedData from "./AggregatedData";
import AddQuantativeRecordTableRow from "./AddQuantativeRecordTableRow";
import EditRecordModal from "./EditRecordModal";
import { useAddToast } from "../Context/ToastContext";
import useCombinationGenerate from "../Services/useCombinationGenerate";

export default function QuantativePeriodListItem({
  period,
  onUpdateRecord,
  onAddRecord,
  onDeleteRecord,
  onDeletePeriod,
  onLockPeriod,
  onUpdatePeriodComments,
  onEditPeriod,
  indicatorId,
  units,
  isSharedActivity,
  isUserActivityOwner,
  isUserPortfolioOwner,
  portfolioId,
}) {
  const addToast = useAddToast();
  const [disaggregations, setDisaggregations] = useState([]);
  const [areFiltersShowing, setAreFiltersShowing] = useState(false);
  const [filteredDisaggregationValues, setFilteredDisaggregationValues] =
    useState({});
  const [isAddingRecord, setIsAddingRecord] = useState(false);
  const [canAddRecord, setCanAddRecord] = useState(true);
  const [isDeletePeriodModalOpen, setIsDeletePeriodModalOpen] = useState(false);
  const [recordWithValueBeingEdited, setRecordWithValueBeingEdited] =
    useState(null);
  const [
    recordWithDisaggregationsBeingEdited,
    setRecordWithDisaggregationsBeingEdited,
  ] = useState(null);
  const [isEditRecordModalOpen, setIsEditRecordModalOpen] = useState(false);
  const [comments, setComments] = useState(period.comments);
  const [isAddingComments, setIsAddingComments] = useState(
    period.comments && period.comments.length !== 0
  );
  const [errorMessageForNoCombination, setErrorMessageForNoCombination] =
    useState(false);
  const { allCombinations } = useCombinationGenerate();

  useEffect(() => {
    const fetchData = async () => {
      const response = await callApi(
        `indicators/${indicatorId}/disaggregations/${portfolioId}`
      );
      const data = await response.json();
      setDisaggregations(data);
    };

    fetchData();
  }, [indicatorId, portfolioId]);

  const handleDeletePeriod = async () => {
    const response = await callApi(`periods/${period.id}`, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "DELETE",
    });

    if (response.ok) {
      onDeletePeriod(period);
    }
  };

  const handleUpdateRecord = async (record) => {
    const response = await callApi(`records/${record.id}`, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "PUT",
      body: JSON.stringify(record),
    });
    if (response.ok) {
      setRecordWithValueBeingEdited(null);
      const record = await response.json();
      onUpdateRecord(record);
    } else {
      const jsonResponse = await response.json();
      addToast({
        variant: "error",
        message: jsonResponse.errors[0].description,
      });
    }
  };

  const handleDeleteRecord = async (record) => {
    const response = await callApi(`records/${record.id}`, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "DELETE",
    });
    if (response.ok) {
      onDeleteRecord(record);
    }
  };

  const handleAddRecord = async (record) => {
    const response = await callApi(`periods/${period.id}/records`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(record),
    });

    if (response.ok) {
      setIsAddingRecord(false);
      const record = await response.json();
      onAddRecord(record);
    }
  };

  const handleSaveComments = (period) => {
    onUpdatePeriodComments(period, comments);
  };

  const handleFilterDisaggregation = (
    disaggregation,
    disaggregationValue = null
  ) => {
    setFilteredDisaggregationValues((prevState) => ({
      ...prevState,
      [disaggregation.title]: disaggregationValue,
    }));
  };

  const handleOnEdit = (record) => {
    setRecordWithValueBeingEdited(record.id);
    setCanAddRecord(false);
  };

  const handleOnBlur = () => {
    setRecordWithValueBeingEdited(null);
    setCanAddRecord(true);
  };

  const handleOnLockPeriod = async (period, lockedState) => {
    const response = await callApi(`periods/${period.id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify([
        {
          op: "replace",
          path: "/isLocked",
          value: lockedState,
        },
      ]),
    });

    if (response.ok) {
      const record = await response.json();
      onUpdateRecord(record);
    }
  };

  const uniqueDisaggregationValues =
    period.records.length &&
    period.records
      .map((r) => [...r.disaggregationValues])
      .reduce((uniques, disaggregationValues) => {
        for (const d of disaggregationValues) {
          if (!uniques.some((u) => u.id === d.id)) {
            uniques.push(d);
          }
        }
        return uniques;
      })
      .sort((a, b) => a.displayOrder - b.displayOrder);

  const totalValue = period.records.reduce((acc, record) => {
    acc += record.value;
    return acc;
  }, 0);

  const totalTarget = period.records.reduce((acc, record) => {
    acc += record.targetValue;
    return acc;
  }, 0);

  const progress = Math.round((totalValue / totalTarget) * 100);

  let filteredActualRecords = period.records.filter((r) => {
    let isInTheFilteredList = (dv) => {
      if (
        filteredDisaggregationValues[dv.disaggregation] &&
        filteredDisaggregationValues[dv.disaggregation].value !== dv.value
      ) {
        return true;
      }
      return false;
    };

    let recordHasFilteredDisaggregationValue =
      r.disaggregationValues.some(isInTheFilteredList);

    return !recordHasFilteredDisaggregationValue;
  });

  const handleEditRecordDisaggregationsClick = (record) => {
    setRecordWithDisaggregationsBeingEdited(record);
    setIsEditRecordModalOpen(true);
  };

  const generatedCombinationsFunction = () => {
    let array = [];
    let result = [];
    disaggregations.map((item) => {
      let innerArray = [];
      item.disaggregationValues.map((eachItem) =>
        innerArray.push(eachItem.value)
      );
      array.push(innerArray);
      return array;
    });
    if (array.length > 0) {
      result = allCombinations(array);
    }

    return result;
  };

  const checkVisiblity = () => {
    let status = false;

    if (disaggregations.length === 0 && period.records.length > 0) {
      return status;
    }

    if (disaggregations.length > 0) {
      let array = [];
      let periodsUniqueCombinations = [];
      var result = generatedCombinationsFunction();
      result.map((item) => {
        let generatedText = "";
        for (let i = 0; i < item.length; i++) {
          generatedText = generatedText + item[i] + ",";
        }

        array.push(generatedText);
        return array;
      });

      period.records.map((item) => {
        let generatedText = "";
        for (let i = 0; i < item.disaggregationValues.length; i++) {
          generatedText =
            generatedText + item.disaggregationValues[i].value + ",";
        }

        periodsUniqueCombinations.push(generatedText);
        return periodsUniqueCombinations;
      });

      array.map((item) => {
        let foundItem = periodsUniqueCombinations.find(
          (eachItem) => item === eachItem
        );

        if (foundItem === undefined) {
          status = true;
        }

        return status;
      });

      if (status === false) {
        return false;
      } else {
        return true;
      }
    }

    return true;
  };

  const addRecord = () => {
    if (checkVisiblity() === false) {
      setErrorMessageForNoCombination(true);
      setCanAddRecord(false);
      setTimeout(() => {
        setErrorMessageForNoCombination(false);
        setCanAddRecord(true);
      }, 5000);
    } else {
      setIsAddingRecord((prevState) => !prevState);
      setErrorMessageForNoCombination(false);
      setCanAddRecord(true);
    }
  };

  return (
    <>
      <DeletePeriodModal
        isOpen={isDeletePeriodModalOpen}
        onHide={() => setIsDeletePeriodModalOpen(false)}
        onSubmit={handleDeletePeriod}
      />

      {
        <div className="mb-4 d-flex">
          {!areFiltersShowing && (
            <div
              onClick={() => setAreFiltersShowing(true)}
              className="icon-and-text"
            >
              <i className="icon bi bi-filter"></i>
              Show Filters
            </div>
          )}

          {areFiltersShowing && (
            <div
              onClick={() => setAreFiltersShowing(false)}
              className="icon-and-text"
            >
              <i className="icon bi bi-filter"></i>
              Hide Filters
            </div>
          )}

          <div
            disabled={!canAddRecord}
            onClick={() => addRecord()}
            className="icon-and-text has-left-border"
          >
            <i className="icon bi bi-file-earmark-bar-graph"></i>
            {isAddingRecord ? "Cancel Add Record" : "Add Record"}
          </div>

          {!isSharedActivity && (
            <>
              {!((comments && comments.length > 0) || isAddingComments) && (
                <div
                  onClick={() => setIsAddingComments(true)}
                  variant="outline-secondary"
                  className="icon-and-text has-left-border"
                >
                  <i className="icon bi bi-chat-left"></i>
                  Add Comments
                </div>
              )}

              {!period.isLocked &&
                (isUserActivityOwner || isUserPortfolioOwner) && (
                  <div
                    onClick={() => handleOnLockPeriod(period, true)}
                    className="icon-and-text has-left-border"
                  >
                    <i className="icon bi bi-lock"></i>
                    Lock Period
                  </div>
                )}
              {period.isLocked &&
                (isUserActivityOwner || isUserPortfolioOwner) && (
                  <div
                    onClick={() => handleOnLockPeriod(period, false)}
                    className="icon-and-text has-left-border"
                  >
                    <i className="icon bi bi-unlock"></i>
                    Unlock Period
                  </div>
                )}
              {period.documentLink && (
                <a className="ml-2" href={period.documentLink}>
                  Document Link
                </a>
              )}

              {(isUserActivityOwner || isUserPortfolioOwner) && (
                <>
                  <div
                    onClick={() => onEditPeriod(period)}
                    className="icon-and-text has-left-border"
                  >
                    <i className="icon bi bi-pencil-square"></i>
                    Edit Period
                  </div>
                  <div
                    onClick={() => setIsDeletePeriodModalOpen(true)}
                    className="icon-and-text has-left-border"
                  >
                    <i className="icon bi bi-trash"></i>
                    Delete Period
                  </div>
                </>
              )}
            </>
          )}
        </div>
      }
      <span className="caption mb-2">Period</span>
      <h2 className="h2 mb-4">
        {period.title
          ? period.title
          : moment(period.start).format("Do MMM YYYY") +
            " - " +
            moment(period.end).format("Do MMM YYYY")}
      </h2>
      <div>
        <p>{period.narrative && period.narrative}</p>
        <div className="mb-4">
          <TotalsTable
            totalTarget={totalTarget}
            totalValue={totalValue}
            progress={progress}
          />
        </div>
      </div>

      <Tabs>
        <Tab eventKey="dataEntry" title="Data Entry">
          {period.records.length > 0 ? null : (
            <div className="alert info">
              There are no records for this period. Click "Add Record" to get
              started.
            </div>
          )}

          {areFiltersShowing && (
            <div className="mt-3 mb-3 period-filters">
              {disaggregations.length > 0 && period.records.length > 0
                ? disaggregations.map((d) => (
                    <div key={d.id}>
                      <Form.Label>{d.title}</Form.Label>
                      <Form.Control
                        as="select"
                        className="select-menu"
                        onChange={(e) =>
                          handleFilterDisaggregation(
                            d,
                            d.disaggregationValues[e.target.value]
                          )
                        }
                      >
                        <option value="-1">-- No Filter --</option>
                        {d.disaggregationValues.map((dv, i) => (
                          <option key={i} value={i}>
                            {dv.value}
                          </option>
                        ))}
                      </Form.Control>
                    </div>
                  ))
                : null}
            </div>
          )}

          {!isSharedActivity && (
            <div className="mt-4 mb-3 d-flex justify-content-between">
              Click the table values to edit data
            </div>
          )}

          <Table style={{ tableLayout: "fixed" }} hover size="sm">
            <thead>
              <tr>
                <th style={{ width: "75px" }}>ID</th>
                <th colSpan={disaggregations.length}>Disaggregations</th>
                <th colSpan="3">Values</th>
              </tr>
              {period.records.length > 0 ? (
                <tr>
                  <th />
                  {disaggregations.map((d) => (
                    <th className="text-break">{d.title}</th>
                  ))}
                  <th>Planned</th>
                  <th>Achieved</th>
                  <th />
                </tr>
              ) : null}
            </thead>
            <tbody>
              {filteredActualRecords.length > 0
                ? filteredActualRecords.map((r) => (
                    <tr key={r.id}>
                      <td>{r.id}</td>
                      {disaggregations.map((d) => {
                        let dv = r.disaggregationValues.find(
                          (x) => x.disaggregation === d.title
                        );
                        if (dv != null) {
                          return <td key={dv.id}>{dv.value}</td>;
                        } else {
                          return <td />;
                        }
                      })}
                      <QuantativeRecordValue
                        onEdit={() => handleOnEdit(r)}
                        isBeingEdited={recordWithValueBeingEdited === r.id}
                        onUpdateRecord={handleUpdateRecord}
                        onDeleteRecord={handleDeleteRecord}
                        record={r}
                        onBlur={handleOnBlur}
                        isSharedActivity={isSharedActivity}
                        onEditRecordDisaggregationClick={() =>
                          handleEditRecordDisaggregationsClick(r)
                        }
                      />
                    </tr>
                  ))
                : null}
              {errorMessageForNoCombination === true ? (
                <>
                  <tr>
                    <td colSpan={4 + disaggregations.length}>
                      <p>
                        <span className="text-danger">
                          There can be only one record for each disaggregation
                          combination in a period - if you want to add more
                          records for this period either add additional
                          disaggregation to the indicator or create an
                          additional period.
                        </span>
                      </p>
                    </td>
                  </tr>
                </>
              ) : (
                <>
                  <AddQuantativeRecordTableRow
                    isAddingRecord={isAddingRecord}
                    disaggregations={disaggregations}
                    onAddRecord={handleAddRecord}
                    records={period.records}
                    units={units}
                  />
                </>
              )}
            </tbody>
          </Table>

          {((comments && comments.length > 0) || isAddingComments) && (
            <div className="mt-4">
              <label>
                Comments
                {comments !== period.comments && (
                  <span className="text-secondary"> (unsaved)</span>
                )}
              </label>
              <Form.Group>
                <Form.Control
                  as="textarea"
                  rows={3}
                  onChange={(e) => setComments(e.target.value)}
                  value={comments}
                  placeholder="Add any additional comments here about this period."
                />
              </Form.Group>
              {comments !== period.comments && (
                <div className="d-flex">
                  <Button
                    onClick={() => handleSaveComments(period)}
                    className="button primary"
                  >
                    Save Comments
                  </Button>
                </div>
              )}
            </div>
          )}
        </Tab>
        <Tab eventKey="aggregatedData" title="Aggregated Data">
          <AggregatedData
            period={period}
            uniqueDisaggregationValues={uniqueDisaggregationValues}
          />
        </Tab>
      </Tabs>
      {isEditRecordModalOpen && (
        <EditRecordModal
          record={recordWithDisaggregationsBeingEdited}
          otherRecords={period.records.filter(
            (r) => r.id !== recordWithDisaggregationsBeingEdited.id
          )}
          disaggregations={disaggregations}
          isOpen={isEditRecordModalOpen}
          onHide={() => {
            setRecordWithDisaggregationsBeingEdited(null);
            setIsEditRecordModalOpen(false);
          }}
          onSubmit={handleUpdateRecord}
        />
      )}
    </>
  );
}
