import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import moment from "moment";
import DeletePeriodModal from "./DeletePeriodModal";
import QualitativeRecordValue from "./QualitativeRecordValue";
import callApi from "../Helpers/callApi";
import AddQualitativeRecordTableRow from "./AddQualitativeRecordTableRow";

export default function QualitativePeriodListItem({
  period,
  onUpdateRecord,
  onAddRecord,
  onDeleteRecord,
  onDeletePeriod,
  onUpdatePeriodComments,
  onEditPeriod,
  indicatorId,
  isSharedActivity,
  isUserActivityOwner,
  isUserPortfolioOwner,
  portfolioId,
}) {
  const [disaggregations, setDisaggregations] = useState([]);
  const [isAddingRecord, setIsAddingRecord] = useState(false);
  const [isDeletePeriodModalOpen, setIsDeletePeriodModalOpen] = useState(false);
  const [periodComments, setPeriodComments] = useState(period.comments);
  const [updateOrAddError, setUpdateOrAddError] = useState("");
  const [errorMessageForNoDisaggregation, setErrorMessageForNoDisaggregation] =
    useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const response = await callApi(
        `indicators/${indicatorId}/disaggregations/${portfolioId}`
      );
      const data = await response.json();
      setDisaggregations(data);
    };

    fetchData();
  }, [indicatorId, portfolioId]);

  const handleDeletePeriod = async () => {
    const response = await callApi(`periods/${period.id}`, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "DELETE",
    });

    if (response.ok) {
      onDeletePeriod(period);
    }
  };

  const handleDeleteRecord = async (record) => {
    const response = await callApi(`records/${record.id}`, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "DELETE",
    });
    if (response.ok) {
      onDeleteRecord(record);
    }
  };

  const handleUpdateRecord = async (record) => {
    const response = await callApi(`records/${record.id}`, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "PUT",
      body: JSON.stringify(record),
    });
    const updateResponse = await response.json();

    if (response.ok) {
      onUpdateRecord(updateResponse);
    } else {
      setUpdateOrAddError(updateResponse.errors[0].description);
      setTimeout(() => {
        setUpdateOrAddError("");
      }, 5000);
    }
  };

  const checkVisiblity = () => {
    if (disaggregations.length === 0 && period.records.length > 0) {
      return false;
    }

    /*if (disaggregations.length > 0) {
      var result = generatedCombinationsFunction();
      if (period.records.length >= result.length) {
        return false;
      } else {
        return true;
      }
    }*/

    return true;
  };

  const handleAddRecord = async (record) => {
    const response = await callApi(`periods/${period.id}/records`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(record),
    });

    if (response.ok) {
      setIsAddingRecord(false);
      const record = await response.json();
      onAddRecord(record);
    } else {
      const addResponse = await response.json();
      setUpdateOrAddError(addResponse.message);
      setTimeout(() => {
        setUpdateOrAddError("");
      }, 5000);
    }
  };

  const handleCancelRecord = () => {
    setIsAddingRecord(false);
  };

  const handleSaveComments = (period) => {
    onUpdatePeriodComments(period, periodComments);
  };

  const handleOnLockPeriod = async (period, lockedState) => {
    const response = await callApi(`periods/${period.id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify([
        {
          op: "replace",
          path: "/isLocked",
          value: lockedState,
        },
      ]),
    });

    if (response.ok) {
      const record = await response.json();
      onUpdateRecord(record);
    }
  };

  const handleIsTargetAchievedOnChange = async (period, newValue) => {
    const response = await callApi(`periods/${period.id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify([
        {
          op: "replace",
          path: "/isTargetAchieved",
          value: newValue,
        },
      ]),
    });

    if (response.ok) {
      const record = await response.json();
      onUpdateRecord(record);
    }
  };

  const handleTrendFromPreviousPeriodOnChange = async (period, newValue) => {
    const response = await callApi(`periods/${period.id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify([
        {
          op: "replace",
          path: "/TrendFromPreviousPeriod",
          value: newValue,
        },
      ]),
    });

    if (response.ok) {
      const record = await response.json();
      onUpdateRecord(record);
    }
  };

  const addRecord = () => {
    if (checkVisiblity() === false) {
      setErrorMessageForNoDisaggregation(true);

      setTimeout(() => {
        setErrorMessageForNoDisaggregation(false);
      }, 5000);
    } else {
      setIsAddingRecord((prevState) => !prevState);
      setErrorMessageForNoDisaggregation(false);
    }
  };

  return (
    <>
      <DeletePeriodModal
        isOpen={isDeletePeriodModalOpen}
        onHide={() => setIsDeletePeriodModalOpen(false)}
        onSubmit={handleDeletePeriod}
      />
      {
        <>
          {!isSharedActivity && !isAddingRecord && (
            <div className="mb-4 d-flex">
              <div
                onClick={() => addRecord()}
                className="icon-and-text"
                disabled={errorMessageForNoDisaggregation}
              >
                <i className="icon bi bi-file-earmark-bar-graph"></i>
                Add Record
              </div>

              {!period.isLocked &&
                (isUserActivityOwner || isUserPortfolioOwner) &&
                !isSharedActivity && (
                  <div
                    onClick={() => handleOnLockPeriod(period, true)}
                    className="icon-and-text has-left-border"
                  >
                    <i class="icon bi bi-lock"></i>
                    Lock Period
                  </div>
                )}
              {period.isLocked &&
                (isUserActivityOwner || isUserPortfolioOwner) &&
                !isSharedActivity && (
                  <div
                    onClick={() => handleOnLockPeriod(period, false)}
                    className="icon-and-text has-left-border"
                  >
                    <i class="icon bi bi-unlock"></i>
                    Unlock Period
                  </div>
                )}
              {(isUserActivityOwner || isUserPortfolioOwner) &&
                !isSharedActivity && (
                  <>
                    <div
                      onClick={() => onEditPeriod(period)}
                      className="icon-and-text has-left-border"
                    >
                      <i class="icon bi bi-pencil-square"></i>
                      Edit Period
                    </div>
                    <div
                      onClick={() => setIsDeletePeriodModalOpen(true)}
                      className="icon-and-text has-left-border"
                    >
                      <i class="icon bi bi-trash"></i>
                      Delete Period
                    </div>
                  </>
                )}

              {period.documentLink && (
                <a className="ml-2" href={period.documentLink}>
                  Document Link
                </a>
              )}
            </div>
          )}
        </>
      }

      <span class="caption mb-2">Period</span>
      <h2 className="h2 mb-4">
        {period.title
          ? period.title
          : moment(period.start).format("Do MMM YYYY") +
            " - " +
            moment(period.end).format("Do MMM YYYY")}
      </h2>

      <p>{period.narrative && period.narrative}</p>
      <h3 className="h3">Records</h3>
      {period.records.length > 0 ? null : (
        <div className="alert info">
          There are no records for this period. Click "Add Record" to get
          started.
        </div>
      )}

      <Table style={{ tableLayout: "fixed" }} size="sm">
        <colgroup>
          <col width="42%" />
          <col width="42%" />
          <col width="16%" />
        </colgroup>
        <thead>
          <tr>
            <th>Planned or expected</th>
            <th>Achieved or assessed</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {period.records.length > 0
            ? period.records.map((r) => (
                <QualitativeRecordValue
                  disaggregations={disaggregations}
                  onUpdateRecord={handleUpdateRecord}
                  onDeleteRecord={handleDeleteRecord}
                  record={r}
                  isSharedActivity={isSharedActivity}
                  key={r.id}
                />
              ))
            : null}
          {errorMessageForNoDisaggregation === true ? (
            <>
              <tr>
                <td colSpan={3}>
                  <div className="mb-0 alert error">
                    There can be only one record for each disaggregation
                    combination in a period - if you want to add more records
                    for this period either add additional disaggregation to the
                    indicator or create an additional period.
                  </div>
                </td>
              </tr>
            </>
          ) : (
            <>
              <AddQualitativeRecordTableRow
                isAddingRecord={isAddingRecord}
                disaggregations={disaggregations}
                onAddRecord={handleAddRecord}
                onCancelRecord={handleCancelRecord}
                records={period.records}
              />
              {updateOrAddError !== "" && (
                <tr>
                  <td colSpan={3}>
                    <div className="mb-0 alert error">{updateOrAddError}</div>
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </Table>

      <h3 className="h3 mt-4">Period Summary</h3>
      <Form.Row>
        <Form.Group as={Col}>
          <Form.Label>Is Target Achieved</Form.Label>
          <Form.Control
            as="select"
            className="select-menu max-width-400"
            value={period.isTargetAchieved}
            onChange={(e) =>
              handleIsTargetAchievedOnChange(
                period,
                e.target.value.toLowerCase()
              )
            }
          >
            <option disabled selected value>
              -- select an option --
            </option>
            <option value="1">Exceeded plans or expectations</option>
            <option value="2">Met plans or expectations</option>
            <option value="3">Partly met plan or expectations</option>
            <option value="4">Below plans or expectations</option>
          </Form.Control>
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>Trend From Previous Period</Form.Label>
          <Form.Control
            as="select"
            className="select-menu max-width-400"
            value={period.trendFromPreviousPeriod}
            onChange={(e) =>
              handleTrendFromPreviousPeriodOnChange(
                period,
                e.target.value.toLowerCase()
              )
            }
          >
            <option disabled selected value>
              -- select an option --
            </option>
            <option value="1">Improved</option>
            <option value="2">Similar</option>
            <option value="3">Decreased</option>
          </Form.Control>
          {period.trendFromPreviousPeriod && (
            <span className="badge warning inline-block mt-3">
              The results of this period are{" "}
              {period.trendFromPreviousPeriod === 1 && "better than "}
              {period.trendFromPreviousPeriod === 2 && "similar to "}
              {period.trendFromPreviousPeriod === 3 && "worse than "}
              the previous period
            </span>
          )}
        </Form.Group>
      </Form.Row>

      <Form.Group>
        <Form.Label>Comments</Form.Label>
        <Form.Control
          as="textarea"
          className="textarea"
          rows={5}
          onChange={(e) => setPeriodComments(e.target.value)}
          value={periodComments}
          placeholder="Comments."
        />
      </Form.Group>

      <Button
        onClick={() => handleSaveComments(period)}
        variant="outline-secondary"
      >
        Save Comments
      </Button>
    </>
  );
}
