import React from "react";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import Pager from "../../Shared/Pager";
import { Link } from "react-router-dom";

export default function ResultsList({
  portfolioId,
  results,
  page,
  setPage,
  pageSize,
  totalResultCount,
  setSearchString,
  isLoading,
}) {
  return (
    <>
      <h2 className="h2">Results</h2>
      <div class="filter-activities mt-4 mb-4">
        <label className="bold">Find a result</label>
        <Form.Control
          type="text"
          onChange={(e) => {
            setPage(1);
            setSearchString(e.target.value);
          }}
        />
      </div>

      {isLoading ? (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      ) : (
        <>
          <Table className="mt-3 mb-4">
            <thead>
              <tr>
                <th>Title</th>
                <th>Labels</th>
                <th>Activity</th>
              </tr>
            </thead>
            <tbody>
              {results.map((r) => (
                <tr key={r.id}>
                  <td>{r.title}</td>
                  <td>
                    {r.labels &&
                      r.labels.split(",").map((l, i) => (
                        <span key={i} className="pl-1">
                          <span className="badge info me-1">{l}</span>
                        </span>
                      ))}
                  </td>
                  <td>
                    {r.activityId && (
                      <Link
                        to={`/${portfolioId}/activities/${r.activityId}`}
                        className="icon-and-text"
                      >
                        <i class="icon bi bi-link-45deg"></i>
                        {r.activityTitle}
                      </Link>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pager
            totalItemCount={totalResultCount}
            pageSize={pageSize}
            page={page}
            onPageChanged={setPage}
          />
        </>
      )}
    </>
  );
}
