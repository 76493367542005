import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

export default function EditRecordModal({
  isOpen,
  onHide,
  onSubmit,
  record,
  otherRecords,
  disaggregations,
}) {
  const [recordBeingEdited, setRecordBeingEdited] = useState(record);

  const hasAMatchingSelectedValue = (disaggregationValue) => {
    return recordBeingEdited.disaggregationValues.some((dv) => {
      return disaggregationValue.id === dv.id;
    });
  };

  const hasTheSameDisaggregationValuesAsSelected = (disaggregationValues) => {
    return (
      disaggregationValues.every((dv) => hasAMatchingSelectedValue(dv)) &&
      disaggregationValues.length ===
      recordBeingEdited.disaggregationValues.length
    );
  };

  let isAnotherRecordWithSameDisaggregations = otherRecords.some((r) =>
    hasTheSameDisaggregationValuesAsSelected(r.disaggregationValues)
  );

  console.log(isAnotherRecordWithSameDisaggregations);

  const onDisaggregationValueChanged = (
    oldDisaggregationValueId,
    newDisaggregationValueId
  ) => {
    setRecordBeingEdited((prevState) => ({
      ...prevState,
      disaggregationValues: prevState.disaggregationValues.map((dv) => {
        if (dv.id === oldDisaggregationValueId) {
          dv.id = newDisaggregationValueId;
        }
        return dv;
      }),
    }));
  };

  const getDisaggregationValues = (disaggregationValueId) => {
    let disaggregation = getDisaggregationFromDisaggregationValue(
      disaggregationValueId
    );
    if (disaggregation == null) {
      return [];
    }
    return disaggregation.disaggregationValues;
  };

  const getDisaggregationFromDisaggregationValue = (disaggrgeationValueId) => {
    return disaggregations.find((d) =>
      d.disaggregationValues.find((dv) => dv.id === disaggrgeationValueId)
    );
  };

  const handleOnHide = () => {
    onHide();
  };

  const handleOnSubmit = () => {
    onSubmit(recordBeingEdited);
    handleOnHide();
  };

  return (
    <Modal show={isOpen} onHide={handleOnHide}>
      <Modal.Header>
        <Modal.Title>Edit Record</Modal.Title>
        <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={handleOnHide}></button>
      </Modal.Header>
      <Modal.Body>
        {(recordBeingEdited.disaggregationValues || []).map((rdv, i) => (
          <Form.Group key={i} controlId="role">
            <Form.Label>{rdv.disaggregation}</Form.Label>
            <Form.Control
              as="select"
              value={rdv.id}
              onChange={(e) =>
                onDisaggregationValueChanged(rdv.id, parseInt(e.target.value))
              }
            >
              {getDisaggregationValues(rdv.id).map((dv, i) => (
                <option key={dv.id} value={dv.id}>
                  {dv.value}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        ))}
        {isAnotherRecordWithSameDisaggregations && (
          <div className="alert error">
            Another record has the same disaggregations
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="button primary"
          onClick={handleOnSubmit}
          disabled={isAnotherRecordWithSameDisaggregations}
        >
          Save
        </Button>
        <Button className="button tertiary" onClick={handleOnHide}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
