import React from "react";
import ExportActivityData from "./ExportActivityData";
import ExportActivityAggregatedData from "./ExportActivityAggregatedData";
import ExportActivityLogFrameData from "./ExportActivityLogFrameData";

export default function ExportData({ activityId }) {
  return (
    <>
      <ExportActivityData activityId={activityId} />
      <ExportActivityAggregatedData activityId={activityId} />
      <ExportActivityLogFrameData activityId={activityId} />
    </>
  );
}
