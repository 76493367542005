import React from "react";
import ListGroup from "react-bootstrap/ListGroup";
import ManageEvidencePopover from "./ManageEvidencePopover";
import { Link, useRouteMatch } from "react-router-dom";

export default function EvidenceListItemLink(props) {
  const {
    evidence,
    onClickDeleteEvidence,
    onClickEditEvidence,
    onClickShareEvidence,
    isUserPortfolioOwner,
    onClickRemove,
    onClickGotoHome,
    onClickAddToPortfolio,
    onClickAskToShare,
  } = props;
  let match = useRouteMatch();

  return (
    <ListGroup.Item className="d-flex align-items-center ps-0">
      <div>
        <Link to={`${match.url}/${evidence.id}`}>{evidence.title}</Link>
        <div className="mt-3">
          <span className="badge success inline">
            {evidence.isPublic ? "Public" : "Private"}
          </span>
        </div>
      </div>
      <ManageEvidencePopover
        onClickDelete={() => onClickDeleteEvidence(evidence)}
        onClickEdit={() => onClickEditEvidence(evidence)}
        onClickShare={() => onClickShareEvidence(evidence)}
        removeVisible={evidence.isShared}
        isFromNestingOrSharedActivity={evidence.isFromNestingOrSharedActivity}
        isPublic={evidence.isPublic}
        isUserPortfolioOwner={isUserPortfolioOwner}
        onClickRemove={() => onClickRemove(evidence)}
        onClickGotoHome={() => onClickGotoHome(evidence)}
        onClickAddToPortfolio={() => onClickAddToPortfolio(evidence)}
        onClickAskToShare={() => onClickAskToShare(evidence)}
      />
    </ListGroup.Item>
  );
}
