import React from "react";
import { Switch, Route } from "react-router-dom";
import RelationDetails from "./RelationDetails";

export default function EvidenceLayout() {
  return (
    <Switch>
      <Route path="/:portfolioId/activities/:activityId/relations/:relationId">
        <RelationDetails />
      </Route>
    </Switch>
  );
}
