import React from "react";
import { LineChart, Line, XAxis, Tooltip, ResponsiveContainer } from "recharts";
import formatNumber from "../Helpers/formatNumber";

export default function Chart({
  data,
  xAxisDataKey,
  line1DataKey,
  line2DataKey,
  line1Name,
  line2Name,
}) {
  return (
    <ResponsiveContainer>
      <LineChart data={data} margin={{ left: 40, right: 10, top: 10 }}>
        <Line
          type="monotone"
          dataKey={line1DataKey}
          stroke="#82ca9d"
          name={line1Name}
        />
        <Line
          type="monotone"
          dataKey={line2DataKey}
          stroke="#8884d8"
          name={line2Name}
        />
        <Tooltip formatter={formatNumber} />
        <XAxis dataKey={xAxisDataKey} tick={false} />
      </LineChart>
    </ResponsiveContainer>
  );
}
