import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

export default function UnlinkRelationModal(props) {
  const { isOpen, onHide, onSubmit } = props;

  return (
    <Modal show={isOpen} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>Unlink Relation</Modal.Title>
        <button
          type="button"
          className="btn-close btn-close-white"
          aria-label="Close"
          onClick={onHide}
        ></button>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to unlink this relation from this evidence?</p>
        <p>
          The relation will not be deleted. It will still be visible in the
          Relations tab and can be relinked later.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="button primary"
          onClick={() => {
            onSubmit();
            onHide();
          }}
        >
          Unlink Relation
        </Button>
        <Button className="button tertiary" onClick={onHide}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
