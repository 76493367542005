import React, { useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";

export default function EditEvidenceRelationModal(props) {
  const { isOpen, onHide, onSubmit, evidenceRelation } = props;
  const [isValidated, setIsValidated] = useState(false);
  const [causality, setCausality] = useState(evidenceRelation.causality);
  const [direction, setDirection] = useState(evidenceRelation.direction);
  const [isNecessary, setIsNecessary] = useState(evidenceRelation.isNecessary);
  const [evidenceProduced, setEvidenceProduced] = useState(
    evidenceRelation.evidenceProduced
  );
  const [relativeImportanceYesNo, setRelativeImportanceYesNo] = useState(
    evidenceRelation.isRelativeImportance
  );
  const [timeLagYesNo, setTimeLagYesNo] = useState(evidenceRelation.isTimeLag);
  const [relativeImportanceScale, setRelativeImportanceScale] = useState(
    evidenceRelation.relativeImportanceScale
  );
  const [timeLag, setTimeLag] = useState(evidenceRelation.timeLag);
  const [scaleOfChangeYesNo, setScaleOfChangeYesNo] = useState(
    evidenceRelation.isScaleChange
  );
  const [evidenceEffectBox1, setEvidenceEffectBox1] = useState(
    evidenceRelation.evidenceEffect1
  );
  const [evidenceEffectBox2, setEvidenceEffectBox2] = useState(
    evidenceRelation.evidenceEffect2
  );
  const [comment, setComment] = useState(evidenceRelation.comment);

  const handleHide = () => {
    onHide();
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      onSubmit({
        ...evidenceRelation,
        direction: direction,
        causality: causality,
        isNecessary: isNecessary,
        evidenceProduced: evidenceProduced,
        isRelativeImportance: relativeImportanceYesNo,
        relativeImportanceScale: relativeImportanceScale,
        isTimeLag: timeLagYesNo,
        timeLag: timeLag,
        isScaleChange: scaleOfChangeYesNo,
        evidenceEffect1: evidenceEffectBox1,
        evidenceEffect2: evidenceEffectBox2,
        comment,
      });
      handleHide();
    }

    setIsValidated(true);
  };

  const getDirectionString = () => {
    switch (direction) {
      case "direct":
        return "Positive or negative change in the first result leads to positive or negative change in second result";
      case "inverse":
        return "Change in the first result produces the opposite effect in the second result (for example, increasing the first result reduces the second result)";
      case "none":
        return "Change in the first result has no effect on the second result";
      case "unknown":
        return "Evidence provides information about the relationship without knowing the type of relationship or direction of effect";
      default:
        return "an unknown change";
    }
  };

  const changeDirection = (e) => {
    e.preventDefault();
    setDirection(e.target.value.toLowerCase());

    if (e.target.value.toLowerCase() === "unknown") {
      setCausality(null);
    } else {
      setCausality(0);
    }
  };

  const showEvidenceImportantScale = (e) => {
    e.preventDefault();
    setRelativeImportanceYesNo(e.target.value.toLowerCase());
    if (e.target.value.toLowerCase() === "no") {
      setRelativeImportanceScale(null);
    } else {
      setRelativeImportanceScale(0);
    }
  };

  const showTimeLagText = (e) => {
    e.preventDefault();
    setTimeLagYesNo(e.target.value.toLowerCase());
    if (e.target.value.toLowerCase() === "no") {
      setTimeLag("");
    }
  };

  const showEvidenceEffectText = (e) => {
    setScaleOfChangeYesNo(e.target.value.toLowerCase());

    if (e.target.value.toLowerCase() === "no") {
      setEvidenceEffectBox1("");
      setEvidenceEffectBox2("");
    }
  };

  const changeCausalityValue = (e) => {
    let currentValue = e.target.value;
    setCausality(currentValue);

    /*if (currentValue <= 0.1) {
      setCausality(0);
    } else if (currentValue >= 0.2 && currentValue <= 0.25) {
      setCausality(0.25);
    } else if (currentValue >= 0.7 && currentValue <= 0.75) {
      setCausality(0.75);
    } else if (currentValue >= 0.95 && currentValue <= 1) {
      setCausality(1);
    }*/
  };

  const changeRelativeImportanceScale = (e) => {
    let currentValue = e.target.value;
    setRelativeImportanceScale(currentValue);

    /*if (currentValue <= 0.1) {
      setRelativeImportanceScale(0);
    } else if (currentValue >= 0.2 && currentValue <= 0.25) {
      setRelativeImportanceScale(0.25);
    } else if (currentValue >= 0.7 && currentValue <= 0.75) {
      setRelativeImportanceScale(0.75);
    } else if (currentValue >= 0.95 && currentValue <= 1) {
      setRelativeImportanceScale(1);
    }*/
  };

  const [showDirectionHelp, setShowDirectionHelp] = useState(false);
  const targetDirectionHelp = useRef(null);

  return (
    <Modal show={isOpen} size="lg" onHide={handleHide} backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>Edit Evidence</Modal.Title>
        <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={handleHide}></button>
      </Modal.Header>
      <Form
        autoComplete="off"
        noValidate
        validated={isValidated}
        onSubmit={handleSubmit}
      >
        <Modal.Body>
          <>
            <Form.Group controlId="direction">
              <Form.Label className="label bold">Direction</Form.Label>
              <label>
                What does the evidence say about the relationship?
              </label>
              <Form.Control
                required
                value={direction}
                as="select"
                className="select-menu"
                onChange={(e) => changeDirection(e)}
              >
                <option disabled selected value="">
                  -- select an option --
                </option>
                <option value="direct">
                  Evidence supports direct relationship
                </option>
                <option value="inverse">
                  Evidence supports inverse relationship
                </option>
                <option value="none">Evidence supports no relationship</option>
                <option value="unknown">Unknown</option>
              </Form.Control>



              {direction && (
                <>
                  <div className="icon-and-text mt-2" ref={targetDirectionHelp} onClick={() => setShowDirectionHelp(!showDirectionHelp)}>
                    <i className="icon bi bi-question-circle"></i>
                    Help
                  </div>
                  <Overlay target={targetDirectionHelp.current} show={showDirectionHelp} placement="right">
                    {(props) => (
                      <Tooltip id="overlay-example" {...props}>
                        An increase in the first result would result in{" "}
                        {getDirectionString()} in the second result
                      </Tooltip>
                    )}
                  </Overlay>
                </>
              )}
              <Form.Control.Feedback type="invalid">
                Please enter the direction
              </Form.Control.Feedback>
            </Form.Group>
            {direction !== "unknown" && (
              <Form.Group controlId="causality">
                <Form.Label className="label bold">Causality</Form.Label>
                <label>
                  How strongly does the evidence support what it says about the
                  relationship?
                </label>
                <Form.Control
                  value={causality}
                  onChange={(e) => changeCausalityValue(e)}
                  type="range"
                  min="0"
                  max="1"
                  step="0.01"
                  placeholder="Causality"
                />
                <Form.Text className="d-flex w-100 justify-content-between">
                  <div>
                    Evidence<br></br>provides no<br></br>support
                  </div>
                  <div>
                    Evidence<br></br>provides some<br></br>support
                  </div>
                  <div>
                    Evidence<br></br>provides good<br></br>support
                  </div>
                  <div>
                    Evidence<br></br>provides<br></br>certainty
                  </div>
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  Please enter the causality
                </Form.Control.Feedback>
              </Form.Group>
            )}

            <Form.Group controlId="isNecessary">
              <Form.Label className="label bold">Is it Necessary</Form.Label>
              <label>
                Does the evidence say whether the first result is necessary to
                achieve the second?
              </label>
              <Form.Control
                as="select"
                className="select-menu"
                value={isNecessary}
                onChange={(e) => setIsNecessary(e.target.value.toLowerCase())}
              >
                <option disabled selected value>
                  -- select an option --
                </option>
                <option>yes</option>
                <option>no</option>
                <option>unknown</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="evidenceProduced">
              <Form.Label className="label bold">Production</Form.Label>
              <label>
                Was the evidence produced directly about this relationship from
                the results of this relationship or was it produced in a similar
                context and assumed to apply to this relationship?
              </label>
              <Form.Control
                as="select"
                className="select-menu"
                value={evidenceProduced}
                onChange={(e) =>
                  setEvidenceProduced(e.target.value.toLowerCase())
                }
              >
                <option disabled selected value>
                  -- select an option --
                </option>
                <option value="directly">
                  The evidence applies directly for this relationship
                </option>
                <option value="related">
                  The evidence was produced in related context or different time
                  period
                </option>
                <option value="unknown">Unknown</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="relativeImportanceYesNo">
              <Form.Label className="label bold">Relative Importance</Form.Label>
              <label>
                Does the evidence say how important the first result is for
                changing or achieving the second?
              </label>
              <Form.Control
                as="select"
                value={relativeImportanceYesNo}
                className="select-menu"
                onChange={(e) => showEvidenceImportantScale(e)}
              >
                <option disabled selected value>
                  -- select an option --
                </option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </Form.Control>
            </Form.Group>
            {relativeImportanceYesNo && (
              <Form.Group controlId="relativeImportanceScale">
                <Form.Control
                  value={relativeImportanceScale}
                  onChange={(e) => changeRelativeImportanceScale(e)}
                  type="range"
                  min="0"
                  max="1"
                  step="0.01"
                  placeholder="relativeImportanceScale"
                />
                <Form.Text className="d-flex w-100 justify-content-between">
                  <div>
                    First result has little<br></br>to no influence on
                    <br></br>second (in relation<br></br>to others)
                  </div>
                  <div>
                    First result is one of a<br></br>number of similar
                    <br></br>influences for second<br></br>result
                  </div>
                  <div>
                    First result is a<br></br>significant<br></br>influence for
                    <br></br>second result
                  </div>
                  <div>
                    First result is<br></br>sole and only<br></br>influence on
                    <br></br>second
                  </div>
                </Form.Text>
              </Form.Group>
            )}
            <Form.Group controlId="timeLagYesNo">
              <Form.Label className="label bold">Time Lag</Form.Label>
              <label>
                Does the evidence indicator say anything about the time lag for
                the first result to affect the second?
              </label>
              <Form.Control
                as="select"
                onChange={(e) => showTimeLagText(e)}
                className="select-menu"
                value={timeLagYesNo}
              >
                <option disabled selected value>
                  -- select an option --
                </option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </Form.Control>
            </Form.Group>
            {timeLagYesNo === "yes" && (
              <Form.Group controlId="timeLag">
                <label>What time lag does the evidence suggest?</label>
                <Form.Control
                  type="text"
                  className="textbox"
                  value={timeLag}
                  onChange={(e) => setTimeLag(e.target.value.toLowerCase())}
                  placeholder="e.g. 6 months"
                />
              </Form.Group>
            )}
            <Form.Group controlId="scaleOfChangeYesNo">
              <Form.Label className="label bold">Effect size</Form.Label>
              <label>
                Does the evidence indicator say anything about the scale of
                change that the first result can have on the second?
              </label>
              <Form.Control
                as="select"
                value={scaleOfChangeYesNo}
                className="select-menu"
                onChange={(e) => showEvidenceEffectText(e)}
              >
                <option disabled selected value>
                  -- select an option --
                </option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </Form.Control>
            </Form.Group>
            {scaleOfChangeYesNo === "yes" && (
              <>
                <Form.Group controlId="evidenceEffectBox1">
                  <label>
                    What does the evidence say about effect?
                  </label>
                  <div style={{ marginTop: "10px" }}></div>
                  <Form.Control
                    type="text"
                    className="textbox"
                    value={evidenceEffectBox1}
                    onChange={(e) =>
                      setEvidenceEffectBox1(e.target.value.toLowerCase())
                    }
                    placeholder="Change in result 1 comment"
                  />
                </Form.Group>
                <Form.Group controlId="evidenceEffectBox2">
                  <label>Leads to</label>
                  <Form.Control
                    type="text"
                    className="textbox"
                    value={evidenceEffectBox2}
                    onChange={(e) =>
                      setEvidenceEffectBox2(e.target.value.toLowerCase())
                    }
                    placeholder="Change in result 2 comment"
                  />
                </Form.Group>
              </>
            )}
            <Form.Group controlId="comment">
              <Form.Label className="label bold">Comment</Form.Label>
              <label>
                Does the evidence say anything else about this relationship
              </label>
              <Form.Control
                type="textarea"
                className="textarea"
                value={comment}
                onChange={(e) => setComment(e.target.value.toLowerCase())}
                placeholder="comment"
              />
            </Form.Group>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button className="button primary" type="submit">
            Save
          </Button>
          <Button
            className="button tertiary"
            onClick={handleHide}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
