import React from "react";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";

export default function DeleteIndicatorModal({
  isOpen,
  onHide,
  handleDeleteIndicatorClick,
}) {
  return (
    <Modal show={isOpen} onHide={onHide} size="lg">
      <Modal.Header>
        <Modal.Title>Delete Indicator</Modal.Title>
        <button
          type="button"
          className="btn-close btn-close-white"
          aria-label="Close"
          onClick={onHide}
        ></button>
      </Modal.Header>
      <Modal.Body>Are you sure you want to delete this indicator?</Modal.Body>
      <Modal.Footer>
        <Button className="button primary" onClick={handleDeleteIndicatorClick}>
          Delete
        </Button>
        <Button className="button tertiary" onClick={onHide}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
