import React, { useState, useEffect } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import moment from "moment";
import Breadcrumbs from "../Shared/Breadcrumbs";
import usePortfolioOwnerStatus from "../Hooks/usePortfolioOwnerStatus";
import ResultTableData from "./ResultTableData";
import DeleteEvidenceModal from "../Activity/TheoryOfChange/DeleteEvidenceModal";
import EditEvidenceModal from "./EditEvidenceModal";
import useEvidenceService from "../Services/useEvidenceService";
import usePortfolioService from "../Services/usePortfolioService";
import UnlinkButton from "../Shared/UnlinkButton";
import EditButton from "../Shared/EditButton";
import EditEvidenceRelationModal from "./EditEvidenceRelationModal";
import UnlinkRelationModal from "./UnlinkRelationModal";
import UnshareEvidenceModal from "./UnshareEvidenceModal";
import callApi from "../Helpers/callApi";
import { useAddToast } from "../Context/ToastContext";
import AddIndicatorModalAtEvidence from "./AddIndicatorModalAtEvidence";
import UnlinkIndicatorModal from "./UnlinkIndicatorModal";
import ContactOwnerForDeleteEvidence from "./ContactOwnerForDeleteEvidence";

export default function EvidenceDetailsForPortfolio() {
  const addToast = useAddToast();
  //const [isAddIndicatorModalOpen, setIsAddIndicatorModalOpen] = useState(false);
  const { portfolioId, evidenceId } = useParams();
  let history = useHistory();
  const [evidence, setEvidence] = useState({});
  const [portfolio, setPortfolio] = useState({});
  const isUserPortfolioOwner = usePortfolioOwnerStatus();
  const [isDeleteEvidenceModalOpen, setIsDeleteEvidenceModalOpen] =
    useState(false);
  const [isRemoveEvidenceModalOpen, setIsRemoveEvidenceModalOpen] =
    useState(false);
  const [isEditEvidenceModalOpen, setIsEditEvidenceModalOpen] = useState(false);
  const [evidenceRelationToBeEdited, setEvidenceRelationToBeEdited] =
    useState(null);
  const [evidenceRelationToBeUnlinked, setEvidenceRelationToBeUnlinked] =
    useState(null);
  const [allIndicators, setAllIndicators] = useState([]);
  const [isAddIndicatorModalOpen, setIsAddIndicatorModalOpen] = useState(false);
  const [indicatorToBeUnlinked, setIndicatorToBeUnlinked] = useState(null);

  const [sharedWithPortfolios, setSharedWithPortfolios] = useState([]);
  const [openContactOwnerModal, setOpenContactOwnerModal] = useState(false);
  const [contactOwnerPortfolio, setContactOwnerPortfolio] = useState(null);
  const [evidencePublicProperty, setEvidencePublicProperty] = useState(false);

  const {
    getEvidence,
    updateEvidence,
    deleteEvidence,
    updateEvidenceRelation,
    unlinkRelation,
    unlinkIndicator,
  } = useEvidenceService();
  const { getPortfolio, getAllIndicatorsWithShared } = usePortfolioService(); //getAllIndicators

  useEffect(() => {
    const fetchData = async () => {
      const response = await getPortfolio(portfolioId);
      if (response.ok) setPortfolio(response.data);
    };

    fetchData();
  }, [portfolioId, getPortfolio]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await callApi(
        `evidence/${evidenceId}/sharedwithportfolios`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const jsonResponse = await response.json();
        setSharedWithPortfolios(jsonResponse);
      }
    };

    fetchData();
  }, [evidenceId]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getEvidence(evidenceId, portfolioId);
      if (response.ok) setEvidence(response.data);
    };

    fetchData();
  }, [evidenceId, portfolioId, getEvidence]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getAllIndicatorsWithShared(portfolioId);
      if (response.ok) setAllIndicators(response.data);
    };

    fetchData();
  }, [evidenceId, portfolioId, getAllIndicatorsWithShared]);

  const breadcrumbItems = [
    { path: `/${portfolioId}`, label: portfolio.title },
    { label: evidence.title },
  ];

  const handleDeleteEvidence = async () => {
    const response = await deleteEvidence(evidenceId);

    if (response.ok) {
      history.replace(`/${portfolioId}/evidence`);
    } else {
      const jsonResponse = await response.json();
      addToast({
        variant: "error",
        message: jsonResponse.errors[0].description,
      });
    }
  };

  const handleRemoveEvidence = async () => {
    let removeResponse = await callApi(
      `evidence/${evidenceId}/${portfolioId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!removeResponse.ok) {
      const jsonResponse = await removeResponse.json();
      addToast({
        variant: "error",
        message: jsonResponse.error[0],
      });
    } else {
      setIsRemoveEvidenceModalOpen(false);
      history.replace(`/${portfolioId}/evidence`);
    }
  };

  const handleEditEvidence = async (editedEvidence) => {
    let fullEvidenceObject = { ...evidence, ...editedEvidence };
    const response = await updateEvidence(fullEvidenceObject);

    if (response.ok) {
      setEvidence(fullEvidenceObject);

      if (fullEvidenceObject.isPublic !== evidencePublicProperty) {
        if (fullEvidenceObject.isPublic === false) {
          addToast({
            variant: "information",
            isDanger: false,
            message:
              "The evidence is being changed to private, the evidence will no longer be available to find and add by other users but existing uses will continue as shared usage.",
          });
        } else {
          addToast({
            variant: "information",
            isDanger: false,
            message:
              "This evidence will now be available for users to find and add to their portfolios, existing shared users will be unaffected.",
          });
        }
      }
    }
  };

  const handleUnlinkEvidenceRelation = async () => {
    const response = await unlinkRelation(
      evidence.id,
      evidenceRelationToBeUnlinked.relationId
    );

    if (response.ok) {
      setEvidence((prevState) => {
        return {
          ...prevState,
          evidencedRelations: prevState.evidencedRelations.filter((er) => {
            return er.relationId !== evidenceRelationToBeUnlinked.relationId;
          }),
        };
      });
    }
  };

  const handleUnlinkIndicator = async () => {
    const response = await unlinkIndicator(
      evidence.id,
      indicatorToBeUnlinked.indicatorId
    );

    if (response.ok) {
      setEvidence((prevState) => {
        return {
          ...prevState,
          evidenceIndicators: prevState.evidenceIndicators
            .flat()
            .filter((er) => {
              return er.indicatorId !== indicatorToBeUnlinked.indicatorId;
            }),
        };
      });
    }
  };

  const handleEditEvidenceRelation = async (editedEvidenceRelation) => {
    editedEvidenceRelation.evidenceId = evidence.id;
    const response = await updateEvidenceRelation(
      evidence,
      editedEvidenceRelation
    );

    if (response.ok) {
      setEvidence((prevState) => {
        return {
          ...prevState,
          evidencedRelations: prevState.evidencedRelations.map((er) => {
            if (er.relationId === editedEvidenceRelation.relationId) {
              return editedEvidenceRelation;
            }
            return er;
          }),
        };
      });
    }
  };

  const handleRemoveButtonClick = () => {
    setIsRemoveEvidenceModalOpen(true);
  };

  const handleDeleteButtonClick = () => {
    setIsDeleteEvidenceModalOpen(true);
  };

  const handleEditButtonClick = () => {
    setIsEditEvidenceModalOpen(true);
    setEvidencePublicProperty(evidence.isPublic);
  };

  const handleAddIndicatorButtonClick = () => {
    setIsAddIndicatorModalOpen(true);
  };

  const handleAddIndicatorModalClose = () => {
    setIsAddIndicatorModalOpen(false);
  };

  const handleLinkEvidenceWithIndicator = async (indicators) => {
    let filteredIndicators = indicators.map((x) => x.indicatorId);
    const response = await callApi(`evidence/${evidence.id}/linktoIndicator`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        IndicatorIds: filteredIndicators,
      }),
    });

    if (response.ok) {
      handleAddIndicatorModalClose();
      setEvidence((prevState) => {
        return {
          ...prevState,
          evidenceIndicators: [
            ...prevState.evidenceIndicators,
            indicators.map((x, i) => {
              return x;
            }),
          ],
        };
      });
    }
  };

  const contactPortfolioOwner = (portfolio) => {
    setContactOwnerPortfolio(portfolio);
    setOpenContactOwnerModal(true);
  };

  const contactPortfolioOwnerSubmit = async () => {
    await callApi(
      `portfolios/${contactOwnerPortfolio.id}/contactevidencesharedportfolioowner/${evidenceId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    setContactOwnerPortfolio(null);
    setOpenContactOwnerModal(false);
  };

  const hideContactOwnerModal = () => {
    setContactOwnerPortfolio(null);
    setOpenContactOwnerModal(false);
  };

  return (
    <>
      <div className="page-title">
        <div className="title">
          <Breadcrumbs items={breadcrumbItems} />
          <h1 className="h1">Evidence</h1>
        </div>
      </div>
      <div className="actions mb-4">
        {isUserPortfolioOwner && (
          <div
            className="icon-and-text"
            onClick={handleAddIndicatorButtonClick}
          >
            <i className="icon bi bi-plus-circle"></i>
            Add Indicator
          </div>
        )}
        {evidence.isShared ? (
          <>
            {isUserPortfolioOwner && (
              <div
                className="icon-and-text"
                onClick={handleRemoveButtonClick}
              >
                <i className="icon bi bi-trash"></i>
                Remove Evidence
              </div>
            )}
          </>
        ) : (
          <>
            {isUserPortfolioOwner && (
              <div
                className="icon-and-text has-left-border"
                onClick={handleDeleteButtonClick}
              >
                <i className="icon bi bi-trash"></i>
                Delete Evidence
              </div>
            )}
            {isUserPortfolioOwner && (
              <div className="icon-and-text has-left-border"
                onClick={handleEditButtonClick}
              >
                <i className="icon bi bi-pencil-square"></i>
                Edit Evidence
              </div>
            )}
          </>
        )}
      </div>
      {evidence.isFromNestingOrSharedActivity === false && (
        <div className="d-flex w-100 flex-row-reverse">
        </div>
      )}

      <div className="mt-3 mb-4">
        <div className="badge success inline">
          {evidence.isPublic ? "Public" : "Private"}
        </div>
      </div>


      <span class="caption mb-2">Evidence Title</span>
      <h4 className="h4 mb-4">{evidence.title}</h4>

      <span className="description">Type</span>
      <p style={{ whiteSpace: "pre-line" }}>{evidence.type}</p>

      <span className="description">Methods</span>
      <p style={{ whiteSpace: "pre-line" }}>{evidence.methods}</p>

      <span className="description">Summary</span>
      <p style={{ whiteSpace: "pre-line" }}>{evidence.summary}</p>

      <span className="description">Comments</span>
      <p style={{ whiteSpace: "pre-line" }}>{evidence.comments}</p>

      <span className="description">Authors</span>
      <p style={{ whiteSpace: "pre-line" }}>{evidence.authors}</p>

      <span className="description">Produced with FCDO funding</span>
      <p style={{ whiteSpace: "pre-line" }}>
        {evidence.evidenceProducedWithFCDOFunding ? "Yes" : "No"}
      </p>

      <span className="description">Funding IATI identifer</span>
      <p style={{ whiteSpace: "pre-line" }}>{evidence.fundingIATIIdentifier}</p>

      <span className="description">Valid</span>
      <p style={{ whiteSpace: "pre-line" }}>
        {moment(evidence.validFrom).format("Do MMM YYYY")} -{" "}
        {moment(evidence.validTo).format("Do MMM YYYY")}
      </p>

      <span className="description">Document Link</span>
      <p>
        <a
          href={evidence.documentLink}
          target="_blank"
          rel="noopener noreferrer"
          className="icon-and-text"
        >
          <i class="icon bi bi-file-earmark"></i>
          Open Document
        </a>
      </p>

      <h3 className="h3 mb-1">Relations</h3>
      <p>
        The evidence is used to support these relationships in this portfolio
      </p>
      <Table className="mt-3" bordered>
        <thead>
          <tr>
            <th>From</th>
            <th>To</th>
            <th>Direction</th>
            <th>Level of support for causality</th>
            <th>Is necessary</th>
            {evidence.evidencedRelations &&
              evidence.evidencedRelations.some(
                (x) => x.relativeImportanceScale != null
              ) && <th>Relative importance</th>}
            {evidence.evidencedRelations &&
              evidence.evidencedRelations.some((x) => {
                if (x.timeLag === null || x.timeLag === "") {
                  return false;
                }
                return true;
              }) && <th>Time lag</th>}
            {evidence.evidencedRelations &&
              evidence.evidencedRelations.some((x) => {
                if (
                  (x.evidenceEffect1 === null || x.evidenceEffect1 === "") &&
                  (x.evidenceEffect2 === null || x.evidenceEffect2 === "")
                ) {
                  return false;
                }
                return true;
              }) && <th>Effect size</th>}
            {evidence.evidencedRelations &&
              evidence.evidencedRelations.some((x) => {
                if (x.comment === null || x.comment === "") {
                  return false;
                }
                return true;
              }) && <th>Comment</th>}
            <th />
          </tr>
        </thead>
        <tbody>
          {evidence.evidencedRelations &&
            evidence.evidencedRelations.map((er, i) => (
              <tr key={i}>
                <ResultTableData resultId={er.fromResultId} />
                <ResultTableData resultId={er.toResultId} />
                <td>{er.direction}</td>
                <td>
                  {er.causality === null ? (
                    "No value"
                  ) : (
                    <>
                      <input
                        type="range"
                        className="form-range"
                        min="0"
                        max="1"
                        step="0.01"
                        value={er.causality}
                        disabled
                      />
                      <div className="d-flex w-100 justify-content-between">
                        <div style={{ fontSize: "10px", color: "red" }}>
                          No<br></br>support
                        </div>
                        <div style={{ fontSize: "10px", color: "red" }}>
                          Certainty
                        </div>
                      </div>
                      Value: {er.causality}
                    </>
                  )}
                </td>

                <td>{er.isNecessary}</td>
                {evidence.evidencedRelations &&
                  evidence.evidencedRelations.some(
                    (x) => x.relativeImportanceScale != null
                  ) && (
                    <td>
                      {er.relativeImportanceScale === null ? (
                        "No value"
                      ) : (
                        <>
                          <input
                            type="range"
                            className="form-range"
                            min="0"
                            max="1"
                            step="0.01"
                            value={er.relativeImportanceScale}
                            disabled
                          />
                          <div className="d-flex w-100 justify-content-between">
                            <div style={{ fontSize: "10px", color: "red" }}>
                              Little to no<br></br>influence
                            </div>
                            <div
                              style={{
                                fontSize: "10px",
                                color: "red",
                                marginLeft: "50px",
                              }}
                            >
                              Sole and only<br></br>influence
                            </div>
                          </div>
                          Value: {er.relativeImportanceScale}
                        </>
                      )}
                    </td>
                  )}

                {evidence.evidencedRelations &&
                  evidence.evidencedRelations.some((x) => {
                    if (x.timeLag === null || x.timeLag === "") {
                      return false;
                    }
                    return true;
                  }) && <td>{er.timeLag}</td>}
                {evidence.evidencedRelations &&
                  evidence.evidencedRelations.some((x) => {
                    if (
                      (x.evidenceEffect1 === null ||
                        x.evidenceEffect1 === "") &&
                      (x.evidenceEffect2 === null || x.evidenceEffect2 === "")
                    ) {
                      return false;
                    }
                    return true;
                  }) && (
                    <td>
                      {er.evidenceEffect1}
                      <hr></hr>
                      Leads to
                      <hr></hr>
                      {er.evidenceEffect2}
                    </td>
                  )}
                {evidence.evidencedRelations &&
                  evidence.evidencedRelations.some((x) => {
                    if (x.comment === null || x.comment === "") {
                      return false;
                    }
                    return true;
                  }) && <td>{er.comment}</td>}
                <td>
                  {isUserPortfolioOwner && (
                    <div className="d-flex">
                      <EditButton
                        onClick={() => setEvidenceRelationToBeEdited(er)}
                      />
                      <UnlinkButton
                        onClick={() => setEvidenceRelationToBeUnlinked(er)}
                      />
                    </div>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      <h3 className="h3 mt-4 mb-1">Indicators</h3>
      <p>The evidence uses these indicators from this portfolio</p>
      <Table className="mt-3" bordered>
        <thead>
          <tr>
            <th>Title</th>
            <th>Unlink</th>
          </tr>
        </thead>
        <tbody>
          {evidence.evidenceIndicators &&
            evidence.evidenceIndicators.flat().map((er, i) => (
              <tr key={i}>
                <td>
                  <Link
                    to={(location) => ({
                      ...location,
                      pathname: `/${portfolioId}/indicators/${er.indicatorId}`,
                    })}
                  >
                    {er.title}
                  </Link>
                </td>
                <td>
                  <div className="d-flex">
                    {isUserPortfolioOwner && (
                      <UnlinkButton
                        onClick={() => setIndicatorToBeUnlinked(er)}
                      />
                    )}
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      {evidence.originatingPortfolioId === portfolio.id && (
        <>
          {sharedWithPortfolios.length > 0 && (
            <div style={{ marginTop: "10px" }}>
              <span>
                <strong>This evidence is currently used in:</strong>
              </span>
              <ul>
                {sharedWithPortfolios.map((value, index) => (
                  <li>
                    {" "}
                    {value.title}{" "}
                    <Link onClick={() => contactPortfolioOwner(value)}>
                      contact owner
                    </Link>
                  </li>
                ))}
              </ul>
              <p>
                If the evidence is used in other portfolios you will not be able
                to delete
              </p>
            </div>
          )}
        </>
      )}

      <AddIndicatorModalAtEvidence
        isOpen={isAddIndicatorModalOpen}
        onHide={handleAddIndicatorModalClose}
        allIndicators={allIndicators}
        evidence={evidence}
        onLinkEvidenceWithIndicator={handleLinkEvidenceWithIndicator}
        portfolioId={portfolioId}
      />
      {indicatorToBeUnlinked && (
        <UnlinkIndicatorModal
          isOpen={setIndicatorToBeUnlinked !== null}
          onHide={() => {
            setIndicatorToBeUnlinked(null);
          }}
          onSubmit={handleUnlinkIndicator}
        />
      )}

      <DeleteEvidenceModal
        isOpen={isDeleteEvidenceModalOpen}
        onHide={() => {
          setIsDeleteEvidenceModalOpen(false);
        }}
        onSubmit={handleDeleteEvidence}
      />

      <UnshareEvidenceModal
        isOpen={isRemoveEvidenceModalOpen}
        onHide={() => {
          setIsRemoveEvidenceModalOpen(false);
        }}
        onSubmit={handleRemoveEvidence}
      />
      {isEditEvidenceModalOpen && (
        <EditEvidenceModal
          isOpen={isEditEvidenceModalOpen}
          onHide={() => {
            setIsEditEvidenceModalOpen(false);
          }}
          evidence={evidence}
          onSubmit={handleEditEvidence}
        />
      )}
      {evidenceRelationToBeEdited && (
        <EditEvidenceRelationModal
          isOpen={evidenceRelationToBeEdited !== null}
          onHide={() => {
            setEvidenceRelationToBeEdited(null);
          }}
          evidenceRelation={evidenceRelationToBeEdited}
          onSubmit={handleEditEvidenceRelation}
        />
      )}
      {evidenceRelationToBeUnlinked && (
        <UnlinkRelationModal
          isOpen={setEvidenceRelationToBeUnlinked !== null}
          onHide={() => {
            setEvidenceRelationToBeUnlinked(null);
          }}
          onSubmit={handleUnlinkEvidenceRelation}
        />
      )}
      <ContactOwnerForDeleteEvidence
        isOpen={openContactOwnerModal}
        onHide={hideContactOwnerModal}
        onSubmit={contactPortfolioOwnerSubmit}
        evidenceTitle={evidence.title}
        portfolioTitle={
          contactOwnerPortfolio === null ? "" : contactOwnerPortfolio.title
        }
      ></ContactOwnerForDeleteEvidence>
    </>
  );
}
