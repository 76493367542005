import React from "react";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";

export default function ResultPage(props) {
    const { result, results, onSetResult } = props;

    return (
        <>
            <h2>Result</h2>
            <p>Select the result the indicator(s) will count towards</p>
            <DropdownButton
                variant="outline-primary"
                title={result ? result.title : "Select one"}
            >
                {results.map((r, i) => (
                    <Dropdown.Item onSelect={() => onSetResult(r)} key={i}>
                        {r.title}
                    </Dropdown.Item>
                ))}
            </DropdownButton>
        </>
    );
}
