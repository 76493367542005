import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import callApi from "../../Helpers/callApi";
import AddEvidenceModal from "./AddEvidenceModal";
import LinkEvidenceModal from "./LinkEvidenceModal";
import DeleteEvidenceModal from "./DeleteEvidenceModal";
import UnlinkEvidenceModal from "./UnlinkEvidenceModal";
import ReactFlow, {
  addEdge,
  removeElements,
  Background,
  isEdge,
  Controls,
} from "react-flow-renderer";
import Sidebar from "./Sidebar";
//import useActivityService from "../../Services/useActivityService";
import useResultService from "../../Services/useResultService";
import usePortfolioService from "../../Services/usePortfolioService";

export default function TheoryOfChange({
  portfolioId,
  results,
  isLoadingResults,
}) {
  //const [results, setResults] = useState([]);
  const [evidence, setEvidence] = useState([]);
  const [elements, setElements] = useState([]);
  const [selectedElements, setSelectedElements] = useState([]);
  const { activityId } = useParams();
  const [selectedRelationId, setSelectedRelationId] = useState("");
  const [isAddEvidenceModalOpen, setAddEvidenceModalOpen] = useState(false);
  const [isLinkEvidenceModalOpen, setLinkEvidenceModalOpen] = useState(false);
  const [isUnlinkEvidenceModalOpen, setUnlinkEvidenceModalOpen] =
    useState(false);
  const [isDeleteEvidenceModalOpen, setDeleteEvidenceModalOpen] =
    useState(false);
  const [evidenceIdToBeDeleted, setEvidenceIdToBeDeleted] = useState("");
  const [evidenceToBeUnlinked, setEvidenceToBeUnlinked] = useState(null);
  //const { getResults } = useActivityService();
  const { getEvidence } = usePortfolioService();

  const { updateResult } = useResultService();

  const handleAddEvidenceModalOpen = (relationId) => {
    setSelectedRelationId(relationId);
    setAddEvidenceModalOpen(true);
  };
  const handleLinkEvidenceClick = (relationId) => {
    setSelectedRelationId(relationId);
    setLinkEvidenceModalOpen(true);
  };
  const handleUnlinkEvidenceClick = (relationId, evidence) => {
    setSelectedRelationId(relationId);
    setEvidenceToBeUnlinked(evidence);
    setUnlinkEvidenceModalOpen(true);
  };
  const handleDeleteEvidenceModalClose = () => {
    setEvidenceIdToBeDeleted(null);
    setDeleteEvidenceModalOpen(false);
  };
  const handleLinkEvidenceModalClose = () => {
    setLinkEvidenceModalOpen(false);
    setSelectedRelationId("");
  };
  const handleUnlinkEvidenceModalClose = () => {
    setUnlinkEvidenceModalOpen(false);
    setSelectedRelationId("");
    setEvidenceToBeUnlinked(null);
  };
  const handleAddEvidenceModalClose = () => setAddEvidenceModalOpen(false);
  const handleDeleteEvidenceModalOpen = () => setDeleteEvidenceModalOpen(true);

  const handleClickDeleteEvidence = (evidenceId) => {
    setEvidenceIdToBeDeleted(evidenceId);
    handleDeleteEvidenceModalOpen();
  };

  const handleAddEvidenceSubmit = async (
    title,
    type,
    summary,
    comments,
    documentLink,
    produced,
    validFrom,
    validTo,
    isExternallyProduced
  ) => {
    const response = await callApi(`relations/${selectedRelationId}/evidence`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        title,
        type,
        summary,
        comments,
        documentLink,
        produced,
        validFrom,
        validTo,
        isExternallyProduced,
        activityId,
        relationId: selectedRelationId,
      }),
    });

    if (response.ok) {
      let newEvidence = await response.json();
      newEvidence.id = newEvidence.id.toString();
      // //setting selected element state
      setSelectedElements((prevState) => {
        return prevState.map((e) => {
          return {
            ...e,
            data: {
              ...e.data,
              evidence: [...e.data.evidence, newEvidence],
            },
          };
        });
      });
      let selE = selectedElements[0];
      let el = elements.filter((d) => d.id === selectedElements[0].id);
      // Remove old element from the elements list
      setElements((els) => removeElements(el, els));
      // Add back the updated element
      setElements((els) => addEdge(selE, els));
    }
    handleAddEvidenceModalClose();
  };

  const handleLinkEvidenceSubmit = async (
    evidence,
    direction,
    causality,
    isNecessary,
    evidenceProduced,
    isRelativeImportance,
    relativeImportanceScale,
    isTimeLag,
    timeLag,
    isScaleChange,
    evidenceEffect1,
    evidenceEffect2,
    comment
  ) => {
    const response = await callApi(
      `relations/${selectedRelationId}/evidence/${evidence.id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          direction,
          causality,
          isNecessary,
          evidenceProduced,
          isRelativeImportance,
          relativeImportanceScale,
          isTimeLag,
          timeLag,
          isScaleChange,
          evidenceEffect1,
          evidenceEffect2,
          comment,
        }),
      }
    );

    if (response.ok) {
      setSelectedElements((prevState) => {
        return prevState.map((e) => {
          if (e.data.relationId === selectedRelationId) {
            return {
              ...e,
              data: {
                ...e.data,
                evidence: [...e.data.evidence, evidence],
              },
            };
          }

          return e;
        });
      });

      setElements((prevState) => {
        return prevState.map((e) => {
          if (e.data.relationId === selectedRelationId) {
            return {
              ...e,
              data: {
                ...e.data,
                evidence: [...e.data.evidence, evidence],
              },
            };
          }
          return e;
        });
      });
    }
    handleLinkEvidenceModalClose();
  };

  const handleDeleteEvidence = async () => {
    const response = await callApi(`evidence/${evidenceIdToBeDeleted}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      setSelectedElements((prevState) => {
        return prevState.map((e) => {
          return {
            ...e,
            data: {
              ...e.data,
              evidence: e.data.evidence.filter(
                (d) => d.id !== evidenceIdToBeDeleted
              ),
            },
          };
        });
      });
      let el = elements.filter((d) => d.id === selectedElements[0].id);
      // Remove old element from the elements list
      setElements((els) => removeElements(el, els));
      // Add back the updated element
      setElements((els) => addEdge(selectedElements[0], els));
    }
  };

  const handleUnlinkEvidenceSubmit = async () => {
    const response = await callApi(
      `relations/${selectedRelationId}/evidence/${evidenceToBeUnlinked.id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.ok) {
      setSelectedElements((prevState) => {
        return prevState.map((e) => {
          if (e.data.relationId === selectedRelationId) {
            return {
              ...e,
              data: {
                ...e.data,
                evidence: e.data.evidence.filter(
                  (e) => e.id !== evidenceToBeUnlinked.id
                ),
              },
            };
          }

          return e;
        });
      });

      setElements((prevState) => {
        return prevState.map((e) => {
          if (e.data.relationId === selectedRelationId) {
            return {
              ...e,
              data: {
                ...e.data,
                evidence: e.data.evidence.filter(
                  (e) => e.id !== evidenceToBeUnlinked.id
                ),
              },
            };
          }
          return e;
        });
      });
    }
  };

  const handleAddRelation = async (relation) => {
    let response = await callApi(`relations`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fromResultId: parseInt(relation.fromResultId),
        toResultId: parseInt(relation.toResultId),
      }),
    });

    if (response.ok) {
      let newRelation = await response.json();
      let newElement = {
        id: `${newRelation.id}-${newRelation.fromResultId}-${newRelation.toResultId}`,
        source: newRelation.fromResultId.toString(),
        target: newRelation.toResultId.toString(),
        data: {
          relationId: newRelation.id,
          evidence: [],
        },
        animated: true,
        arrowHeadType: "arrowclosed",
        style: { strokeWidth: 3 },
      };
      setElements((els) => addEdge(newElement, els));
    }
  };

  const handleDeleteRelation = async (relation) => {
    await callApi(
      `relations/from/${relation.fromResultId}/to/${relation.toResultId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  const handleUpdateResult = async (result) => {
    await updateResult(result);
  };

  const handleEvidenceIsJustifiedChanged = async (relationId, isJustified) => {
    const response = await callApi(`relations/${relationId}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify([
        {
          op: "replace",
          path: "/isJustified",
          value: isJustified,
        },
      ]),
    });

    if (response.ok) {
      setSelectedElements((prevState) => {
        return prevState.map((e) => {
          if (e.data.relationId === relationId) {
            e.data.isJustified = isJustified;
            return e;
          }

          return e;
        });
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await getEvidence(portfolioId);
      setEvidence(response.data);
    };

    fetchData();
  }, [portfolioId, getEvidence]);

  useEffect(() => {
    const fetchData = async () => {
      //const response = await getResults(activityId, portfolioId);
      //setResults(response.data);

      let initialNodes = [
        ...results.map((r) => ({
          id: r.id.toString(),
          data: {
            label: (
              <>
                <b>{r.title}</b>
                <p>{r.description}</p>{" "}
              </>
            ),
          },
          position: r.position
            ? {
                x: r.position.x === null ? 0 : r.position.x,
                y: r.position.y === null ? 0 : r.position.y,
              }
            : { x: 0, y: 0 },
          targetPosition: "bottom",
          sourcePosition: "top",
        })),
      ];

      let initialEdges = [
        ...results.map((r) => {
          return r.relations.map((rel) => ({
            id: `${rel.id}-${rel.fromResultId}-${rel.toResultId}`,
            data: {
              relationId: rel.id,
              isJustified: rel.isJustified,
              evidence: rel.evidence,
            },
            source: rel.fromResultId.toString(),
            target: rel.toResultId.toString(),
            animated: true,
            arrowHeadType: "arrowclosed",
            style: {
              strokeWidth: 3,
              stroke: rel.isJustified ? "green" : "black",
            },
            label: rel.evidence.length
              ? `Evidence: ${rel.evidence.length}`
              : null,
            labelBgStyle: { stroke: "black", strokeWidth: "1" },
          }));
        }),
      ].flat();

      let initialElements = [...initialNodes, ...initialEdges];

      setElements(initialElements);
    };

    fetchData();
  }, [results]);

  const onElementsRemove = (elementsToRemove) => {
    elementsToRemove.forEach((e) => {
      if (isEdge(e)) {
        handleDeleteRelation({
          fromResultId: e.source.toString(),
          toResultId: e.target.toString(),
        });
      }
    });
    setElements((els) => removeElements(elementsToRemove, els));
  };

  const onConnect = (params) => {
    handleAddRelation({
      fromResultId: params.source,
      toResultId: params.target,
    });
  };

  const onLoad = (reactFlowInstance) =>
    reactFlowInstance.fitView({ padding: 0.2 });

  const onMoveNode = (event, node) => {
    let result = results.find((r) => r.id === parseInt(node.id));
    result.position = node.position;
    handleUpdateResult(result);
  };
  const onSelectionChange = (selectedElements) => {
    setSelectedElements(selectedElements);
  };
  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <p className="my-2 text-secondary">
        <small>
          Position result nodes anywhere on the canvas and link them together by
          dragging and dropping on the circular handles. The relationship
          between nodes is directed from the top of the source node to the
          bottom of the target node.
        </small>
      </p>
      <p className="text-secondary">
        <small>
          Delete relationships by selecting them and pressing the backspace key.
        </small>
      </p>
      <ReactFlow
        elements={elements}
        onConnect={onConnect}
        onElementsRemove={onElementsRemove}
        onNodeDragStop={onMoveNode}
        onSelectionChange={onSelectionChange}
        onLoad={onLoad}
        connectionLineStyle={{ strokeWidth: 3 }}
        arrowHeadType="arrowclosed"
      >
        <Background variant="dots" gap={24} size={1} />
        <Controls showInteractive={false} />
      </ReactFlow>
      <Sidebar
        handleAddEvidenceModalOpen={handleAddEvidenceModalOpen}
        selectedElements={selectedElements}
        results={results}
        handleDeleteEvidence={handleClickDeleteEvidence}
        onClickLinkEvidence={handleLinkEvidenceClick}
        onClickUnlinkEvidence={handleUnlinkEvidenceClick}
        handleEvidenceIsJustifiedChanged={handleEvidenceIsJustifiedChanged}
      />
      <AddEvidenceModal
        isOpen={isAddEvidenceModalOpen}
        onHide={handleAddEvidenceModalClose}
        onSubmit={handleAddEvidenceSubmit}
      />
      <LinkEvidenceModal
        isOpen={isLinkEvidenceModalOpen}
        onHide={handleLinkEvidenceModalClose}
        onSubmit={handleLinkEvidenceSubmit}
        evidence={evidence}
        selectedElements={selectedElements}
      />
      <DeleteEvidenceModal
        isOpen={isDeleteEvidenceModalOpen}
        onHide={handleDeleteEvidenceModalClose}
        onSubmit={handleDeleteEvidence}
      />
      <UnlinkEvidenceModal
        isOpen={isUnlinkEvidenceModalOpen}
        onHide={handleUnlinkEvidenceModalClose}
        onSubmit={handleUnlinkEvidenceSubmit}
      />
    </div>
  );
}
