import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import moment from "moment";
import Breadcrumbs from "../Shared/Breadcrumbs";
import useActivityOwnerStatus from "../Hooks/useActivityOwnerStatus";
import usePortfolioOwnerStatus from "../Hooks/usePortfolioOwnerStatus";
import ResultTableData from "./ResultTableData";
import DeleteEvidenceModal from "../Activity/TheoryOfChange/DeleteEvidenceModal";
import EditEvidenceModal from "./EditEvidenceModal";
import useEvidenceService from "../Services/useEvidenceService";
import usePortfolioService from "../Services/usePortfolioService";
import UnlinkButton from "../Shared/UnlinkButton";
import EditButton from "../Shared/EditButton";
import EditEvidenceRelationModal from "./EditEvidenceRelationModal";
import UnlinkRelationModal from "./UnlinkRelationModal";
import useActivityService from "../Services/useActivityService";

export default function EvidenceDetails() {
  const { portfolioId, activityId, evidenceId } = useParams();
  let history = useHistory();
  const [evidence, setEvidence] = useState({});
  const [portfolio, setPortfolio] = useState({});
  const [activity, setActivity] = useState({});
  const isUserActivityOwner = useActivityOwnerStatus();
  const isUserPortfolioOwner = usePortfolioOwnerStatus();
  const [isDeleteEvidenceModalOpen, setIsDeleteEvidenceModalOpen] =
    useState(false);
  const [isEditEvidenceModalOpen, setIsEditEvidenceModalOpen] = useState(false);
  const [evidenceRelationToBeEdited, setEvidenceRelationToBeEdited] =
    useState(null);
  const [evidenceRelationToBeUnlinked, setEvidenceRelationToBeUnlinked] =
    useState(null);
  const {
    getEvidence,
    updateEvidence,
    deleteEvidence,
    updateEvidenceRelation,
    unlinkRelation,
  } = useEvidenceService();
  const { getPortfolio } = usePortfolioService();
  const { getActivity } = useActivityService();

  useEffect(() => {
    const fetchData = async () => {
      const response = await getPortfolio(portfolioId);
      if (response.ok) setPortfolio(response.data);
    };

    fetchData();
  }, [portfolioId, getPortfolio]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getActivity(activityId);
      setActivity(response.data);
    };

    fetchData();
  }, [activityId, getActivity]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getEvidence(evidenceId);
      if (response.ok) setEvidence(response.data);
    };

    fetchData();
  }, [evidenceId, getEvidence]);

  const breadcrumbItems = [
    { path: `/${portfolioId}`, label: portfolio.title },
    { path: `/${portfolioId}/activities/${activityId}`, label: activity.title },
    { label: evidence.type },
  ];

  const handleDeleteEvidence = async () => {
    const response = await deleteEvidence(evidenceId);

    if (response.ok) {
      history.replace(`/${portfolioId}/activities/${activityId}/evidence`);
    }
  };

  const handleEditEvidence = async (editedEvidence) => {
    let fullEvidenceObject = { ...evidence, ...editedEvidence };
    const response = await updateEvidence(fullEvidenceObject);

    if (response.ok) {
      setEvidence(fullEvidenceObject);
    }
  };

  const handleUnlinkEvidenceRelation = async () => {
    const response = await unlinkRelation(
      evidence.id,
      evidenceRelationToBeUnlinked.relationId
    );

    if (response.ok) {
      setEvidence((prevState) => {
        return {
          ...prevState,
          evidencedRelations: prevState.evidencedRelations.filter((er) => {
            return er.relationId !== evidenceRelationToBeUnlinked.relationId;
          }),
        };
      });
    }
  };

  const handleEditEvidenceRelation = async (editedEvidenceRelation) => {
    editedEvidenceRelation.evidenceId = evidence.id;
    const response = await updateEvidenceRelation(
      evidence,
      editedEvidenceRelation
    );

    if (response.ok) {
      setEvidence((prevState) => {
        return {
          ...prevState,
          evidencedRelations: prevState.evidencedRelations.map((er) => {
            if (er.relationId === editedEvidenceRelation.relationId) {
              return editedEvidenceRelation;
            }
            return er;
          }),
        };
      });
    }
  };

  const handleDeleteButtonClick = () => {
    setIsDeleteEvidenceModalOpen(true);
  };

  const handleEditButtonClick = () => {
    setIsEditEvidenceModalOpen(true);
  };

  return (
    <>
      <div className="page-title">
        <div className="title">
          <Breadcrumbs items={breadcrumbItems} />
          <h1 className="h1">Evidence</h1>
        </div>
        <div className="actions">
          {(isUserActivityOwner || isUserPortfolioOwner) && (
            <button
              onClick={handleEditButtonClick}
              className="button tertiary with-icon"
            >
              <i class="icon bi bi-folder-minus"></i>Edit Evidence
            </button>
          )}
          {(isUserActivityOwner || isUserPortfolioOwner) && (
            <button
              onClick={handleDeleteButtonClick}
              className="button tertiary with-icon"
            >
              <i class="icon bi bi-folder-x"></i>Delete Portfolio
            </button>
          )}
        </div>
      </div>
      <span className="description">Title</span>
      <p style={{ whiteSpace: "pre-line" }}>{evidence.title}</p>
      <span className="description">Type</span>
      <p style={{ whiteSpace: "pre-line" }}>{evidence.type}</p>
      <span className="description">Summary</span>
      <p style={{ whiteSpace: "pre-line" }}>{evidence.summary}</p>
      <span className="description">Comments</span>
      <p style={{ whiteSpace: "pre-line" }}>{evidence.comments}</p>
      <span className="description">Valid</span>
      <p style={{ whiteSpace: "pre-line" }}>
        {moment(evidence.validFrom).format("Do MMM YYYY")} -{" "}
        {moment(evidence.validTo).format("Do MMM YYYY")}
      </p>
      <span className="description">Document Link</span>
      <p>
        <a
          href={evidence.documentLink}
          target="_blank"
          rel="noopener noreferrer"
          className="icon-and-text"
        >
          <i class="icon bi bi-file-earmark"></i>
          Open Document
        </a>
      </p>
      <div className="d-flex w-100 flex-row-reverse">
        {(isUserActivityOwner || isUserPortfolioOwner) && (
          <Button variant="outline-danger" onClick={handleDeleteButtonClick}>
            Delete Evidence
          </Button>
        )}
        {(isUserActivityOwner || isUserPortfolioOwner) && (
          <Button
            className="mr-1"
            variant="outline-secondary"
            onClick={handleEditButtonClick}
          >
            Edit Evidence
          </Button>
        )}
      </div>

      <h5>Relations</h5>
      <Table className="mt-3" bordered>
        <thead>
          <tr>
            <th>From</th>
            <th>To</th>
            <th>Causality</th>
            <th>Direction</th>
            <th>Is Necessary</th>
            <th>Relative Importance</th>
            <th>Time lag</th>
            <th>Externally Produced</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {evidence.evidencedRelations &&
            evidence.evidencedRelations.map((er, i) => (
              <tr key={i}>
                <ResultTableData resultId={er.fromResultId} />
                <ResultTableData resultId={er.toResultId} />
                <td>
                  <input
                    type="range"
                    className="form-range"
                    min="-10"
                    max="10"
                    value={er.causality}
                    disabled
                  />
                  Value: {er.causality}
                </td>
                <td>{er.direction}</td>
                <td>{er.isNecessary}</td>
                <td>{er.relativeImportance}</td>
                <td>{er.timeLag}</td>
                <td>{er.isExternallyProduced ? "Yes" : "No"}</td>
                <td>
                  <div className="d-flex">
                    <EditButton
                      onClick={() => setEvidenceRelationToBeEdited(er)}
                    />
                    <UnlinkButton
                      onClick={() => setEvidenceRelationToBeUnlinked(er)}
                    />
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      <DeleteEvidenceModal
        isOpen={isDeleteEvidenceModalOpen}
        onHide={() => {
          setIsDeleteEvidenceModalOpen(false);
        }}
        onSubmit={handleDeleteEvidence}
      />
      {isEditEvidenceModalOpen && (
        <EditEvidenceModal
          isOpen={isEditEvidenceModalOpen}
          onHide={() => {
            setIsEditEvidenceModalOpen(false);
          }}
          evidence={evidence}
          onSubmit={handleEditEvidence}
        />
      )}
      {evidenceRelationToBeEdited && (
        <EditEvidenceRelationModal
          isOpen={evidenceRelationToBeEdited !== null}
          onHide={() => {
            setEvidenceRelationToBeEdited(null);
          }}
          evidenceRelation={evidenceRelationToBeEdited}
          onSubmit={handleEditEvidenceRelation}
        />
      )}
      {evidenceRelationToBeUnlinked && (
        <UnlinkRelationModal
          isOpen={setEvidenceRelationToBeUnlinked !== null}
          onHide={() => {
            setEvidenceRelationToBeUnlinked(null);
          }}
          onSubmit={handleUnlinkEvidenceRelation}
        />
      )}
    </>
  );
}
