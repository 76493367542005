import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import ImportActivityDataResponse from "./ImportActivityDataResponse";
import ImportDataDocumentation from "./ImportDataDocumentation";
import useActivityService from "../Services/useActivityService";

export default function ImportActivityData(props) {
  const { activityId, onSuccessfulUpload, portfolio, activity } = props;
  //const [showFileLabel, setshowFileLabel] = React.useState(false)
  const [disabled, setDisabled] = useState(true);
  const [spinnerState, setSpinnerState] = useState("none");
  const [importText, setImportText] = useState("Create Change Request");
  const [importSuccessText, setImportSuccessText] = useState("");
  const [importFailureText, setImportFailureText] = useState("");
  const [fileLabel, setFileLabel] = useState("");
  const [file, setFile] = useState();
  const [importResponse, setImportResponse] = useState({});
  const { createChangeRequest } = useActivityService();

  const uploadData = async () => {
    setImportSuccessText("");
    setImportFailureText("");
    setDisabled(true);
    setSpinnerState("inline-block");
    setImportText("Importing...");

    const data = new FormData();
    data.append("file", file);

    const response = await createChangeRequest(activityId, data);

    setDisabled(true);
    setSpinnerState("none");
    setImportText("Create Change Request");
    setFile(null);
    setFileLabel("");

    setImportResponse(response.data);
    if (response.ok) {
      setImportSuccessText("The file was submitted successfully.");
      onSuccessfulUpload();
    } else {
      setImportFailureText(response.data[0].message);
    }
  };

  const onFileChangeHandler = (event) => {
    let fileCount = event.target.files.length;
    setDisabled(fileCount !== 1);
    if (fileCount === 1) {
      let fileToImport = event.target.files[0];
      //setshowFileLabel(true)
      setFile(fileToImport);
      setFileLabel(fileToImport.name);
      setImportResponse({});
    } else {
      //setshowFileLabel(false)
      setFile();
      setFileLabel("");
    }
  };

  return (
    <>
      {portfolio !== null &&
        portfolio.title !== undefined &&
        activity !== null &&
        activity.title !== undefined && (
          <>
            {portfolio.title + " portfolio level results and groups" ===
              activity.title && (
              <>
                <div className="alert error">
                  <p className="text-bold">
                    Import functionality not available for portfolio activity.
                  </p>
                </div>
              </>
            )}
            {portfolio.title + " portfolio level results and groups" !==
              activity.title && (
              <>
                <h2 className="h2">Import Data</h2>
                <p>Import data into Rex from either Excel or a CSV file.</p>
                <div className="d-flex mt-1">
                  <div className="file-input mb-4">
                    <input
                      type="file"
                      id="file"
                      className="file"
                      onChange={onFileChangeHandler}
                    />
                    <label for="file">
                      <i className="icon bi bi-file-earmark"></i> Select file
                    </label>
                    {fileLabel ? (
                      <div
                        className="file-name custom-file-label"
                        htmlFor="file"
                      >
                        {fileLabel}
                      </div>
                    ) : null}
                  </div>
                </div>

                <button
                  className="button primary with-icon mb-4"
                  onClick={() => uploadData()}
                  disabled={disabled}
                >
                  <i className="icon bi bi-cloud-download"></i> {importText}
                </button>

                <Spinner
                  style={{ display: spinnerState }}
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="mb-4"
                />

                {importSuccessText !== "" && (
                  <div className="alert success">
                    <div className="text">
                      <i className="icon bi bi-check-circle"></i>
                      {importSuccessText}
                    </div>
                  </div>
                )}
                {importFailureText !== "" && (
                  <div className="alert error">
                    <div className="text">
                      <i className="icon bi bi-exclamation-circle"></i>
                      {importFailureText}
                    </div>
                  </div>
                )}

                {importResponse.success != null && (
                  <Row>
                    <Col sm={12}>
                      <ImportActivityDataResponse
                        importResponse={importResponse}
                      />
                    </Col>
                  </Row>
                )}
                <ImportDataDocumentation activityId={activityId} />
              </>
            )}
          </>
        )}
    </>
  );
}
