import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import ResultPage from "./ResultPage";
import IndicatorPage from "./IndicatorPage";
import Button from "react-bootstrap/Button";

export default function AddIndicatorModal(props) {
  const { isOpen, onHide, onLinkIndicatorSubmit, results, portfolioId } = props;
  const [result, setResult] = useState(null);
  const [selectedIndicators, setSelectedIndicators] = useState([]);

  const handleAddIndicator = (newIndicator) => {
    setSelectedIndicators((indicators) => [...indicators, newIndicator]);
  };

  const handleRemoveIndicator = (indicatorId) => {
    setSelectedIndicators((prevState) =>
      prevState.filter((data) => data.id !== indicatorId)
    );
  };

  return (
    <Modal show={isOpen} onHide={onHide} size="lg">
      <Modal.Header>
        <Modal.Title>Add Indicators</Modal.Title>
        <button
          type="button"
          className="btn-close btn-close-white"
          aria-label="Close"
          onClick={onHide}
        ></button>
      </Modal.Header>
      <Modal.Body>
        <ResultPage result={result} results={results} onSetResult={setResult} />
        <IndicatorPage
          selectedIndicators={selectedIndicators}
          portfolioId={portfolioId}
          onAddIndicator={handleAddIndicator}
          onRemoveIndicator={handleRemoveIndicator}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="button primary"
          disabled={!(result && selectedIndicators.length > 0)}
          onClick={() => onLinkIndicatorSubmit(result, selectedIndicators)}
        >
          Add Indicators
        </Button>
        <Button className="button tertiary" onClick={onHide}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
