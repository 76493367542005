import React from "react";
import Table from "react-bootstrap/Table";
import format from "../Helpers/formatNumber";

export default function AggregatedTables({ aggregatedData }) {
  return (
    <div className="mt-2">
      {Object.keys(aggregatedData).map((ad) => (
        <div className="mb-4" key={ad}>
          <h6 className="mb-1">{ad}</h6>
          <Table style={{ tableLayout: "fixed" }} striped size="sm">
            <thead>
              <tr>
                <th style={{ width: "70%" }}>Disaggregation Value</th>
                <th>Planned</th>
                <th>Achieved</th>
              </tr>
            </thead>
            <tbody>
              {aggregatedData[ad].length > 0 &&
                aggregatedData[ad].map((data, i) => (
                  <tr key={i}>
                    <td>{data.disaggregationValue}</td>
                    <td>{format(data.targetValue)}</td>
                    <td>{format(data.value)}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      ))}
    </div>
  );
}
