export default function useCombinationGenerate() {
  const allCombinations = function allCombinations(arrays) {
    let numberOfCombinations = arrays.reduce(
      (res, array) => res * array.length,
      1
    );

    if (numberOfCombinations > 5000) {
      numberOfCombinations = 5000;
    }

    const result = Array(numberOfCombinations)
      .fill(0)
      .map(() => []);

    let repeatEachElement;

    for (let i = 0; i < arrays.length; i++) {
      const array = arrays[i];

      repeatEachElement = repeatEachElement
        ? repeatEachElement / array.length
        : numberOfCombinations / array.length;

      const everyElementRepeatedLength = repeatEachElement * array.length;

      for (let j = 0; j < numberOfCombinations; j++) {
        const index = Math.floor(
          (j % everyElementRepeatedLength) / repeatEachElement
        );
        result[j][i] = array[index];
      }
    }

    return result;
  };

  /*const result = allCombinations([
        ['a', 'b', 'c', 'd'],
        [1, 2, 3],
        [true, false],
      ])
      console.log(result.join('\n'))*/
  return {
    allCombinations,
  };
}
