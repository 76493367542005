import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

export default function LinkEvidenceModal(props) {
  const { isOpen, onHide, onSubmit, evidence, selectedElements } = props;
  const [isValidated, setIsValidated] = useState(false);
  const [evidenceToLink, setEvidenceToLink] = useState(null);
  const [causality, setCausality] = useState(0);
  const [direction, setDirection] = useState("");
  const [isNecessary, setIsNecessary] = useState("");
  const [relativeImportanceYesNo, setRelativeImportanceYesNo] = useState("");
  const [timeLagYesNo, setTimeLagYesNo] = useState("");
  const [timeLag, setTimeLag] = useState("");
  const [evidenceProduced, setEvidenceProduced] = useState("");
  const [relativeImportanceScale, setRelativeImportanceScale] = useState(0);
  const [scaleOfChangeYesNo, setScaleOfChangeYesNo] = useState("");
  const [evidenceEffectBox1, setEvidenceEffectBox1] = useState("");
  const [evidenceEffectBox2, setEvidenceEffectBox2] = useState("");
  const [comment, setComment] = useState("");

  let getAvailableEvidences = () => {
    let result = evidence;

    if (
      selectedElements != null &&
      selectedElements.length > 0 &&
      selectedElements[0].data != null &&
      selectedElements[0].data.evidence != null
    ) {
      let alreadySelectedEvidenceIds = selectedElements[0].data.evidence.map(
        (x) => x.id
      );
      result = result.filter((x) => !alreadySelectedEvidenceIds.includes(x.id));
    }
    return result;
  };

  const resetState = () => {
    setEvidenceToLink(null);
  };

  const handleHide = () => {
    onHide();
    resetState();
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      onSubmit(
        evidenceToLink,
        direction,
        causality,
        isNecessary,
        evidenceProduced,
        relativeImportanceYesNo,
        relativeImportanceScale,
        timeLagYesNo,
        timeLag,
        scaleOfChangeYesNo,
        evidenceEffectBox1,
        evidenceEffectBox2,
        comment
      );
      handleHide();
    }

    setIsValidated(true);
  };

  const getDirectionString = () => {
    switch (direction) {
      case "direct":
        return "Positive or negative change in the first result leads to positive or negative change in second result";
      case "inverse":
        return "Change in the first result produces the opposite effect in the second result (for example, increasing the first result reduces the second result)";
      case "none":
        return "Change in the first result has no effect on the second result";
      case "unknown":
        return "Evidence provides information about the relationship without knowing the type of relationship or direction of effect";
      default:
        return "an unknown change";
    }
  };

  const changeDirection = (e) => {
    e.preventDefault();
    setDirection(e.target.value.toLowerCase());

    if (e.target.value.toLowerCase() === "unknown") {
      setCausality(null);
    } else {
      setCausality(0);
    }
  };

  const showEvidenceImportantScale = (e) => {
    e.preventDefault();
    setRelativeImportanceYesNo(e.target.value.toLowerCase());
    if (e.target.value.toLowerCase() === "no") {
      setRelativeImportanceScale(null);
    } else {
      setRelativeImportanceScale(0);
    }
  };

  const showTimeLagText = (e) => {
    e.preventDefault();
    setTimeLagYesNo(e.target.value.toLowerCase());
    if (e.target.value.toLowerCase() === "no") {
      setTimeLag("");
    }
  };

  const showEvidenceEffectText = (e) => {
    setScaleOfChangeYesNo(e.target.value.toLowerCase());

    if (e.target.value.toLowerCase() === "no") {
      setEvidenceEffectBox1("");
      setEvidenceEffectBox2("");
    }
  };

  const changeCausalityValue = (e) => {
    let currentValue = e.target.value;
    setCausality(currentValue);
    /*if (currentValue <= 0.1) {
      setCausality(0);
    } else if (currentValue >= 0.2 && currentValue <= 0.25) {
      setCausality(0.25);
    } else if (currentValue >= 0.7 && currentValue <= 0.75) {
      setCausality(0.75);
    } else if (currentValue >= 0.95 && currentValue <= 1) {
      setCausality(1);
    }*/
  };

  const changeRelativeImportanceScale = (e) => {
    let currentValue = e.target.value;
    setRelativeImportanceScale(currentValue);

    /*if (currentValue <= 0.1) {
      setRelativeImportanceScale(0);
    } else if (currentValue >= 0.2 && currentValue <= 0.25) {
      setRelativeImportanceScale(0.25);
    } else if (currentValue >= 0.7 && currentValue <= 0.75) {
      setRelativeImportanceScale(0.75);
    } else if (currentValue >= 0.95 && currentValue <= 1) {
      setRelativeImportanceScale(1);
    }*/
  };

  return (
    <Modal show={isOpen} onHide={handleHide} backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>Link Existing Evidence</Modal.Title>
        <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={handleHide}></button>
      </Modal.Header>
      <Form noValidate validated={isValidated} onSubmit={handleSubmit}>
        <Modal.Body>
          <DropdownButton
            variant="outline-primary"
            className="pr-2"
            title={evidenceToLink ? evidenceToLink.title : "Select Evidence"}
          >
            {getAvailableEvidences().map((e, j) => (
              <Dropdown.Item onSelect={() => setEvidenceToLink(e)} key={j}>
                {e.title}
              </Dropdown.Item>
            ))}
          </DropdownButton>

          {evidenceToLink && (
            <>
              <Form.Group controlId="direction">
                <Form.Label className="mb-0">Direction</Form.Label>
                <Form.Text>
                  What does the evidence say about the relationship?
                </Form.Text>
                <Form.Control
                  required
                  as="select"
                  onChange={(e) => changeDirection(e)}
                >
                  <option disabled selected value="">
                    -- select an option --
                  </option>
                  <option value="direct">
                    Evidence supports direct relationship
                  </option>
                  <option value="inverse">
                    Evidence supports inverse relationship
                  </option>
                  <option value="none">
                    Evidence supports no relationship
                  </option>
                  <option value="unknown">Unknown</option>
                </Form.Control>
                {direction && (
                  <Form.Text className="text-muted">
                    {getDirectionString()}
                  </Form.Text>
                )}
                <Form.Control.Feedback type="invalid">
                  Please enter the direction
                </Form.Control.Feedback>
              </Form.Group>
              {direction !== "unknown" && (
                <Form.Group className="mt-2" controlId="causality">
                  <Form.Label className="mb-0">Causality</Form.Label>
                  <Form.Text>
                    How strongly does the evidence support what it says about
                    the relationship?
                  </Form.Text>
                  <Form.Control
                    onChange={(e) => changeCausalityValue(e)}
                    type="range"
                    min="0"
                    max="1"
                    step="0.01"
                    value={causality}
                    placeholder="Causality"
                  />
                  <Form.Text className="d-flex w-100 justify-content-between">
                    <div>
                      Evidence<br></br>provides no<br></br>support
                    </div>
                    <div>
                      Evidence<br></br>provides some<br></br>support
                    </div>
                    <div>
                      Evidence<br></br>provides good<br></br>support
                    </div>
                    <div>
                      Evidence<br></br>provides<br></br>certainty
                    </div>
                  </Form.Text>
                  <Form.Control.Feedback type="invalid">
                    Please enter the causality
                  </Form.Control.Feedback>
                </Form.Group>
              )}
              <Form.Group controlId="isNecessary">
                <Form.Label className="mb-0">Is it Necessary</Form.Label>
                <Form.Text>
                  Does the evidence say whether the first result is necessary to
                  achieve the second?
                </Form.Text>
                <Form.Control
                  as="select"
                  onChange={(e) => setIsNecessary(e.target.value.toLowerCase())}
                >
                  <option disabled selected value>
                    -- select an option --
                  </option>
                  <option>yes</option>
                  <option>no</option>
                  <option>unknown</option>
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="evidenceProduced">
                <Form.Label className="mb-0">Production</Form.Label>
                <Form.Text>
                  Was the evidence produced directly about this relationship
                  from the results of this relationship or was it produced in a
                  similar context and assumed to apply to this relationship?
                </Form.Text>
                <Form.Control
                  as="select"
                  onChange={(e) =>
                    setEvidenceProduced(e.target.value.toLowerCase())
                  }
                >
                  <option disabled selected value>
                    -- select an option --
                  </option>
                  <option value="directly">
                    The evidence applies directly for this relationship
                  </option>
                  <option value="related">
                    The evidence was produced in related context or different
                    time period
                  </option>
                  <option value="unknown">Unknown</option>
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="relativeImportanceYesNo">
                <Form.Label className="mb-0">Relative Importance</Form.Label>
                <Form.Text>
                  Does the evidence say how important the first result is for
                  changing or achieving the second?
                </Form.Text>
                <Form.Control
                  as="select"
                  onChange={(e) => showEvidenceImportantScale(e)}
                >
                  <option disabled selected value>
                    -- select an option --
                  </option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </Form.Control>
              </Form.Group>
              {relativeImportanceYesNo === "yes" && (
                <Form.Group controlId="relativeImportanceScale">
                  <Form.Control
                    onChange={(e) => changeRelativeImportanceScale(e)}
                    type="range"
                    min="0"
                    max="1"
                    step="0.01"
                    value={relativeImportanceScale}
                    placeholder="relativeImportanceScale"
                  />
                  <Form.Text className="d-flex w-100 justify-content-between">
                    <div>
                      First result has little<br></br>to no influence on
                      <br></br>second (in relation<br></br>to others)
                    </div>
                    <div>
                      First result is one of a<br></br>number of similar
                      <br></br>influences for second<br></br>result
                    </div>
                    <div>
                      First result is a<br></br>significant<br></br>influence
                      for<br></br>second result
                    </div>
                    <div>
                      First result is<br></br>sole and only<br></br>influence on
                      <br></br>second
                    </div>
                  </Form.Text>
                </Form.Group>
              )}
              <Form.Group controlId="timeLagYesNo">
                <Form.Label className="mb-0">Time Lag</Form.Label>
                <Form.Text>
                  Does the evidence indicator say anything about the time lag
                  for the first result to affect the second?
                </Form.Text>
                <Form.Control as="select" onChange={(e) => showTimeLagText(e)}>
                  <option disabled selected value>
                    -- select an option --
                  </option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </Form.Control>
              </Form.Group>
              {timeLagYesNo === "yes" && (
                <Form.Group controlId="timeLag">
                  <Form.Text>
                    What time lag does the evidence suggest?
                  </Form.Text>
                  <Form.Control
                    type="text"
                    onChange={(e) => setTimeLag(e.target.value.toLowerCase())}
                    placeholder="e.g. 6 months"
                  />
                </Form.Group>
              )}
              <Form.Group controlId="scaleOfChangeYesNo">
                <Form.Label className="mb-0">Effect size</Form.Label>
                <Form.Text>
                  Does the evidence indicator say anything about the scale of
                  change that the first result can have on the second?
                </Form.Text>
                <Form.Control
                  as="select"
                  onChange={(e) => showEvidenceEffectText(e)}
                >
                  <option disabled selected value>
                    -- select an option --
                  </option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </Form.Control>
              </Form.Group>
              {scaleOfChangeYesNo === "yes" && (
                <>
                  <Form.Group controlId="evidenceEffectBox1">
                    <Form.Text>
                      What does the evidence say about effect?
                    </Form.Text>
                    <div style={{ marginTop: "10px" }}></div>
                    <Form.Control
                      type="text"
                      onChange={(e) =>
                        setEvidenceEffectBox1(e.target.value.toLowerCase())
                      }
                      placeholder="Change in result 1 comment"
                    />
                  </Form.Group>
                  <Form.Group controlId="evidenceEffectBox2">
                    <Form.Text>Leads to</Form.Text>
                    <div style={{ marginTop: "10px" }}></div>
                    <Form.Control
                      type="text"
                      onChange={(e) =>
                        setEvidenceEffectBox2(e.target.value.toLowerCase())
                      }
                      placeholder="Change in result 2 comment"
                    />
                  </Form.Group>
                </>
              )}
              <Form.Group controlId="comment">
                <Form.Label>Comment</Form.Label>
                <Form.Text>
                  Does the evidence say anything else about this relationship
                </Form.Text>
                <Form.Control
                  type="textarea"
                  onChange={(e) => setComment(e.target.value.toLowerCase())}
                  placeholder="comment"
                />
              </Form.Group>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button className="button primary" type="submit">
            Add Evidence
          </Button>
          <Button className="button tertiary" onClick={handleHide}>
            Cancel
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
