import React, { useState } from "react";
import { useRouteMatch } from "react-router-dom";
import ListGroup from "react-bootstrap/ListGroup";
import RemoveIndicatorModal from "./RemoveIndicatorModal";
import useResultService from "../Services/useResultService";
import QuantativeIndicatorListItem from "../Activity/QuantativeIndicatorListItem";
import QualitativeIndicatorListItem from "../Activity/QualitativeIndicatorListItem";

export default function IndicatorList({
    result,
    viewType,
    onRemoveIndicator,
    isSharedActivity,
    isUserActivityOwner,
    isUserPortfolioOwner,
}) {
    const [isRemoveIndicatorModalOpen, setRemoveIndicatorModalOpen] =
        useState(false);
    const [indicatorToBeRemoved, setIndicatorToBeRemoved] = useState(null);
    let match = useRouteMatch();
    const { unlinkIndicator } = useResultService();

    const handleRemoveIndicatorModalClose = () => {
        setIndicatorToBeRemoved(null);
        setRemoveIndicatorModalOpen(false);
    };

    const handleClickRemoveIndicator = (indicatorId) => {
        setIndicatorToBeRemoved(indicatorId);
        setRemoveIndicatorModalOpen(true);
    };

    const handleRemoveIndicator = async () => {
        const response = await unlinkIndicator(result.id, indicatorToBeRemoved);

        if (response.ok) {
            onRemoveIndicator(result, indicatorToBeRemoved);
        }
    };

    return (
        <>
            <h4 className="h4">Indicators</h4>
            {result.indicators && result.indicators.length ? (
                <ListGroup className="mt-1">
                    {result.indicators.map((i) => {
                        return i.typeId === 1 ? (
                            <ListGroup.Item key={i.id} className="ps-0">
                                <QualitativeIndicatorListItem
                                    indicator={i}
                                    result={result}
                                    isUserActivityOwner={isUserActivityOwner}
                                    isUserPortfolioOwner={isUserPortfolioOwner}
                                    isSharedActivity={isSharedActivity}
                                    indicatorUrl={`${match.url}/${result.id}/${i.id}`}
                                    onRemoveIndicator={() => handleClickRemoveIndicator(i.id)}
                                />
                            </ListGroup.Item>
                        ) : (
                            <ListGroup.Item key={i.id} className="ps-0">
                                <QuantativeIndicatorListItem
                                    indicator={i}
                                    result={result}
                                    viewType={viewType}
                                    isUserActivityOwner={isUserActivityOwner}
                                    isUserPortfolioOwner={isUserPortfolioOwner}
                                    isSharedActivity={isSharedActivity}
                                    indicatorUrl={`${match.url}/${result.id}/${i.id}`}
                                    onRemoveIndicator={() => handleClickRemoveIndicator(i.id)}
                                />
                            </ListGroup.Item>
                        );
                    })}
                </ListGroup>
            ) : (
                <p>
                    There are no indicators for this result
                </p>
            )}
            <RemoveIndicatorModal
                isOpen={isRemoveIndicatorModalOpen}
                onHide={handleRemoveIndicatorModalClose}
                onSubmit={handleRemoveIndicator}
            />
        </>
    );
}
