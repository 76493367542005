import React, { useState, useEffect } from "react";
import PortfolioList from "./PortfolioList";
import AddPortfolioModal from "./AddPortfolioModal";
import usePortfolioService from "../Services/usePortfolioService";

export default function HomePage() {
  const [portfolios, setPortfolios] = useState([]);
  const [isAddPortfolioModalOpen, setIsAddPortfolioModalOpen] = useState(false);
  const { getPortfolios, createPortfolio } = usePortfolioService();

  useEffect(() => {
    const fetchData = async () => {
      const response = await getPortfolios();
      setPortfolios(response.data);
    };

    fetchData();
  }, [getPortfolios]);

  const handleNewPortfolioSubmit = async (title, description) => {
    const response = await createPortfolio(title, description);

    if (response.ok) {
      setPortfolios((prevState) => [...prevState, response.data]);
      setIsAddPortfolioModalOpen(false);
    }
  };

  return (
    <>
      <div className="page-title">
        <div className="title">
          <h1 className="h1">Portfolios</h1>
        </div>
        <div className="actions">
          <button
            onClick={() => setIsAddPortfolioModalOpen(true)}
            className="button tertiary with-icon"
          >
            <i class="icon bi bi-folder-plus"></i>Add Portfolio
          </button>
        </div>
      </div>
      <PortfolioList portfolios={portfolios} />
      <AddPortfolioModal
        isOpen={isAddPortfolioModalOpen}
        onHide={() => setIsAddPortfolioModalOpen(false)}
        onSubmit={handleNewPortfolioSubmit}
      />
    </>
  );
}
