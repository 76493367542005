import React from "react";

export default function IndicatorInformation({ indicator }) {
  return (
    <>
      <span class="caption mb-2">Indicator Name</span>
      <h2 className="h2 mb-4">{indicator.title}</h2>
      <p>
        <span className="description">Description</span>
        {indicator.description}
      </p>
      {indicator.methodology && (
        <p>
          <span className="description">Methodology</span>
          {indicator.methodology}
        </p>
      )}
      {indicator.units && (
        <>
          <p>
            <span className="description">Units</span>
            {indicator.units}
          </p>
        </>
      )}
      {indicator.documentLink && (
        <>
          <span className="description">
            Document Link
          </span>
          <a
            href={indicator.documentLink}
            target="_blank"
            rel="noopener noreferrer"
            className="icon-and-text mt-2"
          >
            <i class="icon bi bi-file-earmark"></i>
            View Document
          </a>
        </>
      )}
    </>
  );
}
