import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

export default function DeletePortfolioModal({
  portfolio,
  isOpen,
  onHide,
  onSubmit,
}) {
  const [portfolioUniqueTitle, setPortfolioUniqueTitle] = useState("");

  const handleClose = () => {
    setPortfolioUniqueTitle("");
    onHide();
  };

  const handleSubmit = (e) => {
    onSubmit();
  };

  return (
    <Modal show={isOpen} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>Delete Portfolio</Modal.Title>
        <button
          type="button"
          className="btn-close btn-close-white"
          aria-label="Close"
          onClick={handleClose}
        ></button>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <p>
            This portfolio and all its Activities, Inidicators, Disaggregations
            and Results will be deleted. This is an irreversible operation.
          </p>
          <p>
            If you are you sure you want to delete the Portfolio, please enter
            its unique title to continue.
          </p>
          <Form.Group className="mb-4" controlId="portfolioUniqueTitle">
            <Form.Label>Unique Title</Form.Label>
            <Form.Control
              required
              onChange={(e) => setPortfolioUniqueTitle(e.target.value)}
              value={portfolioUniqueTitle}
              type="text"
              placeholder="Portfolio unique title"
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="button primary"
            type="submit"
            disabled={portfolioUniqueTitle !== portfolio.uniqueTitle}
          >
            Delete
          </Button>
          <Button className="button tertiary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
