import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

export default function AddEvidenceModal(props) {
  const { isOpen, onHide, onSubmit } = props;
  const [isValidated, setIsValidated] = useState(false);
  const [newEvidenceTitle, setNewEvidenceTitle] = useState("");
  const [newEvidenceType, setNewEvidenceType] = useState("");
  const [newEvidenceSummary, setNewEvidenceSummary] = useState("");
  const [newEvidenceComments, setNewEvidenceComments] = useState("");
  const [newEvidenceDocumentLink, setNewEvidenceDocumentLink] = useState("");
  const [newEvidenceProduced, setNewEvidenceProduced] = useState(null);
  const [newEvidenceValidFrom, setNewEvidenceValidFrom] = useState(null);
  const [newEvidenceValidTo, setNewEvidenceValidTo] = useState(null);
  const [newEvidenceProducedFCDOFunding, setNewEvidenceProducedFCDOFunding] =
    useState(false);
  const [newEvidenceIATIIdentifier, setNewEvidenceIATIIdentifier] =
    useState(null);
  const [newEvidenceMethods, setNewEvidenceMethods] = useState("");
  const [newEvidenceAuthors, setNewEvidenceAuthors] = useState("");
  const [newEvidenceIsPublic, setNewEvidenceIsPublic] = useState(false);

  const resetState = () => {
    setNewEvidenceTitle("");
    setNewEvidenceType("");
    setNewEvidenceSummary("");
    setNewEvidenceComments("");
    setNewEvidenceProduced(null);
    setNewEvidenceValidFrom(null);
    setNewEvidenceValidTo(null);
    setNewEvidenceProducedFCDOFunding(false);
    setNewEvidenceIATIIdentifier(null);
    setNewEvidenceMethods("");
    setNewEvidenceAuthors("");
    setNewEvidenceIsPublic(false);
  };

  const handleHide = () => {
    onHide();
    resetState();
    setIsValidated(false);
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      onSubmit(
        newEvidenceTitle,
        newEvidenceType,
        newEvidenceSummary,
        newEvidenceComments,
        newEvidenceDocumentLink,
        newEvidenceProduced,
        newEvidenceValidFrom,
        newEvidenceValidTo,
        newEvidenceProducedFCDOFunding,
        newEvidenceIATIIdentifier,
        newEvidenceMethods,
        newEvidenceAuthors,
        newEvidenceIsPublic
      );
      handleHide();
    }

    setIsValidated(true);
  };

  return (
    <Modal className="modal-dialog-centered modal-dialog-scrollable" show={isOpen} onHide={handleHide} backdrop="static" keyboard={false}>
      <Modal.Header onHide={handleHide}>
        <Modal.Title>Add Evidence</Modal.Title>
        <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={handleHide}></button>
      </Modal.Header>
      <Form
        autoComplete="off"
        noValidate
        validated={isValidated}
        onSubmit={handleSubmit}
      >
        <Modal.Body>
          <Form.Group className="mb-4" controlId="evidenceTitle">
            <Form.Label>Title</Form.Label>
            <Form.Control
              required
              onChange={(e) => setNewEvidenceTitle(e.target.value)}
              type="text"
              placeholder="Evidence title"
            />
            <Form.Control.Feedback type="invalid">
              Please enter a title for the evidence
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-4" controlId="evidenceType">
            <Form.Label>Type</Form.Label>
            <Form.Control
              required
              onChange={(e) => setNewEvidenceType(e.target.value)}
              type="text"
              placeholder="Evidence type"
            />
            <Form.Control.Feedback type="invalid">
              Please enter a type for the evidence
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-4" controlId="evidenceMethods">
            <Form.Label>Methods</Form.Label>
            <Form.Control
              required
              onChange={(e) => setNewEvidenceMethods(e.target.value)}
              type="text"
              placeholder="Evidence methods"
            />
            <Form.Control.Feedback type="invalid">
              Please enter evidence methods
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-4" controlId="evidenceSummary">
            <Form.Label>Summary</Form.Label>
            <Form.Control
              required
              onChange={(e) => setNewEvidenceSummary(e.target.value)}
              as="textarea"
              rows="3"
              placeholder="Evidence Summary"
            />
            <Form.Control.Feedback type="invalid">
              Please enter a summary for the evidence
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-4" controlId="evidenceComments">
            <Form.Label>Comments</Form.Label>
            <Form.Control
              onChange={(e) => setNewEvidenceComments(e.target.value)}
              as="textarea"
              rows="3"
              placeholder="Comments on validity or context"
            />
          </Form.Group>
          <Form.Group className="mb-4" controlId="evidenceDocumentLink">
            <Form.Label>Document Link</Form.Label>
            <Form.Control
              onChange={(e) => setNewEvidenceDocumentLink(e.target.value)}
              type="url"
              pattern="https?://.*"
              placeholder="A link to the evidence"
            />
            <Form.Control.Feedback type="invalid">
              Please enter a valid url beginning with http:// or https://
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-4" controlId="evidenceProduced">
            <Form.Label>When was the evidence produced</Form.Label>
            <Form.Control
              onChange={(e) => setNewEvidenceProduced(e.target.value)}
              type="date"
              placeholder="Produced"
            />
          </Form.Group>
          <Form.Label>Evidence relates to period</Form.Label>
          <Form.Row>
            <Form.Group className="mb-4" as={Col} controlId="evidenceValidFrom">
              <Form.Label>From</Form.Label>
              <Form.Control
                onChange={(e) => setNewEvidenceValidFrom(e.target.value)}
                type="date"
                placeholder="Valid From"
              />
            </Form.Group>
            <Form.Group className="mb-4" as={Col} controlId="evidenceValidTo">
              <Form.Label>To</Form.Label>
              <Form.Control
                onChange={(e) => setNewEvidenceValidTo(e.target.value)}
                type="date"
                placeholder="Valid To"
              />
            </Form.Group>
          </Form.Row>
          <Form.Group className="mb-4" controlId="evidenceAuthors">
            <Form.Label>Authors (optional)</Form.Label>
            <Form.Control
              onChange={(e) => setNewEvidenceAuthors(e.target.value)}
              type="text"
              placeholder="Evidence authors"
            />
          </Form.Group>
          <Form.Group className="mb-4" controlId="EvidenceProducedWithFCDOFunding">
            <Form.Label>Production</Form.Label>
            <Form.Check
              type="checkbox"
              checked={newEvidenceProducedFCDOFunding}
              onChange={(e) =>
                setNewEvidenceProducedFCDOFunding(e.target.checked)
              }
              label="Evidence produced with FCDO funding"
            />
          </Form.Group>
          <Form.Group className="mb-4" controlId="FundingIATIIdentifier">
            <Form.Label>Funding IATI identifier (optional)</Form.Label>
            <Form.Control
              onChange={(e) => setNewEvidenceIATIIdentifier(e.target.value)}
              type="text"
              placeholder="IATI identifier"
            />
          </Form.Group>
          <Form.Group controlId="IsPublic">
            <Form.Label>Is evidence public</Form.Label>
            <Form.Check
              checked={newEvidenceIsPublic}
              type="checkbox"
              label={newEvidenceIsPublic ? "Public" : "Private"}
              onChange={(e) => setNewEvidenceIsPublic(e.target.checked)}
            />
            <Form.Text className="mt-3">
              Public evidences will be searchable and copyable from other
              portfolios
            </Form.Text>
          </Form.Group>
          <div className="alert info mb-0">
            Once added, evidence can be linked to relationships in the theory of
            change
          </div>
        </Modal.Body>
        <Modal.Footer>

          <Button className="button primary" type="submit">
            Add Evidence
          </Button>

          <Button className="button tertiary" onClick={handleHide}>
            Cancel
          </Button>

        </Modal.Footer>
      </Form>
    </Modal>
  );
}
