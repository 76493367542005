import React from "react";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import PortfolioLayout from "../Portfolio/PortfolioLayout";
import HomePage from "./HomePage";

export default function HomeLayout() {
  const { pathname } = useLocation();
  return (
    <Switch>
      <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
      <Route path="/:portfolioId">
        <PortfolioLayout />
      </Route>
      <Route path="/">
        <HomePage />
      </Route>
    </Switch>
  );
}
