import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

export default function EditResultModal(props) {
  const { isOpen, onHide, onSubmit, resultBeingEdited } = props;
  const [resultTitle, setResultTitle] = useState(props.resultBeingEdited.title);
  const [resultDescription, setResultDescription] = useState(
    props.resultBeingEdited.description
  );
  const standardLabels = ["Input", "Output", "Outcome", "Impact"];
  const [resultLabels, setResultLabels] = useState(
    resultBeingEdited.labels
      ? resultBeingEdited.labels
        .split(",")
        .filter((x) => standardLabels.some((y) => x === y))
      : []
  );
  const [otherLabels, setOtherLabels] = useState(
    resultBeingEdited.labels
      ? resultBeingEdited.labels
        .split(",")
        .filter((x) => !standardLabels.some((y) => x === y))
        .join(",")
      : []
  );

  const handleLabelChange = (e, label) => {
    if (e.target.checked) {
      setResultLabels((prevState) => [...prevState, label]);
    } else if (!e.target.checked) {
      setResultLabels((prevState) => prevState.filter((l) => l !== label));
    }
  };

  const combineLabels = () => {
    let selectedStandardLabels = resultLabels.join(",");
    if (selectedStandardLabels !== "") {
      return selectedStandardLabels + "," + otherLabels;
    }
    return otherLabels;
  };

  const handleHide = () => {
    onHide();
  };

  const handleSubmit = () => {
    let labels = combineLabels();

    if (labels.length === 0) {
      labels = "";
    }

    onSubmit(
      resultBeingEdited.id,
      resultBeingEdited.activityId,
      resultBeingEdited.portfolioId,
      resultTitle,
      resultDescription,
      labels,
      resultBeingEdited.position
    );
  };

  return (
    <Modal show={isOpen} onHide={handleHide}>
      <Modal.Header>
        <Modal.Title>Edit Result</Modal.Title>
        <button
          type="button"
          className="btn-close btn-close-white"
          aria-label="Close"
          onClick={handleHide}
        ></button>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="resultTitle">
            <Form.Label>Title</Form.Label>
            <Form.Control
              onChange={(e) => setResultTitle(e.target.value)}
              value={resultTitle}
              type="text"
              placeholder="Result title"
            />
          </Form.Group>
          <Form.Group controlId="resultDescription">
            <Form.Label>Description</Form.Label>
            <Form.Control
              onChange={(e) => setResultDescription(e.target.value)}
              value={resultDescription}
              as="textarea"
              rows="3"
              placeholder="Result description"
            />
          </Form.Group>
          <Form.Group controlId="resultLabels">
            <Form.Label>Labels</Form.Label>
            {standardLabels.map((l) => (
              <Form.Check
                defaultChecked={resultLabels.some((rl) => rl === l)}
                type="checkbox"
                key={l}
                id={l}
                label={l}
                onChange={(e) => handleLabelChange(e, l)}
              />
            ))}
            <Form.Control
              className="mt-4"
              onChange={(e) => setOtherLabels(e.target.value)}
              value={otherLabels}
              type="text"
              placeholder="Other labels separated by a ','"
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="button primary" onClick={handleSubmit}>
          Save
        </Button>
        <Button className="button tertiary" onClick={handleHide}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
