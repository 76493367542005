import React, { useState } from "react";
import { IndicatorSummaryTableRowComponent } from "./IndicatorSummaryTableRowComponent";
import { IndicatorSummaryTableRowTotalComponent } from "./IndicatorSummaryTableRowTotalComponent";
import { IndicatorSummaryTableRowComponentQualitativeIndicator } from "./IndicatorSummaryTableRowComponentQualitativeIndicator";

export default function IndicatorSummaryComponent({
  indicator,
  portfolio,
  allPeriods,
  resultsOwnedInThisPortfolio,
  changeHandler,
  addTargetValue,
  addAchievedValue,
  resultTotalTargetValue,
  resultTotalAchievedValue,
  totalOfAllTargetValueOfThisPeriod,
  totalOfAllArchievedValueOfThisPeriod,
  totalTargetValueForAllPeriods,
  totalAchievedValueForAllPeriods,
  sharedInThisPortfolio,
  changeHandlerForShared,
  disaggregations,
  allFinancialYears,
  allCalendarYears,
  uniquePeriodTitles,
}) {
  const [isPlannedVisible, setIsPlannedVisible] = useState(true);
  const [isAchievedVisible, setIsAchievedVisible] = useState(true);
  //const [selectedDisaggregations, setSelectedDisaggregations] = useState([]);

  //const [isCalendarYear, setIsCalendarYear] = useState(false);
  //const [isFinancialYear, setIsFinancialYear] = useState(false);
  //const [isUserReportedYear, setIsUserReportedYear] = useState(false);

  /*const handleSelectedDisaggregation = (event, selectedItem) => {
    if (event.target.checked === true) {
      setSelectedDisaggregations((item) => [...item, selectedItem]);
    } else {
      let filteredArray = selectedDisaggregations.filter(
        (item) => item.id !== selectedItem.id
      );
      setSelectedDisaggregations(filteredArray);
    }
  };*/

  const changePlanned = () => {
    setIsPlannedVisible(!isPlannedVisible);
  };

  const changeAchieved = () => {
    setIsAchievedVisible(!isAchievedVisible);
  };

  /*const onFinancialYearChange = () => {
    setIsFinancialYear(!isFinancialYear);
  };

  const onCalendarYearChange = () => {
    setIsCalendarYear(!isCalendarYear);
  };

  const onUserReportedYearChange = () => {
    setIsUserReportedYear(!isUserReportedYear);
  };*/

  let uniquePortfolioNames = sharedInThisPortfolio
    ? [
      ...new Set(
        sharedInThisPortfolio.map((x) => x.activity.originatingPortfolioTitle)
      ),
    ]
    : [];

  uniquePortfolioNames = uniquePortfolioNames.sort((a, b) =>
    a.localeCompare(b)
  );
  return (
    <>
      {indicator.typeId === 2 && (
        <>
          <table className="table">
            <thead>
              <th>Result</th>
              <th>Activity</th>
              <th>Include in totals</th>
              <th></th>
              {uniquePeriodTitles.map((v, i) => (
                <th>{v}</th>
              ))}
              <th>Total</th>
            </thead>
            {resultsOwnedInThisPortfolio.length > 0 && (
              <tr>
                <td colSpan={5 + uniquePeriodTitles.length}>
                  <span>
                    <strong>Owned in this Portfolio</strong>
                  </span>
                </td>
              </tr>
            )}
            {resultsOwnedInThisPortfolio &&
              resultsOwnedInThisPortfolio.map((v, index) => (
                <>
                  {v.results.map((result, eachIndex) => (
                    <IndicatorSummaryTableRowComponent
                      result={result}
                      changeHandler={changeHandler}
                      activity={v}
                      allPeriods={allPeriods}
                      uniquePeriodTitles={uniquePeriodTitles}
                      addTargetValue={addTargetValue}
                      addAchievedValue={addAchievedValue}
                      resultTotalTargetValue={resultTotalTargetValue}
                      resultTotalAchievedValue={resultTotalAchievedValue}
                      isPlannedVisible={isPlannedVisible}
                      isAchievedVisible={isAchievedVisible}
                      //selectedDisaggregations={selectedDisaggregations}
                      disaggregations={disaggregations}
                    ></IndicatorSummaryTableRowComponent>
                  ))}
                </>
              ))}
            {sharedInThisPortfolio.length > 0 && (
              <tr>
                <td colSpan={5 + allPeriods.length}>
                  <span>
                    <strong>Shared to or nested in this portfolio</strong>
                  </span>
                </td>
              </tr>
            )}
            {uniquePortfolioNames.map((name, index) => (
              <>
                <tr>
                  <td colSpan={5 + uniquePeriodTitles.length}>
                    <span>
                      <strong>{name}</strong>
                    </span>
                  </td>
                </tr>
                {sharedInThisPortfolio
                  .filter(
                    (x) => x.activity.originatingPortfolioTitle === name
                  )
                  .map((activity, i) => (
                    <>
                      {activity.results.map((result, eachIndex) => (
                        <IndicatorSummaryTableRowComponent
                          result={result}
                          changeHandler={changeHandlerForShared}
                          activity={activity}
                          allPeriods={allPeriods}
                          uniquePeriodTitles={uniquePeriodTitles}
                          addTargetValue={addTargetValue}
                          addAchievedValue={addAchievedValue}
                          resultTotalTargetValue={resultTotalTargetValue}
                          resultTotalAchievedValue={resultTotalAchievedValue}
                          isPlannedVisible={isPlannedVisible}
                          isAchievedVisible={isAchievedVisible}
                          //selectedDisaggregations={selectedDisaggregations}
                          disaggregations={disaggregations}
                        ></IndicatorSummaryTableRowComponent>
                      ))}
                    </>
                  ))}
              </>
            ))}
            {(sharedInThisPortfolio.length > 0 ||
              resultsOwnedInThisPortfolio.length > 0) && (
                <IndicatorSummaryTableRowTotalComponent
                  allPeriods={allPeriods}
                  uniquePeriodTitles={uniquePeriodTitles}
                  totalOfAllTargetValueOfThisPeriod={
                    totalOfAllTargetValueOfThisPeriod
                  }
                  totalOfAllArchievedValueOfThisPeriod={
                    totalOfAllArchievedValueOfThisPeriod
                  }
                  totalTargetValueForAllPeriods={totalTargetValueForAllPeriods}
                  totalAchievedValueForAllPeriods={
                    totalAchievedValueForAllPeriods
                  }
                  isPlannedVisible={isPlannedVisible}
                  isAchievedVisible={isAchievedVisible}
                  //selectedDisaggregations={selectedDisaggregations}
                  disaggregations={disaggregations}
                ></IndicatorSummaryTableRowTotalComponent>
              )}
          </table>

          <p className="description mt-4 mb-3">
            Indicator aggregation approach is: {indicator.aggregationMethod}
          </p>

          <p className="description">Show:</p>
          <div className="mt-3">
            <label className="checkbox block">
              Planned{" "}
              <input type="checkbox"
                checked={isPlannedVisible}
                onChange={changePlanned} />
              <span className="checkmark"></span>
            </label>
            <label className="checkbox block">
              Achieved{" "}
              <input type="checkbox"
                checked={isAchievedVisible}
                onChange={changeAchieved} />
              <span className="checkmark"></span>
            </label>
          </div>
        </>
      )}
      {indicator.typeId === 1 && (
        <>
          {allPeriods.length > 0 ? (
            <>
              <table striped hover className="table">
                <thead>
                  <th>Result</th>
                  <th>Activity</th>
                  <th></th>
                  {uniquePeriodTitles.map((v, i) => (
                    <th>{v}</th>
                  ))}
                </thead>
                {resultsOwnedInThisPortfolio.length > 0 && (
                  <tr>
                    <td colSpan={3 + uniquePeriodTitles.length}>
                      <span className="description mb-0">Owned in this Portfolio</span>
                    </td>
                  </tr>
                )}
                {resultsOwnedInThisPortfolio &&
                  resultsOwnedInThisPortfolio.map((v, index) => (
                    <>
                      {v.results.map((result, eachIndex) => (
                        <IndicatorSummaryTableRowComponentQualitativeIndicator
                          result={result}
                          changeHandler={changeHandler}
                          activity={v}
                          allPeriods={allPeriods}
                          uniquePeriodTitles={uniquePeriodTitles}
                          addTargetValue={addTargetValue}
                          addAchievedValue={addAchievedValue}
                          resultTotalTargetValue={resultTotalTargetValue}
                          resultTotalAchievedValue={resultTotalAchievedValue}
                          isPlannedVisible={isPlannedVisible}
                          isAchievedVisible={isAchievedVisible}
                          //selectedDisaggregations={selectedDisaggregations}
                          disaggregations={disaggregations}
                        ></IndicatorSummaryTableRowComponentQualitativeIndicator>
                      ))}
                    </>
                  ))}
                {sharedInThisPortfolio.length > 0 && (
                  <tr>
                    <td colSpan={3 + allPeriods.length}>
                      <span className="description mb-0">Shared to or nested in this portfolio</span>
                    </td>
                  </tr>
                )}
                {uniquePortfolioNames.map((name, index) => (
                  <>
                    <tr>
                      <td colSpan={3 + uniquePeriodTitles.length}>
                        <span className="description mb-0">{name}</span>
                      </td>
                    </tr>
                    {sharedInThisPortfolio
                      .filter(
                        (x) => x.activity.originatingPortfolioTitle === name
                      )
                      .map((activity, i) => (
                        <>
                          {activity.results.map((result, eachIndex) => (
                            <IndicatorSummaryTableRowComponentQualitativeIndicator
                              result={result}
                              changeHandler={changeHandlerForShared}
                              activity={activity}
                              allPeriods={allPeriods}
                              uniquePeriodTitles={uniquePeriodTitles}
                              addTargetValue={addTargetValue}
                              addAchievedValue={addAchievedValue}
                              resultTotalTargetValue={resultTotalTargetValue}
                              resultTotalAchievedValue={
                                resultTotalAchievedValue
                              }
                              isPlannedVisible={isPlannedVisible}
                              isAchievedVisible={isAchievedVisible}
                              //selectedDisaggregations={selectedDisaggregations}
                              disaggregations={disaggregations}
                            ></IndicatorSummaryTableRowComponentQualitativeIndicator>
                          ))}
                        </>
                      ))}
                  </>
                ))}
              </table>
            </>
          ) : (
            <>
              <p className="mt-3">No periodic record found</p>
            </>
          )}

          <div className="d-flex align-items-top">
            {allPeriods.length > 0 && (
              <div className="me-5">
                <span className="description mt-4 mb-3">Target achievment</span>
                <div className="d-flex align-items-center">
                  <ul style={{ listStyle: "none" }}>
                    <li>
                      <div className="d-flex align-items-center mt-1 mb-2">
                        <i class="target-icon text-color-success bi bi-check2-all"></i> Exceed
                      </div>
                    </li>
                    <li>
                      <div className="d-flex align-items-center mt-1 mb-2">
                        <i class="target-icon text-color-success bi bi-check2"></i> Achieved
                      </div>
                    </li>
                    <li>
                      <div className="d-flex align-items-center mt-1 mb-2">
                        <i class="target-icon text-color-warning bi bi-circle-half"></i> Partially achieved
                      </div>
                    </li>
                    <li>
                      <div className="d-flex align-items-center mt-1 mb-2">
                        <i class="target-icon text-color-error bi bi-x-circle"></i>
                        Not achieved
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            )}

            {allPeriods.length > 0 && (
              <div>
                <span className="description mt-4 mb-3">Trend</span>
                <div className="d-flex align-items-center">
                  <ul style={{ listStyle: "none" }}>
                    <li>
                      <div className="d-flex align-items-center mt-1 mb-2">
                        <i class="target-icon text-color-success bi bi-caret-up-fill"></i> Improving
                      </div>
                    </li>
                    <li>
                      <div className="d-flex align-items-center mt-1 mb-2">
                        <i class="target-icon text-color-success bi bi-dash-lg"></i> Steady
                      </div>
                    </li>
                    <li>
                      <div className="d-flex align-items-center mt-1 mb-2">
                        <i class="target-icon text-color-error bi bi-caret-down-fill"></i>
                        Worsening
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>

  );
}
