import React from "react";
import Pager from "../../Shared/Pager";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import IndicatorListItemLink from "./IndicatorListItemLink";
import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import callApi from "../../Helpers/callApi";
import { useAddToast } from "../../Context/ToastContext";
//import { mutate } from "swr";

export default function IndicatorList(props) {
  const {
    indicators,
    indicatorsTotalItemCount,
    indicatorsPageSize,
    indicatorsPage,
    onPageChanged,
    handleEdit,
    handleDelete,
    handleShare,
    isUserPortfolioOwner,
    indicatorsSharedAtPortfolio,
    sharedIndicatorRemoved,
    setSharedIndicatorRemoved,
    portfolioId,
    sharedActivityOrNestedPortfolioIndicators,
    handleAddToPortfolio,
    handleAskToShare,
  } = props;

  const addToast = useAddToast();
  const [indicatorToBeRemoved, setIndicatorToBeRemoved] = useState(null);
  const [isRemoveIndicatorModalOpen, setIsRemoveIndicatorModalOpen] =
    useState(false);

  let uniquePortfolioNames = indicatorsSharedAtPortfolio
    ? [
      ...new Set(
        indicatorsSharedAtPortfolio.map((x) => x.sharedFromPortfolioTitle)
      ),
    ]
    : [];

  uniquePortfolioNames = uniquePortfolioNames.sort((a, b) =>
    a.localeCompare(b)
  );

  let uniquePortfolioNamesFromNesting =
    sharedActivityOrNestedPortfolioIndicators
      ? [
        ...new Set(
          sharedActivityOrNestedPortfolioIndicators.map(
            (x) => x.sharedActivityOrNestedPortfolioTitle
          )
        ),
      ]
      : [];

  uniquePortfolioNamesFromNesting = uniquePortfolioNamesFromNesting.sort(
    (a, b) => a.localeCompare(b)
  );

  const handleClickRemoveIndicator = async (indicator) => {
    setIsRemoveIndicatorModalOpen(true);
    setIndicatorToBeRemoved(indicator);
  };

  const handleGotoIndicatorHomePortfolio = async (indicator) => {
    window.location.replace(`/${indicator.sharedFromPortfolioId}/indicators`);
  };

  const handleRemoveIndicatorClose = () => {
    setIsRemoveIndicatorModalOpen(false);
    setIndicatorToBeRemoved(null);
  };

  const handleRemoveIndicatorConfirm = async () => {
    let removeResponse = await callApi(
      `portfolios/${portfolioId}/removeindicatorsharing/${indicatorToBeRemoved.id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!removeResponse.ok) {
      const jsonResponse = await removeResponse.json();
      addToast({
        variant: "error",
        message: jsonResponse.error[0],
      });
    } else {
      /*mutate(
        `portfolios/${portfolioId}/activities?searchString=${searchString}&page=${page}&pagesize=${pageSize}`
      );

      mutate(`portfolios/${portfolioId}/allresults`);
      mutate(`portfolios/allactivities/${portfolioId}`);
      mutate(
        `portfolios/${portfolioId}/results?page=${page}&pageSize=${pageSize}&searchString=${searchString}`
      );*/
      setSharedIndicatorRemoved(!sharedIndicatorRemoved);
      handleRemoveIndicatorClose();
    }
  };

  return (
    <>
      {indicators.length ? (
        <div clas="py-3">
          <Row>
            <Col>
              <Pager
                totalItemCount={indicatorsTotalItemCount}
                pageSize={indicatorsPageSize}
                page={indicatorsPage}
                onPageChanged={onPageChanged}
              />
            </Col>
          </Row>
        </div>
      ) : (
        <></>
      )}

      <div className="list-group activities-list-group mt-4">
        {indicators.length ||
          indicatorsSharedAtPortfolio.length ||
          sharedActivityOrNestedPortfolioIndicators.length ? (
          <>
            {indicators.length ? (
              <>
                <h4 className="h4 mt-4 mb-3">
                  This portfolio
                </h4>
              </>
            ) : (
              <></>
            )}
            {indicators.map((i, j) => (
              <IndicatorListItemLink
                key={j}
                indicator={i}
                onClickDeleteIndicator={handleDelete}
                onClickEditIndicator={handleEdit}
                onClickShareIndicator={handleShare}
                isUserPortfolioOwner={isUserPortfolioOwner}
                onClickRemove={handleClickRemoveIndicator}
                onClickGotoHome={handleGotoIndicatorHomePortfolio}
              />
            ))}
            {uniquePortfolioNames.length ? (
              <>
                <h4 className="h4 mt-4 mb-3">
                  Added to this portfolio:
                </h4>
              </>
            ) : (
              <></>
            )}
            {uniquePortfolioNames.map((item, index) => (
              <div key={item}>
                <h4 className="h4 mb-4">From: {item}</h4>
                {indicatorsSharedAtPortfolio
                  .filter((x) => x.sharedFromPortfolioTitle === item)
                  .map((v, i) => (
                    <IndicatorListItemLink
                      key={i}
                      indicator={v}
                      onClickDeleteIndicator={handleDelete}
                      onClickEditIndicator={handleEdit}
                      onClickShareIndicator={handleShare}
                      isUserPortfolioOwner={isUserPortfolioOwner}
                      onClickRemove={handleClickRemoveIndicator}
                      onClickGotoHome={handleGotoIndicatorHomePortfolio}
                    />
                  ))}
              </div>
            ))}
            {sharedActivityOrNestedPortfolioIndicators.length ? (
              <>
                <div className="mt-4 mb-2">
                  In activities or portfolios shared with or nested in this
                  portfolio (read only - can-t be used):
                </div>
                {uniquePortfolioNamesFromNesting.map((item, index) => (
                  <div key={item} className="mt-2">
                    <h4 className="h4 mt-2 mb-3">From: {item}</h4>
                    {sharedActivityOrNestedPortfolioIndicators
                      .filter(
                        (x) => x.sharedActivityOrNestedPortfolioTitle === item
                      )
                      .map((v, i) => (
                        <IndicatorListItemLink
                          key={i}
                          indicator={v}
                          onClickDeleteIndicator={handleDelete}
                          onClickEditIndicator={handleEdit}
                          onClickShareIndicator={handleShare}
                          isUserPortfolioOwner={isUserPortfolioOwner}
                          onClickRemove={handleClickRemoveIndicator}
                          onClickGotoHome={handleGotoIndicatorHomePortfolio}
                          onClickAddToPortfolio={handleAddToPortfolio}
                          onClickAskToShare={handleAskToShare}
                        />
                      ))}
                  </div>
                ))}
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <p>There are no indicators</p>
        )}
      </div>

      <Modal
        show={isRemoveIndicatorModalOpen}
        onHide={handleRemoveIndicatorClose}
      >
        <Modal.Header>
          <Modal.Title>Remove Indicator</Modal.Title>
          <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={handleRemoveIndicatorClose}></button>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to remove this indicator?</p>
          <p>
            This will only remove this indicator from this portfolio. The
            indicator will not be deleted.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button className="button primary" onClick={handleRemoveIndicatorConfirm}>
            Remove
          </Button>
          <Button className="button tertiary" onClick={handleRemoveIndicatorClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
