import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Table from "react-bootstrap/Table";
import moment from "moment";
import useResultService from "../Services/useResultService";

export default function QuantativeIndicatorTotalsTable({ result, indicator }) {
  const [totals, setTotals] = useState(null);
  const { portfolioId, activityId } = useParams();
  const { getIndicatorTotals } = useResultService();

  useEffect(() => {
    const fetchResults = async () => {
      const response = await getIndicatorTotals(
        result.id,
        indicator.id,
        portfolioId
      );
      if (response.ok) {
        setTotals(response.data);
      }
    };

    fetchResults();
  }, [result, indicator, getIndicatorTotals, portfolioId]);

  if (!totals) return null;

  if (!totals.periodTotals.length > 0)
    return (
      <>
        <div className="mt-3 mb-4">
          <Link
            to={`/${portfolioId}/activities/${activityId}/results/${result.id}/${indicator.id}`}
          >
            {indicator.title}
          </Link>
        </div>
        <p className="text-secondary">
          There are no periods for this indicator
        </p>
      </>
    );

  return (
    <>
      <div className="mt-3 mb-4">
        <Link
          to={`/${portfolioId}/activities/${activityId}/results/${result.id}/${indicator.id}`}
        >
          {indicator.title}
        </Link>
      </div>
      <Table size="sm" className="mb-4">
        <thead>
          <tr>
            <th />
            {totals.periodTotals.map((p) => (
              <th key={p.periodId}>
                {moment(p.start).format("MMM YYYY")}-
                {moment(p.end).format("MMM YYYY")}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Achieved</td>
            {totals.periodTotals.map((p) => (
              <td key={p.periodId}>{p.totalActualValue}</td>
            ))}
          </tr>
          <tr>
            <td>Planned</td>
            {totals.periodTotals.map((p) => (
              <td key={p.periodId}>{p.totalTargetValue}</td>
            ))}
          </tr>
        </tbody>
      </Table>
    </>
  );
}
