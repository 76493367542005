import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import AddUserModal from "../Shared/AddUserModal";
import useActivityService from "../Services/useActivityService";
import Button from "react-bootstrap/Button";

import callApi from "../Helpers/callApi";
import UpdateSharedActivityStatusConfirmation from "./UpdateSharedActivityStatusConfirmation";
import RemoveSharedActivityModalConfirmation from "./RemoveSharedActivityModalConfirmation";
import { mutate } from "swr";

export default function Settings() {
  const [users, setUsers] = useState([]);
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [isRemoveUserModalOpen, setIsRemoveUserModalOpen] = useState(false);
  const [userToBeRemoved, setUserToBeRemoved] = useState(null);
  const [sharedWithPortfolios, setSharedWithPortfolios] = useState([]);
  const [showStatusUpdateModal, setShowStatusUpdateModal] = useState(false);
  const [portfolioIdSharedWith, setPortfolioIdSharedWith] = useState(0);
  const [sharedActivityCurrentStatus, setSharedActivityCurrentStatus] =
    useState("");
  const [
    isRemoveingNestingPortfolioModalOpenConfirmation,
    setIsRemoveingNestingPortfolioModalOpenConfirmation,
  ] = useState(false);
  const [
    sharedActivityRemovedOrStausChanged,
    setSharedActivityRemovedOrStausChanged,
  ] = useState(false);
  const [portfolioRemovingFrom, setPortfolioRemovingFrom] = useState("");
  const [nestingPortfolioIdForRemove, setNestingPortfolioIdForRemove] =
    useState(null);
  const { activityId, portfolioId } = useParams();
  const { getUsers, addUser, removeUser } = useActivityService();

  useEffect(() => {
    const fetchData = async () => {
      const response = await getUsers(activityId, portfolioId);
      setUsers(response.data);
    };

    fetchData();
  }, [activityId, getUsers, portfolioId]);

  useEffect(() => {
    const fetchData = async () => {
      let response = await callApi(
        `activities/${activityId}/getsharedwithportfolios/${portfolioId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setSharedWithPortfolios(data);
      }
    };
    fetchData();
  }, [activityId, sharedActivityRemovedOrStausChanged, portfolioId]);

  const handleAddUser = async (email, role) => {
    const response = await addUser(activityId, email, role);

    if (response.ok) {
      setUsers((prevState) => [...prevState, response.data]);
      setIsAddUserModalOpen(false);
    }
  };

  const handleRemoveClick = (user) => {
    setUserToBeRemoved(user);
    setIsRemoveUserModalOpen(true);
  };

  const handleRemoveUserModalClose = () => {
    setUserToBeRemoved(null);
    setIsRemoveUserModalOpen(false);
  };

  const handleRemoveUser = async () => {
    const response = await removeUser(activityId, userToBeRemoved.userId);

    if (response.ok) {
      setUsers((prevState) =>
        prevState.filter((u) => u.userId !== userToBeRemoved.userId)
      );
    }

    handleRemoveUserModalClose();
  };

  const handleSharedActivityStatusChange = async (item) => {
    setSharedActivityCurrentStatus(item.allowNesting);
    setPortfolioIdSharedWith(item.portfolioViewModel.id);
    setShowStatusUpdateModal(true);
  };

  const hideStatusUpdateModal = () => {
    setSharedActivityCurrentStatus("");
    setPortfolioIdSharedWith(0);
    setShowStatusUpdateModal(false);
  };

  const handleStatusUpdate = async () => {
    let response = await callApi(
      `activities/${portfolioId}/${activityId}/changesharedactivitystatus/${portfolioIdSharedWith}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.ok) {
      setSharedActivityRemovedOrStausChanged(
        !sharedActivityRemovedOrStausChanged
      );
      hideStatusUpdateModal();
    }
  };

  const handleRemovePortfolioConfirmation = async () => {
    let shareResponse = await callApi(
      `activities/${activityId}/${nestingPortfolioIdForRemove}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (shareResponse.ok) {
      setSharedActivityRemovedOrStausChanged(
        !sharedActivityRemovedOrStausChanged
      );
      hideRemovePortfolioModal();
      mutate(`portfolios/${portfolioId}/allresults`);
    }
  };

  const hideRemovePortfolioModal = () => {
    setIsRemoveingNestingPortfolioModalOpenConfirmation(false);
    setNestingPortfolioIdForRemove(null);
    setPortfolioRemovingFrom("");
  };

  const handleRemovePortfolio = async (item) => {
    setNestingPortfolioIdForRemove(item.portfolioViewModel.id);
    setPortfolioRemovingFrom(item.portfolioViewModel.title);
    setIsRemoveingNestingPortfolioModalOpenConfirmation(true);
  };

  return (
    <>
      <h2 className="h2">Settings</h2>
      <div className="actions mb-4">
        <div
          className="icon-and-text"
          onClick={() => setIsAddUserModalOpen(true)}
        >
          <i class="icon bi bi-person-plus"></i>
          Add user
        </div>
      </div>
      <div className="py-3">
        <Table size="sm">
          <thead>
            <tr>
              <th>Email</th>
              <th>Role</th>
              <th>Remove</th>
            </tr>
          </thead>
          <tbody>
            {users.map((u) => (
              <tr key={u.email}>
                <td>{u.email}</td>
                <td>{u.role}</td>
                <td>
                  <button
                    class="icon-and-text"
                    onClick={() => handleRemoveClick(u)}
                  >
                    <i class="icon bi bi-x-circle"></i>
                    Remove User
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {sharedWithPortfolios.length > 0 && (
          <>
            <div className="alert warning mt-5">
              <div className="text">
                <i className="icon bi bi-exclamation-triangle"></i>
                This activity is shared with portfolios
              </div>
            </div>
            <h4 className="h4 mb-3">Portfolios</h4>
            <div className="portfolio-list pt-0">
              {sharedWithPortfolios.map((eachItem) => (
                <div className="item">
                  <div className="item-info">
                    <i class="icon bi bi-folder"></i>
                    <span class="title">
                      {eachItem.portfolioViewModel.title}
                    </span>
                    <Link
                      className="icon-and-text margin-left-auto"
                      onClick={() => handleRemovePortfolio(eachItem)}
                    >
                      <i className="icon bi bi-x-circle"></i>
                      {"Remove from sharing in this portfolio"}{" "}
                    </Link>
                  </div>

                  <div className="mt-2">
                    <span className="badge info d-inline">
                      Sharing in nested portfolios is
                      {eachItem.allowNesting === true ? " ON - " : " OFF - "}
                      <Link
                        onClick={() =>
                          handleSharedActivityStatusChange(eachItem)
                        }
                      >
                        {"change"}
                      </Link>
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
        <AddUserModal
          isOpen={isAddUserModalOpen}
          onHide={() => setIsAddUserModalOpen(false)}
          onSubmit={handleAddUser}
        />
        <UpdateSharedActivityStatusConfirmation
          sharedActivityCurrentStatus={sharedActivityCurrentStatus}
          isOpen={showStatusUpdateModal}
          onHide={hideStatusUpdateModal}
          onSubmit={handleStatusUpdate}
        />

        <RemoveSharedActivityModalConfirmation
          isOpen={isRemoveingNestingPortfolioModalOpenConfirmation}
          onSubmit={handleRemovePortfolioConfirmation}
          onHide={hideRemovePortfolioModal}
          portfolioRemovingFrom={portfolioRemovingFrom}
        />

        <Modal show={isRemoveUserModalOpen} onHide={handleRemoveUserModalClose}>
          <Modal.Header>
            <Modal.Title>Remove User</Modal.Title>
            <button
              type="button"
              className="btn-close btn-close-white"
              aria-label="Close"
              onClick={handleRemoveUserModalClose}
            ></button>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to remove this user?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button className="button primary" onClick={handleRemoveUser}>
              Remove
            </Button>
            <Button
              type="button"
              className="button tertiary"
              onClick={handleRemoveUserModalClose}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
