import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";

export default function AddIndicatorModalAtEvidence(props) {
  const {
    isOpen,
    onHide,
    onLinkEvidenceWithIndicator,
    allIndicators,
    evidence,
  } = props;
  const [selectedIndicators, setSelectedIndicators] = useState([]);
  let allIndicatorsResult = allIndicators ? allIndicators : [];

  let getAvailableIndicators = () => {
    let result = allIndicatorsResult;
    if (
      evidence.evidenceIndicators &&
      evidence.evidenceIndicators.length !== 0
    ) {
      let alreadySelectedEvidenceIds = evidence.evidenceIndicators
        .flat()
        .map((x) => x.indicatorId);
      result = result.filter((x) => !alreadySelectedEvidenceIds.includes(x.id));
      return result;
    } else {
      return result;
    }
  };

  const handleRemoveIndicator = (indicatorId) => {
    setSelectedIndicators((prevState) =>
      prevState.filter((data) => data.id !== indicatorId)
    );
  };

  const handleOnSubmit = (e) => {
    onLinkEvidenceWithIndicator(selectedIndicators); //selectedIndicators.map((x) => x.id)
    reset();
    onHide();
  };

  const handleCancel = (e) => {
    reset();
    onHide();
  };

  const reset = () => {
    setSelectedIndicators([]);
  };

  const handleIndicatorOnChange = (indicatorId) => {
    if (selectedIndicators.some((x) => x.id === parseInt(indicatorId))) return;
    let associatedIndicator = allIndicators.find(
      (x) => x.id === parseInt(indicatorId)
    );
    if (associatedIndicator === null) return;
    let tempData = {
      evidenceId: evidence.id,
      indicatorId: parseInt(indicatorId),
      title: associatedIndicator.title,
    };
    setSelectedIndicators((a) => [...a, tempData]);
  };

  return (
    <Modal show={isOpen} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Add Indicators</Modal.Title>
        <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={handleCancel}></button>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="indicatorTitle">
          <Form.Label className="label">Select indicators from the portfolio:</Form.Label>
          <Form.Control
            as="select"
            className="select-menu"
            onChange={(e) => handleIndicatorOnChange(e.target.value)}
          >
            <option disabled selected value="">
              -- select an option --
            </option>
            {getAvailableIndicators().map((a) => (
              <option key={a.id} value={a.id}>
                {a.title}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <div className="mb-3">
          <div>
            <span className="badge info inline-block mb-3">
              {selectedIndicators.length}
            </span>
            <span>Selected indicators:</span>
          </div>
          {selectedIndicators !== null ? (
            selectedIndicators.map((a) => (
              <div class="added-values">
                <span class="badge">{a.title}{" "} <i class="icon bi bi-x-circle" onClick={() => {
                  handleRemoveIndicator(a.id);
                }}></i></span>
              </div>
            ))
          ) : (
            <span>No indicators added yet</span>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="button primary" disabled={!(selectedIndicators.length > 0)}
          onClick={handleOnSubmit}>
          Save
        </Button>
        <Button
          className="button tertiary"
          onClick={handleCancel}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
