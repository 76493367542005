import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

export default function EvidenceAddToPortfolioConfirmation({
  isOpen,
  onHide,
  onSubmit,
}) {
  const handleSubmit = (e) => {
    onSubmit();
  };

  const handleCancel = (e) => {
    onHide();
  };

  return (
    <Modal show={isOpen} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>Add to portfolio confirmation</Modal.Title>
        <button
          type="button"
          className="btn-close btn-close-white"
          aria-label="Close"
          onClick={handleCancel}
        ></button>
      </Modal.Header>
      <Form>
        <Modal.Body>
          <p>Do you really want to add this evidence into this portfolio?</p>
        </Modal.Body>
        <Modal.Footer>

          <Button className="button primary" onClick={handleSubmit}>
            Add to portfolio
          </Button>
          <Button className="button tertiary" onClick={handleCancel}>
            Cancel
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
