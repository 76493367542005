import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import callApi from "../../Helpers/callApi";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";

export default function ShareDisaggregationModalToPortfolio({
  portfolioId,
  isOpen,
  onHide,
  onSubmit,
  allDisaggregations,
}) {
  const [portfolioExists, setPortfolioExists] = useState(false);
  const [portfolioUniqueTitle, setPortfolioUniqueTitle] = useState(null);
  const [selectedIndicator, setSelectedIndicator] = useState([]);

  const handleOnShare = (e) => {
    onSubmit(
      portfolioUniqueTitle,
      selectedIndicator.map((x) => x.id),
      false
    );
    reset();
    onHide();
  };

  const handleOnShareAll = (e) => {
    onSubmit(portfolioUniqueTitle, [], true);
    reset();
    onHide();
  };

  const reset = () => {
    setPortfolioUniqueTitle(null);
    setSelectedIndicator([]);
  };

  const handleCancel = (e) => {
    reset();
    onHide();
  };

  const handleEvidenceOnChange = (evidenceId) => {
    if (selectedIndicator.some((x) => x.id === parseInt(evidenceId))) return;
    let associatedEvidence = allDisaggregations.find(
      (x) => x.id === parseInt(evidenceId)
    );
    if (associatedEvidence === null) return;
    let tempData = {
      id: parseInt(evidenceId),
      title: associatedEvidence.title,
    };
    setSelectedIndicator((a) => [...a, tempData]);
  };

  const handleRemoveEvidence = (evidenceId) => {
    setSelectedIndicator((prevState) =>
      prevState.filter((data) => data.id !== evidenceId)
    );
  };

  //typeahead changes
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const handleSearch = async (query) => {
    setIsLoading(true);
    const SEARCH_URI = `portfolios/allportfoliotitle`;
    const response = await callApi(
      `${SEARCH_URI}?portfolioUniqueTitle=${query}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const result = await response.json();
    const options = result.map((i) => ({
      avatar_url: i.uniqueTitle,
      id: i.id,
      login: i.uniqueTitle,
    }));

    setOptions(options);
    setIsLoading(false);
  };

  const handleChange = async (selected) => {
    if (selected.length > 0) {
      setPortfolioExists(true);
      setPortfolioUniqueTitle(selected[0].login);
    } else {
      setPortfolioExists(false);
      setPortfolioUniqueTitle(null);
    }
  };

  // Bypass client-side filtering by returning `true`. Results are already
  // filtered by the search endpoint, so no need to do it again.
  const filterBy = () => true;

  return (
    <Modal show={isOpen} onHide={onHide} backdrop="static" keyboard={false}>
      <Modal.Header onHide={reset}>
        <Modal.Title>Share Disaggregations</Modal.Title>
        <button
          type="button"
          className="btn-close btn-close-white"
          aria-label="Close"
          onClick={reset}
        ></button>
      </Modal.Header>
      <Form>
        <Modal.Body>
          <Form.Group controlId="evidenceTitle">
            <Form.Label>Share with portfolio:</Form.Label>
            <AsyncTypeahead
              filterBy={filterBy}
              id="async-example"
              isLoading={isLoading}
              labelKey="login"
              minLength={3}
              onSearch={handleSearch}
              onChange={handleChange}
              options={options}
              placeholder="Search for portfolio unique title"
              renderMenuItemChildren={(option, props) => (
                <>
                  <span>{option.login}</span>
                </>
              )}
            />
          </Form.Group>
          <div className="mb-3">
            <Button
              className="icon-and-text"
              onClick={handleOnShareAll}
              variant="link"
              disabled={!(portfolioExists && selectedIndicator.length === 0)}
            >
              <i class="icon bi bi-share"></i>
              Share all disaggregations in portfolio
            </Button>
          </div>
          <div className="mb-3">
            <strong>OR</strong>
          </div>
          <Form.Group controlId="evidenceTitle">
            <Form.Label>Select disaggregations:</Form.Label>
            <Form.Control
              as="select"
              onChange={(e) => handleEvidenceOnChange(e.target.value)}
            >
              <option disabled selected value="">
                -- select an option --
              </option>
              {allDisaggregations
                //.filter((x) => x.isPublic === true)
                .map((a) => (
                  <option key={a.id} value={a.id}>
                    {a.title}
                  </option>
                ))}
            </Form.Control>
          </Form.Group>
          <div className="mb-3">
            <div>
              <span>Selected disaggregations: {selectedIndicator.length}</span>
            </div>
            <div className="added-values">
              {selectedIndicator !== null ? (
                selectedIndicator.map((a) => (
                  <div
                    key={a.id}
                    size="sm"
                    className="badge"
                    variant="secondary"
                    title={a.title}
                  >
                    {a.title}{" "}
                    <i
                      className="icon bi bi-x-circle"
                      onClick={() => {
                        handleRemoveEvidence(a.id);
                      }}
                    ></i>
                  </div>
                ))
              ) : (
                <div className="alert warning">No disaggregation added yet</div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="button primary"
            onClick={handleOnShare}
            disabled={!(portfolioExists && selectedIndicator.length > 0)}
          >
            Share Disaggregations
          </Button>
          <Button className="button tertiary" onClick={handleCancel}>
            Cancel
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
