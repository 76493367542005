import React, { useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";

const IndicatorForm = React.forwardRef((props, ref) => {
  const {
    onSetIndicatorTitle,
    onSetIndicatorDescription,
    onSetIndicatorMethodology,
    onSetIndicatorType,
    onSetIndicatorUnits,
    onSetIndicatorDocumentLink,
    onSetIndicatorAggregationMethod,
    onSetIndicatorIsPublic,
    indicatorBeingEdited,
    validated,
  } = props;
  const isPublicCheckbox = useRef(null);
  const [aggregationMethod, setAggregationMethod] = useState("sum");

  const handleAggregationMethod = (e) => {
    onSetIndicatorAggregationMethod(e.target.value);
    setAggregationMethod(e.target.value);
  };

  return (
    <Form ref={ref} noValidate validated={validated}>
      <Form.Group className="mb-4" controlId="indicatorTitle">
        <Form.Label>Title</Form.Label>
        <Form.Control
          required
          onChange={(e) => onSetIndicatorTitle(e.target.value)}
          type="text"
          placeholder="Indicator title"
          value={indicatorBeingEdited ? indicatorBeingEdited.title : null}
        />
        <Form.Control.Feedback type="invalid">
          Please enter an indicator title
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-4" controlId="indicatorDescription">
        <Form.Label>Description</Form.Label>
        <Form.Control
          required
          onChange={(e) => onSetIndicatorDescription(e.target.value)}
          as="textarea"
          rows="3"
          placeholder="Indicator description"
          value={indicatorBeingEdited ? indicatorBeingEdited.description : null}
        />
        <Form.Control.Feedback type="invalid">
          Please enter an indictor description
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-4" controlId="indicatorMethodology">
        <Form.Label>Methodology</Form.Label>
        <Form.Control
          onChange={(e) => onSetIndicatorMethodology(e.target.value)}
          as="textarea"
          rows="3"
          placeholder="Indicator methodology"
          value={indicatorBeingEdited ? indicatorBeingEdited.methodology : null}
        />
      </Form.Group>

      {!indicatorBeingEdited && (
        <>
          <Form.Group className="mb-4" controlId="indicatorType">
            <Form.Label>Indicator Type</Form.Label>
            <Form.Control
              onChange={(e) => onSetIndicatorType(e.target.value)}
              as="select"
            >
              <option value="2">Quantitative</option>
              <option value="1">Qualitative</option>
            </Form.Control>
          </Form.Group>
        </>
      )}

      <Form.Row>
        <Form.Group className="mb-4" as={Col} controlId="indicatorUnits">
          <Form.Label>Units</Form.Label>
          <Form.Control
            required
            onChange={(e) => onSetIndicatorUnits(e.target.value)}
            type="text"
            placeholder="Indicator units"
            value={indicatorBeingEdited ? indicatorBeingEdited.units : null}
          />
          <Form.Control.Feedback type="invalid">
            Please enter indicator units
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-4" as={Col} controlId="indicatorDocumentLink">
          <Form.Label>Document Link</Form.Label>
          <Form.Control
            onChange={(e) => onSetIndicatorDocumentLink(e.target.value)}
            type="url"
            pattern="https://.*"
            placeholder="Indicator document link"
            value={
              indicatorBeingEdited ? indicatorBeingEdited.documentLink : null
            }
          />
          <Form.Control.Feedback type="invalid">
            Please enter a valid url beginning https://
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group className="mb-4" controlId="indicatorAggregationMethod">
          <Form.Label>Indicator Aggregation Method</Form.Label>
          <Form.Label>
            How should this indicator be aggregated over multiple time periods
          </Form.Label>
          <Form.Control
            onChange={(e) => handleAggregationMethod(e)}
            as="select"
            value={
              indicatorBeingEdited
                ? indicatorBeingEdited.aggregationMethod
                : aggregationMethod
            }
          >
            <option value="sum">Sum of period totals</option>
            <option value="most recent">Most recent period total</option>
            <option value="max">Max period total</option>
            <option value="min">Min period total</option>
            <option value="average">Average</option>
          </Form.Control>
        </Form.Group>
        <Form.Group className="mb-4" controlId="indicatorIsPublic">
          <Form.Label>Is Indicator Public</Form.Label>
          <Form.Check
            ref={isPublicCheckbox}
            checked={indicatorBeingEdited && indicatorBeingEdited.isPublic}
            type="checkbox"
            label={
              isPublicCheckbox.current && isPublicCheckbox.current.checked
                ? "Public"
                : "Private"
            }
            onChange={(e) => onSetIndicatorIsPublic(e.target.checked)}
          />
          <Form.Text>
            Public indicators will be searchable and copyable from other
            portfolios
          </Form.Text>
        </Form.Group>
      </Form.Row>
    </Form>
  );
});

export default IndicatorForm;
