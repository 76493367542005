import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

export default function AddPortfolioModal({ isOpen, onHide, onSubmit }) {
  const [newPortfolioTitle, setNewPortfolioTitle] = useState("");
  const [newPortfolioDescription, setNewPortfolioDescription] = useState("");

  const handleClose = () => {
    setNewPortfolioTitle("");
    setNewPortfolioDescription("");
    onHide();
  };

  const handleSubmit = () => {
    onSubmit(
      newPortfolioTitle,
      newPortfolioDescription
    );
    setNewPortfolioTitle("");
    setNewPortfolioDescription("");
    onHide();
  };

  return (
    <Modal show={isOpen} onHide={handleClose}>
          <Modal.Header>
              <Modal.Title>Add Portfolio</Modal.Title>
              <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={handleClose}></button>
      </Modal.Header>
      <Modal.Body>
        <Form>
                  <Form.Group className="mb-4" controlId="portfolioTitle">
            <Form.Label>Title</Form.Label>
            <Form.Control
              onChange={e => setNewPortfolioTitle(e.target.value)}
              type="text"
              placeholder="Portfolio title"
            />
          </Form.Group>
                  <Form.Group className="mb-4" controlId="portfolioDescription">
            <Form.Label>Description</Form.Label>
            <Form.Control
              onChange={e => setNewPortfolioDescription(e.target.value)}
              as="textarea"
              rows="3"
              placeholder="Portfolio description"
            />
          </Form.Group>
        </Form>
      </Modal.Body>
          <Modal.Footer>
            <Button className="button primary" onClick={handleSubmit}>
                Add Portfolio
            </Button>
            <Button className="button tertiary" onClick={handleClose}>
                Cancel
            </Button>
      </Modal.Footer>
    </Modal>
  );
}
