import React from "react";
import PortfolioListItem from "./PortfolioListItem";

export default function PortfolioList({ portfolios }) {
  return (
    <div className="portfolio-list">
      {portfolios.map((p, i) => (
        <PortfolioListItem key={i} portfolio={p} />
      ))}
    </div>
  );
}
