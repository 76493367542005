import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

export default function AddUserModal({ isOpen, onHide, onSubmit }) {
  const [newUserEmail, setNewUserEmail] = useState(null);
  const [newUserRole, setNewUserRole] = useState(null);

  const handleHide = () => {
    setNewUserEmail(null);
    setNewUserRole(null);
    onHide();
  };

  const handleSubmit = () => {
    onSubmit(newUserEmail, newUserRole).then((response) => {
      if (response) {
        setNewUserEmail(null);
        setNewUserRole(null);
        onHide();
      }
    });
  };

  return (
    <Modal show={isOpen} onHide={handleHide}>
      <Modal.Header>
        <Modal.Title>Add User</Modal.Title>
        <button
          type="button"
          className="btn-close btn-close-white"
          aria-label="Close"
          onClick={handleHide}
        ></button>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-4" controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              onChange={(e) => setNewUserEmail(e.target.value)}
              type="email"
              placeholder="The email of the user"
            />
          </Form.Group>
          <Form.Group className="mb-4" controlId="role">
            <Form.Label>Role</Form.Label>
            <Form.Control
              as="select"
              onChange={(e) => setNewUserRole(e.target.value.toLowerCase())}
            >
              <option disabled selected value>
                -- select an option --
              </option>
              <option>Owner</option>
              <option>Guest</option>
            </Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="button primary"
          disabled={!(newUserEmail && newUserRole)}
          onClick={handleSubmit}
        >
          Add User
        </Button>
        <Button className="button tertiary" onClick={handleHide}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
