import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import { BsFillXCircleFill } from "react-icons/bs";

export default function AddDisaggregationModal({ isOpen, onHide, onSubmit }) {
  const [isValidated, setIsValidated] = useState(false);
  const [newDisaggregationTitle, setNewDisaggregationTitle] = useState("");
  const [newDisaggregationIsPublic, setNewDisaggregationIsPublic] =
    useState(false);
  const [newDisaggregationDescription, setNewDisaggregationDescription] =
    useState("");
  const [newDisaggregationValues, setNewDisaggregationValues] = useState([]);
  const [typedValue, setTypedValue] = useState("");

  const handleRemoveNewDisaggregationValue = (val) => {
    setNewDisaggregationValues((prevState) =>
      prevState.filter((data) => data !== val)
    );
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;

    if (
      form.checkValidity() === false ||
      newDisaggregationValues.length === 0
    ) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      onSubmit(
        newDisaggregationTitle,
        newDisaggregationDescription,
        newDisaggregationValues,
        newDisaggregationIsPublic
      );
      onHide();
    }

    setIsValidated(true);
  };

  return (
    <Modal show={isOpen} onHide={onHide} size="lg">
      <Modal.Header>
        <Modal.Title>Add Disaggregation</Modal.Title>
        <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={onHide}></button>
      </Modal.Header>
      <Form noValidate validated={isValidated} onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group className="mb-4" controlId="disaggregationTitle">
            <Form.Label>Title</Form.Label>
            <Form.Control
              required
              onChange={(e) => setNewDisaggregationTitle(e.target.value)}
              type="text"
              placeholder="Disaggregation title"
            />
            <Form.Control.Feedback type="invalid">
              Please enter an disaggregation title
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-4" controlId="disaggregationDescription">
            <Form.Label>Description</Form.Label>
            <Form.Control
              required
              onChange={(e) => setNewDisaggregationDescription(e.target.value)}
              as="textarea"
              rows="3"
              placeholder="Disaggregation description"
            />
            <Form.Control.Feedback type="invalid">
              Please enter an disaggregation description
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="IsPublic">
            <Form.Label>Is disaggregation public</Form.Label>
            <Form.Check
              checked={newDisaggregationIsPublic}
              type="checkbox"
              label={newDisaggregationIsPublic ? "Public" : "Private"}
              onChange={(e) => setNewDisaggregationIsPublic(e.target.checked)}
            />
            <Form.Text>
              Public disaggregations will be searchable and copyable from other
              portfolios
            </Form.Text>
          </Form.Group>
          <div className="mb-3">
            {newDisaggregationValues.map((v) => (
              <Button size="sm" className="mr-2" variant="secondary">
                {v}{" "}
                <BsFillXCircleFill
                  onClick={() => handleRemoveNewDisaggregationValue(v)}
                />
              </Button>
            ))}
          </div>
          <InputGroup className="w-50 mb-4">
            <FormControl
              isInvalid={isValidated && newDisaggregationValues.length === 0}
              value={typedValue}
              onChange={(e) => setTypedValue(e.target.value)}
              placeholder="Disaggregation values"
              aria-label="Disaggregation Values"
            />
            <InputGroup.Append>
              <button
                className="button tertiary with-icon ms-2 margin-top-auto add-data"
                onClick={(e) => {
                  setNewDisaggregationValues((prevValues) => [
                    ...prevValues,
                    typedValue,
                  ]);
                  setTypedValue("");
                }}
              >
                <i class="icon bi bi-plus-circle"></i>
                Add
              </button>
            </InputGroup.Append>
            <Form.Control.Feedback type="invalid">
              Please enter at least one disaggregation value
            </Form.Control.Feedback>
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>

          <Button className="button primary" type="submit">
            Add Disaggregation
          </Button>
          <Button className="button tertiary" onClick={onHide}>
            Cancel
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
