import React, { useEffect } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HomeLayout from "./Home/HomeLayout";
import { ApplicationPaths } from "./api-authorization/ApiAuthorizationConstants";
import ApiAuthorizationRoutes from "./api-authorization/ApiAuthorizationRoutes";
import { LoginMenu } from "./api-authorization/LoginMenu";
import Unauthorised from "./api-authorization/Unauthorised";
import AuthorizeRoute from "./api-authorization/AuthorizeRoute";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import callApiAnonymous from "./Helpers/callApi";
import {
    ApplicationInsights,
    SeverityLevel,
} from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";
import { ToastProvider } from "./Context/ToastContext";
import ReportAnIssue from "./Shared/ReportAnIssue";
import NotFound from "./api-authorization/NotFound";
import MenuSideBar from "./Shared/MenuSideBar";

function App() {
    useEffect(() => {
        const fetchData = async () => {
            const response = await callApiAnonymous(`configuration`);
            let configuration = await response.json();
            initAppInsights(configuration);
        };

        fetchData();
    });

    const initAppInsights = (configuration) => {
        if (configuration.instrumentationKey == null) return;
        const browserHistory = createBrowserHistory({ basename: "" });
        const reactPlugin = new ReactPlugin();
        const appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: configuration.instrumentationKey,
                enableAutoRouteTracking: true,
                disableFetchTracking: false,
                enableAjaxErrorStatusText: true,
                crossOrigin: "anonymous",
                extensions: [reactPlugin],
                extensionConfig: {
                    [reactPlugin.identifier]: { history: browserHistory },
                },
            },
        });
        appInsights.loadAppInsights();
        appInsights.trackPageView();

        let consoleErrorOriginal = console.error;
        console.error = function (data) {
            appInsights.trackTrace({
                message: data,
                severityLevel: SeverityLevel.Error,
            });
            consoleErrorOriginal(data);
        };
    };

    return (
        <>
            {" "}
            <Router>
                <ToastProvider>
                    <div className="outer-container d-flex">
                        <div className="navigation">
                            <div className="logo-and-close">
                                <div className="logo"></div>
                                <i className="close bi bi-x-circle" id="navigationMobileToggle"></i>
                            </div>
                            <nav>
                                <MenuSideBar></MenuSideBar>
                            </nav>
                        </div>
                        <div className="inner-container">
                            <header className="header">
                                <i className="toggle-menu bi bi-list" id="navigationToggle"></i>
                                <div class="app-info">
                                    <span class="name">REX</span>
                                    <span class="tag-line">Data Application</span>
                                </div>
                                {/*<div className="app-info">*/}
                                {/*    <div className="select-portfolio">*/}
                                {/*        <form action="/sessions" method="get">*/}
                                {/*            <span>Change Portfolio</span>*/}
                                {/*            <select className="select-menu" name="portfolio">*/}

                                {/*            </select>*/}
                                {/*        </form>*/}
                                {/*    </div>*/}
                                {/*</div>*/}

                                <LoginMenu />
                                <ReportAnIssue />
                            </header>
                            <div className="content">
                                <div className="flex-full-height">
                                    <div className="page-content" id="page-content">
                                        <Switch>
                                            <Route
                                                path={ApplicationPaths.ApiAuthorizationPrefix}
                                                component={ApiAuthorizationRoutes}
                                            />
                                            <Route path={"/unauthorised"}>
                                                <Unauthorised />
                                            </Route>
                                            <Route path={"/not-found"}>
                                                <NotFound />
                                            </Route>
                                            <AuthorizeRoute path="/" component={HomeLayout} />
                                        </Switch>
                                    </div>
                                </div>
                            </div>
                            <footer>
                                <span className="copyright">Copyright REX.</span>
                                <img
                                    src="images/logos/icon-logo.svg"
                                    alt="REX"
                                    className="logo"
                                />
                            </footer>
                        </div>
                    </div>
                </ToastProvider>
            </Router>
        </>
    );
}

window.addEventListener("DOMContentLoaded", (event) => {
    // toggle the navigation
    const sidebarToggle = document.body.querySelector("#navigationToggle");
    if (sidebarToggle) {
        sidebarToggle.addEventListener("click", (event) => {
            event.preventDefault();
            document.body.classList.toggle("navigation-toggled");
            localStorage.setItem(
                "sb|navigation-toggle",
                document.body.classList.contains("navigation-toggled")
            );
        });
    }
    const sidebarMobileToggle = document.body.querySelector("#navigationMobileToggle");
    if (sidebarMobileToggle) {
        sidebarMobileToggle.addEventListener("click", (event) => {
            event.preventDefault();
            document.body.classList.toggle("navigation-toggled");
            localStorage.setItem(
                "sb|navigation-toggle",
                document.body.classList.contains("navigation-toggled")
            );
        });
    }
});

export default withAITracking(ReactPlugin, App);
