import React from "react";
import Badge from "react-bootstrap/Badge";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import Collapsible from "../Shared/Collapsible";

export default function ChangeRequestQuantitativeRecordChanges(props) {
  const { changeSet, portfolioId, activityId } = props;

  return (
    <>
      {changeSet.quantativeRecords.length > 0 && (
        <>
          <Collapsible
            label="Quantitative Records"
            className="collapsible"
            badge={(
              <>
                <Badge className="badge inline success">
                  {changeSet.quantativeRecordsAdded} New
                </Badge>
                <Badge className="badge inline info">
                  {changeSet.quantativeRecordsUpdated} Update
                </Badge>
              </>
            )}
          >
            <div className="mt-4">
              {changeSet.quantativeRecords.map((r) => (
                <>

                  <Collapsible
                    label={r.resultTitle + " > " + r.indicatorTitle + " > " + r.periodTitle}
                    className="collapsible-basic"
                    badge={(
                      <Badge className="badge inline info">
                        Total Target:{r.totalPlanned + "  "}  Total Achieved:{
                          r.totalActual
                        }
                      </Badge>
                    )}
                  >

                    <div className="mt-3">
                      {r.records.map((ri) => (

                        <Collapsible
                          label={("Target: " + ri.targetValue + " Achieved: " + ri.value)}
                          className="collapsible-basic"
                          eventKey={ri.resultTitle}
                          badge={(
                            <Badge
                              className="badge inline success me-4"
                            >
                              {ri.id === 0 ? "New" : "Update"}
                            </Badge>
                          )}
                        >

                          <div className="mt-3">
                            {r.id !== 0 && (
                              <Link
                                to={`/${portfolioId}/activities/${activityId}/results/${ri.resultId
                                  }/${ri.indicatorId}`}
                              >
                                {ri.id !== 0 && (
                                  <Badge className="badge info">
                                    {ri.id}
                                  </Badge>
                                )}
                              </Link>
                            )}



                            <Table striped hover>
                              {ri.disaggregationValues.map((dv) => (
                                <tr>
                                  <td>{dv.disaggregation}</td>
                                  <td>{dv.value}</td>
                                </tr>
                              ))}
                            </Table>
                          </div>
                        </Collapsible>


                      ))}
                    </div>

                  </Collapsible>

                  {/* <Accordion className="accordion-flush mt-3">
                    <Card>
                      <Accordion.Toggle
                        as={Card.Header}
                        eventKey="resultIndicator"
                      >
                        <div className="d-flex justify-content-between mt-3">
                          <h6>
                            {r.resultTitle} {">"} {r.indicatorTitle} {">"}{" "}
                            {r.periodTitle}
                          </h6>
                          <div>
                            
                          </div>
                        </div>
                      </Accordion.Toggle>
                      <AccordionCollapse eventKey="resultIndicator">
                        <Card.Body>

                        </Card.Body>
                      </AccordionCollapse>
                    </Card>
                  </Accordion> */}
                </>
              ))}
            </div>
          </Collapsible>
        </>
      )}
    </>
  );
}
