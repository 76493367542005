import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import callApi from "../../Helpers/callApi";

export default function IndicatorPage(props) {
  const { selectedIndicators, portfolioId, onAddIndicator, onRemoveIndicator } =
    props;

  const [searchCriteria, setSearchCriteria] = useState("");
  const [searchedIndicators, setSearchedIndicators] = useState([]);

  const handleSearchCriteriaChange = async (searchCriteria) => {
    setSearchCriteria(searchCriteria);
    const response = await callApi(
      `portfolios/${portfolioId}/allIndicatorsWithShared?searchCriteria=${searchCriteria}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.ok) {
      const jsonResponse = await response.json();
      setSearchedIndicators(jsonResponse);
    }
  };

  const handleIndicatorOnChange = (indicatorId) => {
    if (selectedIndicators.some((x) => x.id === parseInt(indicatorId))) return;

    let associatedIndicator = searchedIndicators.find(
      (x) => x.id === parseInt(indicatorId)
    );

    if (associatedIndicator === null) return;

    onAddIndicator(associatedIndicator);
  };

  const handleRemoveIndicator = (indicatorId) => {
    onRemoveIndicator(indicatorId);
  };

  return (
    <>
      <h4 className="h4 mt-4">Indicator(s)</h4>
      <div className="mb-3">
        <div>
          <span className="badge warning inline-block">
            {selectedIndicators.length} Selected indicators
          </span>
        </div>
        {selectedIndicators !== null ? (
          selectedIndicators.map((i) => (
            <div class="added-values">
                  <span class="badge">
                      {i.title}{" "}
                      <i class="icon bi bi-x-circle" onClick={() => { handleRemoveIndicator(i.id); }}></i>
                  </span>
            </div>
          ))
        ) : (
          <span>No indicators added yet</span>
        )}
      </div>
      <Form.Group className="mb-4">
        <Form.Label>Search for indicators</Form.Label>
        <Form.Control
          onChange={(e) => handleSearchCriteriaChange(e.target.value)}
          type="text"
          placeholder="Start typing the indicator title or description"
        />
      </Form.Group>
      {searchCriteria.length > 0 && (
        <Form.Group>
          <Form.Label>
            <div>Indicators</div>
            <span className="badge success inline-block mt-2">{searchedIndicators.length} indicators found</span>
          </Form.Label>

          <Form.Control
            as="select"
            onChange={(e) => handleIndicatorOnChange(e.target.value)}
          >
            <option disabled selected value="">
              -- select indicator to add --
            </option>
            {searchedIndicators.map((i) => (
              <option key={i.id} value={i.id}>
                {i.title}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      )}
    </>
  );
}
