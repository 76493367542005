import React from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

export default function UpdateSharedActivityStatusConfirmation({
  isOpen,
  onHide,
  onSubmit,
  sharedActivityCurrentStatus,
}) {
  const handleSubmit = (e) => {
    onSubmit();
  };

  const handleCancel = (e) => {
    onHide();
  };

  return (
    <Modal show={isOpen} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>Shared Activity Nesting Status Change</Modal.Title>
        <button
          type="button"
          className="btn-close btn-close-white"
          aria-label="Close"
          onClick={onHide}
        ></button>
      </Modal.Header>
      <Form>
        <Modal.Body>
          {sharedActivityCurrentStatus === true ? (
            <p>
              Restrict allow nesting at nested portfolio users can't see the
              activities.
            </p>
          ) : (
            <p>Allow nesting give nested portfolio users guest access.</p>
          )}
          Are you really want to allow nesting?
        </Modal.Body>
        <Modal.Footer>
          <Button className="button primary" onClick={handleSubmit}>
            Remove
          </Button>
          <Button className="button tertiary" onClick={handleCancel}>
            Cancel
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
