import React from "react";

const ManageActivityPopover = React.forwardRef((props, ref) => {
    return (
        <>
            {props.removeVisible === false && (
                <div class="icon-and-text has-left-border" onClick={props.onClickDelete}>
                    <i class="icon bi-trash"></i>Delete
                </div>
            )}
            {props.removeVisible === false && (
                <div class="icon-and-text has-left-border" onClick={props.onClickEdit}>
                    <i class="icon bi-pencil-square"></i>Edit
                </div>
            )}
            {props.isUserPortfolioOwner === true &&
                props.removeVisible === false && (
                    <div class="icon-and-text has-left-border" onClick={props.onClickShare}>
                        <i class="icon bi-share"></i>Share
                    </div>
                )}
            {props.removeVisible === true && (
                <>
                    <div class="icon-and-text has-left-border" onClick={props.onClickRemove}>
                        <i class="icon bi-trash"></i>Remove
                    </div>

                    <div class="icon-and-text has-left-border" onClick={props.onClickGotoHome}>
                        <i class="icon bi-trash"></i>Home Portfolio
                    </div>
                </>
            )}
        </>
    );
});

export default ManageActivityPopover;
