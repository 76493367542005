import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import callApi from "../../Helpers/callApi";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";

export default function ShareDisaggregationModal({
  disaggregation,
  isOpen,
  onHide,
  onSubmit,
  onSetPortfolioUniqueTitle,
}) {
  const [isValidated, setIsValidated] = useState(false);

  const handleSubmit = (e) => {
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      onSubmit();
      setIsValidated(false);
    }
  };

  const handleCancel = (e) => {
    onSetPortfolioUniqueTitle(null);
    setIsValidated(false);
    onHide();
  };

  //typeahead changes
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const handleSearch = async (query) => {
    setIsLoading(true);
    const SEARCH_URI = `portfolios/allportfoliotitle`;
    const response = await callApi(
      `${SEARCH_URI}?portfolioUniqueTitle=${query}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const result = await response.json();
    const options = result.map((i) => ({
      avatar_url: i.uniqueTitle,
      id: i.id,
      login: i.uniqueTitle,
    }));

    setOptions(options);
    setIsLoading(false);
  };

  const handleChange = async (selected) => {
    if (selected.length > 0) {
      onSetPortfolioUniqueTitle(selected[0].login);
      setIsValidated(true);
    } else {
      onSetPortfolioUniqueTitle(null);
      setIsValidated(false);
    }
  };

  // Bypass client-side filtering by returning `true`. Results are already
  // filtered by the search endpoint, so no need to do it again.
  const filterBy = () => true;

  return (
    <Modal show={isOpen} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>Share Disaggregation</Modal.Title>
        <button
          type="button"
          className="btn-close btn-close-white"
          aria-label="Close"
          onClick={onHide}
        ></button>
      </Modal.Header>
      <Form>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Disaggregation to be shared:</Form.Label>
            <Form.Label>
              {disaggregation ? disaggregation.title : null}
            </Form.Label>
          </Form.Group>
          <Form.Group>
            <Form.Label>Share with Portfolio:</Form.Label>
            <AsyncTypeahead
              filterBy={filterBy}
              id="async-example"
              isLoading={isLoading}
              labelKey="login"
              minLength={3}
              onSearch={handleSearch}
              onChange={handleChange}
              options={options}
              placeholder="Search for portfolio unique title"
              renderMenuItemChildren={(option, props) => (
                <>
                  <span>{option.login}</span>
                </>
              )}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="button primary"
            onClick={handleSubmit}
            disabled={isValidated === false}
          >
            Share
          </Button>
          <Button className="button tertiary" onClick={handleCancel}>
            Cancel
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
