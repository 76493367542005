import React from "react";
import Accordion from "react-bootstrap/Accordion";
//import AccordionCollapse from "react-bootstrap/AccordionCollapse";
import { useRouteMatch, Link } from "react-router-dom";
import { ListGroup } from "react-bootstrap";
import { Card } from "react-bootstrap";
import Button from "react-bootstrap/Button";

export default function NestedPortfolioActivity({
  item,
  setRequestActivityId,
  setRequestPortfolioId,
  setRequestAccessModalConfirmationOpen,
  setRequestAccessModalConfirmationOpenForPortfolio,
}) {
  const match = useRouteMatch();
  const handleActivityRequestClick = (activityId) => {
    setRequestActivityId(activityId);
    setRequestAccessModalConfirmationOpen(true);
  };

  const handlePortfolioRequestClick = (portfolioId) => {
    setRequestPortfolioId(portfolioId);
    setRequestAccessModalConfirmationOpenForPortfolio(true);
  };

  return (
    <div key={item}>
      <p clasName="mt-4 mb-3">
        Included portfolio: <span className="description inline">{item.portfolioViewModel.title}</span>
      </p>
      <div>
        <div style={{ marginBottom: "10px" }}></div>
        <div>
          <Link
            className="icon-and-text"
            to={`${match.url}/${item.groupActivityViewModel.id}`}
          ><i class="icon bi bi-arrow-right-circle"></i>
            {item.groupActivityViewModel.title}
          </Link>
          <div style={{ marginBottom: "10px" }}></div>
        </div>
        {item.activityViewModels.length > 0 && (
          <>
            <p className="description mt-4 mb-4">This portfolio</p>
            <ListGroup>
              {item.activityViewModels.map((activityItem) =>
                activityItem.allowNesting === true ? (
                  <ListGroup.Item className="d-flex w-100 justify-content-between ps-0">
                    <Link to={`${match.url}/${activityItem.id}`}>
                      {activityItem.title}
                    </Link>
                  </ListGroup.Item>
                ) : (
                  <ListGroup.Item className="d-flex w-100 justify-content-between ps-0">
                    <span>
                      You do not have access to this activity.
                      <Link
                        onClick={() =>
                          handleActivityRequestClick(activityItem.id)
                        }
                      >
                        Contact owner to request access.
                      </Link>
                    </span>
                  </ListGroup.Item>
                )
              )}
            </ListGroup>
          </>
        )}
        {item.nestedPortfolioViewModels.length > 0 && (
          <div>
            <span>
              <strong>Included portfolio(s): </strong>
            </span>
            <Accordion>
              {item.nestedPortfolioViewModels.map((innerItem, index1) => (
                <>
                  {innerItem.hasAccess ? (
                    <Card>
                      <Card.Header>
                        <Accordion.Toggle
                          as={Button}
                          variant="link"
                          eventKey={index1}
                        >
                          {innerItem.portfolioViewModel.title}
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey={index1}>
                        <Card.Body>
                          <NestedPortfolioActivity
                            item={innerItem}
                            setRequestActivityId={setRequestActivityId}
                            setRequestPortfolioId={setRequestPortfolioId}
                            setRequestAccessModalConfirmationOpen={
                              setRequestAccessModalConfirmationOpen
                            }
                            setRequestAccessModalConfirmationOpenForPortfolio={
                              setRequestAccessModalConfirmationOpenForPortfolio
                            }
                          ></NestedPortfolioActivity>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  ) : (
                    <Card>
                      <span>
                        You do not have access to this portfolio.
                        <Link
                          onClick={() =>
                            handlePortfolioRequestClick(
                              innerItem.portfolioViewModel.id
                            )
                          }
                        >
                          Contact owner to request access or change nesting type
                          to open{" "}
                        </Link>
                      </span>
                    </Card>
                  )}
                </>
              ))}
            </Accordion>
          </div>
        )}
      </div>
    </div>
  );
}
