import React, { useState, useEffect } from "react";
import useResultService from "../Services/useResultService";
import format from "../Helpers/formatNumber";
import moment from "moment";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import Chart from "../Shared/Chart";
import Collapsible from "../Shared/Collapsible";
import { useParams } from "react-router-dom";

export default function QuantativeIndicatorListItem({
  indicator,
  result,
  viewType,
  indicatorUrl,
  isUserActivityOwner,
  isUserPortfolioOwner,
  isSharedActivity,
  onRemoveIndicator,
}) {
  const [totals, setTotals] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [indicatorLastUpdateDate, setIndicatorLastUpdateDate] = useState(null);
  const { getIndicatorTotals, getIndicatorLastUpdateDate } = useResultService();
  const { portfolioId } = useParams();
  useEffect(() => {
    const fetchResults = async () => {
      const response = await getIndicatorTotals(
        result.id,
        indicator.id,
        portfolioId
      );
      if (response.ok) {
        setTotals(response.data);
        const chartData = response.data.periodTotals.map((x) => ({
          ...x,
          periodTitle: x.title
            ? x.title
            : `${moment(x.start).format("Do MMM YYYY")} - ${moment(
                x.end
              ).format("Do MMM YYYY")}`,
        }));
        setChartData(chartData);
      }
    };

    fetchResults();
  }, [result.id, indicator.id, getIndicatorTotals, portfolioId]);

  useEffect(() => {
    const fetchResults = async () => {
      const response = await getIndicatorLastUpdateDate(
        indicator.id,
        portfolioId
      );
      if (response.ok) {
        setIndicatorLastUpdateDate(response.data.lastUpdated);
      }
    };

    fetchResults();
  }, [indicator.id, getIndicatorLastUpdateDate, portfolioId]);

  return (
    <div>
      {viewType === "graphical" && (
        <>
          <Collapsible label={indicator.title} className="collapsible-basic">
            <div>
              <div className="mt-4 mb-4">
                <Link to={indicatorUrl} className="icon-and-text">
                  <i class="icon bi bi-card-list"></i>
                  View Indicator
                </Link>
                {(isUserActivityOwner || isUserPortfolioOwner) &&
                  !isSharedActivity && (
                    <div
                      className="icon-and-text has-left-border"
                      onClick={onRemoveIndicator}
                    >
                      <i class="icon bi bi-trash"></i>Remove
                    </div>
                  )}
              </div>
              {indicatorLastUpdateDate != null && (
                <div className="last-updated mb-3">
                  Last Updated{" "}
                  {moment(indicatorLastUpdateDate).format("Do MMM YYYY HH:mm")}
                </div>
              )}

              <div className="d-flex flex-column flex-grow-1">
                <div className="totals mt-4">
                  <div>
                    <span>Total Planned:</span>{" "}
                    {format(totals.totalTargetValue)}
                  </div>
                  <div>
                    <span>Total Achieved: </span>
                    {format(totals.totalActualValue)}
                  </div>
                </div>
              </div>
              <div className="totals-chart">
                <Chart
                  data={chartData}
                  line1DataKey="totalTargetValue"
                  line2DataKey="totalActualValue"
                  line1Name="Total Planned Value"
                  line2Name="Total Achieved Value"
                  xAxisDataKey="periodTitle"
                />
              </div>
            </div>
          </Collapsible>
        </>
      )}
      {viewType === "tabular" && (
        <>
          <Collapsible label={indicator.title} className="collapsible-basic">
            <div>
              <div className="mt-4 mb-4">
                <Link to={indicatorUrl} className="icon-and-text">
                  <i class="icon bi bi-card-list"></i>
                  View Indicator
                </Link>
                {(isUserActivityOwner || isUserPortfolioOwner) &&
                  !isSharedActivity && (
                    <div
                      className="icon-and-text has-left-border"
                      onClick={onRemoveIndicator}
                    >
                      <i class="icon bi bi-trash"></i>Remove
                    </div>
                  )}
              </div>
              {indicatorLastUpdateDate != null && (
                <div className="mt-3 mb-2">
                  <small className="text-secondary">
                    Last Updated{" "}
                    {moment(indicatorLastUpdateDate).format(
                      "Do MMM YYYY HH:mm"
                    )}
                  </small>
                </div>
              )}
              <Table size="sm" responsive bordered className="my-2">
                <thead>
                  <tr>
                    <th />
                    <th className="border-left border-right bg-light">Total</th>
                    {(totals.periodTotals || []).map((pt) => (
                      <th
                        key={pt.periodId}
                        className="text-center;"
                        title={
                          moment(pt.start).format("Do MMM YYYY") +
                          " - " +
                          moment(pt.end).format("Do MMM YYYY")
                        }
                      >
                        {pt.title
                          ? pt.title
                          : moment(pt.start).format("MM/YY") +
                            " - " +
                            moment(pt.end).format("MM/YY")}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-secondary">Planned</td>
                    <td className="border-left border-right bg-light">
                      {format(totals.totalTargetValue)}
                    </td>
                    {(totals.periodTotals || []).map((pt) => (
                      <td key={pt.periodId}>{format(pt.totalTargetValue)}</td>
                    ))}
                  </tr>
                  <tr>
                    <td className="text-secondary">Achieved</td>
                    <td className="border-left border-right bg-light">
                      {format(totals.totalActualValue)}
                    </td>
                    {(totals.periodTotals || []).map((pt) => (
                      <td key={pt.periodId}>{format(pt.totalActualValue)}</td>
                    ))}
                  </tr>
                </tbody>
              </Table>
            </div>
          </Collapsible>
        </>
      )}
    </div>
  );
}
