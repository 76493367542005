import React, { useState, useEffect } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import ActivityList from "./ActivityList/ActivityList";
import ResultsPage from "./ResultsPage/ResultsPage";
import IndicatorsPage from "./IndicatorsPage/IndicatorsPage";
import DisaggregationsPage from "./DisaggregationsPage/DisaggregationsPage";
import { useParams, useHistory } from "react-router-dom";
import Settings from "./Settings";
import ExportData from "./ExportData";
import usePortfolioOwnerStatus from "../Hooks/usePortfolioOwnerStatus";
import usePortfolioGuestStatus from "../Hooks/usePortfolioGuestStatus";
import EditPortfolioModal from "./EditPortfolioModal";
import DeletePortfolioModal from "./DeletePortfolioModal";
import usePortfolioService from "../Services/usePortfolioService";
import RelationListForPortfolio from "../Activity/Relations/RelationListForPortfolio";
import callApi from "../Helpers/callApi";
import TheoryOfChange from "../Portfolio/TheoryOfChange/TheoryOfChange";
import { useSWRApi } from "../Helpers/callApi";
import { mutate } from "swr";
import EvidenceListAtPortfolioView from "./Evidence/EvidenceListAtPortfolioView";

export default function PortfolioDetails() {
  const { portfolioId, tabKey } = useParams();
  const history = useHistory();
  //const [allActivities, setAllActivities] = useState([]);
  const [expandedGroups, setExpandedGroups] = useState([]);
  const [portfolioEdited, setPortfolioEdited] = useState(false);
  const [nestedPortfolioActivities, setNestedPortfolioActivities] = useState(
    []
  );
  const [allIndicatorsTogether, setAllIndicatorsTogether] = useState([]);
  const [isIndicatorAdded, setIsIndicatorAdded] = useState(false);
  const [nestedPortfolioRemovedorAdded, setNestedPortfolioRemovedorAdded] =
    useState(false);
  //const [newActivityAddedorEdited, setNewActivityAddedorEdited] =    useState(false);
  //const [evidence, setEvidence] = useState([]);
  const [groupActivity, setGroupActivity] = useState({
    id: "",
    title: "",
  });
  const [
    sharedActivityOrNestedPortfolioEvidences,
    setSharedActivityOrNestedPortfolioEvidences,
  ] = useState([]);

  const [indicatorsSharedAtPortfolio, setIndicatorsSharedAtPortfolio] =
    useState([]);
  const [
    sharedActivityOrNestedPortfolioIndicators,
    setSharedActivityOrNestedPortfolioIndicators,
  ] = useState([]);
  const [sharedIndicatorRemoved, setSharedIndicatorRemoved] = useState(false);

  let { data: resultsData, isLoading: isLoadingResults } = useSWRApi(
    `portfolios/${portfolioId}/allresults`
  );

  let { data: activitiesData, isLoading: isLoadingActivities } = useSWRApi(
    `portfolios/allactivities/${portfolioId}`
  );

  let {
    data: nestedPortfoliosColorData,
    isLoading: isLoadingNestedPortfolioColors,
  } = useSWRApi(`portfolios/nestedPortfolioscolor/${portfolioId}`);

  let { data: evidenceData, isLoading: isLoadingEvidence } = useSWRApi(
    `portfolios/${portfolioId}/evidence`
  );

  activitiesData = activitiesData ? activitiesData.activities : [];
  nestedPortfoliosColorData = nestedPortfoliosColorData
    ? nestedPortfoliosColorData
    : [];
  let evidences = evidenceData ? evidenceData : [];

  let results = resultsData
    ? resultsData.map((r) => ({
      ...r,
      expanded: expandedGroups.some((g) => g.id === r.id),
    }))
    : [];

  useEffect(() => {
    const fetchData = async () => {
      const response = await callApi(
        `portfolios/${portfolioId}/SharedActivityOrNestedPortfolioEvidences`
      );
      const indicatorResponse = await response.json();
      setSharedActivityOrNestedPortfolioEvidences(indicatorResponse);
    };

    fetchData();
  }, [portfolioId]);

  useEffect(() => {
    const fetchData = async () => {
      const responseGroupActivity = await callApi(
        `portfolios/groupactivity/${portfolioId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const resultGroupActivity = await responseGroupActivity.json();
      setGroupActivity(resultGroupActivity);
    };
    fetchData();
  }, [portfolioId, portfolioEdited]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await callApi(`portfolios/${portfolioId}/allIndicators`);
      const indicatorsResponse = await response.json();
      setAllIndicatorsTogether(indicatorsResponse);
    };

    fetchData();
  }, [portfolioId, isIndicatorAdded]);

  useEffect(() => {
    if (!tabKey) {
      history.replace(`/${portfolioId}/activities`);
    }
  }, [history, portfolioId, tabKey]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await callApi(
        `portfolios/${portfolioId}/getnestedportfolios`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();

      setNestedPortfolioActivities(data);
    };

    fetchData();
  }, [portfolioId, nestedPortfolioRemovedorAdded]);

  const [portfolio, setPortfolio] = useState({});
  const [disaggregations, setDisaggregations] = useState([]);
  const [isEditPortfolioModalOpen, setIsEditPortfolioModalOpen] =
    useState(false);
  const isUserPortfolioOwner = usePortfolioOwnerStatus();
  const isUserPortfolioGuest = usePortfolioGuestStatus();
  const { getPortfolio, updatePortfolio, getDisaggregations, deletePortfolio } =
    usePortfolioService();
  const [isDeletePortfolioModalOpen, setIsDeletePortfolioModalOpen] =
    useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getPortfolio(portfolioId);
      setPortfolio(response.data);
    };

    fetchData();
  }, [portfolioId, getPortfolio]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getDisaggregations(portfolioId);
      setDisaggregations(response.data);
    };

    fetchData();
  }, [portfolioId, getDisaggregations]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await callApi(
        `portfolios/${portfolioId}/sharedindicators`
      );
      const indicatorResponse = await response.json();
      setIndicatorsSharedAtPortfolio(indicatorResponse);
    };

    fetchData();
  }, [portfolioId, sharedIndicatorRemoved]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await callApi(
        `portfolios/${portfolioId}/SharedActivityOrNestedPortfolioIndicators`
      );
      const indicatorResponse = await response.json();
      setSharedActivityOrNestedPortfolioIndicators(indicatorResponse);
    };

    fetchData();
  }, [portfolioId, sharedIndicatorRemoved]);

  const handleEditPortfolio = async (title, uniqueTitle, description) => {
    const response = await updatePortfolio(
      portfolioId,
      title,
      uniqueTitle,
      description
    );

    if (response.ok) {
      setPortfolio(response.data);
      setIsEditPortfolioModalOpen(false);
      setPortfolioEdited(!portfolioEdited);
    }
  };

  const handleDeletePortfolio = async () => {
    const response = await deletePortfolio(portfolioId);

    if (response.ok) {
      history.replace("/");
      window.location.href = "/";
    }
  };

  const handleAddDisaggregation = (disaggregation) => {
    setDisaggregations((prevState) => [...prevState, disaggregation]);
  };

  const handleRemoveDisaggregation = (disaggregation) => {
    setDisaggregations((prevState) =>
      prevState.filter((d) => d.id !== disaggregation.id)
    );
  };

  const handleEditDisaggregation = (updatedDisaggregation) => {
    setDisaggregations((prevState) =>
      prevState.map((i) => {
        if (i.id === updatedDisaggregation.id) {
          return updatedDisaggregation;
        }
        return i;
      })
    );
  };

  const handleExpandGroup = (result) => {
    setExpandedGroups((prevState) => {
      if (prevState.some((r) => r.id === result.id)) {
        return prevState.filter((r) => r.id !== result.id);
      } else {
        return [...prevState, result];
      }
    });
  };

  const handleAddResult = () => {
    mutate(`portfolios/${portfolioId}/allresults`);
    mutate(`portfolios/allactivities/${portfolioId}`);
  };

  const handleRemoveResult = () => {
    mutate(`portfolios/${portfolioId}/allresults`);
    mutate(`portfolios/allactivities/${portfolioId}`);
  };

  const handleModifySubResults = () => {
    mutate(`portfolios/${portfolioId}/allresults`);
    mutate(`portfolios/allactivities/${portfolioId}`);
  };

  return (
    <>
      <div className="page-title">
        <div className="title">
          <h1 className="h1">{portfolio.title}</h1>
          {isUserPortfolioOwner && (
            <span className="inline-block mt-1">Unique Title: {portfolio.uniqueTitle}</span>
          )}
        </div>
        <div className="actions">
          <button
            onClick={() => setIsEditPortfolioModalOpen(true)}
            className="button tertiary with-icon"
          >
            <i class="icon bi bi-folder-minus"></i>Edit Portfolio
          </button>
          <button
            onClick={() => setIsDeletePortfolioModalOpen(true)}
            className="button tertiary with-icon"
          >
            <i class="icon bi bi-folder-x"></i>Delete Portfolio
          </button>
        </div>
      </div>

      {/*<h2 class="h2">Activities in {portfolio.title} </h2>
      <p>{portfolio.description}</p>*/}
      <Tabs
        activeKey={tabKey}
        onSelect={(tabKey) => history.push(`${tabKey}`)}
        id="portfolio-page-tabs"
      >
        <Tab eventKey="activities" title="Activities">
          <ActivityList
            groupActivity={groupActivity}
            nestedPortfolioActivities={nestedPortfolioActivities}
            isUserPortfolioOwner={isUserPortfolioOwner}
            portfolio={portfolio}
            setNestedPortfolioRemovedorAdded={setNestedPortfolioRemovedorAdded}
            nestedPortfolioRemovedorAdded={nestedPortfolioRemovedorAdded}
          />
        </Tab>
        {(isUserPortfolioOwner || isUserPortfolioGuest) && (
          <Tab eventKey="results" title="Results">
            <ResultsPage portfolioId={portfolioId} />
          </Tab>
        )}
        {(isUserPortfolioOwner || isUserPortfolioGuest) && (
          <Tab eventKey="evidence" title="Evidence">
            <EvidenceListAtPortfolioView
              evidence={evidences}
              sharedActivityOrNestedPortfolioEvidences={
                sharedActivityOrNestedPortfolioEvidences
              }
              isLoadingEvidence={isLoadingEvidence}
              isUserPortfolioOwner={isUserPortfolioOwner}
            ></EvidenceListAtPortfolioView>
          </Tab>
        )}
        {(isUserPortfolioOwner || isUserPortfolioGuest) && (
          <Tab eventKey="theory-of-change" title="Theory of Change">
            <TheoryOfChange
              results={results}
              portfolioId={portfolioId}
              activities={activitiesData}
              isLoadingActivities={isLoadingActivities}
              isLoadingResults={isLoadingResults}
              isLoadingNestedPortfolioColors={isLoadingNestedPortfolioColors}
              nestedPortfoliosColorData={nestedPortfoliosColorData}
              onExpandGroup={handleExpandGroup}
              onRemoveResult={handleRemoveResult}
              onAddResult={handleAddResult}
              onRemoveSubResult={handleModifySubResults}
              onModifySubResults={handleModifySubResults}
            />
          </Tab>
        )}

        {(isUserPortfolioOwner || isUserPortfolioGuest) && (
          <Tab eventKey="relations" title="Relations">
            <RelationListForPortfolio
              portfolioId={portfolioId}
              portfolio={portfolio}
              nestedPortfolioActivities={nestedPortfolioActivities}
            />
          </Tab>
        )}
        {(isUserPortfolioOwner || isUserPortfolioGuest) && (
          <Tab eventKey="indicators" title="Indicators">
            <IndicatorsPage
              portfolioId={portfolioId}
              disaggregations={disaggregations}
              isUserPortfolioOwner={isUserPortfolioOwner}
              allIndicatorsTogether={allIndicatorsTogether}
              setIsIndicatorAdded={setIsIndicatorAdded}
              isIndicatorAdded={isIndicatorAdded}
              indicatorsSharedAtPortfolio={indicatorsSharedAtPortfolio}
              sharedActivityOrNestedPortfolioIndicators={
                sharedActivityOrNestedPortfolioIndicators
              }
              setSharedIndicatorRemoved={setSharedIndicatorRemoved}
              sharedIndicatorRemoved={sharedIndicatorRemoved}
            />
          </Tab>
        )}
        {(isUserPortfolioOwner || isUserPortfolioGuest) && (
          <Tab eventKey="disaggregations" title="Disaggregations">
            <DisaggregationsPage
              disaggregations={disaggregations}
              portfolioId={portfolioId}
              onAddDisaggregation={handleAddDisaggregation}
              onRemoveDisaggregation={handleRemoveDisaggregation}
              onEditDisaggregation={handleEditDisaggregation}
              isUserPortfolioOwner={isUserPortfolioOwner}
              sharedIndicatorRemoved={sharedIndicatorRemoved}
            />
          </Tab>
        )}
        {isUserPortfolioOwner && (
          <Tab eventKey="export-data" title="Export Data">
            <ExportData portfolioId={portfolioId} />
          </Tab>
        )}
        {isUserPortfolioOwner && (
          <Tab eventKey="settings" title="Settings">
            <Settings
              setNestedPortfolioRemovedorAdded={
                setNestedPortfolioRemovedorAdded
              }
              nestedPortfolioRemovedorAdded={nestedPortfolioRemovedorAdded}
            />
          </Tab>
        )}
      </Tabs>
      {isEditPortfolioModalOpen && (
        <EditPortfolioModal
          portfolio={portfolio}
          isOpen={isEditPortfolioModalOpen}
          onHide={() => setIsEditPortfolioModalOpen(false)}
          onSubmit={handleEditPortfolio}
        />
      )}

      <DeletePortfolioModal
        portfolio={portfolio}
        isOpen={isDeletePortfolioModalOpen}
        onHide={() => setIsDeletePortfolioModalOpen(false)}
        onSubmit={handleDeletePortfolio}
      />
    </>
  );
}
