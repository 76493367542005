import React from "react";
import Accordion from "react-bootstrap/Accordion";
import Badge from "react-bootstrap/Badge";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import AccordionCollapse from "react-bootstrap/AccordionCollapse";
import Collapsible from "../Shared/Collapsible";

export default function ChangeRequestQualtitativeRecordChanges(props) {
  const { changeSet, portfolioId, activityId } = props;
  return (
    <>
      {changeSet.qualitativeRecords.length > 0 && (
        <>
          <Collapsible
            label="Qualitative Records"
            className="collapsible"
            badge={
              <>
                <Badge className="badge inline success">
                  {changeSet.qualitativeRecordsAdded} New
                </Badge>
                <Badge className="badge inline info">
                  {changeSet.qualitativeRecordsUpdated} Update
                </Badge>
              </>
            }
          >
            <div className="mt-4">
              {changeSet.qualitativeRecords.map((r) => (
                <>
                  <Collapsible
                    label={
                      r.resultTitle +
                      " > " +
                      r.indicatorTitle +
                      " > " +
                      r.periodTitle
                    }
                    className="collapsible-basic"
                  >
                    <div className="mt-3">
                      {r.records.map((ri) => (
                        <Accordion className="accordion-flush">
                          <Accordion.Toggle
                            eventKey={ri.resultTitle}
                            as={Card.Header}
                          >
                            <div className="d-flex justify-content-between">
                              <div className="d-flex flex-column">
                                <i>Target</i>
                                <div>{ri.targetValue}</div>
                              </div>
                              <div className="d-flex flex-column">
                                <i>Achieved</i>
                                <div>{ri.value}</div>
                              </div>
                              <div>
                                {r.id !== 0 && (
                                  <Link
                                    to={`/${portfolioId}/activities/${activityId}/results/${ri.resultId}/${ri.indicatorId}`}
                                    className="ml-2"
                                  >
                                    {ri.id !== 0 && (
                                      <Badge className="badge badge-light">
                                        {ri.id}
                                      </Badge>
                                    )}
                                  </Link>
                                )}
                                <Badge variant="secondary" className="ml-2">
                                  {ri.id === 0 ? "New" : "Update"}
                                </Badge>
                              </div>
                            </div>
                          </Accordion.Toggle>
                          <AccordionCollapse eventKey={ri.resultTitle}>
                            <Table striped hover>
                              {ri.disaggregationValues.map((dv) => (
                                <tr>
                                  <td>{dv.disaggregation}</td>
                                  <td>{dv.value}</td>
                                </tr>
                              ))}
                            </Table>
                          </AccordionCollapse>
                        </Accordion>
                      ))}
                    </div>
                  </Collapsible>
                </>
              ))}
            </div>
          </Collapsible>
        </>
      )}
    </>
  );
}
