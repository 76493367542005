import React from "react";
export function IndicatorSummaryTableRowTotalComponent({
  allPeriods,
  totalOfAllTargetValueOfThisPeriod,
  totalOfAllArchievedValueOfThisPeriod,
  totalTargetValueForAllPeriods,
  totalAchievedValueForAllPeriods,
  isPlannedVisible,
  isAchievedVisible,
  selectedDisaggregations,
  disaggregations,
  uniquePeriodTitles,
}) {
  return (
    <tr>
      <td style={{ verticalAlign: "middle" }}></td>
      <td style={{ verticalAlign: "middle" }}></td>
      <td style={{ verticalAlign: "middle" }}>
        <strong>Total</strong>
      </td>
      <td>
        <table>
          <tr>
            {isPlannedVisible ? (
              <td style={{ border: "none" }}>
                <strong>Planned</strong>
              </td>
            ) : (
              <td style={{ border: "none" }}></td>
            )}
          </tr>
          <tr>
            {isAchievedVisible ? (
              <td style={{ border: "none" }}>
                <strong>Achieved</strong>
              </td>
            ) : (
              <td style={{ border: "none" }}></td>
            )}
          </tr>
        </table>
      </td>
      {uniquePeriodTitles.map((period, periodIndex) => (
        <>
          <td>
            <table>
              <tr>
                {isPlannedVisible ? (
                  <td style={{ border: "none" }}>
                    <strong>{totalOfAllTargetValueOfThisPeriod(period)}</strong>
                  </td>
                ) : (
                  <td style={{ border: "none" }}></td>
                )}
              </tr>
              <tr>
                {isAchievedVisible ? (
                  <td style={{ border: "none" }}>
                    <strong>
                      {totalOfAllArchievedValueOfThisPeriod(period)}
                    </strong>
                  </td>
                ) : (
                  <td style={{ border: "none" }}></td>
                )}
              </tr>
            </table>
          </td>
        </>
      ))}
      <td>
        {
          <table>
            <tr>
              {isPlannedVisible ? (
                <td style={{ border: "none" }}>
                  <strong> {totalTargetValueForAllPeriods()}</strong>
                </td>
              ) : (
                <td style={{ border: "none" }}></td>
              )}
            </tr>
            <tr>
              {isAchievedVisible ? (
                <td style={{ border: "none" }}>
                  <strong>{totalAchievedValueForAllPeriods()}</strong>
                </td>
              ) : (
                <td style={{ border: "none" }}></td>
              )}
            </tr>
          </table>
        }
      </td>
    </tr>
  );
}
