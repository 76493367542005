import React, { useState } from "react";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import callApi from "../Helpers/callApi";
import { Link } from "react-router-dom";

export default function ReportAnIssue() {
  const [isReportIssueModalOpen, setIsReportIssueModalOpen] = useState(false);
  const [issueText, setIssueText] = useState("");

  const handleModalHide = () => {
    setIsReportIssueModalOpen(false);
    setIssueText("");
  };

  const handleSubmit = async () => {
    await callApi(`issues`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(issueText),
    });

    handleModalHide();
  };

  return (
    <>
      <Nav.Item
        as={() => (
          <div className="utility-link report-issue">
            <Link
              href="#"
              className="link"
              onClick={() => setIsReportIssueModalOpen(true)}
            >
              <i className="bi bi-envelope-at icon"></i>
              <span className="link-text">Report an Issue</span>
            </Link>
          </div>
        )}
      ></Nav.Item>
      <Modal show={isReportIssueModalOpen} onHide={handleModalHide}>
        <Modal.Header>
          <Modal.Title>Report an Issue</Modal.Title>
          <button
            type="button"
            className="btn-close btn-close-white"
            aria-label="Close"
            onClick={handleModalHide}
          ></button>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-4" controlId="issue">
              <Form.Label>
                Your report will be sent on to the developement team
              </Form.Label>
              <Form.Control
                onChange={(e) => setIssueText(e.target.value)}
                as="textarea"
                rows={5}
                placeholder="Please describe the issue you are experiencing"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="button primary"
            disabled={!issueText.length > 0}
            onClick={handleSubmit}
          >
            Report
          </Button>
          <Button className="button tertiary" onClick={handleModalHide}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
