import React from "react";

const EditButton = (props) => (
  <div className="icon-and-text" {...props}>
    <i className="icon bi bi-pencil-square"></i>
    Edit
  </div>
);

export default EditButton;
