import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import moment from "moment";

export default function EditPeriodModal({ isOpen, onHide, onSubmit, period }) {
  const [title, setTitle] = useState(period.title);
  const [narrative, setNarrative] = useState(period.narrative);
  const [start, setStart] = useState(period.start);
  const [end, setEnd] = useState(period.end);
  const [isAggregatable, setIsAggregatable] = useState(period.isAggregatable);
  const [documentLink, setDocumentLink] = useState(period.documentLink);

  const [validationMessage, setValidationMessage] = useState(null);

  const handleHide = () => {
    reset();
    onHide();
  };

  const reset = () => {
    setTitle(period.title);
    setNarrative(period.narrative);
    setStart(period.start);
    setEnd(period.end);
    setIsAggregatable(period.isAggregatable);
    setDocumentLink(period.documentLink);
  };

  const handleSubmit = () => {
    onSubmit({
      title,
      narrative,
      start,
      end,
      isAggregatable,
      documentLink,
    });
  };

  const isValidInput = () => {
    if (period == null) return false;

    if (!period.start || !period.end) return false;

    if (period.end < period.start) {
      if (validationMessage === null) {
        setValidationMessage(
          "Please ensure the end date is later than the start date "
        );
      }
      return false;
    }

    if (validationMessage !== null) {
      setValidationMessage(null);
    }
    return true;
  };

  return (
    <Modal show={isOpen} onHide={handleHide}>
      <Modal.Header>
        <Modal.Title>Edit Period</Modal.Title>
        <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={handleHide}></button>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="title">
            <Form.Label>Title (optional)</Form.Label>
            <Form.Control
              onChange={(e) => setTitle(e.target.value)}
              type="text"
              value={title}
              placeholder="e.g. Baseline, End of Activity Lifetime"
            />
          </Form.Group>
          <Form.Group controlId="narrative">
            <Form.Label>Narrative (optional)</Form.Label>
            <Form.Control
              value={narrative}
              onChange={(e) => setNarrative(e.target.value)}
              as="textarea"
              placeholder="Narrative"
            />
          </Form.Group>
          <Form.Group controlId="periodStart">
            <Form.Label>Start Date</Form.Label>
            <Form.Control
              value={start ? moment(start).format("YYYY-MM-DD") : null}
              onChange={(e) => setStart(e.target.value)}
              type="date"
              placeholder="Start Date"
            />
          </Form.Group>
          <Form.Group controlId="periodEnd">
            <Form.Label>End Date</Form.Label>
            <Form.Control
              value={end ? moment(end).format("YYYY-MM-DD") : null}
              onChange={(e) => setEnd(e.target.value)}
              type="date"
              placeholder="End Date"
            />
          </Form.Group>
        </Form>
        <Form.Group controlId="isAggregatable">
          <Form.Check
            type="checkbox"
            checked={isAggregatable}
            onChange={(e) => setIsAggregatable(e.target.checked)}
            label="Is Aggregatable"
          />
        </Form.Group>
        <Form.Group controlId="documentLink">
          <Form.Label>Document Link (optional)</Form.Label>
          <Form.Control
            onChange={(e) => setDocumentLink(e.target.value)}
            type="text"
            value={documentLink}
            placeholder="Document Link"
          />
        </Form.Group>

        {validationMessage && (
          <span className="text-danger">{validationMessage}</span>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="button primary"
          disabled={!isValidInput()}
          onClick={handleSubmit}
        >
          Save
        </Button>
        <Button className="button tertiary" onClick={handleHide}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
