import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { BsPencil, BsPlusCircle, BsTrash } from "react-icons/bs";
import Pager from "../Shared/Pager";
import Form from "react-bootstrap/Form";
import useActivityService from "../Services/useActivityService";

export default function UpdateLog() {
  const pageSize = 20;
  const [page, setPage] = useState(1);
  const [logs, setLogs] = useState([]);
  const { activityId } = useParams();
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [searchCriteria, setSearchCriteria] = useState("");
  const { getLogs } = useActivityService();

  useEffect(() => {
    const fetchData = async () => {
      const response = await getLogs(
        activityId,
        page,
        pageSize,
        searchCriteria
      );
      setLogs(response.data.items);
      setTotalItemCount(response.data.totalItemCount);
    };

    fetchData();
  }, [activityId, getLogs, page, pageSize, searchCriteria]);

  const getIcon = (entityOperationType) => {
    switch (entityOperationType) {
      case "Added":
        return BsPlusCircle;
      case "Updated":
        return BsPencil;
      case "Deleted":
        return BsTrash;
      default:
        return BsPencil;
    }
  };

  return (
    <>
      <h2 className="h2">Update Log</h2>
      <div class="filter-activities mb-5">
        <label class="bold">Find a log</label>
        <Form.Control
          type="text"
          onChange={(e) => {
            setPage(1);
            setSearchCriteria(e.target.value);
          }}
        />
      </div>

      {logs.map((l, i) => (
        <div key={i} className="d-flex">
          <div className="d-flex flex-column align-items-center">
            <div
              style={{ width: 40, height: 40 }}
              className="border rounded-circle d-flex justify-content-center align-items-center"
            >
              <TimelineIcon Icon={getIcon(l.entityOperationType)} />
            </div>
            <div style={{ flex: 1, width: 1, background: "#dee2e6" }} />
          </div>
          <div className="ms-3">
            <p className="description mt-2 mb-2">
              {moment(l.created).format("Do MMM YYYY")}
            </p>
            <p className="mb-2">
              <span className="inline user-text">{l.userName}</span>{" "}
              {l.entityOperationType.toLowerCase()} a record in the{" "}
              <span className="inline description">{l.periodTitle}</span> period
              for result{" "}
              <span className="inline description">{l.resultTitle}</span>,
              indicator{" "}
              <span className="inline description">{l.indicatorTitle}</span>.
            </p>
            <div className="mb-4">
              {l.auditLogValues.map((alv, i) => (
                <div key={i} className="mb-2">
                  {alv.propertyNameFriendly}: <del>{alv.previousValue}</del>{" "}
                  {"->"} {alv.currentValue}
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
      <Pager
        totalItemCount={totalItemCount}
        pageSize={pageSize}
        page={page}
        onPageChanged={setPage}
        className="mt-4"
      />
    </>
  );
}

function TimelineIcon({ Icon }) {
  if (!Icon) return null;
  return <Icon />;
}
