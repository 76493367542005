import React from "react";
import { BarChart, XAxis, YAxis, Tooltip, Bar } from "recharts";

export default function AggregatedGraphs({ aggregatedData }) {
  return (
    <>
      {Object.keys(aggregatedData).map((ad) => (
        <div className="mb-1" key={ad}>
          <h6 className="mb-1">{ad}</h6>
          <BarChart
            key={ad}
            width={700}
            height={250}
            margin={{ top: 15, right: 30, left: 20, bottom: 5 }}
            data={aggregatedData[ad]}
          >
            <XAxis dataKey="disaggregationValue" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="targetValue" fill="#82ca9d" />
            <Bar dataKey="value" fill="#8884d8" />
          </BarChart>
        </div>
      ))}
    </>
  );
}
