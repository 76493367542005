import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

export default function AddResultModal(props) {
  const { isOpen, onHide, onSubmit } = props;
  const [newResultTitle, setNewResultTitle] = useState("");
  const [newResultDescription, setNewResultDescription] = useState("");
  const [newResultLabels, setNewResultLabels] = useState([]);
  const [otherLabels, setOtherLabels] = useState("");
  const labelOptions = ["Input", "Output", "Outcome", "Impact"];

  const handleLabelChange = (e, label) => {
    if (e.target.checked) {
      setNewResultLabels((prevState) => [...prevState, label]);
    } else if (!e.target.checked) {
      setNewResultLabels((prevState) => prevState.filter((l) => l !== label));
    }
  };

  const combineLabels = () => {
    let newLabelsAsString = "";
    if (newResultLabels.length > 0) {
      newLabelsAsString = newResultLabels.join();
      return newLabelsAsString + "," + otherLabels;
    }

    return otherLabels;
  };

  const resetState = () => {
    setNewResultTitle("");
    setNewResultDescription("");
    setNewResultLabels([]);
    setOtherLabels("");
  };

  const handleHide = () => {
    onHide();
    resetState();
  };

  const handleSubmit = () => {
    onSubmit(newResultTitle, newResultDescription, combineLabels());
    handleHide();
  };

  return (
    <Modal show={isOpen} onHide={handleHide} backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>Add Result</Modal.Title>
        <button
          type="button"
          className="btn-close btn-close-white"
          aria-label="Close"
          onClick={handleHide}
        ></button>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-4" controlId="resultTitle">
            <Form.Label>Title</Form.Label>
            <Form.Control
              onChange={(e) => setNewResultTitle(e.target.value)}
              type="text"
              placeholder="Result title"
            />
          </Form.Group>
          <Form.Group className="mb-4" controlId="resultDescription">
            <Form.Label>Description</Form.Label>
            <Form.Control
              onChange={(e) => setNewResultDescription(e.target.value)}
              as="textarea"
              rows="3"
              placeholder="Result description"
            />
          </Form.Group>
          <Form.Group className="mb-4" controlId="resultLabels">
            <Form.Label>Labels</Form.Label>
            {labelOptions.map((l) => (
              <Form.Check
                type="checkbox"
                key={l}
                id={l}
                label={l}
                onChange={(e) => handleLabelChange(e, l)}
              />
            ))}
            <Form.Control
              onChange={(e) => setOtherLabels(e.target.value)}
              className="mt-4"
              type="text"
              placeholder="Other labels separated by a ','"
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="button primary" onClick={handleSubmit}>
          Add Result
        </Button>
        <Button className="button tertiary" onClick={handleHide}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
