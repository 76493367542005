import React from "react";
import { ReactComponent as NotFoundGraphic } from "./NotFound.svg";

const NotFound = () => {
  return (
    <div className="pt-5 d-flex flex-column align-items-center">
      <NotFoundGraphic />
      <h3 className="mt-5">
        The requested resource could not be found or may have been deleted.
      </h3>
    </div>
  );
};

export default NotFound;
