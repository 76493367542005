import React from "react";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";

export default function SearchedDisaggregationsList(props) {
  const { disaggregations, onCopyDisaggregation, searchTerm } = props;

  const highlight = (text) => {
    const parts = text.split(new RegExp(`(${searchTerm})`, "gi"));
    return (
      <>
        {parts.map((p) => {
          if (p.toLowerCase() === searchTerm.toLowerCase()) {
            return <mark className="p-0">{p}</mark>;
          }
          return p;
        })}
      </>
    );
  };

  return (
    <>
      {disaggregations.length ? (
        <ListGroup>
          {disaggregations.map((d) => (
            <ListGroup.Item>
              <div className="d-flex w-100 justify-content-between">
                <h5>{highlight(d.title)}</h5>
                <Button
                  onClick={() => onCopyDisaggregation(d)}
                  variant="outline-primary"
                >
                  Add to portfolio
                </Button>
              </div>
              <h6 className="text-muted">Description</h6>
              <p>{d.description}</p>
              <h6 className="text-muted">Values</h6>
              {d.disaggregationValues.length ? (
                <ul>
                  {d.disaggregationValues.map((v) => (
                    <li>{v.value}</li>
                  ))}
                </ul>
              ) : (
                <em className="text-secondary">
                  This disaggregation has no values
                </em>
              )}
            </ListGroup.Item>
          ))}
        </ListGroup>
      ) : (
        <div className="mt-4">
          {!searchTerm || searchTerm.length === 0
            ? ""
            : "No disaggregations found"}
        </div>
      )}
    </>
  );
}
