import React from "react";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import { isNode, isEdge } from "react-flow-renderer";
import RemoveButton from "../../Shared/RemoveButton";
import Badge from "react-bootstrap/Badge";
import RelationSidebarContent from "./RelationSidebarContent";
import ResultSidebarContent from "./ResultSidebarContent";
import { Link } from "react-router-dom";

export default function Sidebar({
  selectedElements,
  results,
  onAddResultClick,
  onExpandGroup,
  onDeleteGroup,
  onSelectGroup,
  onRemoveSubResult,
  onAddToGroupClick,
  onAddToGroupOptionClick,
  handleDeleteEvidence,
  onClickLinkEvidence,
  onClickUnlinkEvidence,
  handleEvidenceIsJustifiedChanged,
}) {
  return (
    <div
      className="shadow"
      style={{
        position: "absolute",
        height: "100%",
        top: 56,
        right: 0,
        bottom: 0,
        width: selectedElements && selectedElements.length > 0 ? 350 : 0,
        transition: "width 0.5s",
        overflow: "hidden",
        zIndex: 999,
      }}
    >
      <div
        className="py-5 px-3"
        style={{
          height: "100%",
          width: selectedElements && selectedElements.length > 0 ? 350 : 0,
          position: "absolute",
          zIndex: 11,
          top: 56,
          right: selectedElements && selectedElements.length > 0 ? 0 : -350,
          overflowX: "hidden",
          transition: "0.5s",
          background: "white",
          visibility: selectedElements ? "visible" : "hidden",
        }}
      >
        {selectedElements && (
          <>
            {/*
            <Button
              variant="outline-secondary"
              onClick={onAddToGroupOptionClick}
            >
              Add to group
            </Button>
            */}
            {/*selectedElements.filter((e) => isNode(e)).length >= 2 && (
              <>
                <h3>Selected Results</h3>
                <Button
                  onClick={onAddResultClick}
                  variant="primary"
                  className="mr-2"
                >
                  Create Group
                </Button>
              </>
            )*/}
            {selectedElements.filter((e) => isEdge(e)).length === 0 && (
              <Button
                variant="outline-secondary"
                onClick={onAddToGroupOptionClick}
              >
                Add to group
              </Button>
            )}
          </>
        )}
        {selectedElements &&
          selectedElements.length > 0 &&
          results &&
          results.length > 0 &&
          selectedElements
            .filter((e) => isNode(e))
            .map((e) => {
              let result = results.find((r) => r.id === parseInt(e.id));
              if (!result) return null;
              return (
                <>
                  <Card key={result.id} className="p-2 mt-2">
                    {result.activityTitle != null && (
                      <>
                        <div className="font-weight-bold text-secondary">
                          Activity
                        </div>
                        <p>
                          <Link
                            to={(location) => ({
                              ...location,
                              pathname: `activities/${result.activityId}`,
                            })}
                          >
                            {result.activityTitle}
                          </Link>
                        </p>
                      </>
                    )}
                    <div className="font-weight-bold text-secondary">
                      Result
                    </div>
                    <p>{result.title}</p>
                    {result.parentResults &&
                      result.parentResults.length > 0 && (
                        <>
                          <div className="font-weight-bold text-secondary pb-2">
                            This result is member of the group
                          </div>
                          <ListGroup>
                            {result.parentResults.map((pr) => (
                              <ListGroup.Item key={pr.id}>
                                <p className="m-0">{pr.title}</p>
                                <div className="d-flex w-100 mt-2 justify-content-end">
                                  <Button
                                    variant="outline-secondary"
                                    onClick={() => onExpandGroup(pr)}
                                  >
                                    {"Collapse to group"}
                                  </Button>
                                </div>
                              </ListGroup.Item>
                            ))}
                          </ListGroup>
                        </>
                      )}
                    {result.subResults && result.subResults.length > 0 && (
                      <>
                        <div className="font-weight-bold text-secondary">
                          This result is parent to a group
                        </div>
                        <Button
                          onClick={() => onExpandGroup(result)}
                          variant="outline-secondary"
                        >
                          {result.expanded
                            ? "Collapse to group"
                            : "Expand group to members"}
                        </Button>
                      </>
                    )}
                    {result.subResults && result.subResults.length > 0 && (
                      <>
                        <div className="font-weight-bold text-secondary pb-2">
                          Sub Results
                        </div>
                        <ListGroup>
                          {result.subResults.map((sr) => (
                            <ListGroup.Item key={sr.id}>
                              <div className="d-flex justify-content-between">
                                <p className="m-0">{sr.title}</p>
                                <RemoveButton
                                  onClick={() => onRemoveSubResult(result, sr)}
                                />
                              </div>
                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                        {/*
                          <div className="d-flex w-100 mt-2 justify-content-end">
                          <Button
                            className="ml-1"
                            onClick={() => onDeleteGroup(result)}
                            variant="outline-danger"
                          >
                            Delete group
                          </Button>
                          </div>*/}
                      </>
                    )}
                    {result.activityTitle != null && (
                      <>
                        <div className="font-weight-bold text-secondary">
                          Description
                        </div>
                        <p>{result.description}</p>
                        <div className="font-weight-bold text-secondary">
                          Labels
                        </div>
                        <p>
                          {result.labels &&
                            result.labels.split(",").map((l, i) => (
                              <span key={i} className="pl-1">
                                <Badge variant="secondary">{l}</Badge>
                              </span>
                            ))}
                        </p>
                        <div className="font-weight-bold text-secondary">
                          Document Link
                        </div>
                        <p>{result.documentLink}</p>
                        <ResultSidebarContent
                          result={results.find(
                            (r) => r.id === parseInt(result.id)
                          )}
                        />
                      </>
                    )}
                  </Card>
                </>
              );
            })}
        {selectedElements &&
          selectedElements.map((e, i) => {
            return isEdge(e) && results.length ? (
              <RelationSidebarContent
                key={i}
                fromResult={results.find((r) => r.id === parseInt(e.source))}
                toResult={results.find((r) => r.id === parseInt(e.target))}
                edge={e.data.relationId}
                evidenceList={e.data.evidence}
                evidenceIsJustified={e.data.isJustified}
                onClickLinkEvidence={onClickLinkEvidence}
                onClickUnlinkEvidence={onClickUnlinkEvidence}
                handleDeleteEvidence={handleDeleteEvidence}
                handleEvidenceIsJustifiedChanged={
                  handleEvidenceIsJustifiedChanged
                }
              />
            ) : null;
          })}
      </div>
    </div>
  );
}
