import { useCallback } from "react";
import callApi from "../Helpers/callApi";
import { useAddToast } from "../Context/ToastContext";

export default function useActivityService() {
  // useCallback needed to memoise toast hook
  const addToast = useCallback(useAddToast(), []);

  // useCallback needed to memoise fetchers used in useEffect hooks
  const getActivity = useCallback(
    async (activityId) => {
      const response = await callApi(`activities/${activityId}`);
      const data = await response.json();

      if (!response.ok) {
        addToast({
          variant: "error",
          message: "There was an error fetching the activity",
        });
      }
      return { status: response.status, ok: response.ok, data };
    },
    [addToast]
  );

  const editActivity = async (activity) => {
    let response = await callApi(`activities/${activity.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(activity),
    });
    const data = await response.json();

    if (!response.ok) {
      addToast({
        variant: "error",
        message: "There was an error editing the activity",
      });
    }

    return { status: response.status, ok: response.ok, data };
  };

  const deleteActivity = async (activity) => {
    const response = await callApi(`activities/${activity.id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      addToast({
        variant: "error",
        message: "There was an error deleting the activity",
      });
    }

    return { status: response.status, ok: response.ok };
  };

  // useCallback needed to memoise fetchers used in useEffect hooks
  const getResults = useCallback(
    async (activityId, portfolioId) => {
      const response = await callApi(
        `activities/${activityId}/results/${portfolioId}`
      );
      const data = await response.json();

      if (!response.ok) {
        addToast({
          variant: "error",
          message: "There was an error fetching the results",
        });
      }
      return { status: response.status, ok: response.ok, data };
    },
    [addToast]
  );

  const getResultsWithIndicators = useCallback(
    async (activityId, portfolioId) => {
      const response = await callApi(
        `activities/${activityId}/resultandindicators/${portfolioId}`
      );
      const data = await response.json();

      if (!response.ok) {
        addToast({
          variant: "error",
          message: "There was an error fetching the results",
        });
      }
      return { status: response.status, ok: response.ok, data };
    },
    [addToast]
  );

  const createResult = async (activityId, title, description, labels) => {
    const response = await callApi(`activities/${activityId}/results`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        title,
        description,
        labels,
        activityId,
      }),
    });
    const data = await response.json();

    if (!response.ok) {
      addToast({
        variant: "error",
        message: "There was an error creating this result",
      });
    }

    return { status: response.status, ok: response.ok, data };
  };

  const getUsers = useCallback(
    async (activityId, portfolioId) => {
      const response = await callApi(
        `activities/${activityId}/users/${portfolioId}`
      );
      const data = await response.json();

      if (!response.ok) {
        addToast({
          variant: "error",
          message: "There was an error fetching the users for this activity",
        });
      }

      return { status: response.status, ok: response.ok, data };
    },
    [addToast]
  );

  const getLogs = useCallback(
    async (activityId, page, pageSize, searchCriteria) => {
      const response = await callApi(
        `activities/${activityId}/logs?page=${page}&pagesize=${pageSize}&searchCriteria=${searchCriteria}`
      );
      const data = await response.json();

      if (!response.ok) {
        addToast({
          variant: "error",
          message: "There was an error fetching the logs for this activity",
        });
      }

      return { status: response.status, ok: response.ok, data };
    },
    [addToast]
  );

  const addUser = async (activityId, email, role) => {
    const response = await callApi(`activities/${activityId}/users`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        role,
      }),
    });
    const data = await response.json();

    if (!response.ok) {
      addToast({
        variant: "error",
        message: data.errors[0].description,
      });
    }

    return { status: response.status, ok: response.ok, data };
  };

  const removeUser = async (activityId, userId) => {
    const response = await callApi(`activities/${activityId}/users/${userId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      addToast({
        variant: "error",
        message: "There was an error removing the user from the activity",
      });
    }

    return { status: response.status, ok: response.ok };
  };

  const getEvidence = useCallback(
    async (activityId) => {
      const response = await callApi(`activities/${activityId}/evidence`);
      const data = await response.json();

      if (!response.ok) {
        addToast({
          variant: "error",
          message: "There was an error fetching the evidence for this activity",
        });
      }

      return { status: response.status, ok: response.ok, data };
    },
    [addToast]
  );

  const createEvidence = async (
    activityId,
    title,
    type,
    summary,
    comments,
    documentLink,
    produced,
    validFrom,
    validTo,
    relationId
  ) => {
    const response = await callApi(`activities/${activityId}/evidence`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        title,
        type,
        summary,
        comments,
        documentLink,
        produced,
        validFrom,
        validTo,
        relationId,
        activityId,
      }),
    });
    const data = await response.json();

    if (!response.ok) {
      addToast({
        variant: "error",
        message: "There was an error creating the evidence",
      });
    }

    return { status: response.status, ok: response.ok, data };
  };

  const createChangeRequest = async (activityId, formData) => {
    const response = await callApi(`activities/${activityId}/change-requests`, {
      method: "POST",
      body: formData,
      headers: {},
    });
    const data = await response.json();

    if (!response.ok) {
      addToast({
        variant: "error",
        message: "There was an error creating the change request",
      });
    }

    return { status: response.status, ok: response.ok, data };
  };

  const getExport = useCallback(
    async (activityId, format) => {
      const response = await callApi(
        `activities/${activityId}/exportData?format=${format}`
      );
      const data = await response.blob();

      if (!response.ok) {
        addToast({
          variant: "error",
          message: "There was an error exporting the data for this activity",
        });
      }

      return { status: response.status, ok: response.ok, data };
    },
    [addToast]
  );

  const getLogFrameExport = useCallback(
    async (activityId) => {
      const response = await callApi(
        `activities/${activityId}/exportLogFrameData`
      );
      const data = await response.blob();

      if (!response.ok) {
        addToast({
          variant: "error",
          message: "There was an error exporting the data for this activity",
        });
      }

      return { status: response.status, ok: response.ok, data };
    },
    [addToast]
  );

  const getAggregatedExport = useCallback(
    async (activityId) => {
      const response = await callApi(
        `activities/${activityId}/exportAggregatedData`
      );
      const data = await response.blob();

      if (!response.ok) {
        addToast({
          variant: "error",
          message: "There was an error exporting the data for this activity",
        });
      }

      return { status: response.status, ok: response.ok, data };
    },
    [addToast]
  );

  const getExportTemplate = useCallback(
    async (activityId) => {
      const response = await callApi(
        `activities/${activityId}/exportTemplateData`
      );
      const data = await response.blob();

      if (!response.ok) {
        addToast({
          variant: "error",
          message: "There was an error exporting the data for this activity",
        });
      }

      return { status: response.status, ok: response.ok, data };
    },
    [addToast]
  );

  return {
    getActivity,
    editActivity,
    deleteActivity,
    getResults,
    getResultsWithIndicators,
    createResult,
    getUsers,
    getLogs,
    addUser,
    removeUser,
    getEvidence,
    createEvidence,
    createChangeRequest,
    getExport,
    getAggregatedExport,
    getLogFrameExport,
    getExportTemplate,
  };
}
