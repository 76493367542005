import { useCallback } from "react";
import callApi from "../Helpers/callApi";
import { useAddToast } from "../Context/ToastContext";

export default function useEvidenceService() {
  // useCallback needed to memoise toast hook
  const addToast = useCallback(useAddToast(), []);

  // useCallback needed to memoise fetchers used in useEffect hooks
  const getEvidence = useCallback(
    async (evidenceId, portfolioId) => {
      const response = await callApi(
        `evidence/${evidenceId}?portfolioId=${portfolioId}`
      );
      const data = await response.json();

      if (!response.ok) {
        addToast({
          variant: "error",
          message: "There was an error fetching the evidence",
        });
      }

      return { ok: response.ok, status: response.status, data };
    },
    [addToast]
  );

  const updateEvidence = async (evidence) => {
    const response = await callApi(`evidence/${evidence.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(evidence),
    });

    if (!response.ok) {
      addToast({
        variant: "error",
        message: "There was an error editing the evidence",
      });
    }

    return response;
  };

  const deleteEvidence = async (evidenceId) => {
    const response = await callApi(`evidence/${evidenceId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });

    /*if (!response.ok) {
      addToast({
        variant: "error",
        message: "There was an error deleting the evidence",
      });
    }*/

    return response;
  };

  const updateEvidenceRelation = async (evidence, evidenceRelation) => {
    const response = await callApi(
      `relations/${evidenceRelation.relationId}/evidence/${evidence.id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(evidenceRelation),
      }
    );

    if (!response.ok) {
      addToast({
        variant: "error",
        message: "There was an error updating the evidenced relation",
      });
    }

    return response;
  };

  const unlinkRelation = async (evidenceId, relationId) => {
    const response = await callApi(
      `relations/${relationId}/evidence/${evidenceId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      addToast({
        variant: "error",
        message: "There was an error unlinking the relation",
      });
    }

    return response;
  };

  const unlinkIndicator = async (evidenceId, indicatorId) => {
    const response = await callApi(
      `evidence/${evidenceId}/indicator/${indicatorId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      addToast({
        variant: "error",
        message: "There was an error unlinking the indicator",
      });
    }

    return response;
  };

  return {
    getEvidence,
    updateEvidence,
    deleteEvidence,
    updateEvidenceRelation,
    unlinkRelation,
    unlinkIndicator,
  };
}
