import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import authService from "../api-authorization/AuthorizeService";
import usePortfolioService from "../Services/usePortfolioService";

export default function usePortfolioGuestStatus() {
  const [isGuest, setIsGuest] = useState(false);
  const { portfolioId } = useParams();
  const { getUsers } = usePortfolioService();

  useEffect(() => {
    const fetchResults = async () => {
      const user = await authService.getUser();
      const response = await getUsers(portfolioId);

      if (response.ok) {
        setIsGuest(
          response.data.some((u) => u.userId === user.sub && u.role === "guest")
        );
      }
    };

    if (!portfolioId) {
      setIsGuest(false);
    } else {
      fetchResults();
    }
  }, [portfolioId, getUsers]);

  return isGuest;
}
