import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import AddUserModal from "../Shared/AddUserModal";
import usePortfolioService from "../Services/usePortfolioService";
import callApi from "../Helpers/callApi";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import UpdateNestedPortfolioStatusConfirmation from "./ActivityList/UpdateNestedPortfolioStatusConfirmation";
import RemoveNestedPortfolioModalConfirmation from "./ActivityList/RemoveNestedPortfolioModalConfirmation";
import { mutate } from "swr";

export default function Settings({
  setNestedPortfolioRemovedorAdded,
  nestedPortfolioRemovedorAdded,
}) {
  const [users, setUsers] = useState([]);
  const [parentPortfolios, setParentPortfolios] = useState([]);
  const [statusChangePortfolioId, setStatusChangePortfolioId] = useState(0);
  const [nestingPortfolioCurrentStatus, setNestingPortfolioCurrentStatus] =
    useState("");
  const [
    isRemoveingNestingPortfolioModalOpenConfirmation,
    setIsRemoveingNestingPortfolioModalOpenConfirmation,
  ] = useState(false);
  const [nestingPortfolioIdForRemove, setNestingPortfolioIdForRemove] =
    useState(null);

  const [showStatusUpdateModal, setShowStatusUpdateModal] = useState(false);
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [isRemoveUserModalOpen, setIsRemoveUserModalOpen] = useState(false);
  const [userToBeRemoved, setUserToBeRemoved] = useState(null);
  const [portfolioRemoved, setPortfolioRemoved] = useState(false);
  const [portfolioRemovingFrom, setPortfolioRemovingFrom] = useState("");
  const { portfolioId } = useParams();
  const { getUsers, addUser, removeUser } = usePortfolioService();

  useEffect(() => {
    const fetchData = async () => {
      const response = await getUsers(portfolioId);
      if (response.ok) setUsers(response.data);
    };

    fetchData();
  }, [portfolioId, getUsers]);

  useEffect(() => {
    const fetchData = async () => {
      let response = await callApi(
        `portfolios/${portfolioId}/getportfoliosnestedwith`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setParentPortfolios(data);
      }
    };

    fetchData();
  }, [portfolioId, portfolioRemoved, nestedPortfolioRemovedorAdded]);

  const handleAddUser = async (email, role) => {
    const response = await addUser(portfolioId, email, role);
    if (response.ok) {
      setUsers((prevState) => [...prevState, response.data]);
      setIsAddUserModalOpen(false);
    }
  };

  const handleRemoveClick = (user) => {
    setUserToBeRemoved(user);
    setIsRemoveUserModalOpen(true);
  };

  const handleRemoveUserModalClose = () => {
    setUserToBeRemoved(null);
    setIsRemoveUserModalOpen(false);
  };

  const handleRemoveUser = async () => {
    const response = await removeUser(portfolioId, userToBeRemoved.userId);

    if (response.ok) {
      setUsers((prevState) =>
        prevState.filter((u) => u.userId !== userToBeRemoved.userId)
      );
    }

    handleRemoveUserModalClose();
  };

  const handleNestingStatusChange = async (item) => {
    setNestingPortfolioCurrentStatus(item.nestingStatus);
    setStatusChangePortfolioId(item.portfolioViewModel.id);
    setShowStatusUpdateModal(true);
  };

  const hideStatusUpdateModal = () => {
    setNestingPortfolioCurrentStatus("");
    setStatusChangePortfolioId(0);
    setShowStatusUpdateModal(false);
  };

  const handleStatusUpdate = async () => {
    let response = await callApi(
      `portfolios/${statusChangePortfolioId}/changeNestingPortfolioStatus/${portfolioId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.ok) {
      setPortfolioRemoved(!portfolioRemoved);
      setNestedPortfolioRemovedorAdded(!nestedPortfolioRemovedorAdded);
      hideStatusUpdateModal();
    }
  };

  const handleRemovePortfolio = async (item) => {
    setNestingPortfolioIdForRemove(item.portfolioViewModel.id);
    setPortfolioRemovingFrom(item.portfolioViewModel.title);
    setIsRemoveingNestingPortfolioModalOpenConfirmation(true);
  };

  const handleRemovePortfolioConfirmation = async () => {
    let shareResponse = await callApi(
      `portfolios/${nestingPortfolioIdForRemove}/${portfolioId}/removenesting`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (shareResponse.ok) {
      setPortfolioRemoved(!portfolioRemoved);
      setNestedPortfolioRemovedorAdded(!nestedPortfolioRemovedorAdded);
      hideRemovePortfolioModal();
      mutate(`portfolios/${portfolioId}/allresults`);
    }
  };

  const hideRemovePortfolioModal = () => {
    setIsRemoveingNestingPortfolioModalOpenConfirmation(false);
    setNestingPortfolioIdForRemove(null);
    setPortfolioRemovingFrom("");
  };

  return (
    <>
      <h2 className="h2">Settings</h2>
      <div className="actions mb-4">
        <div
          className="icon-and-text"
          onClick={() => setIsAddUserModalOpen(true)}
        >
          <i class="icon bi bi-person-plus"></i>
          Add user
        </div>
      </div>
      <Table size="sm">
        <thead>
          <tr>
            <th>Email</th>
            <th>Role</th>
            <th>Remove</th>
          </tr>
        </thead>
        <tbody>
          {users.map((u) => (
            <tr key={u.email}>
              <td>{u.email}</td>
              <td>{u.role}</td>
              <td>
                <button
                  class="icon-and-text"
                  onClick={() => handleRemoveClick(u)}
                >
                  <i class="icon bi bi-x-circle"></i>
                  Remove User
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {parentPortfolios.length > 0 && (
        <div>
          <Row>
            <Col sm={12}>
              <span>
                <strong>
                  This portfolio is nested in these portfolios. Users of these
                  portfolios will be able see the contents of this portfolio as
                  guests
                </strong>
              </span>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <span>
                <strong>Portfolio</strong>
              </span>
            </Col>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            {parentPortfolios.map((eachItem) => (
              <>
                <Col sm={4}>{eachItem.portfolioViewModel.title}</Col>
                <Col sm={4}>
                  <span>
                    <strong style={{ color: "red" }}>
                      Onward nesting is "
                      {eachItem.nestingStatus === "Yes" ? "on" : "off"}"
                    </strong>
                    <Link onClick={() => handleNestingStatusChange(eachItem)}>
                      {"[change]"}
                    </Link>
                  </span>
                </Col>
                <Col sm={4}>
                  <Link onClick={() => handleRemovePortfolio(eachItem)}>
                    {"Remove from nesting in this portfolio"}{" "}
                  </Link>
                </Col>
              </>
            ))}
          </Row>
        </div>
      )}

      <AddUserModal
        isOpen={isAddUserModalOpen}
        onHide={() => setIsAddUserModalOpen(false)}
        onSubmit={handleAddUser}
      />
      <UpdateNestedPortfolioStatusConfirmation
        nestingPortfolioCurrentStatus={nestingPortfolioCurrentStatus}
        isOpen={showStatusUpdateModal}
        onHide={hideStatusUpdateModal}
        onSubmit={handleStatusUpdate}
      />
      <RemoveNestedPortfolioModalConfirmation
        isOpen={isRemoveingNestingPortfolioModalOpenConfirmation}
        onSubmit={handleRemovePortfolioConfirmation}
        onHide={hideRemovePortfolioModal}
        portfolioRemovingFrom={portfolioRemovingFrom}
      />

      <Modal show={isRemoveUserModalOpen} onHide={handleRemoveUserModalClose}>
        <Modal.Header>
          <Modal.Title>Remove User</Modal.Title>
          <button
            type="button"
            className="btn-close btn-close-white"
            aria-label="Close"
            onClick={handleRemoveUserModalClose}
          ></button>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to remove this user?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button className="button primary" onClick={handleRemoveUser}>
            Remove User
          </Button>
          <Button
            className="button tertiary"
            onClick={handleRemoveUserModalClose}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
