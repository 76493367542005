import React, { useState, useEffect } from "react";
import authService from "../api-authorization/AuthorizeService";
import Spinner from "react-bootstrap/Spinner";

export default function ExportData(props) {
  const { portfolioId } = props;
  const [format, setFormat] = useState("json");
  const [disabled, setDisabled] = useState(false);
  const [downloadText, setDownloadText] = useState("Download");
  const [exportUrl, setexportUrl] = useState(
    `api/portfolios/${portfolioId}/exportData?format=${format}`
  );
  const [spinnerState, setSpinnerState] = useState("none");
  useEffect(() => {
    setexportUrl(`api/portfolios/${portfolioId}/exportData?format=${format}`);
  }, [exportUrl, format, portfolioId]);

  const downloadData = async () => {
    setDisabled(true);
    setSpinnerState("inline-block");
    setDownloadText("Downloading..");
    const token = await authService.getAccessToken();
    const options = {
      headers: {
        method: "GET",
      },
    };
    options.headers.Authorization = `Bearer ${token}`;
    await fetch(exportUrl, options).then((response) => {
      setDisabled(false);
      setSpinnerState("none");
      setDownloadText("Download");
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.target = "_BLANK";
        a.rel = "noopener noreferrer";
        a.href = url;
        a.download = `exported-data.${format}`;
        a.click();
      });
      //window.location.href = response.url;
    });
  };

  return (
    <>
      <h2 className="h2">Export Data</h2>
      <div className="description mt-4 mb-3">
        Export data in either CSV or JSON format
      </div>
      <div className="filter-activities d-flex">
        <select
          onChange={(e) => setFormat(e.target.value)}
          className="select-menu"
        >
          <option value="json">Select Format</option>
          <option value="csv">CSV</option>
          <option value="json">JSON</option>
        </select>

        <button
          className="button tertiary with-icon ms-2"
          onClick={() => downloadData()}
          disabled={disabled}
        >
          <i class="icon bi bi-cloud-download"></i>
          {downloadText}
        </button>
      </div>
      <Spinner
        style={{ display: spinnerState }}
        className="mt-4"
        as="span"
        animation="grow"
        size="sm"
        role="status"
        aria-hidden="true"
      />
    </>
  );
}
