import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Table from "react-bootstrap/Table";
import moment from "moment";
import {
  BsFillCaretUpFill,
  BsFillCaretDownFill,
  BsDash,
  BsFillXCircleFill,
  BsCheckAll,
  BsCheck,
  BsCircleHalf,
} from "react-icons/bs";
import useResultService from "../Services/useResultService";

export default function QualitativeIndicatorTotalsTable({ result, indicator }) {
  const [summaryData, setSummaryData] = useState(null);
  const { portfolioId, activityId } = useParams();
  const { getIndicatorSummary } = useResultService();

  useEffect(() => {
    const fetchResults = async () => {
      const response = await getIndicatorSummary(result.id, indicator.id);
      if (response.ok) {
        setSummaryData(response.data);
      }
    };

    fetchResults();
  }, [result, indicator, getIndicatorSummary]);

  if (!summaryData) return null;

  if (!summaryData.periodSummaries.length > 0)
    return (
      <>
        <Link
          to={`/${portfolioId}/activities/${activityId}/results/${result.id}/${indicator.id}`}
        >
          {indicator.title}
        </Link>
        <p className="text-secondary">
          There are no periods for this indicator
        </p>
      </>
    );

  return (
    <>
      <Link
        className="mt-3"
        to={`/${portfolioId}/activities/${activityId}/results/${result.id}/${indicator.id}`}
      >
        {indicator.title}
      </Link>
      <Table size="sm" responsive bordered className="my-2 mt-3">
        <thead>
          <tr>
            <th />
            <th>All Periods</th>
            {(summaryData.periodSummaries || []).map((ps) => (
              <th
                key={ps.periodId}
                className="text-center;"
                title={
                  moment(ps.start).format("Do MMM YYYY") +
                  " - " +
                  moment(ps.end).format("Do MMM YYYY")
                }
              >
                {ps.title
                  ? ps.title
                  : moment(ps.start).format("MM/YY") +
                  " - " +
                  moment(ps.end).format("MM/YY")}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Targets</td>
            <td className="bg-light">
              {summaryData.areAllTargetsAchieved
                ? "All achieved"
                : "Not all acheived"}
            </td>
            {(summaryData.periodSummaries || []).map((ps) => (
              <td key={ps.periodId}>
                <div
                  className="d-flex align-items-center"
                  title={ps.isTargetAchieved}
                >
                  {ps.isTargetAchieved != null && ps.isTargetAchieved === 1 && (
                    <BsCheckAll className="text-success" />
                  )}
                  {ps.isTargetAchieved != null && ps.isTargetAchieved === 2 && (
                    <BsCheck />
                  )}
                  {ps.isTargetAchieved != null && ps.isTargetAchieved === 3 && (
                    <BsCircleHalf />
                  )}
                  {ps.isTargetAchieved != null && ps.isTargetAchieved === 4 && (
                    <BsFillXCircleFill className="text-danger" />
                  )}
                  <span className="ml-1">{ps.isTargetAchievedText}</span>
                </div>
              </td>
            ))}
          </tr>
          <tr>
            <td>Trend</td>
            <td className="bg-light" />
            {(summaryData.periodSummaries || []).map((ps) => (
              <td>
                <div
                  className="d-flex align-items-center"
                  title={ps.trendFromPreviousPeriodText}
                >
                  {ps.trendFromPreviousPeriod != null &&
                    ps.trendFromPreviousPeriod === 1 && (
                      <BsFillCaretUpFill className="text-success" />
                    )}
                  {ps.trendFromPreviousPeriod != null &&
                    ps.trendFromPreviousPeriod === 2 && <BsDash />}
                  {ps.trendFromPreviousPeriod != null &&
                    ps.trendFromPreviousPeriod === 3 && (
                      <BsFillCaretDownFill className="text-danger" />
                    )}
                  <span className="ml-1">{ps.trendFromPreviousPeriodText}</span>
                </div>
              </td>
            ))}
          </tr>
          <tr>
            <td>Records</td>
            <td className="bg-light">
              {(summaryData.periodSummaries || []).reduce(
                (acc, ps) => acc + ps.recordCount,
                0
              )}{" "}
              records
            </td>
            {(summaryData.periodSummaries || []).map((ps) => (
              <td>
                <div>{ps.recordCount} records</div>
              </td>
            ))}
          </tr>
        </tbody>
      </Table>
    </>
  );
}
