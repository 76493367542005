import React, { useState } from "react";
import Toast from "react-bootstrap/Toast";

const ToastsStateContext = React.createContext();
const ToastUpdateContext = React.createContext();

function ToastProvider({ children }) {
  const [toasts, setToasts] = useState([]);
  const addToast = (toast) => {
    toast.id = toasts.length;
    setToasts((prevState) => [...prevState, toast]);
  };
  const removeToast = (toast) => {
    setToasts((prevState) => prevState.filter((t) => t.id !== toast.id));
  };

  return (
      <ToastsStateContext.Provider value={toasts}>
      <ToastUpdateContext.Provider value={addToast}>
        <>
          <div
            style={{
              position: "fixed",
              top: 70,
              right: 10,
              zIndex: 100,
            }}
          >
            {toasts.map((t) => (
              <Toast
                className="bg-white"
                onClose={() => removeToast(t)}
                delay={5000}
                autohide
              >
                <Toast.Header>
                  {t.isDanger === false ? (
                    <>
                      <div
                        style={{ width: 15, height: 15 }}
                        className="rounded mr-2"
                      ></div>
                      <strong className="mr-auto">Information</strong>
                    </>
                  ) : (
                    <>
                      <div
                        style={{ width: 15, height: 15 }}
                        className="bg-danger rounded mr-2"
                      ></div>
                      <strong className="mr-auto">Something went wrong</strong>
                    </>
                  )}
                </Toast.Header>
                <Toast.Body>{t.message}</Toast.Body>
              </Toast>
            ))}
          </div>
          {children}
        </>
      </ToastUpdateContext.Provider>
    </ToastsStateContext.Provider>
  );
}

function useToastState() {
  const context = React.useContext(ToastsStateContext);
  if (context === undefined) {
    throw new Error("useToastState must be used within a ToastProvider");
  }
  return context;
}

function useAddToast() {
  const context = React.useContext(ToastUpdateContext);
  if (context === undefined) {
    throw new Error("useToastUpdate must be used within a ToastProvider");
  }
  return context;
}

export { ToastProvider, useToastState, useAddToast };
