import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import callApi from "../../Helpers/callApi";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Button from "react-bootstrap/Button";

export default function ShareActivitiesModal({
  portfolioId,
  isOpen,
  onHide,
  onSubmit,
  showError,
}) {
  const [portfolioExists, setPortfolioExists] = useState(false);
  const [portfolioUniqueTitle, setPortfolioUniqueTitle] = useState(null);
  const [portfolioTitle, setPortfolioTitle] = useState(null);

  const handleOnShareAll = (e) => {
    onSubmit(portfolioUniqueTitle, portfolioTitle);

    //reset();
    //onHide();
  };

  const reset = () => {
    setPortfolioUniqueTitle(null);
    setPortfolioTitle(null);
  };

  const handleCancel = (e) => {
    reset();
    onHide();
  };

  //typeahead changes
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const handleSearch = async (query) => {
    setIsLoading(true);
    const SEARCH_URI = `portfolios/allportfoliotitle`;
    const response = await callApi(
      `${SEARCH_URI}?portfolioUniqueTitle=${query}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const result = await response.json();
    const options = result.map((i) => ({
      avatar_url: i.title,
      id: i.id,
      login: i.uniqueTitle,
    }));

    setOptions(options);
    setIsLoading(false);
  };

  const handleChange = async (selected) => {
    if (selected.length > 0) {
      setPortfolioExists(true);
      setPortfolioUniqueTitle(selected[0].login);
      setPortfolioTitle(selected[0].avatar_url);
    } else {
      setPortfolioExists(false);
      setPortfolioUniqueTitle(null);
      setPortfolioTitle(null);
    }
  };

  // Bypass client-side filtering by returning `true`. Results are already
  // filtered by the search endpoint, so no need to do it again.
  const filterBy = () => true;

  return (
    <Modal show={isOpen} onHide={onHide} backdrop="static" keyboard={false}>
      <Modal.Header onHide={handleCancel}>
        <Modal.Title>Nest Portfolio</Modal.Title>
        <button
          type="button"
          className="btn-close btn-close-white"
          aria-label="Close"
          onClick={handleCancel}
        ></button>
      </Modal.Header>
      <Form>
        <Modal.Body>
          <Form.Group className="mb-4" controlId="activityTitle">
            <Form.Label>Include this portfolio in portfolio:</Form.Label>
            <AsyncTypeahead
              filterBy={filterBy}
              id="async-example"
              isLoading={isLoading}
              labelKey="login"
              minLength={3}
              onSearch={handleSearch}
              onChange={handleChange}
              options={options}
              placeholder="Search for portfolio unique title"
              renderMenuItemChildren={(option, props) => (
                <>
                  <span>{option.login}</span>
                </>
              )}
            />
          </Form.Group>
          {showError && (
            <div style={{ color: "red" }}>
              This portfolio can’t be nested in portfolio [{portfolioTitle}] as
              [{portfolioTitle}] is already nested under this portfolio and this
              would create a circular nesting.
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="button primary"
            onClick={handleOnShareAll}
            disabled={!portfolioExists}
            type="button"
          >
            Include
          </Button>
          <Button className="button tertiary" onClick={handleCancel}>
            Cancel
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
