import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import authService from "../api-authorization/AuthorizeService";
import useActivityService from "../Services/useActivityService";

export default function useActivityOwnerStatus() {
  const [isOwner, setIsOwner] = useState(false);
  const { activityId, portfolioId } = useParams();
  const { getUsers } = useActivityService();

  useEffect(() => {
    const fetchResults = async () => {
      const user = await authService.getUser();
      const response = await getUsers(activityId, portfolioId);

      setIsOwner(
        response.data.some((u) => u.userId === user.sub && u.role === "owner")
      );
    };

    if (!activityId) {
      setIsOwner(false);
    } else {
      fetchResults();
    }
  }, [activityId, getUsers, portfolioId]);

  return isOwner;
}
