import React, { useState, useEffect } from "react";
import useResultService from "../../Services/useResultService";
//import Badge from "react-bootstrap/Badge";
import ListGroup from "react-bootstrap/ListGroup";
import { Link, useParams } from "react-router-dom";
import { BsBoxArrowInDownLeft } from "react-icons/bs";

export default function ResultSidebarContent({ result }) {
  const { portfolioId } = useParams();
  const activityId = result.activityId ?? 0;
  const [indicators, setIndicators] = useState([]);
  const { getIndicators } = useResultService();

  useEffect(() => {
    const fetchData = async () => {
      const response = await getIndicators(result.id, portfolioId);
      if (response.ok) {
        setIndicators(response.data);
      }
    };

    fetchData();
  }, [result, getIndicators, portfolioId]);

  return (
    <>
      <div className="font-weight-bold text-secondary mb-1">Indicators</div>
      <ListGroup>
        {indicators.map((i) => (
          <ListGroup.Item key={i.id}>
            <Link
              to={`/${portfolioId}/activities/${activityId}/results/${result.id}/${i.id}`}
            >
              {i.title}
              <BsBoxArrowInDownLeft className="ml-1" />
            </Link>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </>
  );
}
