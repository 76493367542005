import React from "react";

export default function Pager({
  totalItemCount,
  pageSize,
  page,
  onPageChanged
}) {
  let totalPages = Math.ceil(totalItemCount / pageSize);
  let defaultPageItemClass = "page-item ";
  let disabled = "disabled ";
  let active = "active ";
  let totalPagesToDisplay = 10;

  let viewPageFrom = page - totalPagesToDisplay / 2;
  if (viewPageFrom < 1) {
    viewPageFrom = 1;
  }

  let viewPageTo = viewPageFrom + totalPagesToDisplay - 1;
  if (viewPageTo > totalPages) {
    viewPageTo = totalPages;
  }

  const pages = [];
  for (let i = viewPageFrom; i <= viewPageTo; i++) {
    let className = defaultPageItemClass;
    if (i === page) {
      className += active;
    }
    pages.push({ page: i, className: className });
  }

  return (
    <nav className="mt-4">
      <ul className="pagination">
        <li
          className={
            page === 1 ? defaultPageItemClass + disabled : defaultPageItemClass
          }
        >
          <button className="page-link" onClick={() => onPageChanged(1)}>
            First
          </button>
        </li>
        <li
          className={
            page === 1 ? defaultPageItemClass + disabled : defaultPageItemClass
          }
        >
          <button className="page-link" onClick={() => onPageChanged(page - 1)}>
            Previous
          </button>
        </li>
        {pages.map((p, i) => (
          <li key={i} className={p.className}>
            <button className="page-link" onClick={() => onPageChanged(p.page)}>
              {p.page}
            </button>
          </li>
        ))}
        <li
          className={
            page === totalPages || totalPages === 0
              ? defaultPageItemClass + disabled
              : defaultPageItemClass
          }
        >
          <button className="page-link" onClick={() => onPageChanged(page + 1)}>
            Next
          </button>
        </li>
        <li
          className={
            page === totalPages || totalPages === 0
              ? defaultPageItemClass + disabled
              : defaultPageItemClass
          }
        >
          <button
            className="page-link"
            onClick={() => onPageChanged(totalPages)}
          >
            Last
          </button>
        </li>
      </ul>
    </nav>
  );
}
