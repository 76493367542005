import React from "react";
import QualitativeIndicatorTotalsTable from "./QualitativeIndicatorTotalsTable";
import QuantativeIndicatorTotalsTable from "./QuantativeIndicatorTotalsTable";

export default function IndicatorTotalsTable({ result, indicator }) {
  return indicator.typeId === 1 ? (
    <QualitativeIndicatorTotalsTable result={result} indicator={indicator} />
  ) : (
    <QuantativeIndicatorTotalsTable result={result} indicator={indicator} />
  );
}
