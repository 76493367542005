import React from "react";
import Badge from "react-bootstrap/Badge";
import Table from "react-bootstrap/Table";
import moment from "moment";
import ChangeRequestQuantitativeRecordChanges from "./ChangeRequestQuantitativeRecordChanges";
import ChangeRequestQualtitativeRecordChanges from "./ChangeRequestQualtitativeRecordChanges";
import Collapsible from "../Shared/Collapsible";

export default function ChangeRequestChanges(props) {
  const { changeSet, portfolioId, activityId } = props;

  return (
    <>
      {changeSet.results.added.length > 0 && (
        <>
          <Collapsible
            label={"Results (" + changeSet.results.added.length + ")"}
            className="collapsible"
          >
            <div className="mt-3" eventKey="results">
              <ol className="ol change-requests">
                {changeSet.results.added.map((r) => (
                  <li>
                    {r.title}
                    {r.id === 0 && (
                      <Badge className="badge inline success">
                        New
                      </Badge>
                    )}
                  </li>
                ))}
              </ol>
            </div>
          </Collapsible>
        </>
      )}

      {changeSet.results.added.length > 0 && (
        <>
          <Collapsible
            label="Indicators"
            className="collapsible"
          >
            <div className="mt-3" eventKey="indicators">
              {changeSet.results.added.map((r) => (
                <>
                  <p className="description mt-4">{r.title}</p>
                  <ol className="ol change-requests">
                    {r.indicators.map((i) => (
                      <li>
                        {i.title}
                        {i.id === 0 && (
                          <Badge className="badge inline success">
                            New
                          </Badge>
                        )}
                      </li>
                    ))}
                  </ol>
                </>
              ))}
            </div>
          </Collapsible>
        </>
      )}

      {changeSet.periods.length > 0 && (
        <>
          <Collapsible
            label="Periods"
            className="collapsible"
          >
            <div className="mt-3" eventKey="periods">
              {changeSet.periods.map((p) => (
                <div className="mb-4">
                  <p className="description">
                    {p.resultTitle} {">"} {p.indicatorTitle}
                  </p>
                  {p.periods.map((rip) => (

                    <Collapsible
                      label={p.title
                        ? rip.title
                        : moment(rip.start).format("Do MMM YYYY") +
                        " - " +
                        moment(rip.end).format("Do MMM YYYY")}

                      badge={rip.id === 0 && (
                        <Badge className="badge inline success">New</Badge>
                      )}

                      className="collapsible-basic"
                    >

                      <div eventKey={rip.start} className="mt-3">
                        {rip.records.map((r) => (
                          <Collapsible
                            label={r.indicatorTypeId === 1 ? (
                              <>
                                <div className="d-flex flex-column">
                                  <i>Target</i>
                                  <div>{r.targetValue}</div>
                                </div>
                                <div className="d-flex flex-column">
                                  <i>Achieved</i>
                                  <div>{r.value}</div>
                                </div>
                              </>
                            ) : (
                              <>
                                Quantitative: Target {r.targetValue}{" "}
                                Achieved {r.value}
                              </>
                            )
                            }
                            badge={(
                              <Badge className="badge inline-block success me-4">
                                {r.id === 0 ? "New" : "Update"}
                              </Badge>
                            )}
                            className="collapsible-basic"
                          >

                            <div className="mt-3">
                              <Table striped hover>
                                {r.disaggregationValues.map((dv) => (
                                  <tr>
                                    <td>{dv.disaggregation}</td>
                                    <td>{dv.value}</td>
                                  </tr>
                                ))}
                              </Table>
                            </div>

                          </Collapsible>
                        ))}
                      </div>
                    </Collapsible>
                  ))}
                </div>
              ))}
            </div>
          </Collapsible>
        </>
      )}

      <ChangeRequestQuantitativeRecordChanges
        changeSet={changeSet}
        portfolio={portfolioId}
        activityId={activityId}
      />

      <ChangeRequestQualtitativeRecordChanges
        changeSet={changeSet}
        portfolio={portfolioId}
        activityId={activityId}
      />

      {changeSet.disaggregations.added.length > 0 && (
        <>
          <Collapsible
            label={"Disaggregations (" + changeSet.disaggregations.added.length + ")"}
            className="collapsible"
          >
            <div>
              {changeSet.disaggregations.added.map((d) => (
                <div className="mb-4">

                  <Collapsible
                    label={d.title}
                    className="collapsible"
                    badge=
                    {
                      d.id === 0 && (
                        <Badge className="badge inline success">
                          New
                        </Badge>
                      )
                    }
                  >
                    <div className="mt-3">
                      <ul className="ul">
                        {d.disaggregationValues.map((dv) => (
                          <li>{dv.value}</li>
                        ))}
                      </ul>
                    </div>
                  </Collapsible>
                </div>
              ))}

            </div>
          </Collapsible>
        </>
      )}

      {changeSet.disaggregationValues.added.length > 0 && (
        <>
          <Collapsible
            label={"Disaggregation Values (" + changeSet.disaggregationValues.added.length + " New)"}
            className="collapsible"
          >
            <div className="mt-3">
              <ul className="ul">
                {changeSet.disaggregationValues.added.map((dv) => (
                  <li>
                    {dv.disaggregation} - {dv.value}
                  </li>
                ))}
              </ul>
            </div>
          </Collapsible>
        </>
      )}
    </>
  );
}
