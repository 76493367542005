import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom"; //Link,
import Table from "react-bootstrap/Table";
import callApi from "../Helpers/callApi";
import Breadcrumbs from "../Shared/Breadcrumbs";
//import { BsBoxArrowInDownLeft } from "react-icons/bs";
import IndicatorTotals from "./IndicatorTotals";
import usePortfolioService from "../Services/usePortfolioService";
import useActivityService from "../Services/useActivityService";
import useResultService from "../Services/useResultService";
import usePortfolioOwnerStatus from "../Hooks/usePortfolioOwnerStatus";
import EditButton from "../Shared/EditButton";
import EditEvidenceRelationModal from "../Evidence/EditEvidenceRelationModal";

export default function RelationDetails() {
  const { portfolioId, activityId, relationId } = useParams();
  const [relation, setRelation] = useState(null);
  const [fromResult, setFromResult] = useState(null);
  const [toResult, setToResult] = useState(null);
  const [portfolio, setPortfolio] = useState({});
  const [activity, setActivity] = useState({});
  const { getPortfolio } = usePortfolioService();
  const { getActivity } = useActivityService();
  const { getResult, getIndicators } = useResultService();
  const isUserPortfolioOwner = usePortfolioOwnerStatus();
  const [evidenceRelationToBeEdited, setEvidenceRelationToBeEdited] =
    useState(null);
  const [evidenceIdForEditedRelation, setEvidenceIdForEditedRelation] =
    useState(0);
  const [evidenceUpdated, setEvidenceUpdated] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      const response = await getPortfolio(portfolioId);
      setPortfolio(response.data);
    };

    fetchData();
  }, [portfolioId, getPortfolio]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getActivity(activityId);
      setActivity(response.data);
    };

    fetchData();
  }, [activityId, getActivity]);

  useEffect(() => {
    const fetchData = async () => {
      const relationResponse = await callApi(
        `relations/${relationId}/${portfolioId}`
      );
      const relation = await relationResponse.json();
      const fromResultResponse = await getResult(relation.fromResultId);
      const fromResult = fromResultResponse.data;
      const fromResultIndicatorsResponse = await getIndicators(fromResult.id);
      const fromResultIndicators = fromResultIndicatorsResponse.data;
      fromResult.indicators = fromResultIndicators;

      const toResultResponse = await getResult(relation.toResultId);
      const toResult = toResultResponse.data;
      const toResultIndicatorsResponse = await getIndicators(toResult.id);
      const toResultIndicators = toResultIndicatorsResponse.data;
      toResult.indicators = toResultIndicators;

      setRelation(relation);
      setFromResult(fromResult);
      setToResult(toResult);
    };

    fetchData();
  }, [relationId, portfolioId, getIndicators, getResult, evidenceUpdated]);

  const breadcrumbItems = [
    { path: `/${portfolioId}`, label: portfolio.title },
    { path: `/${portfolioId}/activities/${activityId}`, label: activity.title },
    { label: "Relation" },
  ];

  if (!relation || !fromResult || !toResult) return null;

  const handleEditEvidenceRelation = async (editedEvidenceRelation) => {
    editedEvidenceRelation.evidenceId = evidenceIdForEditedRelation;
    const response = await callApi(
      `relations/${editedEvidenceRelation.relationId}/evidence/${evidenceIdForEditedRelation}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(editedEvidenceRelation),
      }
    );

    if (response.ok) {
      setEvidenceUpdated(true);
    }
  };

  const handleEvidenceRelationEdit = (evidenceId, evidenceRelation) => {
    setEvidenceIdForEditedRelation(evidenceId);
    setEvidenceRelationToBeEdited(evidenceRelation);
  };

  return (
    <>
      <div className="page-title">
        <div className="title">
          <Breadcrumbs items={breadcrumbItems} />
          <h1 className="h1">Relation</h1>
        </div>
      </div>
      <div className="mt-3">
        <h4 className="h4 mb-1">From</h4>
        <p style={{ whiteSpace: "pre-line" }}>{fromResult.title}</p>
        <h4 className="h4 mb-1">Description</h4>
        {!fromResult.description ? (
          <p>No Description</p>
        ) : (
          <p style={{ whiteSpace: "pre-line" }}>{fromResult.description}</p>
        )}
        <IndicatorTotals result={fromResult} />
      </div>
      <div className="mt-3">
        <h4 className="h4 mb-1">To</h4>
        <p style={{ whiteSpace: "pre-line" }}>{toResult.title}</p>
        <h4 className="h4 mb-1">Description</h4>
        {!toResult.description ? (
          <p>No Description</p>
        ) : (
          <p style={{ whiteSpace: "pre-line" }}>{toResult.description}</p>
        )}
        <IndicatorTotals result={toResult} />
      </div>
      <div className="mt-3">
        <h4 className="h4">Evidence</h4>
        {relation.evidence && relation.evidence.length ? (
          <Table className="table">
            <thead>
              <tr>
                <th>Title</th>
                <th>Type</th>
                <th>Direction</th>
                <th>Level of support for causality</th>
                <th>Is necessary</th>
                {relation.evidence &&
                  relation.evidence.some((x) => {
                    let evidenceRelation = x.evidencedRelations.find(
                      (er) => er.relationId === parseInt(relationId)
                    );
                    if (evidenceRelation.relativeImportanceScale === null) {
                      return false;
                    }
                    return true;
                  }) && <th>Relative importance</th>}
                {relation.evidence &&
                  relation.evidence.some((x) => {
                    let evidenceRelation = x.evidencedRelations.find(
                      (er) => er.relationId === parseInt(relationId)
                    );
                    if (
                      evidenceRelation.timeLag === null ||
                      evidenceRelation.timeLag === ""
                    ) {
                      return false;
                    }
                    return true;
                  }) && <th>Time lag</th>}
                {relation.evidence &&
                  relation.evidence.some((x) => {
                    let evidenceRelation = x.evidencedRelations.find(
                      (er) => er.relationId === parseInt(relationId)
                    );
                    if (
                      (evidenceRelation.evidenceEffect1 === null ||
                        evidenceRelation.evidenceEffect1 === "") &&
                      (evidenceRelation.evidenceEffect2 === null ||
                        evidenceRelation.evidenceEffect2 === "")
                    ) {
                      return false;
                    }
                    return true;
                  }) && <th>Effect size</th>}
                {relation.evidence &&
                  relation.evidence.some((x) => {
                    let evidenceRelation = x.evidencedRelations.find(
                      (er) => er.relationId === parseInt(relationId)
                    );
                    if (
                      evidenceRelation.comment === null ||
                      evidenceRelation.comment === ""
                    ) {
                      return false;
                    }
                    return true;
                  }) && <th>Comment</th>}
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {relation.evidence.map((e) => {
                let evidenceRelation = e.evidencedRelations.find(
                  (er) => er.relationId === parseInt(relationId)
                );

                if (!evidenceRelation) return null;

                return (
                  <tr key={e.id}>
                    <td>{e.title}</td>
                    <td>{e.type}</td>
                    <td>{evidenceRelation.direction}</td>
                    <td>
                      {evidenceRelation.causality === null ? (
                        "No value"
                      ) : (
                        <>
                          <input
                            type="range"
                            className="form-range"
                            min="0"
                            max="1"
                            step="0.01"
                            value={evidenceRelation.causality}
                            disabled
                          />
                          <div className="d-flex w-100 justify-content-between">
                            <div style={{ fontSize: "10px", color: "red" }}>
                              No<br></br>support
                            </div>
                            <div style={{ fontSize: "10px", color: "red" }}>
                              Certainty
                            </div>
                          </div>
                          <div>Value: {evidenceRelation.causality}</div>
                        </>
                      )}
                    </td>
                    <td>{evidenceRelation.isNecessary}</td>
                    {relation.evidence &&
                      relation.evidence.some((x) => {
                        let evidenceRelation = x.evidencedRelations.find(
                          (er) => er.relationId === parseInt(relationId)
                        );
                        if (evidenceRelation.relativeImportanceScale === null) {
                          return false;
                        }
                        return true;
                      }) && (
                        <td>
                          {evidenceRelation.relativeImportanceScale === null ? (
                            "No value"
                          ) : (
                            <>
                              <input
                                type="range"
                                className="form-range"
                                min="0"
                                max="1"
                                step="0.01"
                                value={evidenceRelation.relativeImportanceScale}
                                disabled
                              />
                              <div className="d-flex w-100 justify-content-between">
                                <div style={{ fontSize: "10px", color: "red" }}>
                                  Little to no<br></br>influence
                                </div>
                                <div
                                  style={{
                                    fontSize: "10px",
                                    color: "red",
                                    marginLeft: "50px",
                                  }}
                                >
                                  Sole and only<br></br>influence
                                </div>
                              </div>
                              <div>
                                Value:{" "}
                                {evidenceRelation.relativeImportanceScale}
                              </div>
                            </>
                          )}
                        </td>
                      )}
                    {relation.evidence &&
                      relation.evidence.some((x) => {
                        let evidenceRelation = x.evidencedRelations.find(
                          (er) => er.relationId === parseInt(relationId)
                        );
                        if (
                          evidenceRelation.timeLag === null ||
                          evidenceRelation.timeLag === ""
                        ) {
                          return false;
                        }
                        return true;
                      }) && <td>{evidenceRelation.timeLag}</td>}
                    {relation.evidence &&
                      relation.evidence.some((x) => {
                        let evidenceRelation = x.evidencedRelations.find(
                          (er) => er.relationId === parseInt(relationId)
                        );
                        if (
                          (evidenceRelation.evidenceEffect1 === null ||
                            evidenceRelation.evidenceEffect1 === "") &&
                          (evidenceRelation.evidenceEffect2 === null ||
                            evidenceRelation.evidenceEffect2 === "")
                        ) {
                          return false;
                        }
                        return true;
                      }) && (
                        <td>
                          {evidenceRelation.evidenceEffect1}
                          <hr></hr>
                          Leads to
                          <hr></hr>
                          {evidenceRelation.evidenceEffect2}
                        </td>
                      )}
                    {relation.evidence &&
                      relation.evidence.some((x) => {
                        let evidenceRelation = x.evidencedRelations.find(
                          (er) => er.relationId === parseInt(relationId)
                        );
                        if (
                          evidenceRelation.comment === null ||
                          evidenceRelation.comment === ""
                        ) {
                          return false;
                        }
                        return true;
                      }) && <td>{evidenceRelation.comment}</td>}
                    <td>
                      {isUserPortfolioOwner && (
                        <>
                          <div className="d-flex">
                            <EditButton
                              onClick={() =>
                                handleEvidenceRelationEdit(
                                  e.id,
                                  evidenceRelation
                                )
                              }
                            />
                          </div>
                        </>
                      )}

                      {/* <Link to={`/${portfolioId}/evidence/${e.id}`}>
                        more info <BsBoxArrowInDownLeft className="ml-1" />
                    </Link>*/}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : (
          <p>There is no evidence for this relation test.</p>
        )}
      </div>
      {evidenceRelationToBeEdited && (
        <EditEvidenceRelationModal
          isOpen={evidenceRelationToBeEdited !== null}
          onHide={() => {
            setEvidenceRelationToBeEdited(null);
          }}
          evidenceRelation={evidenceRelationToBeEdited}
          onSubmit={handleEditEvidenceRelation}
        />
      )}
    </>
  );
}
