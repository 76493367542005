import moment from "moment";
import React from "react";
export function IndicatorSummaryTableRowComponent({
  result,
  changeHandler,
  activity,
  allPeriods,
  addTargetValue,
  addAchievedValue,
  resultTotalTargetValue,
  resultTotalAchievedValue,
  isPlannedVisible,
  isAchievedVisible,
  selectedDisaggregations,
  disaggregations,
  uniquePeriodTitles,
}) {
  /*const addTargetDisaggregationValue = (period, valueItem) => {
    let sum = 0;
    for (let i = 0; i < period.records.length; i++) {
      for (let j = 0; j < period.records[i].disaggregationValues.length; j++) {
        if (period.records[i].disaggregationValues[j].id === valueItem.id) {
          sum = sum + period.records[i].targetValue;
        }
      }
    }
    return sum;
  };*/

  return (
    <tr key={result.id}>
      <td style={{ verticalAlign: "middle" }}>{result.title}</td>
      <td style={{ verticalAlign: "middle" }}>{result.activityTitle}</td>
      <td style={{ verticalAlign: "middle" }}>
        <input
          id={"checkbox" + result.id}
          type="checkbox"
          checked={result.isSelected}
          onChange={(e) => changeHandler(e, result, activity)}
        ></input>
      </td>
      <td>
        <table>
          <tr>
            {result.isSelected ? (
              <>
                {isPlannedVisible ? (
                  <>
                    <td style={{ verticalAlign: "middle", border: "none" }}>
                      Planned
                    </td>
                  </>
                ) : (
                  <td style={{ border: "none" }}></td>
                )}
              </>
            ) : (
              <td style={{ border: "none" }}></td>
            )}
          </tr>
          <tr>
            {result.isSelected ? (
              <>
                {isAchievedVisible ? (
                  <>
                    <td style={{ verticalAlign: "middle", border: "none" }}>
                      Achieved
                    </td>
                  </>
                ) : (
                  <td style={{ border: "none" }}></td>
                )}
              </>
            ) : (
              <td style={{ border: "none" }}></td>
            )}
          </tr>
        </table>
      </td>
      {uniquePeriodTitles.map((periodtitle, periodtitleIndex) => (
        <td>
          {result.isSelected && (
            <table>
              <tr>
                {isPlannedVisible ? (
                  <>
                    {allPeriods.map((period, periodIndex) => (
                      <>
                        {period.resultId === result.id &&
                          moment(period.start).format("DD/MM/YYYY") +
                            "-" +
                            moment(period.end).format("DD/MM/YYYY") ===
                            periodtitle && (
                            <td style={{ border: "none" }}>
                              {addTargetValue(period)}{" "}
                            </td>
                          )}
                      </>
                    ))}
                  </>
                ) : (
                  <td style={{ border: "none" }}></td>
                )}
              </tr>
              <tr>
                {isAchievedVisible ? (
                  <>
                    {allPeriods.map((period, periodIndex) => (
                      <>
                        {period.resultId === result.id &&
                          moment(period.start).format("DD/MM/YYYY") +
                            "-" +
                            moment(period.end).format("DD/MM/YYYY") ===
                            periodtitle && (
                            <td style={{ border: "none" }}>
                              {addAchievedValue(period)}
                            </td>
                          )}
                      </>
                    ))}
                  </>
                ) : (
                  <td style={{ border: "none" }}></td>
                )}
              </tr>
            </table>
          )}
        </td>
      ))}
      {/*allPeriods.map((period, periodIndex) => (
        <>
          {period.resultId === result.id ? (
            <td>
              {result.isSelected && (
                <table>
                  <tr>
                    {isPlannedVisible ? (
                      <>
                        {selectedDisaggregations.length === 0 ? (
                          <td style={{ border: "none" }}>
                            {addTargetValue(period)}
                          </td>
                        ) : (
                          <td style={{ border: "none" }}>
                            <table>
                              {disaggregations.map(
                                (eachDisaggregation, index) => (
                                  <>
                                    {selectedDisaggregations.some(
                                      (x) => x.id === eachDisaggregation.id
                                    ) && (
                                      <>
                                        {eachDisaggregation.disaggregationValues.map(
                                          (valueItem, valueIndex) => (
                                            <tr>
                                              <td style={{ border: "none" }}>
                                                {addTargetDisaggregationValue(
                                                  period,
                                                  valueItem
                                                )}
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </>
                                    )}
                                  </>
                                )
                              )}
                            </table>
                          </td>
                        )}
                      </>
                    ) : (
                      <td style={{ border: "none" }}></td>
                    )}
                  </tr>
                  <tr>
                    {isAchievedVisible ? (
                      <td style={{ border: "none" }}>
                        {addAchievedValue(period)}
                      </td>
                    ) : (
                      <td style={{ border: "none" }}></td>
                    )}
                  </tr>
                </table>
              )}
            </td>
          ) : (
            <td></td>
          )}
        </>
      ))*/}
      <td>
        {result.isSelected && (
          <table>
            <tr>
              {isPlannedVisible ? (
                <td style={{ border: "none" }}>
                  <strong>{resultTotalTargetValue(result)}</strong>
                </td>
              ) : (
                <td style={{ border: "none" }}></td>
              )}
            </tr>
            <tr>
              {isAchievedVisible ? (
                <td style={{ border: "none" }}>
                  <strong>{resultTotalAchievedValue(result)}</strong>
                </td>
              ) : (
                <td style={{ border: "none" }}></td>
              )}
            </tr>
          </table>
        )}
      </td>
    </tr>
  );
}
