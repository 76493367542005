import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import IndicatorList from "./IndicatorList";
import SearchIndicatorList from "./SearchIndicatorList";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import callApi from "../../Helpers/callApi";
import AddIndicatorModal from "./AddIndicatorModal";
import EditIndicatorModal from "./EditIndicatorModal";
import usePortfolioService from "../../Services/usePortfolioService";
import ShareIndicatorModalToPortfolio from "./ShareIndicatorModalToPortfolio";
//import mutate from "swr";
import DeleteIndicatorModal from "./DeleteIndicatorModal";
import ShareIndicatorModal from "./ShareIndicatorModal";
import ShareIndicatorModalConfirmation from "./ShareIndicatorModalConfirmation";
import AddToPortfolioConfirmation from "./AddToPortfolioConfirmation";
import RequestAccessModalConfirmation from "../ActivityList/RequestAccessModalConfirmation";
import { useAddToast } from "../../Context/ToastContext";

export default function IndicatorsPage(props) {
  const {
    portfolioId,
    disaggregations,
    isUserPortfolioOwner,
    allIndicatorsTogether,
    setIsIndicatorAdded,
    isIndicatorAdded,
    indicatorsSharedAtPortfolio,
    sharedActivityOrNestedPortfolioIndicators,
    setSharedIndicatorRemoved,
    sharedIndicatorRemoved,
  } = props;
  const [
    isShareIndicatorModalToPortfolioOpen,
    setIsShareIndicatorModalToPortfolioOpen,
  ] = useState(false);
  const [searchTerm, setSearchTerm] = useState(null);
  const addtoToast = useAddToast();
  const [indicators, setIndicators] = useState([]);
  const [indicatorsTotalItemCount, setIndicatorsTotalItemCount] = useState(0);
  const [indicatorsPageSize] = useState(25);
  const [indicatorsPage, setIndicatorsPage] = useState(1);
  const [searchedIndicators, setSearchedIndicators] = useState([]);
  const [isAddIndicatorModalOpen, setIsIndicatorModalOpen] = useState(false);
  const [isAddDisaggregationModalOpen, setIsAddDisaggregationModalOpen] =
    useState(false);
  const [selectedIndicator, setSelectedIndicator] = useState(null);
  const [selectedDisaggregation, setSelectedDisaggregation] = useState(null);
  const [indicatorToBeDeleted, setIndicatorToBeDeleted] = useState(null);
  const [isDeleteIndicatorModalOpen, setisDeleteIndicatorModalOpen] =
    useState(false);
  const [indicatorToBeEdited, setIndicatorToBeEdited] = useState(null);
  const [isEditIndicatorModalOpen, setIsEditIndicatorModalOpen] =
    useState(false);
  const [isAddingNewIndicator, setIsAddingNewIndicator] = useState(false);
  const { createIndicator } = usePortfolioService();
  const [indicatorToBeShared, setIndicatorToBeShared] = useState(null);
  const [portfolioUniqueTitle, setPortfolioUniqueTitle] = useState(null);
  const [isShareIndicatorModalOpen, setIsShareIndicatorModalOpen] =
    useState(false);
  const [
    isShareIndicatorModalOpenConfirmation,
    setIsShareIndicatorModalOpenConfirmation,
  ] = useState(false);
  const [isAddToPortfolioOpen, setIsAddToPortfolioOpen] = useState(false);
  const [sharedIndicators, setSharedIndicators] = useState([]);

  const [isRequestAccess, setIsRequestAccess] = useState(false);
  const [indicatorAskedToShare, setIndicatorAskedToShare] = useState(null);
  const [indicatorAddToPortfolio, setIndicatorAddToPortfolio] = useState(null);
  const [indicatorPublicProperty, setIndicatorPublicProperty] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const response = await callApi(
        `portfolios/${portfolioId}/indicators?page=${indicatorsPage}&pageSize=${indicatorsPageSize}`
      );
      const indicatorsResponse = await response.json();

      setIndicators(indicatorsResponse.items);
      setIndicatorsTotalItemCount(indicatorsResponse.totalItemCount);
    };

    fetchData();
  }, [portfolioId, indicatorsPage, indicatorsPageSize, isAddingNewIndicator]);

  const handleAddIndicatorSubmit = async (
    title,
    description,
    methodology,
    typeId,
    units,
    documentLink,
    aggregationMethod,
    isPublic
  ) => {
    const response = await createIndicator(
      portfolioId,
      title,
      description,
      methodology,
      Number(typeId),
      units,
      documentLink,
      aggregationMethod,
      isPublic
    );
    if (response.ok) {
      setIndicators((prevState) => [...prevState, response.data]);
      setIsAddingNewIndicator(false);
      setIsIndicatorAdded(!isIndicatorAdded);
    }
    handleIndicatorModalClose();
  };

  const handleCopyIndicatorSubmit = async (indicator) => {
    const response = await callApi(
      `portfolios/${portfolioId}/indicators/${indicator.id}/addtoindicator`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.ok) {
      //const newIndicator = await response.json();
      //setIndicators((prevState) => [...prevState, newIndicator]);
      setIsAddingNewIndicator(!isAddingNewIndicator);
      setSharedIndicatorRemoved(!sharedIndicatorRemoved);
      //setShareIndicatorAdded(!shareIndicatorAdded);
    }
    setSearchTerm(null);
    setSearchedIndicators([]);
  };

  const handleEditIndicatorSubmit = async () => {
    const response = await callApi(`indicators/${indicatorToBeEdited.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(indicatorToBeEdited),
    });
    if (response.ok) {
      const editedIndicator = await response.json();

      if (editedIndicator.isPublic !== indicatorPublicProperty) {
        if (editedIndicator.isPublic === false) {
          addtoToast({
            variant: "information",
            isDanger: false,
            message:
              "The indicator is being changed to private, the indicator will no longer be available to find and add by other users but existing uses will continue as shared usage.",
          });
        } else {
          addtoToast({
            variant: "information",
            isDanger: false,
            message:
              "This indicator will now be available for users to find and add to their portfolios, existing shared users will be unaffected.",
          });
        }
      }

      setIndicators((prevState) =>
        prevState.map((i) => {
          if (i.id === editedIndicator.id) {
            return editedIndicator;
          }
          return i;
        })
      );
    }
    handleEditIndicatorModalClose();
  };

  const handleAddDisaggregationSubmit = async () => {
    const response = await callApi(
      `indicators/${selectedIndicator.id}/disaggregations/${selectedDisaggregation.id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.ok) {
      setIndicators((prevState) =>
        prevState.map((ind) => {
          if (ind.id === selectedIndicator.id) {
            return {
              ...ind,
              disaggregations: [...ind.disaggregations, selectedDisaggregation],
            };
          }
          return ind;
        })
      );
    }
    handleAddDisaggregationModalClose();
  };

  const handleIndicatorPageChanged = (page) => {
    setIndicatorsPage(page);
  };

  const handleSearchIndicators = async (searchString) => {
    setSearchTerm(searchString);
    const response = await callApi(
      `indicators/${portfolioId}/search/${searchString}`
    ); //`indicators?searchString=${searchString}`

    if (response.ok) {
      const searchedIndicators = await response.json();
      setSearchedIndicators(searchedIndicators);
    }
  };

  const handleDeleteIndicatorClick = async () => {
    const response = await callApi(`indicators/${indicatorToBeDeleted}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      setIndicators((prevstate) =>
        prevstate.filter((i) => i.id !== indicatorToBeDeleted)
      );
      setisDeleteIndicatorModalOpen(false);
      setIndicatorToBeDeleted(null);
    } else {
      const jsonResponse = await response.json();
      addtoToast({
        variant: "error",
        message: jsonResponse.errors[0].description,
      });
    }
  };

  const handleRemoveDisaggregation = (indicator, disaggregation) => {
    setIndicators((prevState) =>
      prevState.map((ind) => {
        if (ind.id === indicator.id) {
          return {
            ...ind,
            disaggregations: ind.disaggregations.filter(
              (d) => d.id !== disaggregation.id
            ),
          };
        }
        return ind;
      })
    );
  };

  const handleIndicatorSelect = async (indicator) => {
    if (indicator.totalLoaded) return;

    const response = await callApi(`indicators/${indicator.id}/totals`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      let totals = await response.json();
      setIndicators((prevState) =>
        prevState.map((i) => {
          if (indicator.id === i.id) {
            i.totalLoaded = true;
            i.totalActualValue = totals.totalActualValue;
            i.totalTargetValue = totals.totalTargetValue;
            i.disaggregationsTotals = totals.disaggregations;
          }
          return i;
        })
      );
    }
  };

  const handleIndicatorModalOpen = () => setIsIndicatorModalOpen(true);
  const handleIndicatorModalClose = () => setIsIndicatorModalOpen(false);
  const handleAddDisaggregationModalOpen = () =>
    setIsAddDisaggregationModalOpen(true);
  const handleAddDisaggregationModalClose = () =>
    setIsAddDisaggregationModalOpen(false);
  const handleAddDisaggregationClick = (indicator) => {
    setSelectedIndicator(indicator);
    handleAddDisaggregationModalOpen();
  };
  const handleIsDeleteIndicatorModalOpenClick = () => {
    setisDeleteIndicatorModalOpen(true);
  };
  const handleIndicatorToBeDeletedClick = (indicatorId) => {
    handleIsDeleteIndicatorModalOpenClick();
    setIndicatorToBeDeleted(indicatorId);
  };

  /*const handleIndicatorToBeEditedClick = (indicator) => {    
    setIsEditIndicatorModalOpen(true);
    setIndicatorToBeEdited(indicator);    
  };*/

  const handleSetEditedIndicatorProperty = (property, value) => {
    setIndicatorToBeEdited((prevState) => ({
      ...prevState,
      [property]: value,
    }));
  };
  const handleEditIndicatorModalClose = () => {
    setIndicatorToBeEdited(null);
    setIsEditIndicatorModalOpen(false);
  };

  const handleShareIndicatorModalToPortfolioClose = () => {
    setIsShareIndicatorModalToPortfolioOpen(false);
  };

  const handleShareIndicatorToPortfolioModalOpen = () => {
    setIsShareIndicatorModalToPortfolioOpen(true);
  };

  const handleShareIndicatorToPortfolioSubmit = async (
    portfolioUniqueTitle,
    indicatorIds,
    shareAll
  ) => {
    if (shareAll) {
      await callApi(
        `portfolios/${portfolioId}/indicator/share-all-indicator?destinationPortfolioUniqueTitle=${portfolioUniqueTitle}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } else {
      await callApi(
        `portfolios/${portfolioId}/indicator/share-indicator?destinationPortfolioUniqueTitle=${portfolioUniqueTitle}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            indicatorIds: indicatorIds,
          }),
        }
      );
    }

    //mutate(`portfolios/${portfolioId}/evidence`);
  };

  const handleEdit = async (indicator) => {
    setIndicatorToBeEdited(indicator);
    setIsEditIndicatorModalOpen(true);
    setIndicatorPublicProperty(indicator.isPublic);
  };

  const handleDelete = async (indicator) => {
    setIndicatorToBeDeleted(indicator.id);
    setisDeleteIndicatorModalOpen(true);
  };

  const handleShare = async (indicator) => {
    setIndicatorToBeShared(indicator);
    setSharedIndicators((item) => [...item, indicator.id]);
    setIsShareIndicatorModalOpen(true);
  };

  const handleDeleteIndicatorModalClose = () => {
    setIndicatorToBeDeleted(null);
    setisDeleteIndicatorModalOpen(false);
  };

  const handleShareIndicatorModalOpenConfirmation = () => {
    setIsShareIndicatorModalOpenConfirmation(true);
    setIsShareIndicatorModalOpen(false);
  };

  const handleShareIndicatorModalClose = () => {
    setIndicatorToBeShared(null);
    setSharedIndicators([]);
    setIsShareIndicatorModalOpen(false);
  };

  const handleShareIndicatorModalConfirmationClose = () => {
    setIndicatorToBeShared(null);
    setSharedIndicators([]);
    setPortfolioUniqueTitle(null);
    setIsShareIndicatorModalOpenConfirmation(false);
  };

  const handleAddToPortfolioSubmit = async () => {
    const response = await callApi(
      `portfolios/${portfolioId}/indicator/${indicatorAddToPortfolio.id}/indicator-addtoportfolio`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.ok) {
      setIsAddToPortfolioOpen(false);
      setIndicatorAddToPortfolio(null);
    }
    setSharedIndicatorRemoved(!sharedIndicatorRemoved);
  };

  const handleAddToPortfolio = async (indicator) => {
    setIndicatorAddToPortfolio(indicator);
    setIsAddToPortfolioOpen(true);
  };

  const handleAddToPortfolioClose = () => {
    setIsAddToPortfolioOpen(false);
    setIndicatorAddToPortfolio(null);
  };

  const handleAskToShare = async (indicator) => {
    setIndicatorAskedToShare(indicator);
    setIsRequestAccess(true);
  };

  const handleAskToShareSubmit = async () => {
    const response = await callApi(
      `portfolios/${portfolioId}/indicator/${indicatorAskedToShare.id}/indicator-asktoshare`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.ok) {
      setIsRequestAccess(false);
      setIndicatorAskedToShare(null);
    }
  };

  const hideAskToShare = () => {
    setIsRequestAccess(false);
    setIndicatorAskedToShare(null);
  };

  return (
    <>
      <h2 className="h2">Indicators</h2>
      {isAddingNewIndicator ? (
        <>
          <div className="filter-activities mb-4">
            <label className="bold">Find an indicator</label>
            <Form.Control
              type="text"
              placeholder="Search existing indicators"
              onChange={(e) => handleSearchIndicators(e.target.value)}
            />
          </div>
          <div className="actions">
            <div
              className="icon-and-text"
              variant="primary"
              onClick={handleIndicatorModalOpen}
            >
              <i className="icon bi bi-speedometer2"></i>
              Create Indicator
            </div>
            <div
              className="icon-and-text has-left-border"
              onClick={() => setIsAddingNewIndicator(false)}
            >
              <i class="icon bi bi-x-circle"></i>
              Cancel
            </div>
            <div
            className="icon-and-text has-left-border"
            onClick={handleShareIndicatorToPortfolioModalOpen}
          >
            <i className="icon bi bi-share"></i>
            Share Indicators
          </div>
          </div>
        </>
      ) : isUserPortfolioOwner ? (
        <>
          <div
            className="icon-and-text"
            onClick={() => setIsAddingNewIndicator(true)}
          >
            <i className="icon bi bi-speedometer2"></i>
            Add Indicator
          </div>
          <div
            className="icon-and-text has-left-border"
            onClick={handleShareIndicatorToPortfolioModalOpen}
          >
            <i className="icon bi bi-share"></i>
            Share Indicators
          </div>
        </>
      ) : null}

      <Row>
        <Col sm={10}></Col>
        {isUserPortfolioOwner && (
          <Col sm={2} className="text-right">

          </Col>
        )}
      </Row>

      {isAddingNewIndicator ? (
        <SearchIndicatorList
          indicators={searchedIndicators}
          onCopyIndicator={handleCopyIndicatorSubmit}
          searchTerm={searchTerm}
        />
      ) : (
        <IndicatorList
          indicators={indicators}
          indicatorsPageSize={indicatorsPageSize}
          indicatorsPage={indicatorsPage}
          indicatorsTotalItemCount={indicatorsTotalItemCount}
          onAddDisaggregationClick={handleAddDisaggregationClick}
          onIndicatorToBeDeletedClick={handleIndicatorToBeDeletedClick}
          //onIndicatorToBeEditedClick={handleIndicatorToBeEditedClick}
          onRemoveDisaggregation={handleRemoveDisaggregation}
          onSelect={handleIndicatorSelect}
          onPageChanged={handleIndicatorPageChanged}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          handleShare={handleShare}
          isUserPortfolioOwner={isUserPortfolioOwner}
          indicatorsSharedAtPortfolio={indicatorsSharedAtPortfolio}
          sharedIndicatorRemoved={sharedIndicatorRemoved}
          setSharedIndicatorRemoved={setSharedIndicatorRemoved}
          portfolioId={portfolioId}
          sharedActivityOrNestedPortfolioIndicators={
            sharedActivityOrNestedPortfolioIndicators
          }
          handleAddToPortfolio={handleAddToPortfolio}
          handleAskToShare={handleAskToShare}
        />
      )}

      <AddIndicatorModal
        isOpen={isAddIndicatorModalOpen}
        onHide={handleIndicatorModalClose}
        onSubmit={handleAddIndicatorSubmit}
      />
      <EditIndicatorModal
        isOpen={isEditIndicatorModalOpen}
        onHide={handleEditIndicatorModalClose}
        onSubmit={handleEditIndicatorSubmit}
        onChangeIndicatorProperty={handleSetEditedIndicatorProperty}
        indicator={indicatorToBeEdited}
      />
      <ShareIndicatorModalToPortfolio
        portfolioId={portfolioId}
        isOpen={isShareIndicatorModalToPortfolioOpen}
        onSubmit={handleShareIndicatorToPortfolioSubmit}
        onHide={handleShareIndicatorModalToPortfolioClose}
        allIndicators={allIndicatorsTogether}
      />
      <DeleteIndicatorModal
        isOpen={isDeleteIndicatorModalOpen}
        onHide={handleDeleteIndicatorModalClose}
        handleDeleteIndicatorClick={handleDeleteIndicatorClick}
      />
      <ShareIndicatorModal
        isOpen={isShareIndicatorModalOpen}
        onSubmit={handleShareIndicatorModalOpenConfirmation}
        onHide={handleShareIndicatorModalClose}
        indicator={indicatorToBeShared}
        onSetPortfolioUniqueTitle={setPortfolioUniqueTitle}
      />
      <ShareIndicatorModalConfirmation
        isOpen={isShareIndicatorModalOpenConfirmation}
        onSubmit={handleShareIndicatorToPortfolioSubmit}
        onHide={handleShareIndicatorModalConfirmationClose}
        sharedIndicators={sharedIndicators}
        portfolioUniqueTitle={portfolioUniqueTitle}
      />
      <AddToPortfolioConfirmation
        isOpen={isAddToPortfolioOpen}
        onSubmit={handleAddToPortfolioSubmit}
        onHide={handleAddToPortfolioClose}
      />
      <RequestAccessModalConfirmation
        isOpen={isRequestAccess}
        onSubmit={handleAskToShareSubmit}
        onHide={hideAskToShare}
      />

      <Modal
        show={isAddDisaggregationModalOpen}
        onHide={handleAddDisaggregationModalClose}
      >
        <Modal.Header>
          <Modal.Title>Add Disaggregation</Modal.Title>
          <button
            type="button"
            className="btn-close btn-close-white"
            aria-label="Close"
            onClick={handleAddDisaggregationModalClose}
          ></button>
        </Modal.Header>
        <Modal.Body>
          <DropdownButton
            variant="outline-primary"
            className="pr-2"
            title={
              selectedDisaggregation
                ? selectedDisaggregation.title
                : "Select one"
            }
          >
            {disaggregations.map((d, i) => (
              <Dropdown.Item
                onSelect={() => setSelectedDisaggregation(d)}
                key={i}
              >
                {d.title}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="button primary"
            disabled={!selectedDisaggregation}
            onClick={() => handleAddDisaggregationSubmit()}
          >
            Add Disaggregation
          </Button>
          <Button
            className="button tertiary"
            onClick={handleAddDisaggregationModalClose}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      {/*
        <Modal
          show={isDeleteIndicatorModalOpen}
          onHide={() => setisDeleteIndicatorModalOpen(false)}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Indicator</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete this indicator?
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setisDeleteIndicatorModalOpen(false)}
            >
              Cancel
            </Button>
            <Button variant="danger" onClick={handleDeleteIndicatorClick}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
            */}
    </>
  );
}
