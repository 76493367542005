import { useCallback } from "react";
import callApi from "../Helpers/callApi";
import { useAddToast } from "../Context/ToastContext";
import { GetErrors } from "../Helpers/errors";

export default function usePortfolioService() {
  // useCallback needed to memoise toast hook
  const addToast = useCallback(useAddToast(), []);

  // useCallback needed to memoise fetchers used in useEffect hooks
  const getPortfolios = useCallback(async () => {
    const response = await callApi(`portfolios`);
    const data = await response.json();

    if (!response.ok) {
      addToast({
        variant: "error",
        message: "There was an error fetching the portfolios",
      });
    }

    return { status: response.status, ok: response.ok, data };
  }, [addToast]);

  // useCallback needed to memoise fetchers used in useEffect hooks
  const getPortfolio = useCallback(
    async (portfolioId) => {
      const response = await callApi(`portfolios/${portfolioId}`);
      const data = await response.json();

      if (!response.ok) {
        addToast({
          variant: "error",
          message: "There was an error fetching the portfolio",
        });
      }
      return { status: response.status, ok: response.ok, data };
    },
    [addToast]
  );

  const createPortfolio = async (title, description) => {
    const response = await callApi(`portfolios`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        title,
        description,
      }),
    });
    const data = await response.json();

    if (!response.ok) {
      let errors = GetErrors(data);
      addToast({
        variant: "error",
        message: errors.join(" "),
      });
    }

    return { status: response.status, ok: response.ok, data };
  };

  const updatePortfolio = async (
    portfolioId,
    title,
    uniqueTitle,
    description
  ) => {
    const response = await callApi(`portfolios/${portfolioId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: portfolioId,
        title,
        uniqueTitle,
        description,
      }),
    });
    const data = await response.json();

    if (!response.ok) {
      let errors = GetErrors(data);
      addToast({
        variant: "error",
        message: errors.join(". "),
      });
    }

    return { status: response.status, ok: response.ok, data };
  };

  const getUsers = useCallback(
    async (portfolioId) => {
      const response = await callApi(`portfolios/${portfolioId}/users`);
      const data = await response.json();

      if (!response.ok) {
        addToast({
          variant: "error",
          message: "There was an error fetching the users for this portfolio",
        });
      }

      return { status: response.status, ok: response.ok, data };
    },
    [addToast]
  );

  const addUser = async (portfolioId, email, role) => {
    const response = await callApi(`portfolios/${portfolioId}/users`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        role,
      }),
    });
    const data = await response.json();

    if (!response.ok) {
      addToast({
        variant: "error",
        message: data.errors[0].description,
      });
    }

    return { status: response.status, ok: response.ok, data };
  };

  const removeUser = async (portfolioId, userId) => {
    const response = await callApi(
      `portfolios/${portfolioId}/users/${userId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      const jsonResponse = await response.json();
      addToast({
        variant: "error",
        message: jsonResponse.errors[0].description,
      });
    }

    return { status: response.status, ok: response.ok };
  };

  const getDisaggregations = useCallback(
    async (portfolioId) => {
      const response = await callApi(
        `portfolios/${portfolioId}/disaggregations`
      );
      const data = await response.json();

      if (!response.ok) {
        addToast({
          variant: "error",
          message:
            "There was an error fetching the disaggregations for this portfolio",
        });
      }

      return { status: response.status, ok: response.ok, data };
    },
    [addToast]
  );

  const getDisaggregationsWithShared = useCallback(
    async (portfolioId) => {
      const response = await callApi(
        `portfolios/${portfolioId}/disaggregationsWithShared`
      );
      const data = await response.json();

      if (!response.ok) {
        addToast({
          variant: "error",
          message:
            "There was an error fetching the disaggregations for this portfolio",
        });
      }

      return { status: response.status, ok: response.ok, data };
    },
    [addToast]
  );

  // useCallback needed to memoise fetchers used in useEffect hooks
  const getActivityForPortfolio = useCallback(
    async (portfolioId, activityId) => {
      const response = await callApi(
        `portfolios/${portfolioId}/activities/${activityId}`
      );
      const data = await response.json();

      if (!response.ok) {
        addToast({
          variant: "error",
          message: "There was an error fetching the activity",
        });
      }
      return { status: response.status, ok: response.ok, data };
    },
    [addToast]
  );

  const createActivity = async (
    portfolioId,
    title,
    description,
    IATIIdentifier
  ) => {
    const response = await callApi(`portfolios/${portfolioId}/activities`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        title,
        description,
        IATIIdentifier,
      }),
    });
    const data = await response.json();

    if (!response.ok) {
      addToast({
        variant: "error",
        message: "There was an error creating this activity",
      });
    }

    return { status: response.status, ok: response.ok, data };
  };

  const createIndicator = async (
    portfolioId,
    title,
    description,
    methodology,
    typeId,
    units,
    documentLink,
    aggregationMethod,
    isPublic
  ) => {
    const response = await callApi(`portfolios/${portfolioId}/indicators`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        title,
        description,
        methodology,
        typeId,
        units,
        documentLink,
        aggregationMethod,
        isPublic,
      }),
    });
    const data = await response.json();

    if (!response.ok) {
      addToast({
        variant: "error",
        message: "There was an error creating this indicator",
      });
    }

    return { status: response.status, ok: response.ok, data };
  };

  const createResult = async (
    portfolioId,
    title,
    description,
    labels,
    subResults,
    portfolioPosition
  ) => {
    const response = await callApi(`portfolios/${portfolioId}/results`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        title,
        description,
        labels,
        subResults,
        portfolioPosition: {
          portfolioId,
          ...portfolioPosition,
        },
      }),
    });
    const data = await response.json();

    if (!response.ok) {
      addToast({
        variant: "error",
        message: "There was an error creating this result",
      });
    }

    return { status: response.status, ok: response.ok, data };
  };

  const deletePortfolio = async (portfolioId) => {
    const response = await callApi(`portfolios/${portfolioId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      let data = await response.json();
      let errors = GetErrors(data);
      addToast({
        variant: "error",
        message: errors.join(". "),
      });

      return { status: response.status, ok: response.ok };
    }
  };

  const getIndicator = useCallback(
    async (indicatorId, portfolioId) => {
      const response = await callApi(
        `indicators/${indicatorId}/getIndicatorWithDisaggregations/${portfolioId}`
      );
      const data = await response.json();

      if (!response.ok) {
        addToast({
          variant: "error",
          message: "There was an error fetching the indicator details",
        });
      }

      return { status: response.status, ok: response.ok, data };
    },
    [addToast]
  );

  const getResults = useCallback(
    async (portfolioId) => {
      const response = await callApi(`portfolios/allrelations/${portfolioId}`);
      const data = await response.json();
      if (!response.ok) {
        addToast({
          variant: "error",
          message: "There was an error fetching the indicator details",
        });
      }

      return { status: response.status, ok: response.ok, data };
    },
    [addToast]
  );

  const createEvidence = async (
    portfolioId,
    title,
    type,
    summary,
    comments,
    documentLink,
    produced,
    validFrom,
    validTo,
    relationId,
    evidenceProducedWithFCDOFunding,
    fundingIATIIdentifier,
    methods,
    authors,
    isPublic
  ) => {
    const response = await callApi(`portfolios/${portfolioId}/evidence`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        title,
        type,
        summary,
        comments,
        documentLink,
        produced,
        validFrom,
        validTo,
        relationId,
        evidenceProducedWithFCDOFunding,
        fundingIATIIdentifier,
        portfolioId,
        methods,
        authors,
        isPublic,
      }),
    });
    const data = await response.json();

    if (!response.ok) {
      addToast({
        variant: "error",
        message: "There was an error creating the evidence",
      });
    }

    return { status: response.status, ok: response.ok, data };
  };

  const getEvidence = useCallback(
    async (portfolioId) => {
      const response = await callApi(`portfolios/${portfolioId}/evidence`);
      const data = await response.json();

      if (!response.ok) {
        addToast({
          variant: "error",
          message:
            "There was an error fetching the evidence from this portfolio",
        });
      }

      return { status: response.status, ok: response.ok, data };
    },
    [addToast]
  );

  const getAllIndicators = useCallback(
    async (portfolioId) => {
      const response = await callApi(`portfolios/${portfolioId}/allIndicators`);
      const data = await response.json();

      if (!response.ok) {
        addToast({
          variant: "error",
          message:
            "There was an error fetching the indicators from this portfolio",
        });
      }

      return { status: response.status, ok: response.ok, data };
    },
    [addToast]
  );

  const getAllIndicatorsWithShared = useCallback(
    async (portfolioId) => {
      const response = await callApi(
        `portfolios/${portfolioId}/allIndicatorsWithShared`
      );
      const data = await response.json();

      if (!response.ok) {
        addToast({
          variant: "error",
          message:
            "There was an error fetching the indicators from this portfolio",
        });
      }

      return { status: response.status, ok: response.ok, data };
    },
    [addToast]
  );

  return {
    getPortfolios,
    getPortfolio,
    createPortfolio,
    getUsers,
    addUser,
    removeUser,
    updatePortfolio,
    getDisaggregations,
    deletePortfolio,
    getActivityForPortfolio,
    createActivity,
    createIndicator,
    createResult,
    getIndicator,
    getResults,
    createEvidence,
    getEvidence,
    getAllIndicators,
    getAllIndicatorsWithShared,
    getDisaggregationsWithShared,
  };
}
