import React, { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import {
  Badge,
  //Alert,
  //Modal,
  //DropdownButton,
  //Dropdown,
  //Tab,
  //Nav,
  //ListGroup,
} from "react-bootstrap";
//import AddButton from "../../Shared/AddButton";
//import Table from "react-bootstrap/Table";
import { useParams } from "react-router-dom"; //useHistory
import usePortfolioService from "../../Services/usePortfolioService";
import IndicatorTotals from "./IndicatorTotals";
import callApi from "../../Helpers/callApi";
//import EditIndicatorModal from "./EditIndicatorModal";
import Breadcrumbs from "../../Shared/Breadcrumbs";
import IndicatorSummaryComponent from "./IndicatorSummaryComponent";
import moment from "moment";
//import DisaggregationShow from "./DisaggregationShow";
//import { useAddToast } from "../../Context/ToastContext";
//import ContactOwnerForDeleteIndicator from "./ContactOwnerForDeleteIndicator";
import authService from "../../api-authorization/AuthorizeService";
import IndicatorTotalsForQualitative from "./IndicatorTotalsForQualitative";

export default function IndicatorSummary() {
  //const history = useHistory();
  //const addtoToast = useAddToast();
  //const [tabKey, setTabKey] = useState("tabular");
  const [indicator, setIndicator] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [downloadText, setDownloadText] = useState(
    "Export record level data (includes all periods and disaggregation)"
  );
  //const [allResults, setAllResults] = useState([]);
  const [disaggregations, setDisaggregations] = useState([]);
  const [indicatorTotals, setIndicatorTotals] = useState({});
  const { portfolioId, indicatorId } = useParams();
  const { getPortfolio, getIndicator } = usePortfolioService(); //getDisaggregations
  const [indicatorSummary, setIndicatorSummary] = useState([]);
  const [resultsOwnedInThisPortfolio, setResultsOwnedInThisPortfolio] =
    useState([]);
  const [sharedInThisPortfolio, setSharedInThisPortfolio] = useState([]);
  //const [uncheckedResults, setUncheckedResults] = useState([]);

  const [portfolio, setPortfolio] = useState({});
  const [allPeriods, setAllPeriods] = useState([]);
  const [allFinancialYears, setAllFinancialYears] = useState([]);
  const [allCalendarYears, setAllCalendarYears] = useState([]);
  const [uniquePeriodTitles, setUniquePeriodTitles] = useState([]);

  /*const [exportUrl, setexportUrl] = useState(    
  );*/

  const exportUrl = `api/portfolios/${portfolioId}/exportIndicator/${indicatorId}`;

  const [spinnerState, setSpinnerState] = useState("none");
  /*useEffect(() => {
    setexportUrl(`api/portfolios/${portfolioId}/exportIndicator/${indicatorId}`);
  }, [exportUrl, portfolioId, indicatorId]);*/

  const downloadData = async () => {
    setDisabled(true);
    setSpinnerState("inline-block");
    setDownloadText("Downloading..");
    const token = await authService.getAccessToken();
    const options = {
      headers: {
        method: "GET",
      },
    };
    options.headers.Authorization = `Bearer ${token}`;
    await fetch(exportUrl, options).then((response) => {
      setDisabled(false);
      setSpinnerState("none");
      setDownloadText(
        "Export record level data (includes all periods and disaggregation)"
      );
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.target = "_BLANK";
        a.rel = "noopener noreferrer";
        a.href = url;
        a.download = `exported-data.csv`;
        a.click();
      });
      //window.location.href = response.url;
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await getPortfolio(portfolioId);
      setPortfolio(response.data);
    };

    fetchData();
  }, [portfolioId, getPortfolio]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getIndicator(indicatorId, portfolioId);
      setIndicator(response.data.indicatorViewModel);
      setDisaggregations(response.data.disaggregations);
    };

    fetchData();
  }, [indicatorId, getIndicator, portfolioId]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await callApi(
        `indicators/${indicatorId}/totalswiththeportfoliocontributingresults/${portfolioId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const totalResponse = await response.json();
        totalResponse.totalLoaded = true;
        setIndicatorTotals(totalResponse);
      }
    };
    fetchData();
  }, [indicatorId, portfolioId]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await callApi(
        `portfolios/${portfolioId}/summaryresults/${indicatorId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const jsonResponse = await response.json();
        setAllPeriods(jsonResponse.periods);
        setUniquePeriodTitles(jsonResponse.uniquePeriodTitle);
        setAllFinancialYears(jsonResponse.financialYears);
        setAllCalendarYears(jsonResponse.calendarYears);
        //setAllResults(jsonResponse.indicatorSummary);
        const ownedPortfolioResults = jsonResponse.indicatorSummary.filter(
          (x) => x.activity.originatingPortfolioTitle === portfolio.title
        );
        const sharedResults = jsonResponse.indicatorSummary.filter(
          (x) => x.activity.originatingPortfolioTitle !== portfolio.title
        );

        setIndicatorSummary(jsonResponse.indicatorSummary);
        setResultsOwnedInThisPortfolio(ownedPortfolioResults);
        setSharedInThisPortfolio(sharedResults);
      }
    };

    fetchData();
  }, [indicatorId, portfolioId, portfolio.title]);

  const breadcrumbItems = [
    {
      path: `/${portfolioId}/indicators`,
      label: portfolio ? portfolio.title : "Indicators",
    },
    {
      path: `/${portfolioId}/indicators/${indicatorId}`,
      label: indicator ? indicator.title : "Indicator",
    },
    { label: "available reporting summary" },
  ];

  const changeHandler = async (event, paramResult, activity) => {
    const newResult = resultsOwnedInThisPortfolio.map((activityItem, i) => {
      if (activityItem.activity.id === activity.activity.id) {
        let modifiedResults = activityItem.results;
        modifiedResults = modifiedResults.map((resultItem, resultIndex) => {
          if (resultItem.id === paramResult.id) {
            resultItem.isSelected = !resultItem.isSelected;

            return resultItem;
          }

          return resultItem;
        });

        return { ...activityItem, results: modifiedResults };
      } else {
        return activityItem;
      }
    });
    setResultsOwnedInThisPortfolio(newResult);
    /*
    resultsOwnedInThisPortfolio.map((activityItem, i) => {
      if (activityItem.activity.id === activity.activity.id) {
        let modifiedResults = activityItem.results;
        modifiedResults = modifiedResults.map((resultItem, resultIndex) => {
          if (resultItem.id === paramResult.id) {
            resultItem.isSelected = !resultItem.isSelected;

            return resultItem;
          }

          return resultItem;
        });

        return { ...activityItem, results: modifiedResults };
      }
      else {
        return activityItem;
      }
    });*/
  };

  const changeHandlerForShared = async (event, paramResult, activity) => {
    const newResult = sharedInThisPortfolio.map((activityItem, i) => {
      if (activityItem.activity.id === activity.activity.id) {
        let modifiedResults = activityItem.results;
        modifiedResults = modifiedResults.map((resultItem, resultIndex) => {
          if (resultItem.id === paramResult.id) {
            resultItem.isSelected = !resultItem.isSelected;

            return resultItem;
          }

          return resultItem;
        });

        return { ...activityItem, results: modifiedResults };
      } else {
        return activityItem;
      }
    });
    setSharedInThisPortfolio(newResult);
    /*
    resultsOwnedInThisPortfolio.map((activityItem, i) => {
      if (activityItem.activity.id === activity.activity.id) {
        let modifiedResults = activityItem.results;
        modifiedResults = modifiedResults.map((resultItem, resultIndex) => {
          if (resultItem.id === paramResult.id) {
            resultItem.isSelected = !resultItem.isSelected;

            return resultItem;
          }

          return resultItem;
        });

        return { ...activityItem, results: modifiedResults };
      }
      else {
        return activityItem;
      }
    });*/
  };

  const addTargetValue = (period) => {
    let sum = 0;
    for (let i = 0; i < period.records.length; i++) {
      sum = sum + period.records[i].targetValue;
    }

    if (sum % 1 !== 0) {
      sum = sum.toFixed(2);
    }

    return sum;
  };

  const addAchievedValue = (period) => {
    let sum = 0;
    for (let i = 0; i < period.records.length; i++) {
      sum = sum + period.records[i].value;
    }

    if (sum % 1 !== 0) {
      sum = sum.toFixed(2);
    }

    return sum;
  };

  const resultTotalTargetValue = (result) => {
    let sum = 0;
    let counter = 0;
    let periods = allPeriods.filter((x) => x.resultId === result.id);

    for (let i = 0; i < periods.length; i++) {
      for (let j = 0; j < periods[i].records.length; j++) {
        sum = sum + periods[i].records[j].targetValue;
        counter = counter + 1;
      }
    }

    if (indicator.aggregationMethod === "average") {
      if (sum > 0) {
        sum = sum / counter;
      }
    }

    if (sum % 1 !== 0) {
      sum = sum.toFixed(2);
    }

    return sum;
  };

  const totalTargetValueForAllPeriods = (result) => {
    let sum = 0;
    let counter = 0;
    /*for (let i = 0; i < allPeriods.length; i++) {
      for (let j = 0; j < allPeriods[i].records.length; j++) {
        sum = sum + allPeriods[i].records[j].targetValue;
      }
    }*/
    for (const element of indicatorSummary) {
      for (let k = 0; k < element.results.length; k++) {
        if (element.results[k].isSelected) {
          for (let i = 0; i < element.periods.length; i++) {
            if (element.periods[i].resultId === element.results[k].id) {
              for (let j = 0; j < element.periods[i].records.length; j++) {
                sum = sum + element.periods[i].records[j].targetValue;
                counter = counter + 1;
              }
            }
          }
        }
      }
    }

    if (indicator.aggregationMethod === "average") {
      if (sum > 0) {
        sum = sum / counter;
      }
    }

    if (sum % 1 !== 0) {
      sum = sum.toFixed(2);
    }

    return sum;
  };

  const totalAchievedValueForAllPeriods = (result) => {
    let sum = 0;
    let counter = 0;
    /*for (let i = 0; i < allPeriods.length; i++) {
      for (let j = 0; j < allPeriods[i].records.length; j++) {
        sum = sum + allPeriods[i].records[j].targetValue;
      }
    }*/
    for (const element of indicatorSummary) {
      for (let k = 0; k < element.results.length; k++) {
        if (element.results[k].isSelected) {
          for (let i = 0; i < element.periods.length; i++) {
            if (element.periods[i].resultId === element.results[k].id) {
              for (let j = 0; j < element.periods[i].records.length; j++) {
                sum = sum + element.periods[i].records[j].value;
                counter = counter + 1;
              }
            }
          }
        }
      }
    }

    if (indicator.aggregationMethod === "average") {
      if (sum > 0) {
        sum = sum / counter;
      }
    }

    if (sum % 1 !== 0) {
      sum = sum.toFixed(2);
    }

    return sum;
  };

  const resultTotalAchievedValue = (result) => {
    let sum = 0;
    let counter = 0;
    let periods = allPeriods.filter((x) => x.resultId === result.id);

    for (let i = 0; i < periods.length; i++) {
      for (let j = 0; j < periods[i].records.length; j++) {
        sum = sum + periods[i].records[j].value;
        counter = counter + 1;
      }
    }

    if (indicator.aggregationMethod === "average") {
      if (sum > 0) {
        sum = sum / counter;
      }
    }

    if (sum % 1 !== 0) {
      sum = sum.toFixed(2);
    }

    return sum;
  };

  const totalOfAllTargetValueOfThisPeriod = (period) => {
    let sum = 0;
    let counter = 0;

    for (const element of indicatorSummary) {
      for (let k = 0; k < element.results.length; k++) {
        if (element.results[k].isSelected) {
          for (let i = 0; i < element.periods.length; i++) {
            if (
              moment(element.periods[i].start).format("DD/MM/YYYY") +
              "-" +
              moment(element.periods[i].end).format("DD/MM/YYYY") ===
              period &&
              element.results[k].id === element.periods[i].resultId
            ) {
              for (let j = 0; j < element.periods[i].records.length; j++) {
                sum = sum + element.periods[i].records[j].targetValue;
                counter = counter + 1;
              }
            }
          }
        }
      }

      /*for (let i = 0; i < element.periods.length; i++) {
        if (element.periods[i].id === period.id) {
          for (let j = 0; j < element.periods[i].records.length; j++) {
            sum = sum + element.periods[i].records[j].targetValue;
          }
        }
      }*/
    }

    if (indicator.aggregationMethod === "average") {
      if (sum > 0) {
        sum = sum / counter;
      }
    }

    if (sum % 1 !== 0) {
      sum = sum.toFixed(2);
    }

    return sum;
  };

  const totalOfAllArchievedValueOfThisPeriod = (period) => {
    let sum = 0;
    let counter = 0;

    for (const element of indicatorSummary) {
      for (let k = 0; k < element.results.length; k++) {
        if (element.results[k].isSelected) {
          for (let i = 0; i < element.periods.length; i++) {
            if (
              moment(element.periods[i].start).format("DD/MM/YYYY") +
              "-" +
              moment(element.periods[i].end).format("DD/MM/YYYY") ===
              period &&
              element.results[k].id === element.periods[i].resultId
            ) {
              for (let j = 0; j < element.periods[i].records.length; j++) {
                sum = sum + element.periods[i].records[j].value;
                counter = counter + 1;
              }
            }
          }
        }
      }

      /*for (let i = 0; i < element.periods.length; i++) {
        if (element.periods[i].id === period.id) {
          for (let j = 0; j < element.periods[i].records.length; j++) {
            sum = sum + element.periods[i].records[j].targetValue;
          }
        }
      }*/
    }

    if (indicator.aggregationMethod === "average") {
      if (sum > 0) {
        sum = sum / counter;
      }
    }

    if (sum % 1 !== 0) {
      sum = sum.toFixed(2);
    }

    return sum;
  };

  return (
    <>
      <div className="page-title">
        <div className="title">
          <Breadcrumbs items={breadcrumbItems} />
          <h1 className="h1">Portfolio Data Breakdown</h1>
        </div>
      </div>

      {indicator.isPublic ? (
        <Badge className="badge inline-block success">
          Public
        </Badge>
      ) : (
        <>
          <Badge className="badge inline-block success mb-1">Private</Badge>
          <p>
            <small className="small-text">
              This indicator will not be searchable in other portfolios
            </small>
          </p>
        </>
      )}

      <p className="description mb-1">Description</p>
      <p style={{ whiteSpace: "pre-line" }}>{indicator.description}</p>

      <div className="indicator-totals mb-4">
        {indicator.typeId === 2 && (
          <IndicatorTotals indicator={indicatorTotals} />
        )}
        {indicator.typeId === 1 && (
          <IndicatorTotalsForQualitative allPeriods={allPeriods} />
        )}

        <div
          className="icon-and-text mt-3"
          onClick={() => downloadData()}
          disabled={disabled}
        ><i class="icon bi bi-cloud-download"></i>
          {downloadText}
        </div>

        <Spinner
          style={{ display: spinnerState }}
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />

      </div>

      <IndicatorSummaryComponent
        indicator={indicator}
        portfolio={portfolio}
        allPeriods={allPeriods}
        uniquePeriodTitles={uniquePeriodTitles}
        resultsOwnedInThisPortfolio={resultsOwnedInThisPortfolio}
        changeHandler={changeHandler}
        addTargetValue={addTargetValue}
        addAchievedValue={addAchievedValue}
        resultTotalTargetValue={resultTotalTargetValue}
        resultTotalAchievedValue={resultTotalAchievedValue}
        totalOfAllArchievedValueOfThisPeriod={
          totalOfAllArchievedValueOfThisPeriod
        }
        totalOfAllTargetValueOfThisPeriod={totalOfAllTargetValueOfThisPeriod}
        totalTargetValueForAllPeriods={totalTargetValueForAllPeriods}
        totalAchievedValueForAllPeriods={totalAchievedValueForAllPeriods}
        sharedInThisPortfolio={sharedInThisPortfolio}
        changeHandlerForShared={changeHandlerForShared}
        disaggregations={disaggregations}
        allFinancialYears={allFinancialYears}
        allCalendarYears={allCalendarYears}
      ></IndicatorSummaryComponent>
    </>
  );
}
