import React from "react";
import ListGroup from "react-bootstrap/ListGroup";

export default function SearchEvidenceList(props) {
  const { evidences, onCopyIndicator, searchTerm } = props;

  const highlight = (text) => {
    const parts = text.split(new RegExp(`(${searchTerm})`, "gi"));
    return (
      <>
        {parts.map((p) => {
          if (p.toLowerCase() === searchTerm.toLowerCase()) {
            return <mark className="p-0">{p}</mark>;
          }
          return p;
        })}
      </>
    );
  };

  return (
    <>
      {evidences.length ? (
        <ListGroup className="mt-4">
          {evidences.map((i) => (
            <ListGroup.Item>
              <div className="d-flex w-100 justify-content-between">
                <h4 className="h4">{highlight(i.title)}</h4>
                <div
                  className="icon-and-text"
                  onClick={() => onCopyIndicator(i)}
                >
                  <i className="icon bi bi-plus-circle"></i>
                  Add to portfolio
                </div>
              </div>
              <span className="description">Summary</span>
              <p>{i.summary}</p>
              <span className="description">Comments</span>
              <p>{i.comments}</p>
            </ListGroup.Item>
          ))}
        </ListGroup>
      ) : (
        <p className="mt-4">
          {!searchTerm || searchTerm.length === 0
            ? "Enter a search term to find existing evidence"
            : "No evidence found"}
        </p>
      )}
    </>
  );
}
