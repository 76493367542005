import React, { useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import IndicatorForm from "./IndicatorForm";
import Button from "react-bootstrap/Button";

export default function EditIndicatorModal({
  onSubmit,
  onHide,
  isOpen,
  onChangeIndicatorProperty,
  indicator,
}) {
  const form = useRef(null);
  const [isValidated, setIsValidated] = useState(false);

  const handleSubmit = () => {
    if (form.current.checkValidity() === true) {
      onSubmit();
      setIsValidated(false);
      onHide();
    } else {
      setIsValidated(true);
    }
  };

  return (
    <Modal show={isOpen} onHide={onHide} size="lg">
      <Modal.Header>
        <Modal.Title>Edit Indicator</Modal.Title>
        <button
          type="button"
          className="btn-close btn-close-white"
          aria-label="Close"
          onClick={onHide}
        ></button>
      </Modal.Header>
      <Modal.Body>
        <div className="alert warning">
          Editing this indicator will update it everywhere it is currently used.
          Including historical results.
        </div>
        <IndicatorForm
          ref={form}
          indicatorBeingEdited={indicator}
          validated={isValidated}
          onSetIndicatorTitle={(title) =>
            onChangeIndicatorProperty("title", title)
          }
          onSetIndicatorDescription={(description) =>
            onChangeIndicatorProperty("description", description)
          }
          onSetIndicatorMethodology={(methodology) =>
            onChangeIndicatorProperty("methodology", methodology)
          }
          onSetIndicatorType={(type) => onChangeIndicatorProperty("type", type)}
          onSetIndicatorUnits={(units) =>
            onChangeIndicatorProperty("units", units)
          }
          onSetIndicatorDocumentLink={(documentLink) =>
            onChangeIndicatorProperty("documentLink", documentLink)
          }
          onSetIndicatorAggregationMethod={(aggregationMethod) =>
            onChangeIndicatorProperty("aggregationMethod", aggregationMethod)
          }
          onSetIndicatorIsPublic={(isPublic) =>
            onChangeIndicatorProperty("isPublic", isPublic)
          }
        />
      </Modal.Body>
      <Modal.Footer>
        <Button className="button primary" onClick={handleSubmit}>
          Save
        </Button>
        <Button className="button tertiary" onClick={onHide}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
