import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

export default function AddToGroupOptionModal(props) {
  const {
    isOpen,
    onHide,
    onCreateResultButtonClick,
    onExistingResultButtonClick,
  } = props;

  const handleHide = () => {
    onHide();
  };

  const handleAddResultModal = () => {
    onCreateResultButtonClick();
  };

  const handleExistingResultModal = () => {
    onExistingResultButtonClick();
  };

  return (
    <Modal show={isOpen} onHide={handleHide} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Button variant="primary" onClick={handleAddResultModal}>
          Add result(s) to new group
        </Button>
        <div>
          <p>
            A new result will be created for the result in the portfolio
            activity
          </p>
        </div>
        <Button variant="primary" onClick={handleExistingResultModal}>
          Add result(s) as members of existing result or group
        </Button>
        <div>
          <p>
            Results will be added as members of the chosen result, the result
            will remain in current activity
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}
