import authService from "../api-authorization/AuthorizeService";
import useSWR from "swr";

// To be used externally when mutating data via the API
export default async function callApi(path = "", options = { headers: {} }) {
  const token = await authService.getAccessToken();
  options.headers.Authorization = `Bearer ${token}`;
  const response = await fetch(`/api/${path}`, options);

  // Redirect if the user is trying to fetch a restricted resource
  if (
    (!options.method || options.method === "GET") &&
    response.status === 401
  ) {
    window.location = "/unauthorised";
  }
  if (
    (!options.method || options.method === "GET") &&
    response.status === 404
  ) {
    window.location = "/not-found";
  }

  return response;
}

export async function callApiAnonymous(path = "", options = { headers: {} }) {
  const response = await fetch(`/api/${path}`, options);
  return response;
}

const SWRfetcher = (...args) => callApi(...args).then((res) => res.json());

// To be used externally when fetching data from the API
export function useSWRApi(path) {
  const { data, error } = useSWR(path, SWRfetcher);
  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
}

export function useSWRApiForOneTime(path) {
  const { data, error } = useSWR(path, SWRfetcher, {
    //dedupingInterval: 360000,
    //refreshInterval: 360000,
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
}
