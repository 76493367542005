import React, { useState } from "react";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import AggregatedGraphs from "./AggregatedGraphs";
import AggregatedTable from "./AggregatedTables";

export default function AggregatedData({ uniqueDisaggregationValues, period }) {
  const [key, setKey] = useState("tabular");

  if (period.records.length === 0 || !uniqueDisaggregationValues) return null;

  const uniqueDisaggregations = uniqueDisaggregationValues.reduce(
    (acc, cur) => {
      if (!acc.includes(cur.disaggregation)) {
        acc.push(cur.disaggregation);
        return acc;
      }
      return acc;
    },
    []
  );

  let aggregatedData = {};
  uniqueDisaggregations.forEach((ud) => {
    const relevantDisaggregationValues = uniqueDisaggregationValues.filter(
      (udv) => udv.disaggregation === ud
    );
    aggregatedData[ud] = relevantDisaggregationValues.map((rdv) => ({
      disaggregationValue: rdv.value,
      value: period.records.reduce((acc, record) => {
        if (
          record.disaggregationValues &&
          record.disaggregationValues.some((recdv) => recdv.id === rdv.id)
        ) {
          acc += record.value;
        }
        return acc;
      }, 0),
      targetValue: period.records.reduce((acc, record) => {
        if (
          record.disaggregationValues &&
          record.disaggregationValues.some((recdv) => recdv.id === rdv.id)
        ) {
          acc += record.targetValue;
        }
        return acc;
      }, 0),
    }));
  });

  return (
    <Tab.Container id="aggregated-data-tabs" defaultActiveKey="tabular">
      <div className="d-flex justify-content-center mt-3 ">
        <Nav
          variant="pills"
          className="border rounded p-2"
          activeKey={key}
          onSelect={setKey}
        >
          <Nav.Item>
            <Nav.Link eventKey="tabular">Tabular</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="graphical">Graphical</Nav.Link>
          </Nav.Item>
        </Nav>
      </div>
      <Tab.Content>
        <Tab.Pane eventKey="graphical">
          <AggregatedGraphs aggregatedData={aggregatedData} />
        </Tab.Pane>
        <Tab.Pane eventKey="tabular">
          <AggregatedTable aggregatedData={aggregatedData} />
        </Tab.Pane>
      </Tab.Content>
    </Tab.Container>
  );
}
