import React, { useState, useEffect } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { useParams, useHistory } from "react-router-dom";
import ResultList from "./ResultList";
import TheoryOfChange from "./TheoryOfChange/TheoryOfChange";
import UpdateLog from "./UpdateLog";
import Breadcrumbs from "../Shared/Breadcrumbs";
import Settings from "./Settings";
import RelationList from "./Relations/RelationList";
import ExportData from "./ExportData/ExportData";
import ImportActivityData from "./ImportActivityData";
import useActivityOwnerStatus from "../Hooks/useActivityOwnerStatus";
import usePortfolioOwnerStatus from "../Hooks/usePortfolioOwnerStatus";
import ChangeRequestList from "./ChangeRequestList";
import usePortfolioService from "../Services/usePortfolioService";
import useActivityService from "../Services/useActivityService";
import { useSWRApi } from "../Helpers/callApi";

export default function ActivityDetails() {
  const { portfolioId, activityId, tabKey } = useParams();
  const history = useHistory();

  let { data: resultsData, isLoading: isLoadingResults } = useSWRApi(
    `activities/${activityId}/results/${portfolioId}`
  );

  let results = resultsData ? resultsData : [];

  useEffect(() => {
    if (!tabKey) {
      history.replace(`/${portfolioId}/activities/${activityId}/results`);
    }
  }, [history, portfolioId, activityId, tabKey]);

  const [portfolio, setPortfolio] = useState({});
  const [activity, setActivity] = useState({});
  const isUserActivityOwner = useActivityOwnerStatus();
  const isUserPortfolioOwner = usePortfolioOwnerStatus();
  const { getPortfolio, getActivityForPortfolio } = usePortfolioService();
  const { getActivity } = useActivityService();

  const handleRefreshActivity = async () => {
    const response = await getActivity(activityId);
    setActivity(response.data);
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await getActivityForPortfolio(portfolioId, activityId);
      setActivity(response.data);
    };
    fetchData();
  }, [activityId, portfolioId, getActivityForPortfolio]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getPortfolio(portfolioId);
      setPortfolio(response.data);
    };

    fetchData();
  }, [portfolioId, getPortfolio]);

  const breadcrumbItems = [
    { path: `/${portfolioId}`, label: portfolio.title },
    { label: activity.title },
  ];

  return (
    <>
      <div className="page-title">
        <div className="title">
          <Breadcrumbs items={breadcrumbItems} />
          <h1 className="h1">{portfolio.title}</h1>
        </div>
      </div>
      <div fluid={tabKey === "theory-of-change"}>
        <h2 className="h2">Activity: {activity.title}</h2>
        <p>{activity.description}</p>
        <Tabs
          activeKey={tabKey}
          onSelect={(tabKey) => history.push(`${tabKey}`)}
          id="activity-page-tabs"
        >
          <Tab eventKey="results" title="Results">
            <ResultList
              activity={activity}
              isUserActivityOwner={isUserActivityOwner}
              isUserPortfolioOwner={isUserPortfolioOwner}
            />
          </Tab>
          <Tab eventKey="theory-of-change" title="Theory of Change">
            {tabKey === "theory-of-change" && (
              <TheoryOfChange
                portfolioId={portfolioId}
                isLoadingResults={isLoadingResults}
                results={results}
              />
            )}
          </Tab>
          <Tab eventKey="relations" title="Relations">
            <RelationList activityId={activityId} portfolioId={portfolioId} />
          </Tab>
          <Tab eventKey="export-data" title="Export Data">
            <ExportData activityId={activityId} />
          </Tab>
          <Tab eventKey="import-data" title="Import Data">
            <ImportActivityData
              portfolio={portfolio}
              activity={activity}
              activityId={activityId}
              onSuccessfulUpload={handleRefreshActivity}
            />
            <ChangeRequestList
              portfolio={portfolio}
              activity={activity}
              onApprove={handleRefreshActivity}
            />
          </Tab>
          {(isUserActivityOwner || isUserPortfolioOwner) &&
            !activity.isShared && (
              <Tab eventKey="settings" title="Settings">
                <Settings />
              </Tab>
            )}
          {(isUserActivityOwner || isUserPortfolioOwner) &&
            !activity.isShared && (
              <Tab eventKey="update-log" title="Update Log">
                {tabKey === "update-log" && <UpdateLog />}
              </Tab>
            )}
        </Tabs>
      </div>
    </>
  );
}
