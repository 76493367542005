import React, { useState } from "react";
import { useSWRApi } from "../../Helpers/callApi";
import ResultsList from "./ResultsList";

export default function ResultsPage({ portfolioId }) {
  const pageSize = 20;
  const [page, setPage] = useState(1);
  const [searchString, setSearchString] = useState("");
  let { data: resultsData, isLoading: isLoadingResults } = useSWRApi(
    `portfolios/${portfolioId}/results?page=${page}&pageSize=${pageSize}&searchString=${searchString}`
  );

  let totalResultCount = resultsData ? resultsData.totalItemCount : 0;
  let results = resultsData ? resultsData.items : [];

  return (
    <>
      <ResultsList
        portfolioId={portfolioId}
        results={results}
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        totalResultCount={totalResultCount}
        setSearchString={setSearchString}
        isLoading={isLoadingResults}
      />
    </>
  );
}
