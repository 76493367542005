import React from "react";
import ListGroup from "react-bootstrap/ListGroup";

const ManageEvidencePopover = React.forwardRef((props, ref) => {
  return (
    <div className="actions margin-left-auto">
      {props.removeVisible === false &&
        props.isFromNestingOrSharedActivity === false && (
          <div className="icon-and-text" onClick={props.onClickDelete}>
            <i className="icon bi bi-trash"></i>Delete
          </div>
        )}
      {props.removeVisible === false &&
        props.isFromNestingOrSharedActivity === false && (
          <div
            className="icon-and-text has-left-border"
            onClick={props.onClickEdit}
          >
            <i className="icon bi bi-pencil-square"></i>
            Edit
          </div>
        )}
      {props.isUserPortfolioOwner === true &&
        props.removeVisible === false &&
        props.isFromNestingOrSharedActivity === false && (
          <div
            className="icon-and-text has-left-border"
            onClick={props.onClickShare}
          >
            <i className="icon bi bi-share"></i>
            Share
          </div>
        )}
      {props.removeVisible === true && (
        <>
          <div
            className="icon-and-text has-left-border"
            onClick={props.onClickRemove}
          >
            <i className="icon bi bi-trash"></i>
            Remove
          </div>
          <div
            className="icon-and-text has-left-border"
            onClick={props.onClickGotoHome}
          >
            <i className="icon bi bi-arrow-right-circle"></i>
            Home Portfolio
          </div>
        </>
      )}
      {props.isFromNestingOrSharedActivity === true && (
        <>
          {props.isPublic ? (
            <ListGroup.Item action onClick={props.onClickAddToPortfolio}>
              Add to portfolio
            </ListGroup.Item>
          ) : (
            <ListGroup.Item action onClick={props.onClickAskToShare}>
              Ask owner to share
            </ListGroup.Item>
          )}
        </>
      )}
    </div>
  );
});

export default ManageEvidencePopover;
