import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import IndicatorInformation from "./IndicatorInformation";
import Spinner from "react-bootstrap/Spinner";
import PeriodList from "./PeriodList";
import Breadcrumbs from "../Shared/Breadcrumbs";
import { mutate } from "swr";
import AddPeriodModal from "./AddPeriodModal";
import EditPeriodModal from "./EditPeriodModal";
import callApi, { useSWRApi } from "../Helpers/callApi";
import QuantativePeriodListItem from "./QuantativePeriodListItem";
import QualitativePeriodListItem from "./QualitativePeriodListItem";
import useActivityOwnerStatus from "../Hooks/useActivityOwnerStatus";
import usePortfolioOwnerStatus from "../Hooks/usePortfolioOwnerStatus";
//import useActivityNestedViewerStatus from "../Hooks/useActivityNestedViewerStatus";
import usePortfolioService from "../Services/usePortfolioService";
import useResultService from "../Services/useResultService";

export default function IndicatorDetails() {
  const { portfolioId, activityId, resultId, indicatorId } = useParams();
  const [selectedPeriodId, setSelectedPeriodId] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [portfolio, setPortfolio] = useState({});
  const [activity, setActivity] = useState({});
  const [indicator, setIndicator] = useState({});
  const { data: periods, isLoading: isLoadingPeriods } = useSWRApi(
    `results/${resultId}/indicators/${indicatorId}/periods/${portfolioId}`
  );
  const [isAddPeriodModalOpen, setIsAddPeriodModalOpen] = useState(false);
  const [isEditPeriodModalOpen, setIsEditPeriodModalOpen] = useState(false);
  const isUserActivityOwner = useActivityOwnerStatus();
  const isUserPortfolioOwner = usePortfolioOwnerStatus();
  //const isUserActivityNestedViewer = useActivityNestedViewerStatus();
  const { getPortfolio } = usePortfolioService();
  const { addPeriod } = useResultService();

  useEffect(() => {
    const fetchData = async () => {
      const response = await getPortfolio(portfolioId);
      setPortfolio(response.data);
    };

    fetchData();
  }, [portfolioId, getPortfolio]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await callApi(
        `portfolios/${portfolioId}/activities/${activityId}`
      );
      const data = await response.json();
      setActivity(data);
    };

    fetchData();
  }, [portfolioId, activityId]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await callApi(
        `indicators/${indicatorId}/GetIndicatorDetails/${portfolioId}`
      );
      const data = await response.json();
      setIndicator(data);
    };

    fetchData();
  }, [indicatorId, portfolioId]);

  const breadcrumbItems = [
    { path: `/${portfolioId}`, label: portfolio.title },
    { path: `/${portfolioId}/activities/${activityId}`, label: activity.title },
    { label: indicator.title },
  ];

  const handleCreateNewPeriod = async (
    period,
    shouldCreateRecordCombinations
  ) => {
    const response = await addPeriod(
      resultId,
      indicatorId,
      period,
      shouldCreateRecordCombinations
    );

    if (response.ok) {
      mutate(
        `results/${resultId}/indicators/${indicatorId}/periods/${portfolioId}`
      );
    }
  };

  const handleUpdateRecord = (selectedPeriodId, record) => {
    mutate(
      `results/${resultId}/indicators/${indicatorId}/periods/${portfolioId}`,
      (prevState) =>
        prevState.map((p) => {
          if (p.id === selectedPeriodId) {
            return {
              ...p,
              records: p.records.map((r) => {
                if (r.id === record.id) {
                  return record;
                }
                return r;
              }),
            };
          }
          return p;
        })
    );
  };

  const handleDeleteRecord = (selectedPeriodId, record) => {
    mutate(
      `results/${resultId}/indicators/${indicatorId}/periods/${portfolioId}`,
      (prevState) =>
        prevState.map((p) => {
          if (p.id === selectedPeriodId) {
            return {
              ...p,
              records: p.records.filter((r) => r.id !== record.id),
            };
          }
          return p;
        })
    );
  };

  const handleAddRecord = (selectedPeriodId, record) => {
    mutate(
      `results/${resultId}/indicators/${indicatorId}/periods/${portfolioId}`,
      (prevState) =>
        prevState.map((p) => {
          if (p.id === selectedPeriodId) {
            return {
              ...p,
              records: [...p.records, record],
            };
          }
          return p;
        })
    );
  };

  const handleDeletePeriod = (period) => {
    mutate(
      `results/${resultId}/indicators/${indicatorId}/periods/${portfolioId}`,
      (prevState) =>
        prevState.filter((p) => {
          return p.id !== period.id;
        })
    );

    setSelectedPeriodId(null);
  };

  const handleUpdatePeriodComments = async (period, comments) => {
    const response = await callApi(`periods/${period.id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify([
        {
          op: "replace",
          path: "/comments",
          value: comments,
        },
      ]),
    });

    if (response.ok) {
      mutate(
        `results/${resultId}/indicators/${indicatorId}/periods/${portfolioId}`
      );
    }
  };

  const handleOnEditPeriod = (period) => {
    setSelectedPeriod(period);
    setIsEditPeriodModalOpen(true);
  };

  const handleEditPeriodModalClose = () => {
    setIsEditPeriodModalOpen(false);
    setSelectedPeriod(null);
  };

  const handleEditPeriodModalSubmit = async (period) => {
    const response = await callApi(`periods/${selectedPeriodId}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify([
        {
          op: "replace",
          path: "/title",
          value: period.title,
        },
        {
          op: "replace",
          path: "/narrative",
          value: period.narrative,
        },
        {
          op: "replace",
          path: "/start",
          value: period.start,
        },
        {
          op: "replace",
          path: "/end",
          value: period.end,
        },
        {
          op: "replace",
          path: "/isAggregatable",
          value: period.isAggregatable,
        },
        {
          op: "replace",
          path: "/documentLink",
          value: period.documentLink,
        },
      ]),
    });

    if (response.ok) {
      mutate(
        `results/${resultId}/indicators/${indicatorId}/periods/${portfolioId}`
      );
      setIsEditPeriodModalOpen(false);
      setSelectedPeriod(null);
    }
  };

  return (
    <>
      <div className="page-title">
        <div className="title">
          <Breadcrumbs items={breadcrumbItems} />
          <h1 className="h1">Indicator Details</h1>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-9">
            {selectedPeriodId === null && (
              <IndicatorInformation indicator={indicator} />
            )}
            {selectedPeriodId !== null &&
              (indicator.typeId === 1 ? (
                <QualitativePeriodListItem
                  key={selectedPeriodId}
                  isSharedActivity={activity.isShared}
                  isUserActivityOwner={isUserActivityOwner}
                  isUserPortfolioOwner={isUserPortfolioOwner}
                  onUpdateRecord={(record) =>
                    handleUpdateRecord(selectedPeriodId, record)
                  }
                  onAddRecord={(record) =>
                    handleAddRecord(selectedPeriodId, record)
                  }
                  onDeleteRecord={(record) =>
                    handleDeleteRecord(selectedPeriodId, record)
                  }
                  onEditPeriod={(period) => handleOnEditPeriod(period)}
                  onDeletePeriod={handleDeletePeriod}
                  indicatorId={indicatorId}
                  period={periods.find((p) => p.id === selectedPeriodId)}
                  onUpdatePeriodComments={handleUpdatePeriodComments}
                  portfolioId={portfolioId}
                />
              ) : indicator.typeId === 2 ? (
                <QuantativePeriodListItem
                  key={selectedPeriodId}
                  isSharedActivity={activity.isShared}
                  isUserActivityOwner={isUserActivityOwner}
                  isUserPortfolioOwner={isUserPortfolioOwner}
                  onUpdateRecord={(record) =>
                    handleUpdateRecord(selectedPeriodId, record)
                  }
                  onAddRecord={(record) =>
                    handleAddRecord(selectedPeriodId, record)
                  }
                  onDeleteRecord={(record) =>
                    handleDeleteRecord(selectedPeriodId, record)
                  }
                  onEditPeriod={(period) => handleOnEditPeriod(period)}
                  onDeletePeriod={handleDeletePeriod}
                  onUpdatePeriodComments={handleUpdatePeriodComments}
                  indicatorId={indicatorId}
                  period={periods.find((p) => p.id === selectedPeriodId)}
                  units={indicator.units}
                  portfolioId={portfolioId}
                />
              ) : null)}
          </div>
          <div className="col-md-3">
            <div className="periods">
              {isLoadingPeriods ? (
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              ) : (
                <>
                  <h2 className="h2 mb-3">Periods</h2>
                  {(isUserActivityOwner || isUserPortfolioOwner) &&
                    !activity.isShared && (
                      <div
                        className="icon-and-text mt-1 mb-4"
                        onClick={() => setIsAddPeriodModalOpen(true)}
                      >
                        <i class="icon bi-plus-circle"></i>
                        Add Period
                      </div>
                    )}

                  {selectedPeriodId && selectedPeriodId && (
                    <div
                      className="icon-and-text has-left-border mb-4"
                      onClick={() => setSelectedPeriodId(null)}
                      active={selectedPeriodId === null}
                    >
                      <i class="icon bi bi-card-list"></i>
                      Indicator Details
                    </div>
                  )}
                  <PeriodList
                    periods={periods}
                    selectedPeriodId={selectedPeriodId}
                    onSelectPeriodId={(periodId) =>
                      setSelectedPeriodId(periodId)
                    }
                    indicatorTypeId={indicator.typeId}
                    indicatorAggregationMethod={indicator.aggregationMethod}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <AddPeriodModal
        isOpen={isAddPeriodModalOpen}
        onHide={() => setIsAddPeriodModalOpen(false)}
        onSubmit={handleCreateNewPeriod}
        indicatorTypeId={indicator.typeId}
      />
      {selectedPeriod != null && (
        <EditPeriodModal
          isOpen={isEditPeriodModalOpen}
          onHide={handleEditPeriodModalClose}
          onSubmit={handleEditPeriodModalSubmit}
          period={selectedPeriod}
        />
      )}
    </>
  );
}
