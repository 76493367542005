import React, { useState } from 'react';

const Collapsible = (props) => {
    const [open, setOpen] = useState(false);
    const toggle = () => {
        setOpen(!open);
    };

    return (
        <div className={props.className}>
            <div className={props.className + "-title"}>
                <button className={`collapsible-button ${open ? "open" : ""}`} onClick={toggle}>{props.label}</button>
                {props.badge}
            </div>
            {open &&
                <div className="collapsible-body">{props.children}</div>
            }
        </div>
    );
};

export default Collapsible;