import React, { useState } from "react";
import format from "../Helpers/formatNumber";
import DeleteRecordModal from "./DeleteRecordModal";

export default function QuantativeRecordValue({
  record,
  onUpdateRecord,
  onDeleteRecord,
  onBlur,
  isBeingEdited,
  onEdit,
  isSharedActivity,
  onEditRecordDisaggregationClick,
}) {
  const [isEditingActual, setIsEditingActual] = useState(false);
  const [isEditingTarget, setIsEditingTarget] = useState(false);
  const [editedValue, setEditedValue] = useState(0);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (isEditingActual) {
        record.value = editedValue;
        onUpdateRecord(record);
      }
      if (isEditingTarget) {
        record.targetValue = editedValue;
        onUpdateRecord(record);
      }
      handleBlur();
    }
  };

  const handleClickActual = () => {
    if (isSharedActivity) return;

    setIsEditingActual(true);
    setEditedValue(record.value);
    onEdit();
  };

  const handleClickTarget = () => {
    if (isSharedActivity) return;

    setIsEditingTarget(true);
    setEditedValue(record.targetValue);
    onEdit();
  };

  const handleBlur = () => {
    setIsEditingActual(false);
    setIsEditingTarget(false);
    setEditedValue(0);
    onBlur();
  };

  const handleDeleteClick = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteSubmit = () => {
    onDeleteRecord(record);
  };

  const handleEditRecordClick = () => {
    onEditRecordDisaggregationClick();
  };

  return isBeingEdited ? (
    <>
      {isEditingActual && (
        <td colSpan="2">
          <span className="px-2 text-secondary">Press Enter to submit</span>
        </td>
      )}
      <td>
        <input
          className="textbox w-100"
          type="number"
          autoFocus={true}
          onFocus={(e) => e.target.select()}
          onKeyDown={handleKeyDown}
          onChange={(e) => setEditedValue(parseFloat(e.target.value))}
          onBlur={handleBlur}
          value={editedValue}
          data-testid="input"
        />
      </td>
      {isEditingTarget && (
        <td colSpan="2">
          <span className="pl-2 text-secondary">Press Enter to submit</span>
        </td>
      )}
    </>
  ) : (
    <>
      <td
        onClick={handleClickTarget}
        className="mr-3 w-100"
        style={{ borderBottom: "1px dotted #6c757d" }}
      >
        {format(record.targetValue)}
      </td>
      <td
        onClick={handleClickActual}
        className="w-100"
        style={{ borderBottom: "1px dotted #6c757d" }}
      >
        {format(record.value)}
      </td>
      <td align="right">
        <div className="edit-delete-record">
          {!isSharedActivity && (
            <div className="icon-and-text" onClick={handleEditRecordClick}>
              <i className="icon bi bi-pencil-square"></i>
              Edit
            </div>
          )}
          {!isSharedActivity && (
            <div data-testid="delete" className="icon-and-text mt-2" onClick={handleDeleteClick}>
              <i className="icon bi bi-trash"></i>
              Delete
            </div>
          )}
        </div>
        <DeleteRecordModal
          isOpen={isDeleteModalOpen}
          onHide={() => setIsDeleteModalOpen(false)}
          onSubmit={handleDeleteSubmit}
        />
      </td>
    </>
  );
}
