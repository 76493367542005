import React from "react";
import Button from "react-bootstrap/Button";

const svgButton = React.forwardRef((props, ref) => (
  <Button {...props} ref={ref}>
    {props.children}
  </Button>
));

export default svgButton;
